import {removeSessionInfoProperty, setSessionInfoProperty} from '../../utils/session'
import {Actions} from './actions'
import {HubContextType} from './context'

export const hubReducer = (state: HubContextType, action: Actions): HubContextType => {
  const newState = {
    ...state
  }

  switch (action.type) {
    case 'SET_CHAIN':
      return {
        ...state,
        chain: action.chain
      }
    case 'SET_IS_LEDGER':
      if (!action.isLedger) {
        removeSessionInfoProperty('isLedger')
      } else {
        setSessionInfoProperty('isLedger', action.isLedger)
      }


      return {
        ...state,
        isLedger: action.isLedger
      }
    case 'SET_REVENUE_SHARING':
      return {
        ...state,
        revenueSharing: action.revenueSharing
      }
    case 'SET_TOKENS':
      if (!action.force && state.pauseRefreshTokens) return state

      return {
        ...state,
        tokens: action.tokens
      }
    case 'ADD_TOKENS':
      if (!newState.tokens) {
        newState.tokens = {
          yaah: 0,
          yooh: 0
        }
      }

      if (action.tokens?.yooh) {
        newState.tokens.yooh = (newState.tokens.yooh ?? 0) + action.tokens.yooh
      }

      if (action.tokens?.yaah) {
        newState.tokens.yaah = (newState.tokens.yaah ?? 0) + action.tokens.yaah
      }

      return newState
    case 'SET_TWITTER_USERNAME':
      if (!action.twitterUsername) {
        removeSessionInfoProperty('twitterUsername')
      } else {
        setSessionInfoProperty('twitterUsername', action.twitterUsername)
      }

      return {
        ...state,
        twitterUsername: action.twitterUsername
      }
    case 'SET_USERNAME':
      if (!action.username) {
        removeSessionInfoProperty('username')
      } else {
        setSessionInfoProperty('username', action.username)
      }

      return {
        ...state,
        username: action.username
      }
    case 'SET_SESSION_TOKEN':
      if (!action.sessionToken) {
        removeSessionInfoProperty('sessionToken')
      } else {
        setSessionInfoProperty('sessionToken', action.sessionToken)
      }

      return {
        ...state,
        sessionToken: action.sessionToken
      }
    case 'SET_PUBLIC_KEY':
      if (action.publicKey === undefined) {
        removeSessionInfoProperty('publicKey')
      } else {
        setSessionInfoProperty('publicKey', action.publicKey)
      }

      return {
        ...state,
        publicKey: action.publicKey
      }
    case 'SET_ENABLE_QUEST_TUTO':
      return {
        ...state,
        questTutoEnabled: action.enabled
      }
    case 'SET_ROOM':
      return {
        ...state,
        room: action.room
      }
    case 'SET_ROOM_METADATA':
      return {
        ...state,
        roomMetadata: action.roomMetadata
      }
    case 'SET_ROOM_SESSION':
      if (!action.roomSession) {
        removeSessionInfoProperty('roomSession')
      } else {
        setSessionInfoProperty('roomSession', action.roomSession)
      }

      return {
        ...state,
        roomSession: action.roomSession
      }
    case 'SET_DISCONNECT_WALLET':
      return {
        ...state,
        disconnectWallet: action.disconnectWallet
      }
    case 'SET_CONSENT':
      if (action.consent === undefined) {
        removeSessionInfoProperty('consent')
      } else {
        setSessionInfoProperty('consent', action.consent)
      }

      return {
        ...state,
        consent: action.consent
      }
    case 'SET_CHAT_OPEN':
      return {
        ...state,
        chatOpen: action.chatOpen
      }
    case 'SET_SHOW_WALLET':
      return {
        ...state,
        showWallet: action.showWallet
      }
    case 'SET_SHOW_CONNECT_MODAL':
      return {
        ...state,
        showConnectModal: action.showConnectModal
      }
    case 'SET_SHOW_PROFILE_MODAL':
      return {
        ...state,
        showProfileModal: action.showProfileModal,
        profileUuid: action.userUuid
      }
    case 'SET_PFP':
      if (action.pfp === undefined) {
        removeSessionInfoProperty('pfp')
      } else {
        setSessionInfoProperty('pfp', action.pfp)
      }

      return {
        ...state,
        pfp: action.pfp
      }
    case 'SET_USER_UUID':
      if (action.userUuid === undefined) {
        removeSessionInfoProperty('userUuid')
      } else {
        setSessionInfoProperty('userUuid', action.userUuid)
      }

      return {
        ...state,
        userUuid: action.userUuid
      }
    case 'SET_REFERRAL':
      if (action.referral === undefined) {
        removeSessionInfoProperty('referral')
      } else {
        setSessionInfoProperty('referral', action.referral)
      }

      return {
        ...state,
        referral: action.referral
      }
    case 'SET_ACTIVATED_REFERRAL':
      if (action.activatedReferral === undefined) {
        removeSessionInfoProperty('activatedReferral')
      } else {
        setSessionInfoProperty('activatedReferral', action.activatedReferral)
      }

      return {
        ...state,
        activatedReferral: action.activatedReferral
      }
    case 'SET_REFERRAL_TO_BE_CLAIMED':
      return {
        ...state,
        referralToBeClaimed: action.toBeClaimed
      }
    case 'SET_RACKBACK_AVAILABLE':
      return {
        ...state,
        rackbackAvailable: action.rackbackAvailable
      }
    case 'SET_ROLE':
      if (action.role === undefined) {
        removeSessionInfoProperty('role')
      } else {
        setSessionInfoProperty('role', action.role)
      }

      return {
        ...state,
        role: action.role
      }
    case 'SET_PAUSE_REFRESH_TOKENS': {
      return {
        ...state,
        pauseRefreshTokens: action.pauseRefreshTokens
      }
    }
    default:
      return state
  }
}