import {Howl} from 'howler'
import {CDN_URL} from '../../../utils/constants'

export const clickAffirmativeSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-2.mp3`],
  volume: 0.15,
  preload: true
})

export const coinsSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-coin.mp3`],
  volume: 0.2,
  preload: true
})

export const selectSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-3.mp3`],
  volume: 0.2,
  preload: true
})

export const selectSoundRepeat = selectSoundBombz

export const clickNegativeSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-negative.mp3`],
  volume: 0.2,
  preload: true
})

export const winSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/win_nomusic_nopirate.mp3`],
  volume: 0.20,
  preload: true
})

export const loseSoundBombz = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/losing.mp3`],
  volume: 0.2,
  preload: true
})