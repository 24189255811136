import {State} from './types'

export const bubbleState: State = {
  loading: false,
  exited: false,
  particles: {},
  references: {
    ui: {
      level: {},
      worldScreen: {}
    }
  },
  external: {}
}