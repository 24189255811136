@import "scss-variables";

.wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  font-size: 32px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 4rem;
    max-width: 300px;
    text-align: center;

    .purple {
      color: $color-blue;
    }

    button {
      width: 100%;
      text-align: center;
      font-family: "Baloo Thambi";
      font-size: 18px;
      font-weight: 400;
      justify-content: center;
    }

    .change-button {
      margin-top: 1rem;
    }

    .solana-button {
      border-radius: 0.5rem;
      background-color: $color-blue;
    }
  }
}
