@import "scss-variables";

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  font-family: "Riffic";
  font-size: 24px;
  z-index: 500;

  .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 21;
  }

  .container {
    z-index: 22;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    &.full-size {
      width: 100%;
      height: 100%;
    }

    &.image {
      img {
        width: 50%;
        max-width: 300px;
      }
    }
  }

  &.no-blur {
    backdrop-filter: none;
  }

  @media screen and (min-width: $sm-breakpoint) {
    font-size: 32px;
  }

  &.loading-modal {
    z-index: 19;
  }

  .loading-content {
    color: white;
    text-shadow: 4px 3px 0 #444444;
    text-align: center;

    z-index: 20;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    max-height: 400px;
    max-width: 480px;
    background-color: $color-gray;
    border-radius: 1rem;
    box-shadow: black 0px 8px 24px;
    word-wrap: break-word;
    font-size: 22px;
    font-family: "Baloo Thambi";

    .title {
      padding: 1rem;
      border-bottom: 1px solid $color-lighten-gray;
      text-align: center;

      img,
      svg {
        height: 12px;
        margin: 0 0.2rem;
      }
    }

    .text {
      padding: 1rem;
      flex: 1;
      font-size: 1rem;

      .input {
        width: 100%;
      }

      img,
      svg {
        height: 10px;
        margin: 0 0.2rem;
      }
    }

    .footer {
      display: flex;
      padding: 1rem;

      button {
        width: 100%;

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ button {
          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
