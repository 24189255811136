// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {Schema, type} from '@colyseus/schema'
import {LevelSchema} from './LevelSchema'

export class SoloRoomState extends Schema {
  @type(LevelSchema) public level: LevelSchema = new LevelSchema();
}
