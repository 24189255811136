import {Emitter} from '@pixi/particle-emitter'
import {AxiosRequestConfig, AxiosResponse} from 'axios'
import {Room} from 'colyseus.js'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {Application, Container, LoaderResource} from 'pixi.js'

export type RevealData = {
  title: string
  active: boolean
  image: string
  width: number
  height: number
  rows: number
  columns: number
  priceToReveal: number
  chunks: {
    [chunkId: number]: Chunk
  }
  history?: {
    chunkId: number
    user: string
  }[]
}

export type Chunk = {
  revealed: boolean
  top: number
  left: number
  width: number
  height: number
  treasure: ITreasure
}

export type TreasureRarity = 'common' | 'rare' | 'epic' | 'legendary'
export type TreasureType = 'NFT' | 'WL' | 'YAAH'

export interface ITreasure<I = unknown> {
  name: string
  rarity: TreasureRarity
  type: TreasureType
  claimed?: boolean
  info?: I
}

export type TreasureNFT = ITreasure<{
  address: string
}>

export type TreasureWL = ITreasure

export type TreasureYAAH = ITreasure<{
  quantity: number
}>

export const isNFTTreasure = (treasure: ITreasure): treasure is TreasureNFT => {
  return (treasure as TreasureNFT).type === 'NFT'
}

export const isWLTreasure = (treasure: ITreasure): treasure is TreasureWL => {
  return (treasure as TreasureWL).type === 'WL'
}

export const isYAAHTreasure = (treasure: ITreasure): treasure is TreasureYAAH => {
  return (treasure as TreasureYAAH).type === 'YAAH'
}


export type State = {
  app?: Application
  viewport?: Viewport
  resources?: PIXI.utils.Dict<LoaderResource>
  loading?: boolean
  exited?: boolean
  dragged?: boolean
  particles: {
    container?: Container
    emitters?: Emitter[]
  }
  references: {
    image?: Container
  }
  external: {
    onNotEnoughTokens?: () => void
    onTreasureHistory?: (history: {
      winner: string,
      treasure: ITreasure
    }[]) => void
    onWLTreasureWon?: (revealId: string, chunkId: number) => void
    showToast?: (type?: 'info' | 'warn' | 'error' | 'success', message?: string, treasure?: ITreasure) => void
    showModal?: (props: {
      title: string
      text?: string
      copyText?: string
      image?: string
      closeText?: string
      onClose?: () => void
    }) => void
    showConfirm?: (props: {
      title: string
      text?: string
      image?: string
      acceptText?: string
      refuseText?: string
      onAccept?: () => void
      onRefuse?: () => void
    }) => void
    showLoading?: (loading: boolean, progress?: number, loadingText?: string) => void
    refreshTokens?: () => Promise<{
      yaah?: number
      yooh?: number
    }>
    //setTokens?: (tokens: {
    //  yaah: number
    //  yooh: number
    //}) => void
    clearGame?: () => void
    tryOrReconnect?: <T = object>(requestConfig: AxiosRequestConfig, onError?: ((err: unknown) => void) | undefined) => Promise<AxiosResponse<T, any> | undefined>
    sessionToken?: string
  },
  room?: Room
}