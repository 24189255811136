import {sound} from '@pixi/sound'
import {SoundName} from './enums'
import {bubbleState} from './state'
import {BUBBLE_CDN_URL} from './utils/constants'

export const playMusic = (soundName: SoundName) => {
  Object.entries(SoundName).filter((entry) => entry[0].includes('music') && entry[0] !== soundName).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })

  const currentMusic = sound.find(soundName)

  if (!currentMusic.isPlaying && currentMusic.isLoaded) currentMusic.play()
}

export const stopAllMusicAndAmbience = () => {
  Object.entries(SoundName).filter((entry) => entry[0].includes('music') || entry[0].includes('ambience')).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })
}

export const playAmbience = (soundName: SoundName) => {
  Object.entries(SoundName).filter((entry) => entry[0].includes('ambience') && entry[0] !== soundName).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })

  const currentMusic = sound.find(soundName)

  if (!currentMusic.isPlaying && currentMusic.isLoaded) currentMusic.play()
}

export const playPower = (soundName: SoundName) => {
  Object.entries(SoundName).filter((entry) => entry[0].includes('power') && entry[0] !== soundName).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })

  const currentPower = sound.find(soundName)

  if (!currentPower.isPlaying && currentPower.isLoaded) currentPower.play()
}

export const playCombo = (soundName: SoundName) => {
  Object.entries(SoundName).filter((entry) => entry[0].includes('combo') && entry[0] !== soundName).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })

  const currentCombo = sound.find(soundName)

  if (!currentCombo.isPlaying && currentCombo.isLoaded) currentCombo.play()
}

export const randomBubblePop = () => {
  const choices = [
    SoundName.bubblePop1,
    SoundName.bubblePop2,
    SoundName.bubblePop3,
  ]

  return sound.find(choices[Math.floor(Math.random() * choices.length)])
}

export const randomBubblePopNeutral = () => {
  const choices = [
    SoundName.bubblePopNeutral1,
    SoundName.bubblePopNeutral2,
    SoundName.bubblePopNeutral3,
    SoundName.bubblePopNeutral4,
  ]

  return sound.find(choices[Math.floor(Math.random() * choices.length)])
}

export const randomPirateThrowBubble = () => {
  const choices = [
    SoundName.pirateThrowBubble1,
    SoundName.pirateThrowBubble2
  ]

  return sound.find(choices[Math.floor(Math.random() * choices.length)])
}

export const randomCannonFire = () => {
  const choices = [
    SoundName.cannonFire1
  ]

  return sound.find(choices[Math.floor(Math.random() * choices.length)])
}

export const loadSounds = (onProgress?: (progress: number) => void, onComplete?: () => void) => {
  const nbSounds = Object.keys(SoundName).length
  let soundLoaded = 0

  const loaded = (error: Error) => {
    if (bubbleState.exited) return

    if (error && !bubbleState.exited && bubbleState.external.clearGame) {
      bubbleState.exited = true

      bubbleState.external.clearGame()

      if (bubbleState.external.showLoading) bubbleState.external.showLoading(false)

      if (bubbleState.external.showModal) bubbleState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the sound resources, please check your internet connection and refresh the page'
      })
    }

    ++soundLoaded

    if (soundLoaded === nbSounds && onComplete) {
      onComplete()
    } else if (onProgress) {
      onProgress((soundLoaded / nbSounds) * 100)
    }
  }

  loadMusics(loaded)
  loadAmbiences(loaded)
  loadBubblePop(loaded)
  loadCombos(loaded)
  loadPirate(loaded)
  loadCannon(loaded)
  loadEffects(loaded)
  loadUI(loaded)
}

const loadMusics = (loaded: (error: Error) => void) => {
  sound.add(SoundName.music1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/musics/loop1.mp3',
    volume: 0.14,
    loop: true,
    loaded
  })

  sound.add(SoundName.music2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/musics/loop2.mp3',
    volume: 0.03,
    loop: true,
    loaded
  })

  sound.add(SoundName.music3, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/musics/loop3.mp3',
    volume: 0.04,
    loop: true,
    loaded
  })

  sound.add(SoundName.music4, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/musics/loop4.mp3',
    volume: 0.07,
    loop: true,
    loaded
  })

  sound.add(SoundName.music5, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/musics/loop5.mp3',
    volume: 0.10,
    loop: true,
    loaded
  })
}

const loadAmbiences = (loaded: (error: Error) => void) => {
  sound.add(SoundName.ambience1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ambiences/ambience_no-whistle.mp3',
    volume: 0.2,
    loop: true,
    loaded
  })

  sound.add(SoundName.ambience2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ambiences/ambience-4_no-whistle_no-battle.mp3',
    volume: 0.2,
    loop: true,
    loaded
  })
}


const loadBubblePop = (loaded: (error: Error) => void) => {
  sound.add(SoundName.bubblePopNeutral1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/neutral-1.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/neutral-2.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral3, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/neutral-3.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral4, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/neutral-4.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePop1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/pop-1.mp3',
    volume: 0.3,
    loaded
  })

  sound.add(SoundName.bubblePop2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/pop-2.mp3',
    volume: 0.3,
    loaded
  })

  sound.add(SoundName.bubblePop3, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/bubbles/pop-3.mp3',
    volume: 0.3,
    loaded
  })
}


const loadCombos = (loaded: (error: Error) => void) => {
  sound.add(SoundName.combo1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/combos/combo-1.mp3',
    volume: 0.4,
    loaded
  })

  sound.add(SoundName.combo2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/combos/combo-2.mp3',
    volume: 0.4,
    loaded
  })

  sound.add(SoundName.combo3, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/combos/combo-3.mp3',
    volume: 0.4,
    loaded
  })
}


const loadPirate = (loaded: (error: Error) => void) => {
  sound.add(SoundName.pirateThrowBubble1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/pirates/throw-bubble-1.mp3',
    volume: 0.25,
    speed: 1.2,
    loaded
  })

  sound.add(SoundName.pirateThrowBubble2, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/pirates/throw-bubble-2.mp3',
    volume: 0.25,
    speed: 1.2,
    loaded
  })
}


const loadCannon = (loaded: (error: Error) => void) => {
  sound.add(SoundName.cannonLoadBubble, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/cannon/load-bubble.mp3',
    volume: 0.08,
    loaded
  })

  sound.add(SoundName.cannonFire1, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/cannon/fire-1.mp3',
    volume: 0.4,
    loaded
  })
}


const loadEffects = (loaded: (error: Error) => void) => {
  sound.add(SoundName.powerBomb, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/powers/bomb.mp3',
    loaded
  })

  sound.add(SoundName.powerCoin, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/powers/coin.mp3',
    volume: 0.35,
    loaded
  })

  sound.add(SoundName.powerHeart, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/powers/heart.mp3',
    volume: 0.3,
    loaded
  })

  sound.add(SoundName.powerHammer, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/powers/hammer.mp3',
    volume: 0.3,
    loaded
  })

  sound.add(SoundName.powerFire, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/powers/fire.mp3',
    volume: 0.4,
    speed: 1.5,
    loaded
  })
}


const loadUI = (loaded: (error: Error) => void) => {
  sound.add(SoundName.pauseButton, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/pause-button.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.musicToggle, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/music-toggle.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.resumeButton, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/resume-button.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.restartButton, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/restart-button-2.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.homeButton, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/home-button.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.zeroStar, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/losing.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.oneStar, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/win-3_nomusic.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.twoStars, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/win-3_nopirate.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.threeStars, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/game/win-3.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.mainMenuPlay, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/menus/main_start-button_nomusic.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.selectLevel, {
    preload: true,
    url: BUBBLE_CDN_URL + '/sounds/ui/menus/map_select-level_nomusic_nopirate.mp3',
    volume: 0.2,
    loaded
  })
}