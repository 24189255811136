@import "scss-variables";

.loading-overlay {
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  left: 0;
  top: 0;

  &.blur {
    backdrop-filter: blur(10px);
  }

  svg {
    color: $color-blue;
  }
}
