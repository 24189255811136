import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {HUB_CDN_URL} from '../../../../../utils/constants'
import {formatClassName} from '../../../../../utils/global'
import {ButtonIcon} from '../../../../components/buttons/button'
import {AppCard} from '../../../../components/cards/app-card'
import {useHubContext} from '../../../../state/context'
import styles from './home.module.scss'

export type HomeProps = HtmlHTMLAttributes<HTMLDivElement>

export const Home = ({className, ...props}: HomeProps) => {
  const {dispatch} = useHubContext()
  const navigate = useNavigate()

  const slide1ref = useRef<HTMLDivElement>(null)

  const date = new Date()
  date.setUTCHours(23, 59, 59, 999)

  const [endDailyQuest, setEndDailyQuest] = useState(date)

  useEffect(() => {
    if (!slide1ref.current) return

    slide1ref.current.style.backgroundImage = 'url(\'https://storage.googleapis.com/hub-cdn/marketing/banners/slots.jpg\')'
  }, [slide1ref])

  const openWallet = () => {
    dispatch({
      type: 'SET_SHOW_WALLET',
      showWallet: true
    })
  }

  return (
    <div className={formatClassName(styles, `home ${className}`)} {...props}>
      <div className={formatClassName(styles, 'banner')}>
        <div ref={slide1ref} className={formatClassName(styles, 'slide')}></div>
        <div className={formatClassName(styles, 'description')}>
          <div className={formatClassName(styles, 'title')}>Slots Released!</div>
          <div className={formatClassName(styles, 'text')}>
            spin & win!
          </div>
          <ButtonIcon icon="gamepad" className={formatClassName(styles, 'cta')} onClick={() => navigate('/dashboard/games/slots')}>play</ButtonIcon>
        </div>
        <div className={formatClassName(styles, 'shadow')}></div>
      </div>
      {/*<div className={formatClassName(styles, 'repetitive-quest')}>
        <div className={formatClassName(styles, 'info')}>
          <div className={formatClassName(styles, 'name')}>Daily Rewards</div>
          <div className={formatClassName(styles, 'description')}>
            of <YOOH /> 220<a href="#" onClick={() => navigate('/dashboard/rewards')}>more info</a>
          </div>
        </div>
        <div className={formatClassName(styles, 'date')}>
          <FlipDate date={endDailyQuest} showLabels={false} onEnded={() => {
            const date = new Date()
            date.setUTCHours(24, 0, 0, 0)

            setEndDailyQuest(date)
          }} />
        </div>
      </div>*/}
      {/*<div className={formatClassName(styles, 'repetitive-quest')}>
        <div className={formatClassName(styles, 'info')}>
          <div className={formatClassName(styles, 'name')}>Daily Rewards</div>
          <div className={formatClassName(styles, 'description')}>
            of <YOOH /> 220<a href="#" onClick={() => navigate('/dashboard/quests')}>more info</a>
          </div>
        </div>
        <div className={formatClassName(styles, 'date')}>
          <FlipDate date={endDailyQuest} showLabels={false} onEnded={() => {
            const date = new Date()
            date.setUTCHours(24, 0, 0, 0)

            setEndDailyQuest(date)
          }} />
        </div>
      </div>*/}
      <div className={formatClassName(styles, 'have-fun')}>
        <FontAwesomeIcon icon='gamepad' />
        <span>Start having fun!</span>
      </div>
      {/*<TwitterTweetButton
        text="It's a test!"
        url='https://storage.googleapis.com/hub-cdn/marketing/banners/slots.jpg'
      >
        Tweet
      </TwitterTweetButton>*/}
      <div className={formatClassName(styles, 'grid')}>
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/slots.jpg`} onClick={() => navigate('/dashboard/games/slots')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/bombz.png`} onClick={() => navigate('/dashboard/games/bombz')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/baddie-beast.png`} onClick={() => navigate('/dashboard/games/baddie-beast')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/abc.png`} onClick={() => navigate('/dashboard/games/abc')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/bubble.png`} onClick={() => navigate('/dashboard/games/bubble')} labelType="skill" />
      </div>
    </div>
  )
}