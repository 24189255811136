import {Connection, PublicKey, TokenAmount} from '@solana/web3.js'

export const USDC_MINT_ADDRESS = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')
export const SOL_MINT_ADDRESS = new PublicKey('So11111111111111111111111111111111111111112')
export const PIRATE_ATA_USDC_ADDRESS = new PublicKey('7ZhhEkCHRWXXFyPE69YgUqbc2ASvserBWVcPgDGt8Hoo')

export const getTokenAccountInfo = async (connection: Connection, ownerPublicKey: PublicKey, mint: PublicKey) => {
  const result = await connection.getParsedTokenAccountsByOwner(ownerPublicKey, {
    mint,
  })

  if (result.value.length === 1) {
    const tokenAmount = result.value[0].account.data.parsed.info.tokenAmount as TokenAmount
    const tokenAssociatedAccount = result.value[0].pubkey

    return {
      tokenAmount,
      tokenAssociatedAccount
    }
  } else {
    return
  }
}

export const walletAvailableTokens = {
  USDC: {
    name: 'USDC',
    mint: USDC_MINT_ADDRESS,
    decimals: 6
  },
  SOL: {
    name: 'SOL',
    mint: SOL_MINT_ADDRESS,
    decimals: 9
  }
}