@import "scss-variables";

.connect-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;

  .connect-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;

    img {
      width: auto;
    }

    .wallet {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      font-size: 32px;

      .install-wallet {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        font-family: "Riffic";
        font-size: 32px;
        max-width: 300px;

        small {
          font-size: 18px;
        }

        a {
          color: $color-blue;
          text-decoration: none;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 4rem;
        width: 100%;
        max-width: 350px;
        text-align: center;

        .purple {
          color: $color-blue;
        }

        button {
          width: 100%;
          text-align: center;
          font-family: "Baloo Thambi";
          font-size: 18px;
          font-weight: 400;
          justify-content: center;
        }

        .change-button {
          margin-top: 1rem;
        }

        .solana-button {
          border-radius: 0.5rem;
          background-color: $color-blue;
        }
      }
    }
  }

  .ledger-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: normal;
    gap: 0.5rem;
    user-select: none;
    cursor: pointer;

    .toggle {
      pointer-events: none;
    }
  }
}
