import copy from 'copy-to-clipboard'
import React, {ChangeEventHandler, ReactElement, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {CSSTransitionProps} from 'react-transition-group/CSSTransition'
import {formatClassName, formatTextWithYOOH} from '../../../utils/global'
import {Button} from '../buttons/button'
import {Input, InputProps} from '../input/input'
import styles from './modal.module.scss'

export type OverlayModalProps = Partial<CSSTransitionProps> & {
  children?: JSX.Element
  show?: boolean
  onClickBackground?: () => void
  containerClassName?: string
}

export type GenericModalProps = OverlayModalProps & {
  show?: boolean
  title?: string
  text?: string
  input?: ReactElement
  image?: string
  footer?: ReactElement
  zIndex?: number
  className?: string
}

export const OverlayModal = ({show, children, zIndex, className, onClickBackground, containerClassName, ...props}: OverlayModalProps) => {
  const modalRef = useRef(null)

  return (
    <CSSTransition
      in={show}
      nodeRef={modalRef}
      timeout={150}
      classNames={{
        enter: 'transition-enter',
        enterActive: 'transition-enter-active',
        exit: 'transition-exit',
        exitActive: 'transition-exit-active',
      }}
      unmountOnExit
      {...props}
    >
      <div ref={modalRef} className={formatClassName(styles, `modal ${className ? className : ''}`)} style={{zIndex}} >
        <div className={formatClassName(styles, 'background')} onClick={onClickBackground}></div>
        <div className={formatClassName(styles, `container ${containerClassName ? containerClassName : ''}`)}>
          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

export const GenericModal = ({
  image,
  title,
  text,
  input,
  footer,
  ...props
}: GenericModalProps) => <OverlayModal containerClassName={`full-size ${image ? 'image' : ''}`} {...props}>
    <>
      {image && <img src={image} />}
      <div className={formatClassName(styles, 'content')}>
        <div className={formatClassName(styles, 'title')}>{formatTextWithYOOH(title)}</div>
        <div className={formatClassName(styles, 'text')}>
          {formatTextWithYOOH(text)}
          {input && <div className={formatClassName(styles, 'input')}>{input}</div>}
        </div>
        <div className={formatClassName(styles, 'footer')}>
          {footer}
        </div>
      </div>
    </>
  </OverlayModal>

export type LoadingModalProps = OverlayModalProps & {
  progress?: number
  loadingText?: string
}

export const LoadingModal = ({
  progress,
  loadingText = 'Loading...',
  className,
  ...props
}: LoadingModalProps) => <OverlayModal {...props} >
    <div className={formatClassName(styles, `modal loading-modal ${className ?? ''}`)}>
      <div className={formatClassName(styles, 'loading-content')}>{loadingText}{progress !== undefined ? <><br />{`${progress}%`}</> : ''}</div>
    </div>
  </OverlayModal>

export type ModalProps = Omit<GenericModalProps, 'footer'> & {
  onClose: () => void
  closeText?: string
  copyText?: string
}

export const Modal = ({closeText = 'close', onClose, copyText, ...props}: ModalProps) => {
  const footer =
    <>
      {copyText &&
        <Button onClick={() => {
          const copied = copy(copyText)
          if (copied) {
            toast.success('Text copied!', {
              pauseOnFocusLoss: false
            })
          } else {
            toast.warn('Impossible to copy the text! Copy manually :-)', {
              pauseOnFocusLoss: false
            })
          }
        }}>copy</Button>
      }
      <Button className={formatClassName(styles, 'gray')} onClick={onClose}>{closeText}</Button>
    </>

  return <GenericModal
    footer={footer}
    {...props}
  />
}

export type ConfirmModalProps = Omit<GenericModalProps, 'footer'> & {
  onAccept: () => void
  onRefuse: () => void
  acceptText?: string
  refuseText?: string
}

export const ConfirmModal = ({acceptText = 'ok', refuseText, onAccept, onRefuse, ...props}: ConfirmModalProps) => {
  const footer =
    <>
      {refuseText && <Button className={formatClassName(styles, 'gray')} onClick={onRefuse}>{refuseText}</Button>}
      <Button onClick={onAccept}>{acceptText}</Button>
    </>

  return <GenericModal
    footer={footer}
    {...props}
  />
}

export type InputModalProps = Omit<GenericModalProps, 'footer'> & {
  onAccept: (value?: string) => void
  onRefuse: () => void
  inputProps?: InputProps
  acceptText?: string
  refuseText?: string
}

export const InputModal = ({inputProps, acceptText = 'ok', refuseText, onAccept, onRefuse, ...props}: InputModalProps) => {
  const [value, setValue] = useState('')

  const inputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value)
  }

  const footer =
    <>
      {refuseText && <Button className={formatClassName(styles, 'gray')} onClick={onRefuse}>{refuseText}</Button>}
      <Button onClick={() => onAccept(value)}>{acceptText}</Button>
    </>

  const input = <Input
    {...inputProps}
    className={formatClassName(styles, `${inputProps?.className} input`)}
    value={value}
    onChange={inputChange}
    autoFocus
  />

  return <GenericModal
    footer={footer}
    input={input}
    {...props}
  />
}