import React from 'react'
import {formatClassName} from '../../utils/global'
import {AptosWalletPage} from '../pages/wallets/aptos-wallet'
import {SolanaWalletPage} from '../pages/wallets/solana-wallet'
import {useHubContext} from '../state/context'
import styles from './wallet-layout.module.scss'

export const WalletLayout = () => {
  const {state: {chain}} = useHubContext()

  //if ((isAndroid || isIOS) && !isPhantomInstalled) {
  //  return <Navigate to="/install-wallet" />
  //}

  //if (!chain) {
  //  return <Navigate to="/select-chain" />
  //}

  let children

  if (chain) {
    switch (chain) {
      case 'solana':
        children = <SolanaWalletPage />
        break
      case 'aptos':
        children = <AptosWalletPage />
        break
    }
  }

  return (
    <div className={formatClassName(styles, 'wallet-layout')}>
      {children}
    </div>
  )
}
