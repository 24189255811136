import {revealState} from '../state'
import {RevealData} from '../types'

export const getReveal = async (id: string) => {
  if (!revealState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

  const response = await revealState.external.tryOrReconnect({
    method: 'GET',
    url: '/api/v1/app/reveal/',
    params: {
      id
    },
    responseType: 'arraybuffer'
  })

  if (!response || response.status !== 200) {
    console.error(`Impossible to find the reveal with id=${id}`,)

    return
  }

  const blob = new Blob(
    [response.data as BlobPart],
    {type: response.headers['content-type']}
  )
  const image = URL.createObjectURL(blob)

  return image
}

export const getRevealState = async (id: string) => {
  if (!revealState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

  const response = await revealState.external.tryOrReconnect<RevealData>({
    method: 'GET',
    url: '/api/v1/app/reveal/state/',
    params: {
      id
    }
  })

  return response
}
export const claimNFTTreasure = async (revealId: string, chunkId: number, onError?: (err: unknown) => void) => {
  if (!revealState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

  const response = await revealState.external.tryOrReconnect<{nft: any}>({
    url: '/api/v1/quests/claim-nft-raffle',
    method: 'POST',
    data: {
      revealId,
      chunkId
    }
  }, onError)

  return response?.data
}