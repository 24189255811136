import {Room} from 'colyseus.js'

export const revealMessage = (room: Room, chunkId: number) => {
  room.send('reveal', chunkId)
}

export const randomRevealMessage = (room: Room) => {
  room.send('random-reveal')
}

export const syncHistoryMessage = (room: Room) => {
  room.send('sync-history')
}

export const syncRevealMessage = (room: Room) => {
  room.send('sync-reveal')
}
