@import "scss-variables";

.history {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;

  .content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: $xs-breakpoint) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow: hidden;

      .header {
        text-align: center;
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        overflow-y: auto;

        img {
          width: auto;
          height: auto;
          max-height: 1rem;
        }
      }
    }
  }
}
