import React, {ReactElement, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {Flip, ToastContainer} from 'react-toastify'
import {getMaintenanceStatus, MaintenanceData} from '../../maintenance/maintenance'
import {formatClassName} from '../../utils/global'
import {hideMaintenance, maintenanceVisible} from '../../utils/session'
import {InputProps} from '../components/input/input'
import {ConfirmModal, InputModal, LoadingModal, Modal} from '../components/modal/modal'
import {useHub} from '../hooks/use-hub'
import {useHubContext} from '../state/context'
import {hubState} from '../state/hub'
import styles from './hub-layout.module.scss'

export const HubLayout = () => {
  const {disconnect} = useHub()
  const {dispatch} = useHubContext()
  const [ready, setReady] = useState(false)

  const [maintenance, setMaintenance] = useState<MaintenanceData>()

  const [modalState, setModalState] = useState<{
    show: boolean
    title?: string
    text?: string
    copyText?: string
    image?: string
    closeText?: string
    onClose?: () => void
  }>({
    show: false
  })

  const [confirmState, setConfirmState] = useState<{
    show: boolean
    title?: string
    text?: string | ReactElement
    image?: string
    acceptText?: string
    refuseText?: string
    onAccept?: (value?: string) => void
    onRefuse?: () => void
  }>({
    show: false
  })

  const [inputState, setInputState] = useState<{
    show: boolean
    title?: string
    text?: string
    image?: string
    inputProps?: InputProps
    acceptText?: string
    refuseText?: string
    onAccept?: (value?: string) => void
    onRefuse?: () => void
  }>({
    show: false
  })

  const [showLoading, setShowLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState<number | undefined>(0)
  const [loadingText, setLoadingText] = useState<string>()

  useEffect(() => {
    hubState.disconnect = disconnect

    hubState.showModal = ({title, text, copyText, image, closeText, onClose}) => {
      setShowLoading(false)
      setConfirmState({show: false})
      setInputState({show: false})

      setModalState({
        show: true,
        title,
        text,
        closeText,
        copyText,
        image,
        onClose
      })
    }

    hubState.showConfirm = ({title, text, image, acceptText, refuseText, onAccept, onRefuse}) => {
      setShowLoading(false)
      setModalState({show: false})
      setInputState({show: false})

      setConfirmState({
        show: true,
        title,
        text,
        image,
        acceptText,
        refuseText,
        onAccept,
        onRefuse
      })
    }

    hubState.showInputModal = ({title, text, image, inputProps, acceptText, refuseText, onAccept, onRefuse}) => {
      setShowLoading(false)
      setModalState({show: false})
      setConfirmState({show: false})

      setInputState({
        show: true,
        title,
        text,
        image,
        inputProps,
        acceptText,
        refuseText,
        onAccept,
        onRefuse
      })
    }

    hubState.showLoading = (loading, progress, loadingText) => {
      setShowLoading(loading)
      setLoadingProgress(progress)
      setLoadingText(loadingText)
    }

    hubState.setSessionToken = (sessionToken) => {
      dispatch({
        type: 'SET_SESSION_TOKEN',
        sessionToken
      })
    }
  }, [])

  useEffect(() => {
    const checkMaintenance = async () => {
      const result = await getMaintenanceStatus()

      if (!result) return false

      const service = 'hub'
      const maintenanceData = result[service]
      const {fromDate, toDate, id, message} = maintenanceData

      if (!fromDate || !toDate || !message) return false

      if (Date.now() >= new Date(fromDate).getTime() && Date.now() <= new Date(toDate).getTime() && hubState.showConfirm) {
        setMaintenance(result.hub)

        hubState.showConfirm({
          title: 'Website under maintenance',
          text: message.replace('${fromDate}', new Date(fromDate).toLocaleString()).replace('${toDate}', new Date(toDate).toLocaleString()),
          acceptText: 'go to website',
          onAccept() {
            window.location.href = 'https://piratesquadnft.com'
          }
        })

        return true
      } else if (maintenanceVisible(service, id) && Date.now() < new Date(fromDate).getTime() && hubState.showConfirm) {
        setMaintenance(undefined)

        hubState.showConfirm({
          title: 'Maintenance coming',
          text: message.replace('${fromDate}', new Date(fromDate).toLocaleString()).replace('${toDate}', new Date(toDate).toLocaleString()),
          acceptText: 'understood',
          onAccept() {
            hideMaintenance(service, id)
          }
        })

        return false
      }

      return false
    }

    const intervalMaintenance = setInterval(checkMaintenance, 60000)

    checkMaintenance().then(inMaintenance => {
      setReady(!inMaintenance)
    })

    return () => {
      clearInterval(intervalMaintenance)
    }
  }, [])

  return (
    <div className={formatClassName(styles, 'hub-layout')}>
      {
        ready && !maintenance && <Outlet />
      }
      <Modal
        {...modalState}
        onClose={() => {
          if (modalState.onClose) modalState.onClose()
          setModalState({show: false})
        }}
      />
      <ConfirmModal
        {...confirmState}
        onAccept={() => {
          if (confirmState.onAccept) confirmState.onAccept()
          setConfirmState({show: false})
        }}
        onRefuse={() => {
          if (confirmState.onRefuse) confirmState.onRefuse()
          setConfirmState({show: false})
        }}
      />
      <InputModal
        {...inputState}
        onAccept={(value?: string) => {
          if (inputState.onAccept) inputState.onAccept(value)
          setInputState({show: false})
        }}
        onRefuse={() => {
          if (inputState.onRefuse) inputState.onRefuse()
          setInputState({show: false})
        }}
      />
      <LoadingModal show={showLoading} progress={loadingProgress} loadingText={loadingText} />
      <ToastContainer toastClassName={formatClassName(styles, 'custom-toast')} theme='dark' transition={Flip} autoClose={3000} />
    </div>
  )
}
