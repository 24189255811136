import {BUBBLE_CDN_URL, initWorldScreen, Leaderboard} from 'pirate-squad-bubble'
import {LeaderboardEntry} from 'pirate-squad-bubble/src/types'
import React, {Fragment, HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../../utils/global'
import {ButtonIcon} from '../../../../../../../components/buttons/button'
import {useHubContext} from '../../../../../../../state/context'
import {hubState} from '../../../../../../../state/hub'
import styles from './classic-leaderboard.module.scss'

const formatRank = (rank: number) => {
  return rank === 1 ? '1st' : rank === 2 ? '2nd' : rank === 3 ? '3rd' : `${rank}th`
}

export type ClassicScoreProps = HtmlHTMLAttributes<HTMLDivElement> & {
  onClose: () => void
}

export const ClassicLeaderboard = ({className, onClose, ...props}: ClassicScoreProps) => {
  const {state: {room, roomMetadata}, dispatch} = useHubContext()
  const [leaderboardArray, setLeaderboardArray] = useState<LeaderboardEntry[]>([])

  const [rank, setRank] = useState<number>()
  const [ended, setEnded] = useState(false)

  if (ended) {
    dispatch({
      type: 'SET_ROOM_SESSION'
    })
  }

  useEffect(() => {
    if (!room) return

    room.onMessage<Leaderboard>('leaderboard', async (leaderboard) => {
      if (!leaderboard || !room) return

      const leaders = leaderboard

      setRank(leaders.findIndex((leader) => leader.clientId === room.sessionId) + 1)
      setEnded(!leaders.find((leader) => !leader.ended))

      setLeaderboardArray(leaders)
    })

    room.onMessage<{
      name: string
      reward: number
      fees: number
    }>('win', async ({name, reward}) => {
      if (hubState.showModal) {
        if (hubState.refreshTokens) {
          hubState.refreshTokens()
        }

        hubState.showModal({
          title: 'Congrats you won!',
          text: roomMetadata.reward ? `You won ${reward / 100} ${name}` : undefined,
          image: roomMetadata.reward ? BUBBLE_CDN_URL + '/ui/big-chest.png' : undefined
        })
      }
    })

    room.send('leaderboard')
  }, [room])

  const handleClose = () => {
    initWorldScreen()

    dispatch({
      type: 'SET_ROOM'
    })

    dispatch({
      type: 'SET_ROOM_METADATA'
    })

    dispatch({
      type: 'SET_ROOM_SESSION'
    })

    onClose()
  }

  const sameScoreWinner = ended && leaderboardArray.length >= 2 && leaderboardArray[0].score === leaderboardArray[1].score &&
    leaderboardArray[0].ended === leaderboardArray[1].ended &&
    leaderboardArray[0].won === leaderboardArray[1].won

  return (
    <div className={formatClassName(styles, `classic-leaderboard ${className}`)} {...props}>
      {
        room &&
        <div className={formatClassName(styles, 'leaderboard')}>
          <h2 className={formatClassName(styles, 'rank')}>
            {rank !== undefined
              ? ended
                ? rank === 1
                  ? 'You won!'
                  : 'You lost!'
                : `You are ${formatRank(rank)}`
              : '...'}
          </h2>

          <div className={formatClassName(styles, 'leaders')}>
            <div className={formatClassName(styles, 'leaders-grid')}>
              <div className={formatClassName(styles, 'header')}>Rank</div>
              <div className={formatClassName(styles, 'header')}>Player name</div>
              <div className={formatClassName(styles, 'header')}>Score</div>
              {
                leaderboardArray.map((leader, index) =>
                  <Fragment key={`leader-${index}`}>
                    <div>{formatRank(index + 1)}</div>
                    <div>{leader.name}</div>
                    <div>{
                      leader.left
                        ? 'Left'
                        : leader.ended
                          ? leader.won
                            ? (sameScoreWinner && index === 0)
                              ? `(fastest) ${leader.score}`
                              : leader.score
                            : leader.score
                          : 'playing'
                    }
                    </div>
                  </Fragment>
                )
              }
            </div>
          </div>
          {/*<div className={formatClassName(styles, 'timer')}>
            {showTimer
              ? <>
                <span>starting in <Countdown date={endDate} renderer={renderer} /></span>
              </>
              : <>
                {players.length === rooms.find((room) => room.roomId === selectedRoom.id)?.maxClients ? 'waiting for players to be ready' : 'waiting for other players to join'}
              </>
            }
          </div>*/}
          <div className={formatClassName(styles, 'actions')}>
            <ButtonIcon disabled={!ended} flip="horizontal" icon="right-from-bracket" className={formatClassName(styles, 'exit-button no-color')} onClick={handleClose}>
              {ended ? 'exit' : 'waiting for players to finish the game'}
            </ButtonIcon>
          </div>
        </div>
      }
    </div>
  )
}