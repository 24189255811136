// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {MapSchema, Schema, type} from '@colyseus/schema'
import {PlayerSchema} from './PlayerSchema'
import {SoloRoomState} from './SoloRoomState'

export class ClassicRoomState extends Schema {
  @type({map: SoloRoomState}) public soloRooms: MapSchema<SoloRoomState> = new MapSchema<SoloRoomState>();
  @type({map: PlayerSchema}) public players: MapSchema<PlayerSchema> = new MapSchema<PlayerSchema>();
  @type('number') public level!: number
  @type('boolean') public started!: boolean
  @type('boolean') public ended!: boolean
}
