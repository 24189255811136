import {sound} from '@pixi/sound'
import gsap, {Power1} from 'gsap'
import {ease} from 'pixi-ease'
import {SkeletonData, Spine} from 'pixi-spine'
import * as PIXI from 'pixi.js'
import {Container, Filter, Graphics, Point, Text, TextMetrics, TextStyle} from 'pixi.js'
import {destroyBubble, initBubble, randomBubbleSkin} from './bubble'
import {BubbleSkinName, SoundName} from './enums'
import {dropShadowYellow} from './filters'
import {goToNextLevel, nextLevelAccessible} from './game'
import {remainingBubblesToFire, resetLevel, rowColumnToCoord} from './level'
import {stopAllMusicAndAmbience} from './sound'
import {bubbleState} from './state'
import {getGameHeight, getGameWidth, getRatio, radialGradient} from './utils'
import {initWorldScreen} from './worldscreen'

export const initLevelUI = () => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.resources) return

  // Main container
  const topContainer = initGlobalContainer()

  if (!topContainer) return

  // Lives
  initLives(topContainer)

  if (bubbleState.mode === 'solo') {
    // Coin
    initCoin(topContainer)

    // Stars
    initStars(topContainer)
  } else {
    // Multi timer
    initTimer(topContainer)

    // Multi actual rank
    initRank(topContainer)
  }

  // Pause button
  initPauseButton(topContainer)

  // Init level limit
  initLevelLimit()

  // Init bubble count
  initBubbleCount()

  // Init waves left count
  initWavesLeftCount(topContainer)

  topContainer.zIndex = 20

  bubbleState.viewport.addChild(topContainer)

  bubbleState.references.topContainer = topContainer
}

const topBarHeight = 50

const initGlobalContainer = () => {
  if (!bubbleState.viewport || !bubbleState.level) return undefined

  const ratio = getRatio()

  const container = new Container()

  const graphics = new Graphics()
  graphics
    .beginFill(0xC69C6D)
    .lineStyle(8 * ratio, 0x42210B)
    .drawRoundedRect(0, 0, getGameWidth() - 70 * ratio, topBarHeight * ratio, topBarHeight * ratio)
    .endFill()

  container.addChild(graphics)

  container.x = 10 * ratio
  container.y = 10 * ratio

  bubbleState.references.ui.level.container = container

  return container
}

const initLives = (container: Container) => {
  if (!bubbleState.level || !bubbleState.resources) return undefined

  const ratio = getRatio()

  // Background count
  const textContainer = new Container()

  const textBackground = new Graphics()
  textBackground
    .beginFill(0x42210B)
    .lineStyle(4 * ratio, 0xFFF8D0)
    .drawRoundedRect(0, 0, 85 * ratio, 30 * ratio, 35 * ratio)
    .endFill()

  textContainer.addChild(textBackground)

  textContainer.y = container.height / 2 - textBackground.height / 2 - 2 // -2 to take border in account

  container.addChild(textContainer)

  // Text

  const counterText = new Text('', new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFF8D0,
    fontSize: 22 * ratio
  }))

  counterText.y = 2 * ratio

  bubbleState.references.ui.level.heartCount = counterText

  textContainer.addChild(counterText)

  // Spine heart object

  const heart = new Spine(bubbleState.resources.heart.spineData as SkeletonData)

  heart.scale.set(0.16 * ratio, 0.16 * ratio)
  heart.x = heart.width / 2 - 8 * ratio
  heart.y = container.height / 2 - 2 * ratio

  container.addChild(heart)

  bubbleState.references.ui.level.heart = heart

  setHeartCount(bubbleState.level.state.lives)
}

const initCoin = (container: Container) => {
  if (!bubbleState.level || !bubbleState.resources) return undefined

  const ratio = getRatio()

  // Background count
  const textContainer = new Container()

  const textBackground = new Graphics()
  textBackground
    .beginFill(0x42210B)
    .lineStyle(4 * ratio, 0xFFF8D0)
    .drawRoundedRect(0, 0, 80 * ratio, 30 * ratio, 35 * ratio)
    .endFill()

  textContainer.addChild(textBackground)

  textContainer.x = container.width - 105 * ratio
  textContainer.y = container.height / 2 - textBackground.height / 2 - 2 * ratio // -2 to take border in account

  container.addChild(textContainer)

  // Text

  const counterText = new Text('', new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFF8D0,
    fontSize: 22 * ratio
  }))

  counterText.y = 2 * ratio

  bubbleState.references.ui.level.coinCount = counterText

  textContainer.addChild(counterText)

  // Spine coin object

  const coin = new Spine(bubbleState.resources.coin.spineData as SkeletonData)

  coin.scale.set(0.15 * ratio, 0.15 * ratio)
  coin.x = container.width - coin.width * 1.7
  coin.y = container.height / 2 - 4 * ratio

  container.addChild(coin)

  bubbleState.references.ui.level.coin = coin

  setCoinCount(0)
}

const initStars = (container: Container) => {
  if (!bubbleState.level || !bubbleState.resources) return undefined

  const ratio = getRatio()

  const stars = []

  const starSize = 20 * ratio
  const starSpace = 30 * ratio
  const baseX = container.width / 2 - starSize - starSpace

  for (let i = 0; i < 3; i++) {
    const star = new Spine(bubbleState.resources.star.spineData as SkeletonData)
    star.skeleton.setSkinByName('Empty')

    star.width = starSize
    star.height = starSize
    star.x = baseX + i * starSpace
    star.y = container.height / 2 - starSize / 2 + 5 * ratio

    container.addChild(star)

    stars.push(star)
  }

  bubbleState.references.ui.level.stars = stars

  stars.forEach((star, index) => setTimeout(() => star.state.setAnimation(0, 'StarAppears'), (index + 1) * 100))
}

const initTimer = (container: Container) => {
  if (!bubbleState.level || !bubbleState.resources) return undefined

  const ratio = getRatio()

  const counterText = new Text('', new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0x42210b,
    fontSize: 22 * ratio
  }))

  bubbleState.references.ui.level.timerCount = counterText

  container.addChild(counterText)

  setTimer(0)
}

export const setTimer = (timer: number) => {
  if (!bubbleState.references.ui.level.timerCount || !bubbleState.references.topContainer) return

  const ratio = getRatio()
  const container = bubbleState.references.topContainer

  const counterText = bubbleState.references.ui.level.timerCount

  const minutes = Math.floor(timer / 60)
  const seconds = timer % 60

  counterText.text = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`

  const textMetrics = TextMetrics.measureText(counterText.text, counterText.style as TextStyle)

  counterText.x = container.width / 2 - textMetrics.width / 2
  counterText.y = Math.floor(topBarHeight / 2 * ratio) - textMetrics.height / 2
}

const initRank = (container: Container) => {
  if (!bubbleState.level || !bubbleState.resources) return undefined

  const ratio = getRatio()

  const counterText = new Text('', new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0x42210b,
    fontSize: 22 * ratio
  }))

  counterText.y = 2 * ratio

  bubbleState.references.ui.level.rankText = counterText

  container.addChild(counterText)

  setRank('1st')
}

export const setRank = (rank: string) => {
  if (!bubbleState.references.ui.level.rankText) return
  const ratio = getRatio()

  const counterText = bubbleState.references.ui.level.rankText

  counterText.text = rank

  const textMetrics = TextMetrics.measureText(counterText.text, counterText.style as TextStyle)

  counterText.x = (getGameWidth() - 70 * ratio) - textMetrics.width - 13 * ratio
  counterText.y = Math.floor(topBarHeight / 2 * ratio) - textMetrics.height / 2
}

const initPauseButton = (container: Container) => {
  if (!bubbleState.viewport || !bubbleState.resources) return

  const ratio = getRatio()

  const pauseButton = new PIXI.Sprite(bubbleState.resources.pauseWhite)

  pauseButton.interactive = true
  pauseButton.cursor = 'pointer'
  pauseButton.on('pointertap', (event) => {
    sound.find(SoundName.pauseButton).play()
    showPauseMenu()
    event.stopPropagation()
  })

  pauseButton.scale.set(0.14 * ratio, 0.14 * ratio)
  pauseButton.x = container.width
  pauseButton.y = container.height / 2 - pauseButton.height / 2 - 4 * ratio

  container.addChild(pauseButton)
}

const initBubbleCount = () => {
  if (!bubbleState.level || !bubbleState.viewport) return undefined

  const ratio = getRatio()

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFF8D0,
    fontSize: 20 * ratio
  })

  const counterText = new Text('', style)

  counterText.x = getGameWidth() / 2 + 50
  counterText.y = getGameHeight() - 30 * ratio

  bubbleState.references.ui.level.bubbleCount = counterText

  bubbleState.viewport.addChild(counterText)

  setBubbleCount(remainingBubblesToFire())
}

const initWavesLeftCount = (container: Container) => {
  if (!bubbleState.level) return

  const ratio = getRatio()

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: bubbleState.level.background === 'night' ? 0xffffff : 0x42210b,
    fontSize: 20 * ratio
  })

  const counterText = new Text('', style)

  bubbleState.references.ui.level.linesLeftCount = counterText

  container.addChild(counterText)

  updateWavesLeftCount()
}

export const showPauseMenu = () => {
  if (!bubbleState.viewport || !bubbleState.resources?.playBlack || !bubbleState.level) return

  bubbleState.level.state.paused = true

  pauseTimer()

  const container = new Container()
  container.x = 0
  container.y = 0
  container.width = getGameWidth()
  container.height = getGameHeight()
  container.interactive = true

  container.on('pointertap', (event) => event.stopPropagation())

  const background = new PIXI.Graphics()
    .beginTextureFill({
      texture: radialGradient(
        'rgba(255, 255, 255, 0.95)',
        'rgba(255, 255, 255, 0.2)',
        getGameWidth() * 0.5,
        getGameHeight() * 0.5,
        100,
        Math.max(getGameWidth(), getGameHeight()),
        getGameWidth(),
        getGameHeight())
    })
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  container.addChild(background)

  // Paused text

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0x333333,
    fontSize: 64
  })

  const pauseText = new Text('PAUSED', style)

  const textMetrics = TextMetrics.measureText('PAUSED', style)

  pauseText.x = getGameWidth() / 2 - textMetrics.width / 2
  pauseText.y = getGameHeight() / 5

  container.addChild(pauseText)

  // Play button

  const resumeButton = new PIXI.Sprite(bubbleState.resources.playBlack)

  resumeButton.interactive = true
  resumeButton.cursor = 'pointer'
  resumeButton.on('pointertap', (event) => {
    sound.find(SoundName.resumeButton).play()
    hidePauseMenu()
    event.stopPropagation()
  })

  resumeButton.scale.set(0.4, 0.4)
  resumeButton.x = getGameWidth() * 0.5 - resumeButton.width * 0.5
  resumeButton.y = getGameHeight() * 0.5 - resumeButton.height * 0.5

  container.addChild(resumeButton)

  // Sound button

  const soundButton = new PIXI.Sprite(bubbleState.resources.soundBlack)

  soundButton.interactive = true
  soundButton.cursor = 'pointer'
  soundButton.on('pointertap', (event) => {
    sound.find(SoundName.musicToggle).play()
    toggleMute(soundButton)
    event.stopPropagation()
  })

  soundButton.alpha = sound.find(SoundName.ambience1).muted ? 0.3 : 1

  soundButton.scale.set(0.2, 0.2)
  soundButton.x = getGameWidth() * 0.5 - soundButton.width * 0.5
  soundButton.y = getGameHeight() * 0.5 + soundButton.height * 1.5

  container.addChild(soundButton)

  if (bubbleState.mode === 'solo') {
    // Home button

    const homeButton = new PIXI.Sprite(bubbleState.resources.homeBlack)

    homeButton.interactive = true
    homeButton.cursor = 'pointer'
    homeButton.on('pointertap', () => {
      if (!bubbleState.viewport) return
      sound.find(SoundName.homeButton).play()

      initWorldScreen()
    })

    homeButton.scale.set(0.2, 0.2)
    homeButton.x = getGameWidth() * 0.5 - homeButton.width * 2.5
    homeButton.y = getGameHeight() * 0.5 - homeButton.height * 0.5

    container.addChild(homeButton)

    // Retry button

    const retryButton = new PIXI.Sprite(bubbleState.resources.retryBlack)

    retryButton.interactive = true
    retryButton.cursor = 'pointer'
    retryButton.on('pointertap', (event) => {
      sound.find(SoundName.restartButton).play()
      resetLevel()
      hidePauseMenu()
      event.stopPropagation()
    })

    retryButton.scale.set(0.2, 0.2)
    retryButton.x = getGameWidth() * 0.5 + retryButton.width * 1.5
    retryButton.y = getGameHeight() * 0.5 - retryButton.height * 0.5

    container.addChild(retryButton)
  }

  bubbleState.references.ui.pauseMenu = container

  container.zIndex = 100

  bubbleState.viewport.addChild(container)
}

const hidePauseMenu = () => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.references.ui.pauseMenu) return

  bubbleState.viewport.removeChild(bubbleState.references.ui.pauseMenu)

  bubbleState.level.state.paused = false

  resumeTimer()
}

const toggleMute = (soundButton: PIXI.Sprite) => {
  sound.find(SoundName.ambience1).muted = !sound.find(SoundName.ambience1).muted
  sound.find(SoundName.ambience2).muted = !sound.find(SoundName.ambience2).muted
  sound.find(SoundName.music1).muted = !sound.find(SoundName.music1).muted
  sound.find(SoundName.music2).muted = !sound.find(SoundName.music2).muted
  sound.find(SoundName.music3).muted = !sound.find(SoundName.music3).muted
  sound.find(SoundName.music4).muted = !sound.find(SoundName.music4).muted
  sound.find(SoundName.music5).muted = !sound.find(SoundName.music5).muted

  soundButton.alpha = sound.find(SoundName.ambience1).muted ? 0.3 : 1
}

export const pauseTimer = () => {
  if (!bubbleState.level) return

  bubbleState.level.state.totalTime += Date.now() - bubbleState.level.state.lastPauseTimestamp
}

export const resumeTimer = () => {
  if (!bubbleState.level) return

  bubbleState.level.state.lastPauseTimestamp = Date.now()
}

export const getTotalTimeElapsed = () => {
  if (!bubbleState.level) return 0

  return Date.now() - bubbleState.level.state.lastPauseTimestamp + bubbleState.level.state.totalTime
}

export const setHeartCount = (count: number) => {
  if (!bubbleState.references.ui.level.heartCount || !bubbleState.references.ui.level.heart) return

  const ratio = getRatio()

  const counterText = bubbleState.references.ui.level.heartCount

  counterText.text = `${count}`

  const textMetrics = TextMetrics.measureText(`${count}`, counterText.style as TextStyle)

  counterText.x = (95 * ratio) / 2 - textMetrics.width / 2 + 13 * ratio

  bubbleState.references.ui.level.heart.state.setAnimation(0, 'Idle')
}

export const setCoinCount = (count: number) => {
  if (!bubbleState.references.ui.level.coinCount || !bubbleState.references.ui.level.coin) return

  const ratio = getRatio()

  const counterText = bubbleState.references.ui.level.coinCount

  counterText.text = `${count}`

  const textMetrics = TextMetrics.measureText(`${count}`, counterText.style as TextStyle)

  counterText.x = (90 * ratio) / 2 - textMetrics.width / 2 + 13 * ratio

  bubbleState.references.ui.level.coin.state.setAnimation(0, 'Idle')
}

export const setBubbleCount = (count: number) => {
  if (!bubbleState.references.ui.level.bubbleCount || !bubbleState.viewport) return

  const counterText = bubbleState.references.ui.level.bubbleCount

  counterText.text = `${count} left`
}

export const updateWavesLeftCount = () => {
  if (!bubbleState.references.ui.level.linesLeftCount || !bubbleState.viewport || !bubbleState.level || !bubbleState.level.state.bubbleWidth) return

  const ratio = getRatio()

  const counterText = bubbleState.references.ui.level.linesLeftCount

  if ((bubbleState.level.state.actualTopRowIndex ?? 0) > 0) {
    const bubblesLeft = bubbleState.level.pushLineBubbleCount - (bubbleState.level.state.numberOfBubblesSent % bubbleState.level.pushLineBubbleCount)

    counterText.text = `next wave in ${bubblesLeft} bubbles`
  } else {
    counterText.text = 'no wave left'
  }

  const textMetrics = TextMetrics.measureText(counterText.text, counterText.style as TextStyle)

  counterText.x = getGameWidth() / 2 - textMetrics.width / 2
  counterText.y = topBarHeight * ratio + textMetrics.height / 2
  //counterText.alpha = 0.5
}

const initLevelLimit = () => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.level.state.bubbleWidth) return undefined

  const container = new Container()

  const graphics = new Graphics()
  graphics
    .beginFill(0xC69C6D, 0.2)
    .drawRect(0, 0, getGameWidth(), 4)
    .endFill()

  container.addChild(graphics)

  const coords = rowColumnToCoord(bubbleState.level.limitNumberOfRow - 1, 0)

  if (!coords) return

  container.x = 0
  container.y = coords.y - bubbleState.level.state.bubbleWidth * 0.5 + 4

  bubbleState.references.ui.level.bubbleLimit = container

  bubbleState.viewport.addChild(container)
}

export const showScoreMenu = (won: boolean, stars: number) => {
  if (!bubbleState.gameState || !bubbleState.viewport || !bubbleState.resources?.playBlack || !bubbleState.level) return

  if (bubbleState.mode === 'classic' && bubbleState.external.openClassicLeaderboardScreen) {
    bubbleState.external.openClassicLeaderboardScreen()

    return
  }

  const container = new Container()
  container.x = 0
  container.y = 0
  container.width = getGameWidth()
  container.height = getGameHeight()
  container.interactive = true

  container.on('pointertap', (event) => event.stopPropagation())

  const background = new PIXI.Graphics()
    .beginTextureFill({
      texture: radialGradient(
        'rgba(255, 255, 255, 0.95)',
        'rgba(255, 255, 255, 0.2)',
        getGameWidth() * 0.5,
        getGameHeight() * 0.5,
        100,
        Math.max(getGameWidth(), getGameHeight()),
        getGameWidth(),
        getGameHeight())
    })
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  container.addChild(background)

  // Your score text

  const yourScoreStyle = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0x333333,
    fontSize: 50
  })

  const yourScoreText = new Text('', yourScoreStyle)

  yourScoreText.style = yourScoreStyle
  yourScoreText.text = `YOU ${won ? 'WON' : 'LOST'}`

  const yourScoreMetrics = TextMetrics.measureText(yourScoreText.text, yourScoreStyle)

  yourScoreText.x = getGameWidth() / 2 - yourScoreMetrics.width / 2
  yourScoreText.y = getGameHeight() / 3

  container.addChild(yourScoreText)

  // Score text

  if (won) {
    const scoreStyle = new TextStyle({
      fontFamily: 'Baloo Thambi',
      fontWeight: '700',
      fill: 0xFFDA17,
      fontSize: 100,
      dropShadow: true,
      dropShadowColor: 0xB94700,
      dropShadowAngle: Math.PI / 2
    })

    const scoreText = new Text(bubbleState.level.state.score, scoreStyle)

    const textMetrics = TextMetrics.measureText(`${bubbleState.level.state.score}`, scoreStyle)

    scoreText.x = getGameWidth() / 2 - textMetrics.width / 2
    scoreText.y = yourScoreText.y + textMetrics.height * 0.5

    container.addChild(scoreText)
  } else {
    const scoreStyle = new TextStyle({
      fontFamily: 'Baloo Thambi',
      fontWeight: '700',
      fill: 0x333333,
      fontSize: 40
    })

    const scoreText = new Text('tokens are lost', scoreStyle)

    const textMetrics = TextMetrics.measureText(scoreText.text, scoreStyle)

    scoreText.x = getGameWidth() / 2 - textMetrics.width / 2
    scoreText.y = yourScoreText.y + yourScoreText.height + textMetrics.height * 0.5

    container.addChild(scoreText)
  }

  stopAllMusicAndAmbience()

  const soundName = stars === 0 ? SoundName.zeroStar : stars === 1 ? SoundName.oneStar : stars === 2 ? SoundName.twoStars : SoundName.threeStars

  sound.find(soundName).play()

  if (bubbleState.mode === 'solo') {
    // Three stars Spine

    const threeStars = new Spine(bubbleState.resources.threeStars.spineData as SkeletonData)

    threeStars.scale.set(0.5, 0.5)
    threeStars.x = getGameWidth() * 0.5
    threeStars.y = yourScoreText.y - threeStars.height * 0.5

    threeStars.state.setAnimation(0, 'WithoutStars')

    if (won && stars > 0) {
      threeStars.state.addAnimation(0, `${stars === 1 ? 'One' : stars === 2 ? 'Two' : 'Three'}Star${stars === 1 ? '' : 's'}`)
    }

    container.addChild(threeStars)

    bubbleState.references.ui.level.threeStars = threeStars


    if (nextLevelAccessible()) {
      const nextButton = new PIXI.Sprite(bubbleState.resources.nextYellow)

      nextButton.interactive = true
      nextButton.cursor = 'pointer'
      nextButton.on('pointertap', (event) => {
        event.stopPropagation()

        sound.find(SoundName.resumeButton).play()

        hideScoreMenu()
        goToNextLevel()
      })

      nextButton.scale.set(0.4, 0.4)
      nextButton.x = getGameWidth() * 0.5 - nextButton.width * 0.5
      nextButton.y = getGameHeight() * 0.75 - nextButton.height * 0.5

      nextButton.filters = [dropShadowYellow() as unknown as Filter]

      container.addChild(nextButton)
    }

    // Retry button

    const retryButton = new PIXI.Sprite(bubbleState.resources.retryBlack)

    retryButton.interactive = true
    retryButton.cursor = 'pointer'
    retryButton.on('pointertap', (event) => {
      sound.find(SoundName.restartButton).play()
      resetLevel()
      hidePauseMenu()
      event.stopPropagation()
    })

    retryButton.scale.set(0.2, 0.2)
    retryButton.x = getGameWidth() * 0.5 - retryButton.width * 2.5
    retryButton.y = getGameHeight() * 0.75 - retryButton.height * 0.5

    container.addChild(retryButton)

    // Home button

    const homeButton = new PIXI.Sprite(bubbleState.resources.homeBlack)

    homeButton.interactive = true
    homeButton.cursor = 'pointer'
    homeButton.on('pointertap', () => {
      if (!bubbleState.viewport) return

      sound.find(SoundName.homeButton).play()

      initWorldScreen()
    })

    homeButton.scale.set(0.2, 0.2)
    homeButton.x = getGameWidth() * 0.5 + homeButton.width * 1.5
    homeButton.y = getGameHeight() * 0.75 - homeButton.height * 0.5

    container.addChild(homeButton)
  } else {
    const leaderboardButton = new PIXI.Sprite(bubbleState.resources.leaderboardYellow)

    leaderboardButton.interactive = true
    leaderboardButton.cursor = 'pointer'
    leaderboardButton.on('pointertap', (event) => {
      event.stopPropagation()

      if (bubbleState.mode === 'classic' && bubbleState.external.openClassicLeaderboardScreen) {
        bubbleState.external.openClassicLeaderboardScreen()
      }
    })

    leaderboardButton.scale.set(0.4, 0.4)
    leaderboardButton.x = getGameWidth() * 0.5 - leaderboardButton.width * 0.5
    leaderboardButton.y = getGameHeight() * 0.75 - leaderboardButton.height * 0.5

    leaderboardButton.filters = [dropShadowYellow() as unknown as Filter]

    container.addChild(leaderboardButton)
  }

  bubbleState.references.ui.scoreMenu = container

  container.zIndex = 100

  bubbleState.viewport.addChild(container)
}

const hideScoreMenu = () => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.references.ui.scoreMenu) return

  bubbleState.viewport.removeChild(bubbleState.references.ui.scoreMenu)

  bubbleState.level.state.ended = false

  resumeTimer()
}

export const showScoreAndCombo = (point: Point, score: number, multiply: number) => {
  if (!bubbleState.viewport) return

  const ratio = getRatio()

  const container = new Container()
  container.x = 0
  container.y = 0
  container.width = getGameWidth()
  container.height = getGameHeight()

  const fontSize = (multiply === 1 ? 40 : multiply === 2 ? 50 : 60) * ratio

  const scoreStyle = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFDA17,
    fontSize,
    dropShadow: true,
    dropShadowColor: 0xB94700,
    dropShadowAngle: Math.PI / 2
  })

  const multiplyStyle = scoreStyle.clone()
  multiplyStyle.fill = multiply === 1 ? 0xFFDA17 : multiply === 2 ? 0xffb917 : 0xff8f17

  const scoreText = new Text(score, scoreStyle)
  const multiplyText = new Text(`x ${multiply}`, multiplyStyle)

  const scoreMetrics = TextMetrics.measureText(scoreText.text, scoreStyle)
  const multiplyMetrics = TextMetrics.measureText(multiplyText.text, scoreStyle)

  scoreText.x = Math.min(getGameWidth() - scoreMetrics.width - 10, Math.max(10, point.x - scoreMetrics.width * 0.5))
  scoreText.y = Math.min(getGameHeight() - scoreMetrics.height - 10, Math.max(multiplyMetrics.height, point.y - scoreMetrics.height * 0.5))

  multiplyText.x = Math.min(getGameWidth() - multiplyMetrics.width - 10, Math.max(10, point.x - multiplyMetrics.width * 0.5))
  multiplyText.y = Math.min(getGameHeight() - multiplyMetrics.height * 2 - 10, Math.max(0, point.y - multiplyMetrics.height * 1.5))

  container.addChild(scoreText, multiplyText)

  container.zIndex = 90

  bubbleState.viewport.addChild(container)

  ease.add(container, {y: container.y - 15, alpha: 0}, {ease: 'easeInQuad', duration: 1500})

  setTimeout(() => bubbleState.viewport?.removeChild(container), 1500)
}

export const showLevelNumber = () => {
  if (!bubbleState.viewport || !bubbleState.references.ui.level.bubbleLimit) return

  const ratio = getRatio()

  const levelStyle = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFDA17,
    fontSize: 40 * ratio,
    dropShadow: true,
    dropShadowColor: 0xB94700,
    dropShadowAngle: Math.PI / 2
  })

  const levelText = new Text(`Level ${(bubbleState.gameState?.actualLevel ?? 0) + 1}${bubbleState.level?.name ? ` - ${bubbleState.level?.name}` : ''}`, levelStyle)

  const levelMetrics = TextMetrics.measureText(levelText.text, levelStyle)

  levelText.x = getGameWidth() / 2 - levelMetrics.width / 2
  levelText.y = bubbleState.references.ui.level.bubbleLimit.y + levelMetrics.height / 2

  bubbleState.viewport.addChild(levelText)

  ease.add(levelText, {alpha: 0}, {ease: 'linear', duration: 1000, wait: 1000}).on('complete', () => {
    bubbleState.viewport?.removeChild(levelText)
  })
}

export const spawnScoreBubble = (onAnimationEnded: () => void) => {
  if (!bubbleState.level || !bubbleState.references.cannon || !bubbleState.viewport) return

  const skin = randomBubbleSkin(bubbleState.level.bubbleInLevel)

  if (skin) {
    const bubble = initBubble(skin.name as BubbleSkinName)

    if (bubble) {
      bubble.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)
      bubble.x = bubbleState.references.cannon.x
      bubble.y = bubbleState.references.cannon.y

      bubbleState.viewport.addChild(bubble)

      gsap.to(bubble, {
        duration: 0.8,
        ease: Power1.easeOut,
        x: bubble.x + (Math.random() * 200 - 100),
        y: getGameHeight() * 0.75 + (Math.random() * 100 - 50),
        rotation: Math.random() * 5,
        onComplete() {
          destroyBubble(bubble, 100, false)

          onAnimationEnded()
        }
      })
    }
  }
}