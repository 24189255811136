@import "scss-variables";

.profile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  max-width: 1200px;

  .settings {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    background-color: $color-dark-gray;
    border-radius: 0.5rem;
    padding: 1rem;

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    .preview {
      position: relative;
      line-height: 0;

      .change {
        position: absolute;
        bottom: -0.5rem;
        right: -0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          height: auto;
          padding: 0.5rem;
          border: 4px solid $color-dark-gray;
          border-radius: 2rem;
          background-color: $color-danger;
        }
      }

      img {
        max-height: 150px;
        border-radius: 0.5rem;
      }
    }

    .username {
      display: flex;
      gap: 0.5rem;
      font-size: 2rem;
      font-weight: bold;
      align-items: center;

      svg {
        cursor: pointer;
        font-size: 1rem;
      }
    }

    .wallet-address {
      word-break: break-all;
    }

    .middle {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  .twitter {
    display: flex;
    flex-direction: column;
    border: 1px dashed #1d9bf0;
    border-radius: 0.5rem;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    .text {
      flex: 1;
    }
  }

  .stats-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;

    .grouped {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      border-radius: 0.5rem;

      &.unified-bg {
        background: $color-dark-gray;
      }

      .stats {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem;
        background: $color-dark-gray;
        border-radius: 0.5rem;

        .name {
          opacity: 0.7;
          white-space: nowrap;
        }

        .value {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1.25rem;
          font-weight: bold;

          svg {
            font-size: 1rem;
          }

          img {
            height: 1rem;
          }
        }
      }
    }
  }
}
