import {Sprite} from 'pixi.js'
import {SlotsSymbolName} from './enums'
import {slotsState} from './state'

export class ReelSymbol {
  name: SlotsSymbolName
  sprite: Sprite
  speed: {
    vx: number
    vy: number
  }
  stopNextLoop: boolean
  line?: number
  reel?: number

  constructor(
    name: SlotsSymbolName,
    speed: {
      vx: number
      vy: number
    } = {
        vx: 0,
        vy: 0
      }
  ) {
    this.name = name
    this.speed = speed

    this.sprite = new Sprite(slotsState.resources[name])

    this.sprite.pivot.set(this.sprite.width * 0.5, this.sprite.height * 0.5)

    this.stopNextLoop = false
  }

  update(delta: number) {
    this.sprite.x += delta * this.speed.vx
    this.sprite.y += delta * this.speed.vy
  }

  swapSprite(name: SlotsSymbolName, sprite: Sprite) {
    const oldSprite = this.sprite

    const parent = oldSprite.parent

    sprite.pivot.set(sprite.width * 0.5, sprite.height * 0.5)
    sprite.position.copyFrom(oldSprite.position)

    parent.removeChild(oldSprite)
    parent.addChild(sprite)

    this.sprite = sprite
    this.name = name
  }
}