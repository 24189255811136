@import "scss-variables";

.rev-share {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  max-width: 1200px;

  .repetitive-quests {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    .repetitive-quest {
      flex: 1;
    }
  }

  .repetitive-quest {
    display: flex;
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-direction: column;
    font-family: "Baloo Thambi";
    position: relative;

    opacity: 0;
    animation: appear 1s ease 0.5s forwards;

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    &.daily-reward {
      background-image: url(https://storage.googleapis.com/hub-cdn/hub/images/boat.jpeg);
    }

    &.deposit-offer {
      background-image: url(https://cdn.piratesquadnft.com/games/baddie-beast/marketing/bsl.jpg);

      button {
        background-color: #e5a413;

        &:hover {
          background-color: #c78c0c;
        }
      }
    }

    .bg {
      position: absolute;
      inset: 0;
      background-color: #fff1;
    }

    .info {
      position: relative;
      padding-right: 2rem;
      padding: 1rem;
      gap: 1rem;
      font-size: 1rem;
      flex: 1;
      align-items: center;
      text-align: center;

      @media screen and (min-width: $sm-breakpoint) {
        text-align: left;
      }

      .name {
        position: relative;
        z-index: 1;
        font-family: "Riffic";
        font-size: 1.25rem;
        text-shadow: #000c 0px 0px 3px;

        img {
          height: 1rem;
        }
      }

      .description {
        text-shadow: #000c 0px 0px 3px;

        img {
          width: 10px;
          height: 10px;
        }

        a {
          color: $text-color;
          padding-left: 1rem;
        }
      }

      .details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-top: 1rem;

        button {
          height: auto;
          padding: 0.25rem 0.5rem;
          background-color: $color-light-gray;
          gap: 0.5rem;

          svg {
            height: 0.8rem;
          }

          &:hover {
            background-color: $color-gray;
          }
        }

        .detail {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          //background: $color-light-gray;
          border-radius: 0.5rem;
          gap: 0.25rem;
          white-space: nowrap;
          font-size: 0.75rem;
        }
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      width: auto;
      font-size: 1rem;

      > div {
        font-size: 1rem;
      }
    }
  }

  .grouped {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border-radius: 0.5rem;

    opacity: 0;
    animation: appear ease 1s forwards;

    &.unified-bg {
      background: $color-dark-gray;
    }

    .stats {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem;
      background: $color-dark-gray;
      border-radius: 0.5rem;

      .name {
        display: flex;
        white-space: nowrap;
        gap: 0.5rem;
        align-items: center;

        button {
          height: auto;
          padding: 0rem 0.5rem;
        }
      }

      .value {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1.25rem;
        font-weight: bold;

        &.flip > div {
          font-size: 1rem;
          margin: 0 -0.5rem;
        }

        svg {
          font-size: 1rem;
        }

        img {
          height: 1rem;
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    width: 100%;
    min-height: 3rem;
    width: 100%;
    height: fit-content;

    > * {
      width: 100%;
      aspect-ratio: 1/1;
      margin: 0 auto;
      cursor: pointer;
    }

    @media screen and (min-width: $xs-breakpoint) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .nft {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      animation: appear 1s ease forwards;
      max-width: 300px;
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
