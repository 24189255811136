import {UserRole} from '../../state/context'

export interface ChatServerToClientEvents {
  // Errors
  unauthorized: () => void
  blocked: (message: {
    code: 'no-points' | 'error'
    retryMS?: number
  }) => void
  // Event
  connected: () => void
  message: (message: string) => void
  history: (messages: string) => void
  count_connected: (countConnected: number) => void
  'remove-message': (messageUUID: string) => void
  error: (message: string) => void
}

export interface ChatClientToServerEvents {
  message: (message: string, replyTo?: string) => void
  history: () => void
  authenticate: (sessionToken: string) => void
  tip: (socketId: string, amount: number) => void
  'pfp-changed': () => void
  'remove-message': (messageUUID: string) => void
  ban: (chatUserId: string, socketId: string) => void
}

export type IMessage<D = {
  message: string
}> = {
  type: 'message' | 'tip'
  uuid: string
  userUuid: string
  createdAt: number
  username: string
  data: D
  pfp?: string
  socketId?: string
  replyTo?: IMessage
  role?: UserRole
}

export type NormalMessage = IMessage
export type TipMessage = IMessage<{
  sender: {
    username: string,
    userUuid: string,
  }
  receiver: {
    username: string,
    userUuid: string,
  }
  amount: number
  message: string
}>

export const isNormalMessage = (message: IMessage): message is NormalMessage => {
  return (message as NormalMessage).type === 'message'
}

export const isTipMessage = (message: IMessage): message is TipMessage => {
  return (message as TipMessage).type === 'tip'
}