.aptos-connect {
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    color: #333;

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 80%;
      max-width: 480px;
      background-color: white;
      border-radius: 1rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      word-wrap: break-word;
      font-size: 22px;
      font-family: "Baloo Thambi";

      button {
        border-radius: 0;

        &:not(:last-child) {
          border-bottom: 1px solid white;
        }
      }
    }
  }
}
