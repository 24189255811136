@import "scss-variables";

.textarea {
  display: flex;
  font-family: "Baloo Thambi";
  font-size: 1rem;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  resize: none;
  background-color: $color-light-gray;
  color: $text-color;

  &:focus {
    outline: 1px solid $color-blue;
  }
}
