@import "scss-variables";

.button {
  display: flex;
  font-family: "Baloo Thambi";
  font-size: 1rem;
  background-color: $color-blue;
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  font-weight: 500;
  position: relative;

  &:hover:not(:disabled),
  &.selected {
    background-color: darken($color: $color-blue, $amount: 10);
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #999999aa;
    color: #ffffffaa;

    svg {
      color: #33333333;
    }
  }

  &.loading {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.gray {
    background-color: #666;
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background-color: darken($color: #666, $amount: 10);
    }
  }

  &.success {
    background-color: $color-success;
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background-color: darken($color: $color-success, $amount: 10);
    }
  }

  &.warning {
    background-color: $color-warning;
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background-color: darken($color: $color-warning, $amount: 10);
    }
  }

  &.no-color {
    color: $text-color;
    background-color: transparent;

    &:hover:not(:disabled),
    &.selected {
      background-color: $color-light-gray;
    }

    &:disabled {
      cursor: not-allowed;
      color: #aaa !important;

      svg {
        color: #aaa !important;
      }
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.icon {
    width: auto;
    gap: 1rem;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.floating {
      svg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

      .children {
        visibility: hidden;
      }
    }
  }
}
