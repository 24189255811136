import Decimal from 'decimal.js'
import React, {ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useState} from 'react'
import Countdown, {CountdownRendererFn, calcTimeDelta} from 'react-countdown'
import {toast} from 'react-toastify'
import {Socket, io} from 'socket.io-client'
import {YOOH} from '../../../svg/token'
import {CDN_URL} from '../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../utils/global'
import {Button, ButtonIcon} from '../../components/buttons/button'
import {ConnectButton} from '../../components/buttons/connect-button'
import {Input} from '../../components/input/input'
import {PopoverHelp} from '../../components/popover/popover'
import {Toggle} from '../../components/toggle/toggle'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import styles from './abc.module.scss'
import {ambienceABC, clickAffirmativeSoundABC, clickNegativeSoundABC, coinsSoundABC, loseSoundABC, selectSoundABC, winSoundABC} from './sounds'
import {ABCTokenA, ABCTokenB, ABCTokenC, ABCTokenRandom} from './svg'
import {ABCClientToServerEvents, ABCGame, ABCGameMode, ABCServerToClientEvents, ABCSide} from './types'

const rendererLight: CountdownRendererFn = ({seconds}) =>
  <span className={formatClassName(styles, 'countdown')} >
    {seconds}
  </span>
const timeAgoRenderer = (createdAt: number) => {
  const {seconds, minutes, hours, days} = calcTimeDelta(createdAt, {overtime: true})

  return <span className={formatClassName(styles, 'countdown')}>
    {
      days > 0 || hours > 0
        ? 'hours ago'
        : minutes > 0
          ? `${minutes}m ago`
          : `${seconds}s ago`
    }
  </span>
}

export type ABCProps = HtmlHTMLAttributes<HTMLDivElement>

const MAX_BET = {
  'solo': 20,
  'pvp': Number.MAX_SAFE_INTEGER
}

//export const ABCClientURL = 'ws://localhost:3021'
//export const ABCClientPath = undefined
//export const ABCClientURL = 'wss://test-server.piratesquadnft.com'
//export const ABCClientPath = '/abc/socket.io'
//export const ABCClientURL = 'wss://s1.piratesquadnft.com'
//export const ABCClientPath = '/abc/socket.io'
export const ABCClientURL = 'wss://s2.piratesquadnft.com'
export const ABCClientPath = '/abc/socket.io'

export const ABC = ({className, ...props}: ABCProps) => {
  const {state: {tokens, sessionToken, userUuid}, dispatch} = useHubContext()
  const [opponent, setOpponent] = useState<ABCGameMode>('solo')
  const [side, setSide] = useState<ABCSide>('a')
  const [bet, setBet] = useState('')
  const [creatingGame, setCreatingGame] = useState(false)
  const [myGamesChecked, setMyGamesChecked] = React.useState(false)
  const [showAllActiveGames, setShowAllActiveGames] = React.useState(true)

  const betChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number)) {
      setBet(value)
    } else {
      if (number < 0) {
        setBet('0')
      } else if (number > MAX_BET[opponent]) {
        setBet(String(MAX_BET[opponent]))
      } else {
        setBet(value)
      }
    }
  }

  const allIn = () => {
    if (tokens?.yooh === undefined) return

    coinsSoundABC.play()

    betChange({target: {value: String(tokens.yooh / 100)}} as any)
  }

  useEffect(() => {
    betChange({target: {value: String(bet)}} as any)
  }, [opponent])

  // WEBSOCKET

  const [socket, setSocket] = useState<Socket<ABCServerToClientEvents, ABCClientToServerEvents>>()
  const [isConnected, setIsConnected] = useState(false)
  const [countConnected, setCountConnected] = useState(0)
  const [history, setHistory] = useState<ABCGame[]>([])
  const [myHistory, setMyHistory] = useState<ABCGame[]>([])
  const [activeGames, setActiveGames] = useState<ABCGame[]>([])
  const [cancelingGames, setCancelingGames] = useState<ABCGame[]>([])
  const [runningGame, setRunningGame] = useState<ABCGame>()

  useEffect(() => {
    ambienceABC.play()

    return () => {
      ambienceABC.stop()
    }
  }, [])

  useEffect(() => {
    setSocket(undefined)
  }, [sessionToken])

  useEffect(() => {
    if (socket) return

    const newSocket: Socket<ABCServerToClientEvents, ABCClientToServerEvents> = io(ABCClientURL, {
      path: ABCClientPath
    })

    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (sessionToken) {
        newSocket.emit('authenticate', sessionToken)
      } else {
        newSocket.emit('history')
      }
    })

    newSocket.on('connected', () => {
      setIsConnected(true)
    })

    newSocket.on('count_connected', (count) => {
      setCountConnected(count)
    })

    newSocket.on('disconnect', () => {
      setIsConnected(false)
    })
  }, [sessionToken, socket])

  useEffect(() => {
    if (!socket) return

    return () => {
      socket.disconnect()
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return

    socket.removeListener('new-game')
    socket.on('new-game', (message) => {
      const game = JSON.parse(message) as ABCGame

      const newActiveGames = [
        game,
        ...activeGames
      ]

      if (game.myGame) {
        setRunningGame(game)
      }

      setActiveGames([
        ...newActiveGames.filter(activeGame => activeGame.mode === 'pvp'),
        ...newActiveGames.filter(activeGame => activeGame.mode === 'solo')
      ])
    })

    socket.removeListener('cancel-game')
    socket.on('cancel-game', (roundId, remove, newAmount) => {
      const activeGameId = activeGames.findIndex(game => game.roundId === roundId)

      if (remove && activeGameId !== -1) {
        setActiveGames([
          ...activeGames.slice(0, activeGameId),
          ...activeGames.slice(activeGameId + 1)
        ])
      }

      if (runningGame?.roundId === roundId) {
        setRunningGame(undefined)
      }

      setCancelingGames(cancelingGames.filter(cancelingGame => cancelingGame.roundId !== roundId))

      if (newAmount !== undefined) {
        dispatch({
          type: 'SET_TOKENS',
          tokens: {
            ...tokens,
            yooh: newAmount
          },
          force: true
        })
      }
    })

    socket.removeListener('game-ended')
    socket.on('game-ended', (message, myGame) => {
      let game = JSON.parse(message) as ABCGame

      const activeGameId = activeGames.findIndex(activeGame => activeGame.roundId === game.roundId)

      if (activeGameId === -1) {
        game.endingAt = Date.now() + 3000

        if (game.participants.a?.userUuid === userUuid || game.participants.b?.userUuid === userUuid || game.participants.c?.userUuid === userUuid) {
          if (game.mode === 'solo') {
            setRunningGame(game)
          }

          if (game.mode === 'solo' || (game.mode == 'pvp' && runningGame?.roundId === game.roundId)) {
            if (game.result?.winnerUserUuid === userUuid) {
              setTimeout(() => winSoundABC.play(), 1500)
            } else {
              setTimeout(() => loseSoundABC.play(), 1500)
            }
          }
        }

        const newActiveGames = [
          game,
          ...activeGames
        ]

        setActiveGames([
          ...newActiveGames.filter(activeGame => activeGame.mode === 'pvp'),
          ...newActiveGames.filter(activeGame => activeGame.mode === 'solo')
        ])
      } else {
        const copy = [
          ...activeGames
        ]

        copy[activeGameId] = {
          ...game,
          endingAt: Date.now() + 3000
        }

        game = copy[activeGameId]

        if (runningGame?.roundId === game.roundId || myGame) {
          setRunningGame(game)

          if (game.result?.winnerUserUuid === userUuid) {
            setTimeout(() => winSoundABC.play(), 1500)
          } else {
            setTimeout(() => loseSoundABC.play(), 1500)
          }
        }

        setActiveGames([
          ...copy.filter(activeGame => activeGame.mode === 'pvp'),
          ...copy.filter(activeGame => activeGame.mode === 'solo')
        ])
      }
    })

    socket.removeListener('history')
    socket.on('history', (message) => {
      const parsedValue = JSON.parse(message)

      setHistory(parsedValue.history)
      if (parsedValue.myHistory) {
        setMyHistory(parsedValue.myHistory)
      }
      setActiveGames(parsedValue.activeGames)
    })

    socket.removeListener('joined-game')
    socket.on('joined-game', (message) => {
      const game = JSON.parse(message) as ABCGame

      setRunningGame(game)
    })

    socket.removeListener('update-game')
    socket.on('update-game', (message) => {
      const game = JSON.parse(message) as ABCGame

      const activeGameId = activeGames.findIndex(activeGame => activeGame.roundId === game.roundId)

      activeGames[activeGameId] = game

      if (activeGameId !== -1 && runningGame?.roundId === activeGames[activeGameId].roundId) {
        setRunningGame(game)
      }

      setActiveGames([...activeGames])
    })

    socket.removeListener('error')
    socket.on('error', (message, code, data) => {
      if (data) {
        const parsedData = JSON.parse(data)

        if (parsedData.amount !== undefined && tokens?.yooh !== undefined) {
          dispatch({
            type: 'SET_TOKENS',
            tokens: {
              ...tokens,
              yooh: tokens?.yooh + (parsedData.amount ?? 0) * 100
            },
            force: true
          })
        }
      }

      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: false
      })

      setCreatingGame(false)

      toast.error(formatTextWithYOOH(message))

      if (socket && sessionToken) {
        setTimeout(() => socket.emit('authenticate', sessionToken), 1000)
      }
    })
  }, [socket, history, tokens, activeGames, myGamesChecked, isConnected, userUuid])

  useEffect(() => {
    if (activeGames.find(activeGame => activeGame.status === 'ended' && (activeGame.participants.a?.userUuid === userUuid
      || activeGame.participants.b?.userUuid === userUuid
      || activeGame.participants.c?.userUuid === userUuid)
    )) {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: true
      })
    } else {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: false
      })
    }
  }, [activeGames])

  useEffect(() => {
    if (!runningGame?.endingAt) return

    const timeout = setTimeout(() => {
      setRunningGame(undefined)
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, [runningGame])

  const createGame = () => {
    if (!socket || creatingGame) return

    dispatch({
      type: 'SET_PAUSE_REFRESH_TOKENS',
      pauseRefreshTokens: true
    })

    setCreatingGame(true)

    if (Number(bet) === 0) {
      clickNegativeSoundABC.play()

      toast.error(formatTextWithYOOH('Minimal amount is $YOOH 1'))

      setCreatingGame(false)

      return
    }

    if ((tokens?.yooh ?? 0) >= Number(bet) * 100) {
      clickAffirmativeSoundABC.play()

      socket.emit('create-game', Number(bet), opponent, side)

      if (tokens?.yooh !== undefined) {
        dispatch({
          type: 'SET_TOKENS',
          tokens: {
            ...tokens,
            yooh: tokens.yooh - Number(bet) * 100
          },
          force: true
        })
      }

      setTimeout(() => {
        setCreatingGame(false)
      }, 1000)
    } else {
      clickNegativeSoundABC.play()

      toast.error(formatTextWithYOOH('Not enough $YOOH on your account to create the game'))

      setCreatingGame(false)
    }
  }

  const joinGame = (game: ABCGame, side: ABCSide) => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Joining the game?',
        text: <div className={formatClassName(styles, 'confirm-join')}>
          It will cost you <YOOH /> {game.formattedAmount / 100}
        </div>,
        acceptText: 'join',
        refuseText: 'leave',
        onAccept() {
          if (!socket) return

          dispatch({
            type: 'SET_PAUSE_REFRESH_TOKENS',
            pauseRefreshTokens: true
          })

          if (tokens?.yooh !== undefined && tokens.yooh >= game.formattedAmount) {
            clickAffirmativeSoundABC.play()

            dispatch({
              type: 'SET_TOKENS',
              tokens: {
                ...tokens,
                yooh: tokens.yooh - game.formattedAmount
              },
              force: true
            })

            socket.emit('join-game', game.roundId, side)
          }
        }
      })
    }
  }

  const cancelGame = (game: ABCGame) => {
    if (!socket) return

    selectSoundABC.play()

    setCancelingGames([
      ...cancelingGames,
      game
    ])

    socket.emit('cancel-game', game.roundId)
  }

  const removeGameFromActiveList = (game: ABCGame) => {
    setHistory([
      game,
      ...history.slice(0, 8)
    ])

    if (game.participants.a?.userUuid === userUuid || game.participants.b?.userUuid === userUuid || game.participants.c?.userUuid === userUuid) {
      setMyHistory([
        game,
        ...myHistory.slice(0, 8)
      ])
    }

    setActiveGames([
      ...activeGames.filter(activeGame => activeGame.roundId !== game.roundId)
    ])

    if (userUuid && game.result?.winnerUserUuid === userUuid) {
      dispatch({
        type: 'SET_TOKENS',
        tokens: {
          ...tokens,
          yooh: (tokens?.yooh ?? 0) + (game.result.winnerAmount ?? 0)
        },
        force: true
      })
    }
  }

  activeGames.forEach((activeGame) => {
    if (activeGame.endingAt !== undefined && Date.now() - activeGame.endingAt > 1) {
      removeGameFromActiveList(activeGame)
    }
  })

  const myGamesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMyGamesChecked(event.target.checked)
  }

  const changeOpponent = (opponent: ABCGameMode) => {
    selectSoundABC.play()

    setOpponent(opponent)
  }

  const changeSide = (side: ABCSide) => {
    selectSoundABC.play()

    setSide(side)
  }

  const openProfile = (userUuid?: string) => {
    if (!userUuid || userUuid === 'PirateSquad') return

    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  return (
    <div className={formatClassName(styles, `abc ${className ? className : ''}`)} {...props}>
      <div className={formatClassName(styles, 'game-container')}>
        <div className={formatClassName(styles, 'game')}>
          <div className={formatClassName(styles, 'settings')}>
            <div className={formatClassName(styles, 'setting')}>
              <div className={formatClassName(styles, 'title')}>
                Choose Opponent <PopoverHelp>
                  <>
                    <div>How to play</div>
                    <ul>
                      <li>
                        Choose your Opponent, House (Pirate Squad Bot) or User (PVP)
                      </li>
                      <li>
                        Choose your letter either A, B, C or Random
                      </li>
                      <li>
                        Add desired wagering amount in $YOOH
                      </li>
                      <li>
                        Click on ”Triple or Nothing!”
                      </li>
                    </ul>
                    <div>
                      For PVP mode wait till users join your game.<br />
                      For House mode wait for the result!<br />
                      Good luck!<br />
                    </div>
                  </>
                </PopoverHelp>
              </div>
              <div className={formatClassName(styles, 'content')}>
                <div className={formatClassName(styles, 'multi-button')}>
                  <Button className={formatClassName(styles, `no-color ${opponent === 'solo' && 'selected'}`)} onClick={() => changeOpponent('solo')}>House</Button>
                  <Button className={formatClassName(styles, `no-color ${opponent === 'pvp' && 'selected'}`)} onClick={() => changeOpponent('pvp')}>User</Button>
                </div>
              </div>
            </div>
            <div className={formatClassName(styles, 'setting')}>
              <div className={formatClassName(styles, 'title')}>
                Choose Your Letter
              </div>
              <div className={formatClassName(styles, 'content tokens')}>
                <div className={formatClassName(styles, `token a ${side === 'a' && 'selected'}`)} onClick={() => changeSide('a')}>
                  <ABCTokenA />
                </div>
                <div className={formatClassName(styles, `token b ${side === 'b' && 'selected'}`)} onClick={() => changeSide('b')}>
                  <ABCTokenB />
                </div>
                <div className={formatClassName(styles, `token c ${side === 'c' && 'selected'}`)} onClick={() => changeSide('c')}>
                  <ABCTokenC />
                </div>
                <div className={formatClassName(styles, `token random ${side === 'random' && 'selected'}`)} onClick={() => changeSide('random')}>
                  <ABCTokenRandom />
                </div>
              </div>
            </div>
            <div className={formatClassName(styles, 'setting')}>
              <div className={formatClassName(styles, 'title')}>
                Parameters
              </div>
              <div className={formatClassName(styles, 'content parameters')}>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div>Bet Amount {Number(bet === '' ? 0 : bet) !== 0 && <span>(potential win: {new Decimal(bet).mul(100).floor().mul(3).mul(0.95).floor().div(100).toString()})</span>}</div>
                    <div className={formatClassName(styles, 'all-in')} onClick={allIn}>max bet</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <YOOH />
                    <Input type="number" placeholder='0.00' min={0} max={MAX_BET[opponent]} value={bet} onChange={betChange} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).add(1).toString()}} as any)
                        coinsSoundABC.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).minus(1).toString()}} as any)
                        coinsSoundABC.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={formatClassName(styles, 'setting create')}>
              {
                !sessionToken
                  ? <ConnectButton />
                  : <ButtonIcon icon="coins" loading={creatingGame || !isConnected || !tokens} loadingWithChildren onClick={createGame}>
                    {!isConnected ? 'Connecting' : !tokens ? 'Loading tokens' : creatingGame ? 'Creating game' : '”Triple or Nothing!'}
                  </ButtonIcon>
              }
            </div>
          </div>
        </div>
        <div className={formatClassName(styles, `scene ${runningGame ? 'running-game' : ''}`)}>
          <img src={`${CDN_URL}/games/abc/whale.png`} alt="whale" className={formatClassName(styles, 'whale')} />
          <img src={`${CDN_URL}/games/abc/wave.png`} alt="wave" className={formatClassName(styles, 'wave')} />
          <img src={`${CDN_URL}/games/abc/water.png`} alt="water" className={formatClassName(styles, 'water')} />
          <img src={`${CDN_URL}/games/abc/boat-full.png`} alt="boat" className={formatClassName(styles, 'boat')} />
          <div className={formatClassName(styles, 'island-absolute')}>
            <div className={formatClassName(styles, 'island-relative')}>
              <img src={`${CDN_URL}/games/abc/island.png`} alt="island" className={formatClassName(styles, 'island')} />
              <img src={`${CDN_URL}/games/abc/moon.png`} alt="moon" className={formatClassName(styles, 'moon')} />
              <img src={`${CDN_URL}/games/abc/skeleton.png`} alt="skeleton" className={formatClassName(styles, 'skeleton')} />
              <img src={`${CDN_URL}/games/abc/pirate.png`} alt="pirate" className={formatClassName(styles, 'pirate')} />
              <img src={`${CDN_URL}/games/abc/baddie.png`} alt="baddie" className={formatClassName(styles, 'baddie')} />
              <div className={formatClassName(styles, 'letters-absolute')}>
                <div className={formatClassName(styles, 'letters-relative')}>
                  <div className={formatClassName(styles, 'letter-a')}>
                    <div className={formatClassName(styles, `letter-container ${runningGame?.result?.winner === 'a' ? 'winner' : ''}`)}>
                      <img
                        src={`${CDN_URL}/games/abc/a.png`}
                        alt="a"
                        className={formatClassName(styles, `letter ${runningGame?.participants?.a && !runningGame?.result?.winner ? 'selected' : ''}`)}
                      />
                    </div>
                    <YOOH
                      className={formatClassName(styles, `coin ${runningGame?.result?.winner === 'a' ? 'winner' : ''}`)}
                    />
                    <img
                      src={`${CDN_URL}/games/abc/shadow.png`}
                      alt="shadow"
                      className={formatClassName(styles, 'shadow')}
                    />
                  </div>
                  <div className={formatClassName(styles, 'letter-b')}>
                    <div className={formatClassName(styles, `letter-container ${runningGame?.result?.winner === 'b' ? 'winner' : ''}`)}>
                      <img
                        src={`${CDN_URL}/games/abc/b.png`}
                        alt="b"
                        className={formatClassName(styles, `letter ${runningGame?.participants?.b && !runningGame?.result?.winner ? 'selected' : ''}`)}
                      />
                    </div>
                    <YOOH
                      className={formatClassName(styles, `coin ${runningGame?.result?.winner === 'b' ? 'winner' : ''}`)}
                    />
                    <img
                      src={`${CDN_URL}/games/abc/shadow.png`}
                      alt="shadow"
                      className={formatClassName(styles, 'shadow')}
                    />
                  </div>
                  <div className={formatClassName(styles, 'letter-c')}>
                    <div className={formatClassName(styles, `letter-container ${runningGame?.result?.winner === 'c' ? 'winner' : ''}`)}>
                      <img
                        src={`${CDN_URL}/games/abc/c.png`}
                        alt="c"
                        className={formatClassName(styles, `letter ${runningGame?.participants?.c && !runningGame?.result?.winner ? 'selected' : ''}`)}
                      />
                    </div>
                    <YOOH
                      className={formatClassName(styles, `coin ${runningGame?.result?.winner === 'c' ? 'winner' : ''}`)}
                    />
                    <img
                      src={`${CDN_URL}/games/abc/shadow.png`}
                      alt="shadow"
                      className={formatClassName(styles, 'shadow')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={formatClassName(styles, `users ${runningGame ? 'visible ' : ''}`)}>
          <ABCTokenA />
          {runningGame?.participants?.a?.username
            ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.a?.userUuid)}>{runningGame.participants.a.username}</span>
            : 'waiting user'
          }
          <ABCTokenB />
          {runningGame?.participants?.b?.username
            ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.b?.userUuid)}>{runningGame.participants.b.username}</span>
            : 'waiting user'
          }
          <ABCTokenC />
          {runningGame?.participants?.c?.username
            ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.c?.userUuid)}>{runningGame.participants.c.username}</span>
            : 'waiting user'
          }
        </div>
      </div>
      <div className={formatClassName(styles, 'active-games')}>
        <div className={formatClassName(styles, 'title')}>
          Active Games {activeGames.length > 0 && <>({activeGames.length})</>}
          <span className={formatClassName(styles, 'active-games-toggle')} onClick={() => setShowAllActiveGames(!showAllActiveGames)} >
            <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={showAllActiveGames} readOnly />
            <span>show all</span>
          </span>
        </div>
        {
          (showAllActiveGames ? activeGames : activeGames.slice(-5)).map(game => {
            const isCanceling = !!cancelingGames.find(cancelingGame => cancelingGame.roundId === game.roundId)
            const myGame = game.participants.a?.userUuid === userUuid || game.participants.b?.userUuid === userUuid || game.participants.c?.userUuid === userUuid

            return <div key={`active-game-${game.roundId}`} className={formatClassName(styles, 'active-game')}>
              <div className={formatClassName(styles, 'participants')}>
                <div className={formatClassName(styles, game.participants.a?.username ? '' : 'waiting')}>
                  <ABCTokenA />
                  {game?.participants?.a?.username
                    ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.a?.userUuid)}>{game.participants.a.username}</span>
                    : 'waiting user'
                  }
                </div>
                <div className={formatClassName(styles, game.participants.b?.username ? '' : 'waiting')}>
                  <ABCTokenB />
                  {game?.participants?.b?.username
                    ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.b?.userUuid)}>{game.participants.b.username}</span>
                    : 'waiting user'
                  }
                </div>
                <div className={formatClassName(styles, game.participants.c?.username ? '' : 'waiting')}>
                  <ABCTokenC />
                  {game?.participants?.c?.username
                    ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(runningGame?.participants?.c?.userUuid)}>{game.participants.c.username}</span>
                    : 'waiting user'
                  }
                </div>
              </div>
              <div className={formatClassName(styles, 'middle b')}>
                {
                  game.endingAt !== undefined
                    ? <Countdown date={game.endingAt} renderer={rendererLight} onComplete={() => removeGameFromActiveList(game)}></Countdown>
                    : myGame
                      ? <Button className={formatClassName(styles, 'no-color cancel-button')} loading={isCanceling} onClick={() => cancelGame(game)}>leave <YOOH /> {(game.formattedAmount ?? 0) / 100}</Button>
                      : <div className={formatClassName(styles, 'join-options')}>
                        {
                          !game.participants.a && <Button className={formatClassName(styles, 'no-color join-button')} loading={!isConnected} onClick={() => joinGame(game, 'a')}>
                            join <ABCTokenA /><YOOH /> {(game.formattedAmount ?? 0) / 100}
                          </Button>
                        }
                        {
                          !game.participants.b && <Button className={formatClassName(styles, 'no-color join-button')} loading={!isConnected} onClick={() => joinGame(game, 'b')}>
                            {isConnected && <>
                              join <ABCTokenB /><YOOH /> {(game.formattedAmount ?? 0) / 100}
                            </>}
                          </Button>
                        }
                        {
                          !game.participants.c && <Button className={formatClassName(styles, 'no-color join-button')} loading={!isConnected} onClick={() => joinGame(game, 'c')}>
                            {isConnected && <>
                              join <ABCTokenC /><YOOH /> {(game.formattedAmount ?? 0) / 100}
                            </>}
                          </Button>
                        }
                      </div>}
              </div>
              <div className={formatClassName(styles, 'total c')}>
                potential win <YOOH /> {Math.floor(game.formattedAmount * 3 * 0.95) / 100}
              </div>
            </div>
          })
        }

      </div>
      <div className={formatClassName(styles, 'history-games')}>
        <div className={formatClassName(styles, 'title')}>
          History
          {
            sessionToken && <span className={formatClassName(styles, 'my-games-toggle')} onClick={() => myGamesChange({target: {checked: !myGamesChecked}} as any)} >
              <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={myGamesChecked} readOnly />
              <span>only my games</span>
            </span>
          }
        </div>
        <div className={formatClassName(styles, 'list')}>
          {
            (myGamesChecked ? myHistory : history).slice(0, 9).map(game => {
              if (!game.result) return

              const winnerSide = game.result.winner as 'a' | 'b' | 'c'
              const loserSides = ['a', 'b', 'c'].filter(entry => entry !== winnerSide) as ('a' | 'b' | 'c')[]
              const myGame = game.participants.a?.userUuid === userUuid || game.participants.b?.userUuid === userUuid || game.participants.c?.userUuid === userUuid
              const won = game.result && winnerSide && game.participants[winnerSide]?.userUuid === userUuid

              return <div key={`history-${game.roundId}`} className={formatClassName(styles, `history ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                <div className={formatClassName(styles, 'info')}>
                  {myGame
                    ? <>
                      <div className={formatClassName(styles, `username ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                        You {won ? <>won <YOOH /> {(game.result?.winnerAmount ?? 0) / 100}</> : <>lost <YOOH /> {(game.formattedAmount ?? 0) / 100}</>}
                      </div>
                      <div>
                        vs {won
                          ? game.mode === 'solo'
                            ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[loserSides[0]]?.userUuid)}>{game.participants[loserSides[0]]?.username}</span>
                            : <>
                              <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[loserSides[0]]?.userUuid)}>{game.participants[loserSides[0]]?.username}</span>
                              &nbsp;&&nbsp;
                              <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[loserSides[1]]?.userUuid)}>{game.participants[loserSides[1]]?.username}</span>
                            </>
                          : <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[winnerSide]?.userUuid)}>{game.participants[winnerSide]?.username}</span>
                        }
                      </div>
                    </>
                    : <>
                      <div className={formatClassName(styles, 'username')}>
                        <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[winnerSide]?.userUuid)}>{game.participants[winnerSide]?.username}</span>
                        won<YOOH /> {(game.result?.winnerAmount ?? 0) / 100}
                      </div>
                      <div>
                        <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[loserSides[0]]?.userUuid)}>{game.participants[loserSides[0]]?.username}</span>
                        &nbsp;&&nbsp;
                        <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[loserSides[1]]?.userUuid)}>{game.participants[loserSides[1]]?.username}</span>
                      </div>
                    </>
                  }
                </div>
                <div className={formatClassName(styles, 'badge')}>
                  <img src={`${CDN_URL}/games/abc/${game.result?.winner}.png`} alt={game.result?.winner} />
                </div>
                <div className={formatClassName(styles, 'time-ago')}>
                  <Countdown date={game.createdAt} renderer={() => timeAgoRenderer(game.endedAt ?? Date.now())} overtime></Countdown>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}