import {sound} from '@pixi/sound'
import Decimal from 'decimal.js'
import FontFaceObserver from 'fontfaceobserver'
import gsap, {Power1, Power2} from 'gsap'
import {Spine} from 'pixi-spine'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {Container, Text, TextMetrics, TextStyle} from 'pixi.js'
import {CDN_URL} from '../../../../utils/constants'
import {BOMBZ_NUMBER_OF_SLOTS} from '../constants'
import {BombzGame, BombzRevealCellMessage} from '../types'
import {explosionAt} from './effect'
import {SoundName} from './enums'
import {explodePowerUp, initPowerUp} from './power-up'
import {loadSounds, randomBubblePopNeutral} from './sound'
import {bombzState} from './state'
import {BombzState} from './types'
import {getBombzGameHeight, getBombzGameWidth} from './utils'

/**
 * Initialize the game into a canvas DOM object
 * @param canvas The canvas DOM object to inject the game into
 * @returns The application object
 */
export const initBombzGame = async (
  canvas: HTMLCanvasElement | undefined,
  config?: BombzState['external'],
  onLoaderProgress?: (progress: number, exited?: boolean) => void
) => {
  if (!canvas) return

  bombzState.external = {
    ...config,
    revealCell,
    initBombsGrid,
    cashout,
    creatingGame,
    startProgram,
    exitProgram
  }

  bombzState.exited = false

  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2

  const parent = canvas.parentElement!

  const app = new PIXI.Application({
    view: canvas,
    resolution: Math.max(2, window.devicePixelRatio),
    autoDensity: true,
    antialias: true,
    resizeTo: parent,
    backgroundAlpha: 0
  })

  bombzState.app = app

  app.stage.interactive = true

  app.ticker.stop()

  const loader = PIXI.Assets

  const gsapTicker = () => {
    app.ticker.update()
  }

  // Now, we use 'tick' from gsap
  gsap.ticker.add(gsapTicker)

  bombzState.external.clearGame = async () => {
    bombzState.exited = true

    gsap.ticker.remove(gsapTicker)
    loader.unloadBundle('bombz')
    sound.removeAll()
    app.destroy()

    bombzState.external.clearGame = undefined
  }

  const rifficFont = new FontFaceObserver('Riffic')
  const balooThambiFont = new FontFaceObserver('Baloo Thambi')

  loader.addBundle('bombz', {
    bomb: CDN_URL + '/games/bombz/images/bomb.png',
    yooh: CDN_URL + '/games/bombz/images/yooh.png',
    // Spine
    heart: CDN_URL + '/games/bubble/animations/heart/Heart.json',
    coin: CDN_URL + '/games/bubble/animations/coin/Coin.json',
    powerUp: CDN_URL + '/games/bubble/animations/power-up/PowerUp.json',
    explosion: CDN_URL + '/games/bubble/animations/explosion/Explosion.json',
    // Particles
    fireParticle: CDN_URL + '/games/bubble/particles/fire.png',
    circleParticle: CDN_URL + '/games/bubble/particles/circle.png',
    // Effects
    waterDisplacement: CDN_URL + '/games/bubble/effects/water-displacement.jpg',
  })

  let resources

  try {
    await loadSounds()
    resources = await loader.loadBundle('bombz')
  } catch (err) {
    console.error(err)

    if (!bombzState.exited && bombzState.external.clearGame) {
      bombzState.exited = true

      bombzState.external.clearGame()

      if (bombzState.external.showLoading) bombzState.external.showLoading(false)

      if (bombzState.external.showModal) bombzState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the graphic resources, please check your internet connection and refresh the page'
      })
    }

    return
  }

  bombzState.resources = resources

  try {
    await Promise.all([
      rifficFont.load(),
      balooThambiFont.load()
    ])

    if (onLoaderProgress) onLoaderProgress(100)

    app.resize()

    createViewport()

    initBombsGrid()

    app.ticker.add((delta) => {
      const roundedDelta = new Decimal(delta).mul(10).round().div(10).toNumber()

      update(roundedDelta)
    })
  } catch (err) {
    console.error('loading failed', err)
  }

  return bombzState.external.clearGame
}

const initBombsGrid = (game?: BombzGame) => {
  if (!bombzState.viewport) {
    setTimeout(() => {
      try {
        initBombsGrid(game)
      } catch (err) {console.error(err)}
    }, 100)

    return
  }

  if (bombzState.references.cells) {
    bombzState.references.cells.forEach(cell => {
      try {
        cell.destroy()
        cell.removeFromParent()
        // eslint-disable-next-line no-empty
      } catch (err) {}
    })
  }

  const viewport = bombzState.viewport

  if (!viewport) return

  viewportReset()

  if (!bombzState.references.cells) bombzState.references.cells = []

  for (let i = 0; i < BOMBZ_NUMBER_OF_SLOTS; i++) {
    const sqrtSlots = Math.sqrt(BOMBZ_NUMBER_OF_SLOTS)

    const row = Math.floor(i / sqrtSlots)
    const column = i % sqrtSlots

    const powerUp = initPowerUp()

    if (!powerUp) return

    powerUp.scale.set(1, 1)
    powerUp.width = 70
    powerUp.height = 70
    powerUp.x = (500 / sqrtSlots) * 0.5 + column * (500 / sqrtSlots)
    powerUp.y = (500 / sqrtSlots) * 0.5 + row * (500 / sqrtSlots)
    powerUp.alpha = 0

    bombzState.references.cells[i] = powerUp

    if (game) {
      if (game.cells[i].revealed) {
        powerUp.alpha = 0

        if (game.cells[i].status === 'coin') {
          const yooh = createYoohSprite(powerUp)

          viewport.addChild(yooh)
        }
      } else {
        powerUp.interactive = true
        powerUp.cursor = 'pointer'

        powerUp.on('pointertap', async () => {
          if (powerUp.state.getCurrent(0).animation?.name === 'Idle' && bombzState.external.sendRevealCell) {
            bombzState.external.sendRevealCell(i)

            gsap.to(powerUp.scale, {
              duration: 1.5,
              ease: Power2.easeOut,
              x: powerUp.scale.x * 0.5,
              y: powerUp.scale.y * 0.5
            })
          }
        })

        gsap.to(powerUp, {
          duration: 0.8,
          //ease: Power1.easeOut,
          alpha: 1,
        })
      }
    } else {
      gsap.to(powerUp, {
        duration: Math.random() + 1,
        //ease: Power1.easeOut,
        alpha: 0.3,
        yoyo: true,
        repeat: -1,
      })
    }

    setMiddleText(game ? 'click to reveal' : 'bet to start a game!')

    setBombzMultiplierText('max multiplier 15.2x')

    viewport.addChild(powerUp)
  }

  //title.alpha = 0

  //ease.add(title, {
  //  y: title.y + title.height * 0.1,
  //  alpha: 1
  //}, {
  //  ease: 'easeOutQuad',
  //})
}

const setMiddleText = (text: string) => {
  if (!bombzState.viewport) return

  if (!bombzState.references.middleText) {
    const style = new TextStyle({
      fontFamily: 'Riffic',
      fill: 0xFFFFFF,
      fontSize: 28,
      dropShadow: true,
      dropShadowAngle: 90,
      dropShadowColor: 0x333333,
      dropShadowAlpha: 0.1
    })

    const middleText = new Text('', style)

    const textMetrics = TextMetrics.measureText(middleText.text, style)

    middleText.x = 250 - textMetrics.width / 2
    middleText.y = 250 - textMetrics.height * 0.75

    bombzState.references.middleText = middleText

    bombzState.viewport.addChild(middleText)
  }

  const middleText = bombzState.references.middleText

  middleText.text = text

  const textMetrics = TextMetrics.measureText(middleText.text, middleText.style)

  middleText.x = 250 - textMetrics.width / 2
}

export const setBombzMultiplierText = (text: string) => {
  if (!bombzState.viewport) return

  if (!bombzState.references.multiplierText) {
    const style = new TextStyle({
      fontFamily: 'Riffic',
      fill: 0xFFFFFF,
      fontSize: 16,
      dropShadow: true,
      dropShadowAngle: 90,
      dropShadowColor: 0x333333,
      dropShadowAlpha: 0.1
    })

    const multiplierText = new Text('', style)

    multiplierText.alpha = 0.8

    const textMetrics = TextMetrics.measureText(multiplierText.text, style)

    multiplierText.x = 250 - textMetrics.width / 2
    multiplierText.y = 250 + textMetrics.height * 0.25

    bombzState.references.multiplierText = multiplierText

    bombzState.viewport.addChild(multiplierText)
  }

  const multiplierText = bombzState.references.multiplierText

  multiplierText.text = text

  const textMetrics = TextMetrics.measureText(multiplierText.text, multiplierText.style)

  multiplierText.x = 250 - textMetrics.width / 2
}

const cashout = (game: BombzGame, finalRate: number, finalWin: number) => {
  if (!bombzState.viewport) return

  let nbRevealing = 0

  for (let index = 0; index < Object.keys(game.cells).length; index++) {
    const powerUp = bombzState.references.cells?.[index]

    if (!powerUp?.state) continue

    powerUp.interactive = false

    setTimeout(() => {
      if (!bombzState.viewport || !powerUp?.state || powerUp.state.getCurrent(0).animation?.name !== 'Idle' || powerUp.alpha === 0) return

      powerUp.state.timeScale = 1
      powerUp.width = 70
      powerUp.height = 70

      explodePowerUp(powerUp)

      randomBubblePopNeutral()

      if (game.cells[index].status === 'coin') {
        const yooh = createYoohSprite(powerUp)

        bombzState.viewport.addChild(yooh)
      } else {
        const bomb = createBombSprite(powerUp)

        bombzState.viewport.addChild(bomb)
      }
    }, (nbRevealing++) * 50)
  }

  setMiddleText(`${finalWin / 100} won (${finalRate}x)`)
}

const revealCell = ({
  cellIndex,
  cell,
  nextMultiplier,
  game
}: BombzRevealCellMessage) => {
  if (!bombzState.viewport) return

  const powerUp = bombzState.references.cells?.[cellIndex]

  if (!powerUp) return

  powerUp.state.timeScale = 1
  powerUp.width = 70
  powerUp.height = 70

  explodePowerUp(powerUp)

  if (cell.status === 'coin') {
    const yooh = createYoohSprite(powerUp)

    bombzState.viewport.addChild(yooh)

    const currentPower = sound.find(SoundName.clickAffirmative)

    currentPower.play()

    setMiddleText(`next ${nextMultiplier}x`)
  } else if (game?.cells) {
    const bomb = createBombSprite(powerUp)

    bombzState.viewport.addChild(bomb)

    explosionAt(powerUp.width, powerUp.x, powerUp.y)

    let nbRevealing = 0

    for (let index = 0; index < Object.keys(game.cells).length; index++) {
      const powerUp = bombzState.references.cells?.[index]

      if (!powerUp?.state) continue

      powerUp.interactive = false

      setTimeout(() => {
        if (!bombzState.viewport || !powerUp?.state || powerUp.state.getCurrent(0).animation?.name !== 'Idle' || powerUp.alpha === 0) return

        powerUp.state.timeScale = 1
        powerUp.width = 70
        powerUp.height = 70

        explodePowerUp(powerUp)

        randomBubblePopNeutral()

        if (game.cells[index].status === 'coin') {
          const yooh = createYoohSprite(powerUp)

          bombzState.viewport.addChild(yooh)
        } else {
          const bomb = createBombSprite(powerUp)

          bombzState.viewport.addChild(bomb)
        }
      }, 500 + (nbRevealing++) * 50)
    }

    const currentPower = sound.find(SoundName.clickBomb)

    currentPower.play()

    setMiddleText('You lost!')
  }
}

const creatingGame = () => {
  if (!bombzState.references.cells) return

  for (let index = 0; index < Object.keys(bombzState.references.cells).length; index++) {
    const powerUp = bombzState.references.cells?.[index]

    if (!powerUp?.state) continue

    gsap.to(powerUp.scale, {
      duration: 1,
      //ease: Power1.easeOut,
      x: 0,
      y: 0
    })

    gsap.to(powerUp, {
      duration: 1,
      alpha: 0
    })
  }
}

const startProgram = () => {
  if (bombzState.references.cells) {
    bombzState.references.cells.forEach(cell => {
      try {
        cell.destroy()
        cell.removeFromParent()
        // eslint-disable-next-line no-empty
      } catch (err) {}
    })
  }

  const viewport = bombzState.viewport

  if (!viewport) return

  viewportReset()

  if (!bombzState.references.cells) bombzState.references.cells = []

  let programIndex = 1

  for (let i = 0; i < BOMBZ_NUMBER_OF_SLOTS; i++) {
    const sqrtSlots = Math.sqrt(BOMBZ_NUMBER_OF_SLOTS)

    const row = Math.floor(i / sqrtSlots)
    const column = i % sqrtSlots

    const powerUp = initPowerUp()

    if (!powerUp) return

    powerUp.scale.set(1, 1)
    powerUp.width = 70
    powerUp.height = 70
    powerUp.x = (500 / sqrtSlots) * 0.5 + column * (500 / sqrtSlots)
    powerUp.y = (500 / sqrtSlots) * 0.5 + row * (500 / sqrtSlots)
    powerUp.alpha = 0

    bombzState.references.cells[i] = powerUp

    powerUp.interactive = true
    powerUp.cursor = 'pointer'

    const style = new TextStyle({
      fontFamily: 'Riffic',
      fill: 0x1e90ff,
      fontSize: 128,
      lineHeight: 64,
      stroke: 0xffffff,
      strokeThickness: 16
    })

    const indexText = new Text('', style)

    const textMetrics = TextMetrics.measureText(indexText.text, style)

    indexText.x = - textMetrics.width / 2
    indexText.y = - textMetrics.height / 2

    powerUp.addChild(indexText)

    powerUp.on('pointertap', async () => {
      if (bombzState.external.programStep && indexText.text === '') {
        bombzState.external.programStep(i)

        indexText.text = programIndex++

        const textMetrics = TextMetrics.measureText(indexText.text, style)

        indexText.x = - textMetrics.width / 2
        indexText.y = - textMetrics.height / 2
      }
    })

    gsap.to(powerUp, {
      duration: 0.8,
      //ease: Power1.easeOut,
      alpha: 0.8,
    })

    viewport.addChild(powerUp)
  }

  setMiddleText('click on bubbles to program')
}

const exitProgram = () => {
  initBombsGrid()
}

const createYoohSprite = (powerUp: Spine) => {
  const yooh = new PIXI.Sprite(bombzState.resources.yooh)
  yooh.pivot.set(yooh.width * 0.5, yooh.height * 0.5)
  yooh.width = powerUp.width * 0.6
  yooh.height = powerUp.height * 0.6
  yooh.x = powerUp.x
  yooh.y = powerUp.y
  yooh.alpha = 1
  yooh.rotation = -0.5

  gsap.to(yooh, {
    duration: 0.8,
    ease: Power1.easeOut,
    rotation: 0
  })

  return yooh
}

const createBombSprite = (powerUp: Spine) => {
  const bomb = new PIXI.Sprite(bombzState.resources.bomb)
  bomb.pivot.set(bomb.width * 0.5, bomb.height * 0.5)
  bomb.width = powerUp.width * 0.7
  bomb.height = powerUp.height * 0.7
  bomb.x = powerUp.x
  bomb.y = powerUp.y
  bomb.alpha = 1

  return bomb
}

export const createViewport = () => {
  if (!bombzState.app) return

  //if (bombzState.viewport) {
  //  bombzState.viewport.destroy()
  //}

  bombzState.app.stage.removeChildren()
  bombzState.app.stage.removeAllListeners()

  const viewport = new Viewport({
    screenWidth: getBombzGameWidth(),
    screenHeight: getBombzGameHeight(),

    events: bombzState.app.renderer.events as any
  })

  viewport.sortableChildren = true

  bombzState.app.stage.addChild(viewport)

  bombzState.app.renderer.on('resize', () => {
    viewport.resize(getBombzGameWidth(), getBombzGameHeight())

    viewport.fit(undefined, 500, 500).moveCenter(250, 250)
  })

  viewport.resize(getBombzGameWidth(), getBombzGameHeight())

  viewport.fit(undefined, 500, 500).moveCenter(250, 250)

  bombzState.viewport = viewport

  // Particles

  const particlesContainer = new Container()

  particlesContainer.zIndex = 95

  bombzState.particles.container = particlesContainer

  viewport.addChild(particlesContainer)

  //addWaterEffect(bombzState.app.stage, new Sprite(bombzState.resources.waterDisplacement))
  //addShockwaveEffect(viewport, new Point(250, 250), undefined, {duration: 0.5})
}

/**
 * Update the state of the game
 * @param delta The delta (time passed since previous update)
 */
const update = (delta: number) => {
  updateEmitters(delta)
}

const updateEmitters = (delta: number) => {
  bombzState.particles.emitters?.forEach(emitter => {
    emitter.update(delta * 0.01)
  })
}

const viewportReset = () => {
  const viewport = bombzState.viewport

  if (viewport) {
    viewport.removeChildren()

    bombzState.references.middleText = undefined
    bombzState.references.multiplierText = undefined
    bombzState.references.cells = undefined
  }
}