import {tryOrReconnect} from './hub'

export const getTwitterUser = async () => {
  const result = await tryOrReconnect<{
    twitterUsername: string | undefined
  }>({
    method: 'GET',
    url: '/api/v1/twitter/me'
  })

  if (!result || result.status !== 200) {
    //toast.error('Please verify that you have a Twitter account linked to your Pirate Squad account')
    return
  }

  return result.data
}

export const generatePKCE = async () => {
  const result = await tryOrReconnect<{
    codeChallenge: string
    state: string
  } | undefined>({
    method: 'GET',
    url: '/api/v1/twitter/pkce'
  })

  if (!result || result.status !== 200) return

  return result.data
}
