import React, {TextareaHTMLAttributes} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './textarea.module.scss'

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({className, ...props}, ref) => {
  return <textarea
    ref={ref}
    className={formatClassName(styles, `textarea ${className}`)}
    {...props}
  />
})