@import "scss-variables";

.baddie-beast {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 0.5rem;
  overflow-y: auto;
  max-width: 1200px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media screen and (min-width: $sm-breakpoint) {
    padding: 0;
  }

  .marketing {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    min-height: 100px;
    overflow: hidden;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    @media screen and (min-width: $sm-breakpoint) {
      min-height: 125px;
    }

    @media screen and (min-width: $md-breakpoint) {
      min-height: 150px;
    }

    img {
      position: absolute;
      width: 100%;
    }

    .offer {
      z-index: 1;

      .countdown {
        background-color: transparent;
        color: white;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  .game {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    position: relative;

    @media screen and (min-width: $lg-breakpoint) {
      grid-template-columns: repeat(auto-fit, minmax(500, 1fr));
    }

    .loading {
      backdrop-filter: blur(0.5rem);
    }
  }

  .settings {
    display: grid;
    grid-template-columns: 1fr;
    background-color: $color-dark-gray;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
    justify-content: space-between;

    @media screen and (min-width: $md-breakpoint) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1rem;
      gap: 1rem;
    }

    .setting {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        gap: 1rem;
      }

      .title {
        font-size: 1rem;
        font-weight: 700;
      }

      .content {
        display: flex;
        align-items: center;
        flex: 1;

        &.tokens {
          display: flex;
          justify-content: space-between;

          .token {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.25rem;
            flex: 1;
            padding: 0.5rem 1rem;
            max-width: 5rem;
            border-radius: 0.5rem;
            font-size: 14px;
            font-weight: 600;

            opacity: 0.5;
            transition: all 300ms;

            img {
              transition: all 300ms;
            }

            &.selected {
              background-color: $color-gray;
              opacity: 1;

              &.beast > img {
                filter: drop-shadow(0px 0.25rem 0.75rem rgba(85, 197, 227, 0.6));
              }

              &.baddie > img {
                filter: drop-shadow(0px 0.25rem 0.75rem rgba(218, 68, 255, 0.5));
              }

              &.random > img {
                filter: drop-shadow(0px 0.25rem 0.75rem rgba(255, 216, 52, 0.6));
              }
            }

            &:hover {
              cursor: pointer;

              &:not(.selected) {
                background-color: $color-light-gray;
              }
            }
          }
        }

        &.parameters {
          display: flex;
          gap: 0.5rem;

          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .separator {
            padding-top: 1rem;
          }

          .parameter {
            flex: 1;

            .name {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              font-weight: 500;

              .all-in {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .input {
              display: flex;
              align-items: center;
              background-color: $color-gray;
              border-radius: 0.5rem;
              padding: 0.5rem;
              box-sizing: border-box;
              height: 2.5rem;
              gap: 0.5rem;

              @media screen and (min-width: $sm-breakpoint) {
                height: 3rem;
              }

              &.disabled {
                opacity: 0.7;
                cursor: not-allowed;
              }

              img,
              svg {
                height: 1rem;
                opacity: 0.7;
              }

              input {
                flex: 1;
                background-color: transparent;
                width: 100%;
                outline: 0;
                padding: 0;
                font-weight: 500;
                font-size: 1rem;

                /* Firefox */
                &[type="number"] {
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  -moz-appearance: textfield;
                }
              }

              .increment {
                display: flex;
                flex-direction: row-reverse;
                border-radius: 0.25rem;
                overflow: hidden;

                @media screen and (min-width: $sm-breakpoint) {
                  flex-direction: column;
                }

                button {
                  height: 100%;
                  border-radius: 0;
                  padding: 0;
                  flex: 1;
                  width: 1rem;
                  background: white;
                  color: #333;
                  font-weight: 600;

                  &:hover {
                    background-color: #fffd;
                  }

                  @media screen and (min-width: $sm-breakpoint) {
                    line-height: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      &.create {
        position: relative;
        @media screen and (min-width: $md-breakpoint) {
          grid-column: 2/3;
        }

        button {
          height: 2.5rem;

          @media screen and (min-width: $sm-breakpoint) {
            height: 3rem;
          }
        }

        .confetti {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          pointer-events: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .your-match {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;

    @media screen and (min-width: $xs-breakpoint) {
      padding: 1rem;
      gap: 1rem;
    }

    .title {
      font-size: 1rem;
      font-weight: 700;
    }

    .header {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: 100%;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5rem;
      height: 3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 600;

      .participant {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 100%;

        img {
          height: 100%;
        }

        &:last-child {
          justify-content: end;
        }
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      aspect-ratio: 1/1;
      position: relative;
      margin-top: 1.5rem;
      align-self: center;
      background-color: #e3eafe;
      border-radius: 0.5rem;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;

      animation-name: show;
      animation-duration: 1s;

      .badge {
        position: absolute;
        height: 4rem;
        width: 4rem;
        top: -2rem;
        border-radius: 4rem;
        border: 0.25rem solid white;
        box-sizing: border-box;
        background-color: white;
      }

      > img {
        width: 100%;
        border-radius: 0.5rem;
      }

      .unknown {
        .countdown {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 48px;
          font-weight: 700;
          opacity: 0.7;
        }

        > img {
          opacity: 0.15;
        }
      }
    }

    .results {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e3eafe;
      padding: 0.5rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 600;
      height: 3rem;

      img {
        max-height: 1.5rem;
        height: 100%;
        margin: 0 0.5rem;
      }
    }
  }

  .active-games {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      gap: 1rem;

      .active-games-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        gap: 0.5rem;
        user-select: none;
        cursor: pointer;

        .toggle {
          pointer-events: none;
        }
      }
    }

    .active-game {
      display: grid;
      grid:
        "a c" 1fr
        "b b" 1fr /1fr 1fr;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 600;
      background: linear-gradient(270deg, #fff1 0%, #fff0 50%, #fff1 100%);
      gap: 0.5rem;

      animation-name: show;
      animation-duration: 1s;

      //&.flipping {
      //  animation-delay: 4s;
      //  animation-direction: reverse;
      //}

      @media screen and (min-width: $xs-breakpoint) {
        padding: 1rem;
        grid: "a b c" 100% /1fr auto 1fr;
        gap: 1rem;

        img {
          max-height: 3rem;
        }
      }

      img {
        max-height: 2rem;
      }

      .middle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 2rem;

        .join-button,
        .cancel-button {
          border: 1px solid $color-blue;
          height: 2rem;

          img {
            max-height: 1.5rem;
            height: 100%;
            margin: 0 0.5rem;
          }

          &.cancel-button {
            border: 1px solid $color-warning;
            color: $color-warning;
          }
        }

        .flip {
          position: relative;
          width: 100%;
          height: 100%;
          transform-style: preserve-3d;

          &.beast {
            animation: flip-beast 3s ease forwards;
          }

          &.baddie {
            animation: flip-baddie 3s ease forwards;
          }

          .beast-side,
          .baddie-side {
            width: 2rem;
            height: 2rem;
            position: absolute;
            backface-visibility: hidden;
          }

          .beast-backside,
          .baddie-backside {
            width: 2rem;
            height: 2rem;
            background-color: #888;
            position: absolute;
            top: 0;
            backface-visibility: hidden;
            border-radius: 50%;
            transform-style: preserve-3d;
          }

          .beast-side {
            transform: translateZ(-2px) rotateY(-180deg);
          }

          .beast-backside {
            transform: translateZ(-2px);
          }

          .baddie-side {
            transform: translateZ(2px);
          }

          .baddie-backside {
            transform: translateZ(2px) rotateY(180deg);
          }

          &:after {
            content: "";
            height: 2rem;
            width: 4px;
            background-color: #888;
            position: absolute;
            transform: rotateY(90deg) translateZ(0.8rem);
          }
        }
      }

      .a {
        grid-area: a;
      }

      .b {
        display: flex;
        grid-area: b;

        button {
          width: 100%;
        }
      }

      .c {
        grid-area: c;
      }

      .participant {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 100%;

        img {
          height: 100%;
        }

        &:last-child {
          justify-content: end;
        }
      }
    }
  }

  .history-games {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      gap: 1rem;

      .my-games-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        gap: 0.5rem;
        user-select: none;
        cursor: pointer;

        .toggle {
          pointer-events: none;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        grid-template-columns: repeat(auto-fit, minmax(350, 1fr));
        gap: 1rem;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
      }

      .history {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        gap: 1rem;
        background: linear-gradient(270deg, #fff0 0%, #fff1 100%);

        animation-name: show;
        animation-duration: 1s;

        @media screen and (min-width: $xs-breakpoint) {
          padding: 1rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-between;
          height: 100%;
          gap: 1rem;

          .username {
            gap: 0.5rem;
            font-size: 16px;
            font-weight: 600;
            width: fit-content;
            display: flex;
            align-items: center;

            &.won,
            &.lost {
              padding: 0.1rem 0.4rem;
              border-radius: 0.5rem;
              color: white;
            }

            &.won {
              background-color: rgba($color-success, 0.5);
            }

            &.lost {
              background-color: rgba($color-danger, 0.5);
            }

            img {
              height: 10px;
            }
          }
        }

        .badge {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          width: 3rem;

          @media screen and (min-width: $xs-breakpoint) {
            top: 1rem;
            right: 1rem;
          }

          img {
            width: 3rem;
          }
        }

        .time-ago {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          display: flex;
          justify-content: center;
          width: 3rem;
          font-size: 11px;
          opacity: 0.6;

          @media screen and (min-width: $xs-breakpoint) {
            bottom: 1rem;
            right: 1rem;
          }
        }

        &.beast .badge {
          filter: drop-shadow(0 0 0.25rem #55c5e355);
        }

        &.baddie .badge {
          filter: drop-shadow(0 0 0.25rem #da44ff55);
        }
      }
    }
  }

  .multi-button {
    display: flex;
    width: 100%;
    background-color: #343434;
    border-radius: 0.5rem;
    padding: 0.3rem;
    gap: 0.5rem;
    box-sizing: border-box;
    height: 2.5rem;

    @media screen and (min-width: $sm-breakpoint) {
      height: 3rem;
    }

    > button {
      flex: 1;
      height: 100%;
      transition: all 300ms;

      img {
        height: 100%;
      }

      &.selected {
        color: rgba(0, 0, 0, 1);
        background-color: white;
      }

      &:hover {
        background-color: white !important;

        &:not(.selected) {
          background-color: #fff7 !important;
        }
      }
    }
  }

  .profile {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.confirm-join {
  display: flex;
  align-items: center;

  img {
    width: 1rem !important;
    margin: 0 0.5rem;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes flip-beast {
  0% {
    transform: rotateY(0deg) scale(1);
  }

  50% {
    transform: rotateY(900deg) scale(2);
  }

  100% {
    transform: rotateY(1980deg) scale(1);
  }
}

@keyframes flip-baddie {
  0% {
    transform: rotateY(0deg) scale(1);
  }

  50% {
    transform: rotateY(900deg) scale(2);
  }

  100% {
    transform: rotateY(1800deg) scale(1);
  }
}
