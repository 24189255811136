import {Howl} from 'howler'
import {CDN_URL} from '../../../utils/constants'

export const ambienceABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ambiences/ambience-4_no-whistle_no-battle.mp3`],
  loop: true,
  volume: 0.05,
  preload: true
})

export const clickAffirmativeSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-2.mp3`],
  volume: 0.15,
  preload: true
})

export const coinsSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-coin.mp3`],
  volume: 0.2,
  preload: true
})

export const selectSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-3.mp3`],
  volume: 0.2,
  preload: true
})

export const clickNegativeSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-negative.mp3`],
  volume: 0.2,
  preload: true
})

export const winSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/win_nomusic_nopirate.mp3`],
  volume: 0.25,
  preload: true
})

export const loseSoundABC = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/losing.mp3`],
  volume: 0.2,
  preload: true
})