// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {ArraySchema, Schema, type} from '@colyseus/schema'
import {BubbleCollideEffect, BubbleDestroyEffect} from '../../enums'
import {BubbleSchema} from './BubbleSchema'
import {PowerUpSchema} from './PowerUpSchema'

export class LevelSchema extends Schema {
  @type([BubbleSchema]) public tiles: ArraySchema<BubbleSchema> = new ArraySchema<BubbleSchema>();
  @type([PowerUpSchema]) public powerUp: ArraySchema<PowerUpSchema> = new ArraySchema<PowerUpSchema>();
  @type('number') public levelIndex!: number
  @type('number') public bubbleSpeed!: number
  @type('number') public bubbleWidth!: number
  @type('number') public numberOfBubblesSent!: number
  @type('number') public limitNumberOfBubbles!: number
  @type('number') public score!: number
  @type('number') public lives!: number
  @type('number') public coins!: number
  @type('number') public stars!: number
  @type('number') public actualTopRowIndex!: number
  @type('boolean') public canShoot!: boolean
  @type('boolean') public paused!: boolean
  @type('boolean') public ended!: boolean
  @type('boolean') public won!: boolean
  @type('string') public actualBubbleDestroyEffect!: BubbleDestroyEffect
  @type('string') public nextBubbleDestroyEffect!: BubbleDestroyEffect
  @type('string') public actualBubbleCollideEffect!: BubbleCollideEffect
  @type('string') public nextBubbleCollideEffect!: BubbleCollideEffect
  @type(BubbleSchema) public bubbleInHand: BubbleSchema = new BubbleSchema();
  @type(BubbleSchema) public bubbleInCannon: BubbleSchema = new BubbleSchema();
  @type(BubbleSchema) public bubbleFired: BubbleSchema = new BubbleSchema();
}
