import {AptosSnapAdapter, AptosWalletAdapter, BloctoWalletAdapter, FewchaWalletAdapter, MartianWalletAdapter, PontemWalletAdapter, RiseWalletAdapter, WalletAdapter, WalletProvider} from '@manahippo/aptos-wallet-adapter'
import React, {useEffect, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {formatClassName} from '../../../utils/global'
import {isSessionTokenValid} from '../../../utils/session'
import {AptosConnectButton} from '../../components/aptos/aptos-connect'
import {Button} from '../../components/buttons/button'
import {useAptosWallet} from '../../hooks/use-aptos-wallet'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import styles from './wallet.module.scss'

export const AptosWalletPage = () => {
  const wallets = useMemo(
    () => [
      new MartianWalletAdapter(),
      new PontemWalletAdapter(),
      new RiseWalletAdapter(),
      new FewchaWalletAdapter(),
      new AptosSnapAdapter(),
      new BloctoWalletAdapter(),
      new AptosWalletAdapter(),
    ] as WalletAdapter<string>[],
    []
  )

  return (
    <WalletProvider
      wallets={wallets}
      onError={(error: Error) => {
        console.log('Handle Error Message', error)
      }}
      autoConnect
    >
      <AptosConnector />
    </WalletProvider>
  )
}

const AptosConnector = () => {
  const {dispatch} = useHubContext()
  const navigate = useNavigate()
  const {state: {sessionToken}} = useHubContext()
  const {connected, connect, loading, disconnect} = useAptosWallet()

  const selectChain = () => {
    dispatch({type: 'SET_CHAIN', chain: undefined})
    navigate('/select-chain')
  }

  useEffect(() => {
    if (sessionToken || !connected || isSessionTokenValid()) return

    connect()
  }, [connect, connected, sessionToken])

  useEffect(() => {
    hubState.sessionConnect = connect
  }, [connect])

  useEffect(() => {
    dispatch({type: 'SET_DISCONNECT_WALLET', disconnectWallet: disconnect})
  }, [disconnect])

  return <div className={formatClassName(styles, 'wallet')}>
    <div className={formatClassName(styles, 'form')}>
      {loading && connected
        ? <p>Waiting authorization, <span className={formatClassName(styles, 'purple')}>please sign and wait...</span></p>
        : <p>Connect an <span className={formatClassName(styles, 'purple')}>Aptos wallet</span></p>
      }
      <AptosConnectButton />
      <Button className={formatClassName(styles, 'change-button gray')} onClick={selectChain}>Change chain</Button>
    </div>
  </div>
}