import gsap from 'gsap'
import {SkeletonData, Spine} from 'pixi-spine'
import {Sprite, Text, TextMetrics, TextStyle, Texture} from 'pixi.js'
import {BubbleSkinName} from './enums'
import {randomBubblePop} from './sound'
import {bubbleState} from './state'
import {getRatio} from './utils'

/**
 * Create a new bubble with the specified skin
 * @param skin The skin
 * @returns The bubble Spine object
 */
export const initBubble = (skin = BubbleSkinName.BlueOne): Spine | undefined => {
  if (!bubbleState.resources?.bubble.spineData) return

  const bubble = new Spine(bubbleState.resources.bubble.spineData as SkeletonData)

  bubble.skeleton.setSkinByName(skin)
  bubble.state.setAnimation(0, 'Idle', false)

  return bubble
}


const cachedTextTextures: {
  texture: Texture,
  metrics: TextMetrics
}[] = []

/**
 * Destroy the bubble
 * @param bubble The bubble Spine object to destroy
 * @param score The score to show or undefined if no
 * @param silent Destroy the bubble without sound
 * @param destroyingCallback Callback called after the Destroying animation of the bubble
 */
export const destroyBubble = (bubble: Spine, score?: number, silent?: boolean, destroyingCallback?: () => void) => {
  if (!bubble || bubble.destroyed) return

  const ratio = getRatio()

  bubble.state.setAnimation(0, 'Destroying', false)

  if (!silent) randomBubblePop().play()

  if (score !== undefined) {
    if (!cachedTextTextures[`${score}`]) {
      const style = new TextStyle({
        fontFamily: 'Baloo Thambi',
        fontWeight: '700',
        fill: 0xFFFFFF,
        fontSize: Math.floor((24 / bubble.scale.x) * ratio),
        stroke: 0x666666,
        strokeThickness: Math.floor((2 / bubble.scale.x) * ratio)
      })

      const text = new Text(score, style)
      const textMetrics = TextMetrics.measureText(text.text, style)

      text.updateText(true)

      cachedTextTextures[`${score}`] = {
        texture: text.texture,
        metrics: textMetrics
      }
    }

    const scoreText = new Sprite(cachedTextTextures[`${score}`].texture)
    scoreText.x = -cachedTextTextures[`${score}`].metrics.width / 2
    scoreText.y = -cachedTextTextures[`${score}`].metrics.height / 2

    bubble.addChild(scoreText)

    gsap.to(scoreText, {
      duration: 1,
      y: scoreText.y - cachedTextTextures[`${score}`].metrics.height / 4,
      alpha: 0,
      onComplete() {
        if (destroyingCallback) destroyingCallback()

        // Catch potential errors and mute them (garbage collector can be executed before this)
        try {
          bubble.destroy()
          // eslint-disable-next-line no-empty
        } catch {}
      }
    })
  } else {
    bubble.state.addListener({
      complete(entry) {
        if (entry.animation?.name === 'Destroying') {
          setTimeout(() => {
            if (destroyingCallback) destroyingCallback()

            // Catch potential errors and mute them (garbage collector can be executed before this)
            try {
              bubble.destroy()
              // eslint-disable-next-line no-empty
            } catch {}
          })
        }
      }
    })
  }
}

export const randomBubbleSkin = (availableSkins: BubbleSkinName[]) => {
  if (!bubbleState.resources?.bubble) return

  const skins = bubbleState.resources.bubble.spineData?.skins.filter(skin => availableSkins.includes(skin.name as BubbleSkinName))

  if (!skins) return

  return skins[Math.floor(Math.random() * skins.length)]
}