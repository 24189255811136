import React, {HtmlHTMLAttributes} from 'react'
import {useNavigate} from 'react-router-dom'
import {HUB_CDN_URL} from '../../../../../utils/constants'
import {formatClassName} from '../../../../../utils/global'
import {AppCard} from '../../../../components/cards/app-card'
import {useHubContext} from '../../../../state/context'
import styles from './games.module.scss'

export type GamesProps = HtmlHTMLAttributes<HTMLDivElement>

export const Games = ({className, ...props}: GamesProps) => {
  const {dispatch, state: {sessionToken}} = useHubContext()
  const navigate = useNavigate()

  const launchGame = (game: string, needToBeConnected?: boolean) => {
    if (needToBeConnected) {
      if (!sessionToken) {
        dispatch({
          type: 'SET_SHOW_CONNECT_MODAL',
          showConnectModal: true
        })

        return
      }
    }

    navigate(`/dashboard/games/${game}`)
  }

  return (
    <div className={formatClassName(styles, `games ${className}`)} {...props}>
      <div className={formatClassName(styles, 'grid')}>
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/slots.jpg`} onClick={() => launchGame('slots')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/bombz.png`} onClick={() => launchGame('bombz')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/baddie-beast.png`} onClick={() => launchGame('baddie-beast')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/abc.png`} onClick={() => launchGame('abc')} labelType="luck" />
        <AppCard imageURL={`${HUB_CDN_URL}/cards/games/bubble.png`} onClick={() => launchGame('bubble', true)} labelType="skill" />
      </div>
    </div>
  )
}