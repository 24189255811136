import {SlotsState} from './types'

export const slotsState: SlotsState = {
  loading: false,
  exited: false,
  particles: {},
  references: {
    reelsContainers: [],
    reels: [
      [],
      [],
      []
    ],
    indicators: {},
    celebrations: []
  },
  external: {}
}