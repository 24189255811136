import {useWallet} from '@manahippo/aptos-wallet-adapter'
import {useCallback, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {isSessionTokenValid, setSessionInfo} from '../../utils/session'
import {connectToServer, generateNonce} from '../api/hub'
import {useHubContext} from '../state/context'
import {hubState} from '../state/hub'
import {ConnectResponseType} from './types'
import {useHub} from './use-hub'

export const useAptosWallet = () => {
  const {disconnect: disconnectHub} = useHub()
  const {dispatch, state: {sessionToken}} = useHubContext()
  const {wallet, signMessage, disconnect, connected} = useWallet()

  const publicKey = wallet?.adapter.publicAccount.publicKey?.toString()

  const [previousPublicKey, setPreviousPublicKey] = useState(publicKey)
  const [loading, setLoading] = useState(false)

  const walletConnected = !!wallet

  const connect = useCallback(async (): ConnectResponseType => {
    if (!publicKey || !signMessage) return

    try {
      setLoading(true)

      const nonce = await generateNonce()

      if (!nonce) {
        toast.error('Impossible to get a nonce, please try again later or contact the support team.')
        setLoading(false)

        return
      }

      const message = 'wallet-ownership-verification'

      //const msgPayload = [
      //  'pontem',
      //  'petra',
      //  'martian',
      //  'fewcha',
      //  'rise wallet',
      //  'snap',
      //  'blocto'
      //].includes(wallet.adapter?.name?.toLowerCase() || '')
      //  ? {
      //    message: `${message}_${nonce}`,
      //    nonce
      //  } as SignMessagePayload
      //  : textEncoder.encode(`${message}_${nonce}`)

      const signatureResponse = await signMessage({
        message,
        nonce
      })

      const signature = typeof signatureResponse === 'string' ? signatureResponse : signatureResponse.signature
      const fullMessage = typeof signatureResponse === 'string' ? message : signatureResponse.fullMessage

      const response = await connectToServer(publicKey.toString().substring(2), signature.substring(2), fullMessage, nonce)

      if (response.status === 401) {
        toast.error('We cannot verify your wallet ownership, please try again later or contact the support team.')

        disconnectHub()
      } else if (response.status === 404) {
        toast.error('Request error, please try again later or contact the support team.')
        disconnectHub()
      } else {
        const data = response.data

        if (data && data.sessionToken) {
          dispatch({type: 'SET_SESSION_TOKEN', sessionToken: data.sessionToken})
          dispatch({type: 'SET_PUBLIC_KEY', publicKey})

          setSessionInfo({
            sessionToken: data.sessionToken,
            publicKey: publicKey,
            expireAt: data.expireAt,
            twitterUsername: data.twitter?.username
          })

          hubState.sessionToken = data.sessionToken

          if (data.twitter?.username) {
            dispatch({type: 'SET_TWITTER_USERNAME', twitterUsername: response.data.twitter.username})
          }

          toast.success('You are now connected!')

          setLoading(false)

          return response.data as {
            sessionToken: string
            expireAt: number
            twitter?: {
              username?: string
            }
          }
        } else {
          toast.error('Impossible to find the sessionToken, please try again later or contact the support team')

          disconnectHub()
        }
      }
    } catch (err) {
      toast.info('Wallet change or request error, try to connect again or contact the support team')

      disconnectHub()

      console.info(err)
    }

    setLoading(false)
  }, [publicKey, signMessage])

  useEffect(() => {
    if (!sessionToken) return

    const publicKeyChanged = !previousPublicKey || !publicKey || previousPublicKey !== publicKey

    if ((!walletConnected || !connected || publicKeyChanged) && sessionToken && !isSessionTokenValid()) {
      dispatch({type: 'SET_SESSION_TOKEN', sessionToken: undefined})

      disconnect()
    }
  }, [sessionToken, disconnect, walletConnected, connected, previousPublicKey, publicKey])

  useEffect(() => {
    setPreviousPublicKey(publicKey)
  }, [publicKey])

  return {
    connect,
    disconnect,
    signMessage,
    connected,
    walletConnected,
    loading,
    publicKey
  }
}