import {Howl} from 'howler'
import {CDN_URL} from '../../../../../utils/constants'

export const clickAffirmativeSoundRewards = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-2.mp3`],
  volume: 0.15,
  preload: true
})

export const winSoundRewards = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/win_nomusic_nopirate.mp3`],
  volume: 0.20,
  preload: true
})