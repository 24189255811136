import * as PIXI from 'pixi.js'
import {Point} from 'pixi.js'
import {bubbleState} from '../state'

export const distanceBetween = (x1: number, y1: number, x2: number, y2: number) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)

export const radialGradient = (from: string, to: string, x: number, y: number, r0: number, r1: number, width: number, height: number) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height
  const ctx = c.getContext('2d')!
  const grd = ctx.createRadialGradient(x, y, r0, x, y, r1)
  grd.addColorStop(0, from)
  grd.addColorStop(0.8, to)
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, width, height)
  return PIXI.Texture.from(c)
}

export const linearGradient = (from: string, to: string, x: number, y: number, width: number, height: number) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height
  const ctx = c.getContext('2d')!
  const grd = ctx.createLinearGradient(x, y, width, height)
  grd.addColorStop(0, from)
  grd.addColorStop(1, to)
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, width, height)
  return PIXI.Texture.from(c)
}

const sign = (p1: Point, p2: Point, p3: Point) => {
  return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y)
}

export const pointIsInTriangle = (point: Point, t1: Point, t2: Point, t3: Point) => {
  const d1 = sign(point, t1, t2)
  const d2 = sign(point, t2, t3)
  const d3 = sign(point, t3, t1)

  const has_neg = (d1 < 0) || (d2 < 0) || (d3 < 0)
  const has_pos = (d1 > 0) || (d2 > 0) || (d3 > 0)

  return !(has_neg && has_pos)
}

export const getRatio = () => getGameWidth() / 480

export const getGameWidth = () => {
  if (!bubbleState.app) return 0

  return (bubbleState.app.view.parentNode as HTMLElement).offsetWidth
}

export const getGameHeight = () => {
  if (!bubbleState.app) return 0

  return (bubbleState.app.view.parentNode as HTMLElement).offsetHeight
}

export const index1DFrom2D = (row: number, column: number, sizeOfRow: number) => {
  return (row * sizeOfRow) + column
}

export const index2DFrom1D = (index: number, sizeOfRow: number) => {
  return {
    row: Math.floor(index / sizeOfRow),
    column: index % sizeOfRow
  }
}