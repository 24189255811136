import React, {useEffect, useState} from 'react'
import {YOOH} from '../../../../../../svg/token'
import {formatClassName} from '../../../../../../utils/global'
import {getDailyLeaderboard} from '../../../../../api/quests'
import {Button, ButtonIcon} from '../../../../../components/buttons/button'
import {FlipDate} from '../../../../../components/flip/flip'
import {OverlayModal, OverlayModalProps} from '../../../../../components/modal/modal'
import {useHubContext} from '../../../../../state/context'
import styles from './daily-leaderboard.module.scss'

export type DailyLeaderboard = {
  rewards: number[],
  top: number,
  raffles: number,
  list: {
    username: string
    userUuid: string
    maxStreak: number
    tickets: number
    chanceToWin: number
  }[]
}

export type DailyLeaderboardProps = OverlayModalProps & {
  onHide: () => void
}

export const DailyLeaderboard = ({className, onHide, ...props}: DailyLeaderboardProps) => {
  const {dispatch, state: {sessionToken}} = useHubContext()

  const date = new Date()
  date.setUTCHours(23, 59, 59, 999)

  const [isRefreshing, setIsRefreshing] = useState(true)
  const [leaderboard, setLeaderboard] = useState<DailyLeaderboard>()
  const [endDailyQuest, setEndDailyQuest] = useState(date)
  const [selectedDate, setSelectedDate] = useState(date)

  useEffect(() => {
    refresh(selectedDate)
  }, [selectedDate])

  const refresh = async (date: Date) => {
    setIsRefreshing(true)
    setLeaderboard(await getDailyLeaderboard(date))
    setIsRefreshing(false)
  }

  const changeSelectedDate = (addDay: number) => {
    const newDate = new Date(selectedDate)

    newDate.setUTCDate(newDate.getUTCDate() + addDay)

    setSelectedDate(newDate)
  }

  const goToday = () => {
    setSelectedDate(endDailyQuest)
  }

  const isToday = selectedDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
  const selectedDateString = selectedDate.toISOString().split('T')[0]

  const maxTickets = Math.max(...(leaderboard?.list.map(entry => entry.tickets) ?? [0]))

  const openProfile = (userUuid: string) => {
    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  return <OverlayModal className={formatClassName(styles, `daily-leaderboard-overlay ${className}`)} {...props}>
    <div className={formatClassName(styles, 'daily-leaderboard')}>
      <div className={formatClassName(styles, 'title')}>
        Daily Rewards - Leaderboard
        <ButtonIcon className={formatClassName(styles, 'close-button no-color')} icon="xmark" onClick={onHide}></ButtonIcon>
      </div>
      <div className={formatClassName(styles, `countdown ${isToday ? 'visible' : ''}`)}>
        <FlipDate date={endDailyQuest} showLabels={false} onEnded={() => {
          const date = new Date()
          date.setUTCHours(23, 59, 59, 999)

          setEndDailyQuest(date)
          setSelectedDate(date)
        }} />
      </div>
      <div className={formatClassName(styles, 'details')}>
        <div className={formatClassName(styles, 'detail')}>
          <strong>top 1-{leaderboard?.top ?? '...'}</strong> <YOOH /> {Math.max(...(leaderboard?.rewards ?? [0]).slice(0, leaderboard?.top ?? 0)) / 100} to <YOOH /> {Math.min(...(leaderboard?.rewards ?? [0]).slice(0, leaderboard?.top ?? 0)) / 100}
        </div>
        {
          (leaderboard?.raffles ?? 0) > 0 &&
          <div className={formatClassName(styles, 'detail')}><strong>top {(leaderboard?.top ?? 0) + 1}-infinity</strong> {leaderboard?.raffles ?? 0} raffles of <YOOH /> {(leaderboard?.rewards?.[(leaderboard?.top ?? 0) + 1] ?? 0) / 100}</div>
        }
      </div>
      <div className={formatClassName(styles, 'leaderboard')}>
        <table>
          <thead>
            <tr>
              <th>
                Rank
              </th>
              <th>
                Pirate
              </th>
              <th>
                tickets
              </th>
              <th>
                Prize
              </th>
            </tr>
          </thead>
          <tbody>
            {
              (leaderboard?.list ?? []).map((entry, index) => (
                <tr key={`daily-leaderboard-${index}`}>
                  <td>#{index + 1}</td>
                  <td><span className={formatClassName(styles, 'profile')} onClick={() => openProfile(entry.userUuid)}>{entry.username}</span></td>
                  <td>
                    <div className={formatClassName(styles, 'progress')}>
                      <div className={formatClassName(styles, `bar ${index < (leaderboard?.top ?? 0) ? 'top' : ''}`)} style={{width: `${(entry.tickets / maxTickets) * 100}%`}}></div>
                    </div>
                  </td>
                  <td><YOOH /> {(leaderboard?.rewards[Math.min(index, leaderboard?.rewards.length - 1)] ?? 0) / 100} {index >= (leaderboard?.top ?? 0) && `(${Math.floor(entry.chanceToWin * 10000) / 100}%)`}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className={formatClassName(styles, 'actions')}>
        <ButtonIcon icon="arrow-left" className={formatClassName(styles, 'no-color previous')} disabled={isRefreshing} loading={isRefreshing} onClick={() => changeSelectedDate(-1)}></ButtonIcon>
        <Button className={formatClassName(styles, 'no-color refresh')} loading={isRefreshing} onClick={() => isToday ? refresh(endDailyQuest) : goToday()}>{!isToday ? `${selectedDateString} (go back today)` : 'refresh'}</Button>
        <ButtonIcon icon="arrow-right" className={formatClassName(styles, 'no-color next')} disabled={isToday} loading={isRefreshing} onClick={() => changeSelectedDate(1)}></ButtonIcon>
      </div>
    </div>
  </OverlayModal>
}