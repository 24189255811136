import {Transaction, VersionedTransaction} from '@solana/web3.js'
import {ReactElement} from 'react'
import {TypeOptions} from 'react-toastify'
import {InputProps} from '../components/input/input'
import {ConnectResponseType} from '../hooks/types'

export const hubState: {
  sessionToken?: string
  setSessionToken?: (sessionToken: string) => void
  sessionConnect?: () => ConnectResponseType | undefined
  showModal?: (props: {
    title: string
    text?: string
    copyText?: string
    image?: string
    closeText?: string
    onClose?: () => void
  }) => void
  showConfirm?: (props: {
    title: string
    text?: string | ReactElement
    image?: string
    acceptText?: string
    refuseText?: string
    onAccept?: () => void
    onRefuse?: () => void
  }) => void
  showInputModal?: (props: {
    title: string
    text?: string
    inputProps?: InputProps
    image?: string
    acceptText?: string
    refuseText?: string
    onAccept: (value?: string) => void
    onRefuse?: () => void
  }) => void
  showLoading?: (loading: boolean, progress?: number, loadingText?: string) => void
  refreshTokens?: () => Promise<{
    yaah?: number
    yooh?: number
  }>
  refreshInfo?: (pauseRefreshTokens?: boolean) => Promise<{
    tokens: {
      yaah?: number
      yooh?: number
    },
    notifications: {
      label: string
      message: string
      type: TypeOptions
      createdAt: number
    }[]
    referral: {
      toBeClaimed: number
    }
    revenueSharing: number
  }>
  signMessage?: ((message: Uint8Array) => Promise<Uint8Array>) | undefined
  signTransaction?: (<T extends Transaction | VersionedTransaction>(transaction: T) => Promise<T>) | undefined
  //setTokens?: (tokens: {
  //  yaah: number
  //  yooh: number
  //}) => void
  clearGame?: () => void
  disconnect?: () => void
} = {}