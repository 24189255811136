@import "scss-variables";

.abc {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 0.5rem;
  overflow-y: auto;
  max-width: 1200px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media screen and (min-width: $sm-breakpoint) {
    padding: 0;
  }

  .game-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 1rem;
    flex: none;
    background-image: url("https://cdn.piratesquadnft.com/games/abc/sky.png");
    background-position: center;
    background-size: cover;
    opacity: 0.9;

    .game {
      z-index: 2;
      position: relative;

      .loading {
        backdrop-filter: blur(0.5rem);
      }
    }

    .scene {
      z-index: 1;
      position: relative;
      height: 8rem;
      transition: transform 2s 500ms;
      transform-origin: center 105%;

      @media screen and (min-width: $xs-breakpoint) {
        height: 12rem;
      }

      @media screen and (min-width: $sm-breakpoint) {
        height: 15rem;
      }

      @media screen and (min-width: $md-breakpoint) {
        height: 20rem;
      }

      * {
        pointer-events: none;
      }

      &.running-game {
        transform: scale(3) translateZ(0);

        @media screen and (min-width: $xs-breakpoint) {
          transform: scale(2.5) translateZ(0);
        }

        @media screen and (min-width: $sm-breakpoint) {
          transform: scale(2) translateZ(0);
        }

        @media screen and (min-width: $md-breakpoint) {
          transform: scale(1.6) translateZ(0);
        }

        @media screen and (min-width: $lg-breakpoint) {
          transform: scale(1.4) translateZ(0);
        }

        .pirate,
        .baddie,
        .skeleton,
        .moon,
        .boat,
        .whale,
        .wave {
          opacity: 0;
        }

        .letter {
          &:not(.selected) {
            filter: grayscale(1);
          }
        }
      }

      .whale {
        z-index: 0;
        position: absolute;
        max-height: 40%;
        max-width: 20%;
        left: 0;
        bottom: 34%;
        transform-origin: center;

        transition: all 1s;

        animation: whale 3s infinite alternate;

        @keyframes whale {
          from {
            transform: rotateZ(-5deg);
          }
          to {
            transform: rotateZ(5deg);
          }
        }
      }

      .wave {
        z-index: 0;
        position: absolute;
        max-width: 25%;
        max-height: 60%;
        right: 0;
        bottom: 34%;
        transform-origin: center;

        transition: all 1s;

        animation: wave 4s infinite alternate;

        @keyframes wave {
          from {
            transform: translateY(-2%);
          }
          to {
            transform: translateY(2%);
          }
        }
      }

      .water {
        z-index: 1;
        width: calc(100% + 18rem);
        height: calc(50% + 1rem);
        position: absolute;
        bottom: -1rem;
        left: -14rem;

        transform-origin: center;

        animation: water 10s infinite alternate;

        @keyframes water {
          from {
            transform: translateX(-1.5%);
          }
          to {
            transform: translateX(1.5%);
          }
        }
      }

      .boat {
        z-index: 2;
        position: absolute;
        bottom: 15%;
        right: 20%;
        max-width: 30%;
        max-height: 100%;

        transition: all 1s;

        transform-origin: 75% 75%;

        animation: boat 5s infinite alternate;

        @keyframes boat {
          from {
            transform: rotateZ(-2.5deg);
          }
          to {
            transform: rotateZ(2.5deg);
          }
        }
      }

      .island-absolute {
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -1rem;

        .island-relative {
          position: relative;
          height: 100%;
          width: 100%;

          .island,
          .moon,
          .skeleton,
          .pirate,
          .baddie {
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 100%;
            max-height: 100%;
            transition: all 1s;
          }

          .moon {
            transform-origin: 8%;
            animation: swing 4s ease infinite;

            @keyframes swing {
              50% {
                transform: rotate(0deg);
              }
              60% {
                transform: rotate(3deg);
              }
              70% {
                transform: rotate(-3deg);
              }
              80% {
                transform: rotate(1deg);
              }
              90% {
                transform: rotate(-1deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }

          .letters-absolute {
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            height: 35%;
            width: 17%;
            bottom: 20%;

            .letters-relative {
              position: relative;
              display: flex;
              height: 100%;
              justify-content: center;
              gap: 5%;

              .letter-a,
              .letter-b,
              .letter-c {
                position: relative;
                max-width: 33%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                gap: 10%;

                .letter-container {
                  display: flex;
                  transition: transform 1s 1.5s;
                  z-index: 1;

                  &.winner {
                    transform: translateY(-50%);

                    .letter {
                      filter: none;
                    }
                  }

                  .letter {
                    width: 100%;
                    transition: scale 1s, filter 1s 1s;
                    animation: letter 1s infinite;
                  }
                }

                .coin {
                  position: absolute;
                  width: 0.1rem;
                  left: 0;
                  right: 0;
                  bottom: 20%;
                  margin: auto;
                  opacity: 0;
                  transition: all 1s 1s;

                  @media screen and (min-width: $xs-breakpoint) {
                    width: 0.2rem;
                  }

                  @media screen and (min-width: $sm-breakpoint) {
                    width: 0.3rem;
                  }

                  @media screen and (min-width: $md-breakpoint) {
                    width: 0.4rem;
                  }

                  @media screen and (min-width: $lg-breakpoint) {
                    width: 0.5rem;
                  }

                  &.winner {
                    opacity: 1;
                    filter: drop-shadow(0px 0.25rem 0.75rem rgb(233, 130, 12));
                    transform: scale(2);
                  }
                }

                .shadow {
                }
              }

              .letter-a .letter-container .letter {
                animation-duration: 3200ms;
                animation-delay: 300ms;
              }

              .letter-b {
                max-width: 28%;

                .letter-container .letter {
                  animation-duration: 3500ms;
                  animation-delay: 500ms;
                }
              }

              .letter-c .letter-container .letter {
                animation-duration: 2900ms;
              }

              @keyframes letter {
                from,
                to,
                20% {
                  transform: scale(1, 1);
                }
                40% {
                  transform: scale(0.9, 1.1);
                }
                60% {
                  transform: scale(1.1, 0.9);
                }
                80% {
                  transform: scale(0.95, 1.05);
                }
              }
            }
          }
        }
      }
    }

    .users {
      position: absolute;
      background-color: #fffd;
      color: #666;
      backdrop-filter: blur(0.5rem);
      gap: 0.5rem;
      padding: 0.5rem;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      z-index: 1;
      width: 100%;
      opacity: 0;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.75rem;

      transition: all 1s;

      @media screen and (min-width: $xs-breakpoint) {
        flex-direction: row;
        grid-template-columns: repeat(6, 1fr);
        font-size: 1rem;
        line-height: 1rem;

        svg {
          height: 1rem;
        }
      }

      &.visible {
        opacity: 1;
      }

      svg {
        height: 0.75rem;
        margin: auto;
      }
    }
  }

  .settings {
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
    justify-content: space-between;

    @media screen and (min-width: $md-breakpoint) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1rem;
      gap: 1rem;
    }

    .setting {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        gap: 1rem;
      }

      .title {
        font-size: 1rem;
        font-weight: 700;
        color: #666;
      }

      .content {
        display: flex;
        align-items: end;
        flex: 1;

        &.tokens {
          display: flex;
          justify-content: space-between;

          .token {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 3rem;
            box-sizing: border-box;
            gap: 0.25rem;
            flex: 1;
            padding: 0.5rem 1rem;
            max-width: 5rem;
            border-radius: 0.5rem;
            font-size: 14px;
            font-weight: 600;

            opacity: 0.4;
            transition: all 300ms;

            svg {
              transition: all 300ms;
              width: 100%;
              height: 100%;
            }

            &.selected {
              background-color: white;
              opacity: 1;

              svg {
                fill: #52baf0;
              }
            }

            &:hover {
              cursor: pointer;

              &:not(.selected) {
                background-color: #f0f4fd;
              }
            }
          }
        }

        &.parameters {
          display: flex;
          gap: 0.5rem;

          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .separator {
            padding-top: 1rem;
          }

          .parameter {
            flex: 1;

            .name {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.6);

              .all-in {
                color: rgba(0, 0, 0, 0.6);
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .input {
              display: flex;
              align-items: center;
              background-color: white;
              border-radius: 0.5rem;
              padding: 0.5rem;
              box-sizing: border-box;
              height: 2.5rem;
              gap: 0.5rem;

              @media screen and (min-width: $sm-breakpoint) {
                height: 3rem;
              }

              img,
              svg {
                height: 1rem;
                opacity: 0.7;
              }

              input {
                flex: 1;
                background-color: transparent;
                width: 100%;
                outline: 0;
                padding: 0;
                font-weight: 500;
                font-size: 1rem;
                color: #666;

                /* Firefox */
                &[type="number"] {
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  -moz-appearance: textfield;
                }
              }

              .increment {
                display: flex;
                flex-direction: row-reverse;
                border-radius: 0.25rem;
                overflow: hidden;

                @media screen and (min-width: $sm-breakpoint) {
                  flex-direction: column;
                }

                button {
                  height: 100%;
                  border-radius: 0;
                  padding: 0;
                  flex: 1;
                  width: 1rem;
                  background-color: #d3dfff;
                  font-weight: 600;

                  &:hover {
                    background-color: #b8cbff;
                  }

                  @media screen and (min-width: $sm-breakpoint) {
                    line-height: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      &.create {
        @media screen and (min-width: $md-breakpoint) {
          grid-column: 2/3;
        }

        button {
          height: 2.5rem;
          background-color: #52baf0;

          @media screen and (min-width: $sm-breakpoint) {
            height: 3rem;
          }

          &:hover {
            background-color: #3e9fd3;
          }
        }
      }
    }
  }

  .your-match {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;

    @media screen and (min-width: $xs-breakpoint) {
      padding: 1rem;
      gap: 1rem;
    }

    .title {
      font-size: 1rem;
      font-weight: 700;
    }

    .header {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: 100%;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5rem;
      height: 3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 600;

      .participant {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 100%;

        img {
          height: 100%;
        }

        &:last-child {
          justify-content: end;
        }
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      aspect-ratio: 1/1;
      position: relative;
      margin-top: 1.5rem;
      align-self: center;
      background-color: #e3eafe;
      border-radius: 0.5rem;
      max-width: 300px;
      min-height: 300px;
      max-height: 300px;

      animation-name: show;
      animation-duration: 1s;

      > img {
        width: 100%;
        border-radius: 0.5rem;
      }

      .unknown {
        .countdown {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 48px;
          font-weight: 700;
          opacity: 0.7;
        }

        > img {
          opacity: 0.15;
        }
      }
    }

    .results {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e3eafe;
      padding: 0.5rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 600;
      height: 3rem;

      img {
        max-height: 1.5rem;
        height: 100%;
        margin: 0 0.5rem;
      }
    }
  }

  .active-games {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    .participants {
      > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &.waiting {
          opacity: 0.4;
        }

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      gap: 1rem;

      .active-games-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        gap: 0.5rem;
        user-select: none;
        cursor: pointer;

        .toggle {
          pointer-events: none;
        }
      }
    }

    .active-game {
      display: grid;
      grid:
        "a c" 1fr
        "b b" 1fr /1fr 1fr;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 600;
      background-color: $color-gray;
      gap: 0.5rem;

      animation-name: show;
      animation-duration: 1s;

      @media screen and (min-width: $xs-breakpoint) {
        padding: 1rem;
        grid: "a b c" 100% /1fr auto 1fr;
        gap: 1rem;

        img {
          max-height: 3rem;
        }
      }

      img {
        max-height: 2rem;
      }

      .middle {
        display: flex;
        align-items: center;
        justify-content: center;

        .join-options {
          display: flex;
          gap: 1rem;
        }

        .join-button,
        .cancel-button {
          border: 1px solid #2aacd6;
          height: 2rem;

          svg,
          img {
            max-height: 1.5rem;
            height: 100%;
            margin: 0 0.25rem;

            @media screen and (min-width: $xs-breakpoint) {
              margin: 0 0.5rem;
            }
          }

          &.cancel-button {
            border: 1px solid $color-warning;
            color: $color-warning;
          }
        }
      }

      .a {
        grid-area: a;
      }

      .b {
        display: flex;
        grid-area: b;

        button {
          width: 100%;
        }
      }

      .c {
        grid-area: c;
      }

      .total {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 0.5rem;

        img {
          height: 1rem;
        }
      }
    }
  }

  .history-games {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      gap: 1rem;

      .my-games-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        gap: 0.5rem;
        user-select: none;
        cursor: pointer;

        .toggle {
          pointer-events: none;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        grid-template-columns: repeat(auto-fit, minmax(350, 1fr));
        gap: 1rem;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
      }

      .history {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $color-gray;
        padding: 0.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        gap: 1rem;

        animation-name: show;
        animation-duration: 1s;

        @media screen and (min-width: $xs-breakpoint) {
          padding: 1rem;
        }

        .info {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-between;
          height: 100%;
          gap: 1rem;

          .username {
            gap: 0.5rem;
            font-size: 16px;
            font-weight: 600;
            width: fit-content;
            display: flex;
            align-items: center;

            &.won,
            &.lost {
              padding: 0.1rem 0.4rem;
              border-radius: 0.5rem;
              color: white;
            }

            &.won {
              background-color: rgba($color-success, 0.6);
            }

            &.lost {
              background-color: rgba($color-danger, 0.6);
            }

            img {
              height: 1rem;
            }
          }
        }

        .badge {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          width: 3rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: $xs-breakpoint) {
            top: 1rem;
            right: 1rem;
          }

          img {
            height: 2rem;
          }
        }

        .time-ago {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          display: flex;
          justify-content: center;
          width: 3rem;
          font-size: 11px;
          opacity: 0.6;

          @media screen and (min-width: $xs-breakpoint) {
            bottom: 1rem;
            right: 1rem;
          }
        }
      }
    }
  }

  .profile {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .multi-button {
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.3rem;
    gap: 0.5rem;
    box-sizing: border-box;
    height: 2.5rem;

    @media screen and (min-width: $sm-breakpoint) {
      height: 3rem;
    }

    > button {
      flex: 1;
      height: 100%;
      color: rgba(0, 0, 0, 0.5);
      transition: all 300ms;

      img {
        height: 100%;
      }

      &.selected {
        color: white;
        background-color: #52baf0;
        box-shadow: 0px 4px 4px rgba(187, 196, 220, 0.7);
      }

      &:hover {
        background-color: #52baf0 !important;

        &:not(.selected) {
          background-color: #fff7 !important;
        }
      }
    }
  }
}

.confirm-join {
  display: flex;
  align-items: center;

  img {
    width: 1rem !important;
    margin: 0 0.5rem;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.svg-token {
  fill: #999;
}
