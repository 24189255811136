import {sound} from '@pixi/sound'
import {Client, Room} from 'colyseus.js'
import {SoundName} from '../enums'
import {initLevel} from '../level'
import {setRank} from '../level-ui'
import {bubbleState} from '../state'
import {Leaderboard} from '../types'
import {ClassicRoomState} from './schema/ClassicRoomState'
import {LevelSchema} from './schema/LevelSchema'
import {SoloRoomState} from './schema/SoloRoomState'
import {onConnect, onJoinError, onLeave} from './server-handler'

//initBubbleGame(document.getElementById('game') as HTMLCanvasElement, {
//  tryOrReconnect: fakeTryOrReconnect
//})

//export const bubbleColyseusClientURL = 'wss://test-server.piratesquadnft.com'
export const bubbleColyseusClientURL = 'wss://s2.piratesquadnft.com'
//export const bubbleColyseusClientURL = 'ws://localhost:3000'

const bubbleColyseusClient = new Client(bubbleColyseusClientURL)

export const getBubbleColyseusClient = () => bubbleColyseusClient

export const createSoloLevelRoom = async ({
  options,
  onConnect,
  onLeave,
  onJoinError
}: {
  options: {
    clientWidth: number
    clientHeight: number
    level: number
    sessionToken?: string
  },
  onConnect: (room: Room, level: LevelSchema) => void
  onLeave: (code: number) => void
  onJoinError: (err: unknown) => void
}) => {
  try {
    const room = await bubbleColyseusClient.joinOrCreate('solo', options, SoloRoomState)

    console.log(room.sessionId, 'joined', room.name)

    room.onStateChange.once((state) => {
      onConnect(room, state.level)
    })

    room.onLeave((code) => {
      onLeave(code)

      console.log('leave', code)
    })

    room.onError((code, message) => {
      console.log('ERROR: ', code, ' [', message, ']')
    })

    return room
  } catch (err) {
    onJoinError(err)
  }
}

export const startClassicGame = async (room: Room<ClassicRoomState>) => {
  try {
    console.log(room.sessionId, ' joined classic room ', room.name)

    room.onStateChange(async (state) => {
      const soloRoom = state.soloRooms.get(room.sessionId)

      if (soloRoom) {
        if (!bubbleState.viewport || !bubbleState.gameState || !bubbleState.levels || !bubbleState.external.closeLobbyScreen) return

        sound.find(SoundName.selectLevel).play()

        bubbleState.viewport.removeAllListeners()

        await initLevel(bubbleState.levels[state.level], false, 'classic', room)

        onConnect(room, soloRoom.level, false)

        bubbleState.external.closeLobbyScreen()

        room.onStateChange.clear()
      }
    })

    room.onLeave((code) => {
      onLeave(code)

      console.log('leave', code)
    })

    room.onError((code, message) => {
      console.log('ERROR: ', code, ' [', message, ']')
    })

    room.onMessage<Leaderboard>('leaderboard', async (leaderboard) => {
      if (!leaderboard || !room) return

      const rank = leaderboard.findIndex((leader) => leader.clientId === room.sessionId) + 1

      const strRank = rank === 1 ? '1st' : rank === 2 ? '2nd' : rank === 3 ? '3rd' : `${rank}th`

      setRank(strRank)

      console.log('rank', strRank)
    })

    return room
  } catch (err) {
    onJoinError(err)
  }
}

//(async () => {
//  createSoloLevelRoom({
//    options: {
//      clientWidth: 480,
//      clientHeight: 1000,
//      level: 2
//    },
//    onTilesInit,
//    onTileChange,
//    onJoinError
//  })
//})()