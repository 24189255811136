// Settings

$c_0: black;
$c_1: #353540;

.app-card {
  position: relative;
  z-index: 1;
  width: 100%;
  perspective: 1000px;
  border-radius: 1rem;

  &__cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(120deg, #65f69d 0%, #fda085 100%);
    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
    border-radius: 1rem;

    // Gloss

    &::after {
      display: block;
      content: "";
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      background: linear-gradient(
        226deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.4) 35%,
        rgba(255, 255, 255, 0.2) 42%,
        rgba(255, 255, 255, 0) 60%
      );
      transform: translateY(-20%);
      will-change: transform;
      transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
      border-radius: 1rem;
    }

    .badge {
      position: absolute;
      z-index: 1;
      left: 0.5rem;
      bottom: 0.5rem;
      height: 1rem;
      padding: 0.25rem 0.5rem;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 0.5rem;
      color: white;
      font-family: "Baloo Thambi";
      font-weight: 600;
      img {
        height: 1rem;
      }

      &.luck {
        background: linear-gradient(93.29deg, #3ca909 12.08%, #358510 91.15%);
        border: 2px solid #8dd86a;
        box-shadow: 0px 0px 3.67453px rgba(0, 0, 0, 0.15);
      }

      &.skill {
        background: linear-gradient(93.78deg, #ffb00d 29.7%, #de9600 92.7%);
        border: 2px solid #fbdfa7;
        box-shadow: 0px 0px 3.67453px rgba(0, 0, 0, 0.15);
      }

      &.label {
        opacity: 0.7;
      }
    }
  }

  &:hover &__cover {
    transform: rotateX(7deg) translateY(-6px);

    &::after {
      transform: translateY(0%);
    }
  }

  // Shadows

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba($c_0, 0.5);
    box-shadow: 0 6px 12px 12px rgba($c_0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
    border-radius: 1rem;
  }

  &:hover::before {
    opacity: 0.6;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
  }

  .text {
    position: absolute;
    width: 100%;
    background-color: red;
  }
}
