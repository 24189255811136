import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import axios, {isAxiosError} from 'axios'
import Decimal from 'decimal.js'
import React, {ChangeEventHandler, HtmlHTMLAttributes, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {HUB_CDN_URL} from '../../../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../../../utils/global'
import {useDebounce} from '../../../../../utils/hooks'
import {withdrawRequest} from '../../../../api/liquidity'
import {useHubContext} from '../../../../state/context'
import {Alert} from '../../../alert/alert'
import {Button} from '../../../buttons/button'
import {Input} from '../../../input/input'
import {USDC_MINT_ADDRESS, walletAvailableTokens} from '../utils'
import styles from './withdraw.module.scss'

export type WithdrawProps = HtmlHTMLAttributes<HTMLDivElement>

export const Withdraw = ({className, ...props}: WithdrawProps) => {
  const {state: {publicKey, tokens}} = useHubContext()

  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [fromAmount, setFromAmount] = useState('')
  const [toToken, setToToken] = useState<keyof typeof walletAvailableTokens>('SOL')
  const [toAmount, setToAmount] = useState('')

  const debouncedFromAmount = useDebounce(fromAmount, 500)

  useEffect(() => {
    if (!debouncedFromAmount || isNaN(Number(debouncedFromAmount)) || Number(debouncedFromAmount) === 0) {
      setToAmount('')

      return
    }

    (async () => {
      setIsRefreshing(true)

      if (toToken !== 'USDC') {
        const {data} = await (
          await axios.get('https://quote-api.jup.ag/v4/quote', {
            params: {
              inputMint: USDC_MINT_ADDRESS.toString(),
              outputMint: walletAvailableTokens[toToken].mint.toString(),
              amount: new Decimal(debouncedFromAmount).mul(Math.pow(10, 6)).toNumber(),
              slippageBps: 10
            }
          })
        ).data

        const routes = data

        setToAmount(String(Math.floor(routes[0].outAmount / Math.pow(10, walletAvailableTokens[toToken].decimals) * 100) / 100))
      } else {
        setToAmount(String(Math.floor(Number(debouncedFromAmount) * 100) / 100))
      }

      setIsRefreshing(false)
    })()
  }, [debouncedFromAmount, toToken])

  const fromAmountChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fromAmount = event.target.value

    setFromAmount(fromAmount)
  }

  const withdraw = async () => {
    if (!publicKey || !fromAmount) {
      return
    }

    if (Number(fromAmount) < 1) {
      toast.warning('Minimum withdraw is 1 $YOOH')

      return
    }

    setIsWithdrawing(true)

    const response = await withdrawRequest(Number(fromAmount), toToken, (err) => {
      if (isAxiosError(err)) {
        toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to withdraw, please contact the support team'))
      }
    })

    if (response?.status === 200) {
      fromAmountChange({target: {value: 0}} as any)

      toast.info(formatTextWithYOOH(response.data.message))
    }

    setIsWithdrawing(false)
  }

  const changeToToken = (token: keyof typeof walletAvailableTokens) => {
    setToToken(token)
    setToAmount('')
  }

  const yoohAmount = (tokens?.yooh ?? 0) / 100

  return <div className={formatClassName(styles, `withdraw ${className}`)} {...props}>
    <div className={formatClassName(styles, 'content')}>
      <div className={formatClassName(styles, 'form')}>
        <div className={formatClassName(styles, 'input-icon')} >
          <div className={formatClassName(styles, 'left')}>
            <YOOH />
          </div>
          <Input
            type="text"
            placeholder='$YOOH amount'
            value={fromAmount ?? ''}
            onChange={fromAmountChange}
            maxLength={8}
          />
          <Button loading={!tokens} className='no-color' onClick={() => fromAmountChange({target: {value: yoohAmount}} as any)}>max</Button>
        </div>
        <div className={formatClassName(styles, 'input-icon')} >
          <div className={formatClassName(styles, 'left')}>
            <FontAwesomeIcon icon="up-down" className={formatClassName(styles, 'selector')} />
            {
              isRefreshing
                ? <FontAwesomeIcon spin icon="circle-notch" />
                : <img className={formatClassName(styles, 'clickable')} src={`${HUB_CDN_URL}/tokens/${toToken}.png`} alt={toToken} onClick={() => changeToToken(toToken === 'SOL' ? 'USDC' : 'SOL')} />
            }
          </div>
          <Input
            type="text"
            readOnly
            placeholder={`${toToken} amount`}
            value={toAmount ?? '0'}
            maxLength={8}
          />
        </div>
      </div>
      <Alert type='info' show={true}>1 $YOOH = 1 USDC</Alert>
    </div>
    <Button loading={isWithdrawing} onClick={withdraw}>withdraw</Button>
  </div>
}