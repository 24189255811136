import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {ChangeEventHandler, HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {io, Socket} from 'socket.io-client'
import {YOOH} from '../../../svg/token'
import {PFP_CDN_URL} from '../../../utils/constants'
import {formatClassName} from '../../../utils/global'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import {ButtonIcon} from '../buttons/button'
import {ConnectButton} from '../buttons/connect-button'
import {LoadingOverlay} from '../overlay/loading'
import {Textarea} from '../textarea/textarea'
import styles from './chat.module.scss'
import {ChatClientToServerEvents, ChatServerToClientEvents, IMessage, isNormalMessage, isTipMessage} from './types'

export type ChatProps = HtmlHTMLAttributes<HTMLDivElement>

//export const chatClientURL = 'ws://localhost:3050'
//export const chatClientPath = undefined
//export const chatClientURL = 'wss://test-server.piratesquadnft.com'
//export const chatClientPath = '/chat/socket.io'
//export const chatClientURL = 'wss://s1.piratesquadnft.com'
//export const chatClientPath = '/chat/socket.io'
export const chatClientURL = 'wss://s2.piratesquadnft.com'
export const chatClientPath = '/chat/socket.io'

export const Chat = ({className, ...props}: ChatProps) => {
  const {dispatch, state: {username, sessionToken, pfp, role, userUuid}} = useHubContext()

  const [socket, setSocket] = useState<Socket<ChatServerToClientEvents, ChatClientToServerEvents>>()
  const [isConnected, setIsConnected] = useState(false)
  const [countConnected, setCountConnected] = useState(0)
  const [sending, setSending] = useState(false)
  const [message, setMessage] = useState('')
  const [history, setHistory] = useState<IMessage[]>([])
  const [replyTo, setReplyTo] = useState<IMessage>()

  const container = useRef<HTMLDivElement>(null)

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const messageChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setMessage(event.target.value)

    setTimeout(() => {
      if (!textareaRef.current) return

      textareaRef.current.style.height = '2.5rem'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    })
  }

  const onReplyTo = (message: IMessage) => {
    setReplyTo(message)

    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  const sendMessage = () => {
    if (!socket || message.length === 0) return

    setSending(true)

    socket.emit('message', message, JSON.stringify(replyTo))

    setReplyTo(undefined)
    messageChange({target: {value: ''}} as any)
    setSending(false)
  }

  const removeMessage = (message: IMessage) => {
    if (hubState.showConfirm && socket) {
      hubState.showConfirm({
        title: 'Confirm message removal',
        text: <div>
          <div>from: {message.username}</div>
          <div>text: {message.data.message}</div>
        </div>,
        acceptText: 'remove',
        refuseText: 'cancel',
        onAccept() {
          socket.emit('remove-message', message.uuid)
        }
      })
    }
  }

  const banMessage = (message: IMessage) => {
    if (!message.socketId) return

    if (hubState.showConfirm && socket) {
      hubState.showConfirm({
        title: 'Confirm ban',
        text: <div>
          <div>username: {message.username}</div>
        </div>,
        acceptText: 'ban',
        refuseText: 'cancel',
        onAccept() {
          if (!message.socketId) return

          socket.emit('ban', message.userUuid, message.socketId)
        }
      })
    }
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && event.shiftKey == false) {
      event.preventDefault()

      sendMessage()
    }
  }

  useEffect(() => {
    setSocket(undefined)
  }, [sessionToken])

  useEffect(() => {
    if (socket) return

    const newSocket: Socket<ChatServerToClientEvents, ChatClientToServerEvents> = io(chatClientURL, {
      path: chatClientPath
    })

    let connected = false

    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (sessionToken) {
        newSocket.emit('authenticate', sessionToken)
      } else {
        newSocket.emit('history')
      }
    })

    newSocket.on('connected', () => {
      setIsConnected(true)

      connected = true
    })

    newSocket.on('count_connected', (count) => {
      setIsConnected(true)
      setCountConnected(count)
    })

    newSocket.on('disconnect', () => {
      setIsConnected(false)

      connected = false
    })

    newSocket.on('history', (history) => {
      setHistory(JSON.parse(history))

      setTimeout(() => scroll(true), 300)
    })

    newSocket.on('error', (message) => {
      if (connected) {
        toast.error(message)
      } else if (socket && sessionToken) {
        setTimeout(() => newSocket.emit('authenticate', sessionToken), 1000)
      }
    })

    newSocket.emit('history')
  }, [socket, sessionToken])

  useEffect(() => {
    if (!socket) return

    return () => {
      socket.disconnect()
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return

    socket.removeListener('message')
    socket.on('message', (message) => {
      setHistory([
        ...history,
        JSON.parse(message)
      ])
    })

    socket.removeListener('remove-message')
    socket.on('remove-message', (messageUUID) => {
      setHistory(history.filter(entry => entry.uuid !== messageUUID))
    })
  }, [socket, history, container])

  const scroll = (force?: boolean) => {
    if (!container.current) return

    const {offsetHeight, scrollHeight, scrollTop} = container.current as HTMLDivElement
    if (force || scrollHeight <= scrollTop + offsetHeight + 200) {
      container.current.scrollTo(0, scrollHeight)
    }
  }

  const openProfile = (userUuid: string) => {
    if (userUuid === 'PirateSquad') return

    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  useEffect(() => {
    scroll()
  }, [history])

  useEffect(() => {
    if (socket && socket.connected) {
      socket.emit('pfp-changed')
    }
  }, [pfp])

  useEffect(() => {
    if (socket && socket.connected && sessionToken) {
      socket.emit('authenticate', sessionToken)
    }
  }, [username, sessionToken])

  return (
    <div className={formatClassName(styles, `chat ${className}`)} {...props}>
      <div className={formatClassName(styles, 'header')} >
        {/*<FontAwesomeIcon icon="angle-right" />*/}
        <div className={formatClassName(styles, 'title')}>Chat</div>
        <div className={formatClassName(styles, 'connected')}><FontAwesomeIcon icon="user" /> {countConnected}</div>
        <div className={formatClassName(styles, 'close')}><FontAwesomeIcon icon="arrow-right" onClick={() => dispatch({type: 'SET_CHAT_OPEN', chatOpen: false})} /></div>
      </div>
      <div className={formatClassName(styles, 'main')}>
        <div ref={container} className={formatClassName(styles, 'messages')}>
          {
            !isConnected && <LoadingOverlay>Connecting...</LoadingOverlay>
          }
          {history.map(message => {
            let formattedMessage: JSX.Element = <></>

            if (isNormalMessage(message)) {
              formattedMessage = <>{message.data.message}</>
            }

            if (isTipMessage(message)) {
              formattedMessage = <>
                <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(message.data.sender.userUuid)}>
                  @{message.data.sender.username}
                </span>
                &nbsp;
                tipped <YOOH />&nbsp;{message.data.amount / 100} to
                &nbsp;
                <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(message.data.receiver.userUuid)}>
                  @{message.data.receiver.username}
                </span>
              </>
            }

            return <div key={message.uuid} className={formatClassName(styles, `message ${isTipMessage(message) ? 'tip' : ''}`)}>
              {
                message.replyTo && <div className={formatClassName(styles, 'reply-to')}>
                  <FontAwesomeIcon icon="reply" />
                  <div className={formatClassName(styles, 'text')}>
                    <strong>{message.replyTo.username}</strong>: {message.replyTo.data.message}
                  </div>
                </div>
              }
              <div className={formatClassName(styles, `details ${message.replyTo ? 'space-top' : ''}`)}>
                <div className={formatClassName(styles, 'pfp')} onClick={() => openProfile(message.userUuid)}>
                  {/*<PopoverHover popoverContent={<span>click to send tip</span>} positions={['left']}>*/}
                  <img src={`${PFP_CDN_URL}/${message.pfp ?? 'anon.jpg'}`} />
                  {/*</PopoverHover>*/}
                </div>
                <div className={formatClassName(styles, 'content')}>
                  <div className={formatClassName(styles, 'info')}>
                    <div className={formatClassName(styles, 'username')}>
                      {message.username} {message.role && <span className={formatClassName(styles, `role ${message.role}`)}>{message.role === 'administrator' ? 'admin' : message.role}</span>}
                    </div>
                    <div className={formatClassName(styles, 'tools')}>
                      {
                        isNormalMessage(message) &&
                        <>
                          {(message.role !== 'administrator' && (role === 'moderator' || role === 'administrator'))
                            && <>
                              <FontAwesomeIcon className={formatClassName(styles, 'tool')} icon="ban" onClick={() => banMessage(message)} />
                              <FontAwesomeIcon className={formatClassName(styles, 'tool')} icon="trash" onClick={() => removeMessage(message)} />
                            </>
                          }
                          {(sessionToken && message.userUuid !== userUuid) &&
                            <FontAwesomeIcon className={formatClassName(styles, 'tool')} icon="reply" onClick={() => onReplyTo(message)} />
                          }
                        </>
                      }
                    </div>
                  </div>
                  <div className={formatClassName(styles, 'text')}>
                    {formattedMessage}
                  </div>
                </div>
              </div>
            </div>
          }
          )}
        </div>
        <div className={formatClassName(styles, 'actions')}>
          {
            !sessionToken
              ? <ConnectButton className={formatClassName(styles, 'connect')} />
              : <>
                {
                  replyTo && <div className={formatClassName(styles, 'reply-to')}>
                    <div>
                      Answer to <strong>{replyTo.username}</strong>
                    </div>
                    <FontAwesomeIcon icon="xmark" onClick={() => setReplyTo(undefined)} />
                  </div>
                }
                <form onSubmit={(e) => e.preventDefault()}>
                  <Textarea
                    ref={textareaRef}
                    placeholder='Send a message...'
                    value={message}
                    onChange={messageChange}
                    maxLength={200}
                    disabled={!isConnected}
                    autoFocus={!!replyTo}
                    onKeyDown={onKeyDown}
                  />
                  <ButtonIcon disabled={!isConnected} icon="paper-plane" loading={sending} onClick={sendMessage} />
                </form>
              </>
          }
        </div>
      </div>
    </div>
  )
}