@import "scss-variables";

.coupons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  .form {
    display: flex;
    flex-direction: column;

    .claim-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 1rem;
      flex-direction: column;

      @media screen and (min-width: $xs-breakpoint) {
        flex-direction: row;
      }

      .claim {
        display: flex;
        align-items: center;

        img {
          width: 10px;
          height: 10px;
        }

        small {
          opacity: 0.5;
        }

        button {
          height: auto;
          padding: 0.25rem 0.5rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .value {
          padding: 0.25rem 0.5rem;
          background-color: $color-dark-gray;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }
    }

    .name {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    .entry {
      display: flex;

      input {
        flex: 1;
        min-width: 0px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0.5rem;
      }

      button {
        min-width: 6rem;
        height: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.5rem;
      }
    }

    .used-by {
      font-size: 0.8rem;

      a {
        color: $text-color;
      }
    }
  }
}
