import {SkeletonData, Spine} from 'pixi-spine'
import {bombzState} from './state'

export const explosionAt = (size: number, x: number, y: number, onComplete?: () => void) => {
  if (!bombzState.viewport || !bombzState.resources) return

  const explosion = new Spine(bombzState.resources.explosion.spineData as SkeletonData)

  explosion.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'Explosion') {
        if (onComplete) onComplete()

        setTimeout(() => {
          // Catch potential errors and mute them (garbage collector can be executed before this)
          try {
            explosion.destroy()
            // eslint-disable-next-line no-empty
          } catch {}
        })
      }
    }
  })

  const ratio = (size * 1.5) / explosion.width

  explosion.scale.set(ratio, ratio)

  explosion.state.setAnimation(0, 'Explosion', false)

  explosion.x = x
  explosion.y = y

  bombzState.viewport.addChild(explosion)
}