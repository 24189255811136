import {Sound} from '@pixi/sound'
import {SkeletonData, Spine} from 'pixi-spine'
import {PowerUpSkinName} from './enums'
import {powerUpEffects} from './power-up-effects'
import {bubbleState} from './state'

/**
 * Create a new power up with the specified skin
 * @param skin The skin name
 * @returns The power up Spine object
 */
export const initPowerUp = (skin = PowerUpSkinName.EmptyBubble): Spine | undefined => {
  if (!bubbleState.resources?.powerUp.spineData) return

  const powerUp = new Spine(bubbleState.resources.powerUp.spineData as SkeletonData)

  powerUp.skeleton.setSkinByName(skin)
  powerUp.state.setAnimation(0, 'Idle', true)

  return powerUp
}

/**
 * Explode a power up
 * @param powerUp The power up Spine object to destroy
 * @param sound The sound to play with the Explode animation
 * @param explodeCallback Callback called after the Explode animation of the bubble
 */
export const explodePowerUp = (powerUp: Spine, sound?: Sound, explodeCallback?: () => void) => {
  powerUp.state.setAnimation(0, 'Explode', false)

  if (sound) sound.play()

  powerUp.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'Explode') {
        setTimeout(() => {
          if (explodeCallback) explodeCallback()

          // Catch potential errors and mute them (garbage collector can be executed before this)
          try {
            powerUp.destroy()
            // eslint-disable-next-line no-empty
          } catch {}
        })
      }
    }
  })

  // Effect
  const effect = powerUpEffects[powerUp.skeleton.skin?.name as PowerUpSkinName]

  if (effect) effect(bubbleState)
}

