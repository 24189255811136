import React from 'react'
import {CDN_URL} from '../../../../utils/constants'
import {formatClassName} from '../../../../utils/global'
import {ButtonIcon} from '../../../components/buttons/button'
import {OverlayModal, OverlayModalProps} from '../../../components/modal/modal'
import styles from './pay-table.module.scss'

export type PayTableProps = OverlayModalProps & {
  onHide: () => void
}

export const PayTable = ({className, onHide, ...props}: PayTableProps) => {
  return <OverlayModal className={formatClassName(styles, `pay-table-overlay ${className}`)} {...props}>
    <div className={formatClassName(styles, 'pay-table')}>
      <ButtonIcon className={formatClassName(styles, 'close-button no-color')} icon="xmark" onClick={onHide}></ButtonIcon>
      <div className={formatClassName(styles, 'table')}>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x1
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x3
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/apple.png'} />
            <img src={CDN_URL + '/games/slots/images/apple.png'} />
            <img src={CDN_URL + '/games/slots/images/apple.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x10
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/citrus.png'} />
            <img src={CDN_URL + '/games/slots/images/citrus.png'} />
            <img src={CDN_URL + '/games/slots/images/citrus.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x10
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/plum.png'} />
            <img src={CDN_URL + '/games/slots/images/plum.png'} />
            <img src={CDN_URL + '/games/slots/images/plum.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x10
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
            <img src={CDN_URL + '/games/slots/images/cherry.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x20
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/number7.png'} />
            <img src={CDN_URL + '/games/slots/images/number7.png'} />
            <img src={CDN_URL + '/games/slots/images/number7.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x40
          </div>
        </div>
        <div className={formatClassName(styles, 'element')}>
          <div className={formatClassName(styles, 'symbols')}>
            <img src={CDN_URL + '/games/slots/images/bar.png'} />
            <img src={CDN_URL + '/games/slots/images/bar.png'} />
            <img src={CDN_URL + '/games/slots/images/bar.png'} />
          </div>
          <div className={formatClassName(styles, 'pay')}>
            x60
          </div>
        </div>
      </div>
    </div>
  </OverlayModal>
}