import {isAxiosError} from 'axios'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {formatClassName, formatTextWithYOOH} from '../../../../../utils/global'
import {checkDepositChallengeRequest, checkVolumeChallengeRequest, claimDepositChallengeRequest, claimVolumeChallengeRequest, couponRequest} from '../../../../api/hub'
import {Button, ButtonIcon} from '../../../../components/buttons/button'
import {ConnectButton} from '../../../../components/buttons/connect-button'
import {LoadingOverlay} from '../../../../components/overlay/loading'
import {PopoverHelp} from '../../../../components/popover/popover'
import {useHubContext} from '../../../../state/context'
import {hubState} from '../../../../state/hub'
import {DailyLeaderboard} from './daily-leaderboard/daily-leaderboard'
import styles from './rewards.module.scss'
import {winSoundRewards} from './sounds'


export type RewardsProps = HtmlHTMLAttributes<HTMLDivElement>

export const Rewards = ({className, ...props}: RewardsProps) => {
  const {dispatch, state: {publicKey}} = useHubContext()

  const navigate = useNavigate()

  const [showDailyLeaderboard, setShowDailyLeaderboard] = useState(false)
  const [showDailyRewardsDetails, setShowDailyRewardsDetails] = useState(false)

  const [checkingDepositChallenge, setCheckingDepositChallenge] = useState(false)
  const [claimingDepositChallenge, setClaimingDepositChallenge] = useState(false)
  const [depositToday, setDepositToday] = useState(0)
  const [depositCompletion, setDepositCompletion] = useState(0)
  const [depositChallengePreviousLevel, setDepositChallengePreviousLevel] = useState<{
    level: number
    minDeposit: number
    rewardValue: number
  }>()
  const [depositChallengeNextLevel, setDepositChallengeNextLevel] = useState<{
    level: number
    minDeposit: number
    rewardValue: number
  }>()

  const [checkingVolumeChallenge, setCheckingVolumeChallenge] = useState(false)
  const [claimingVolumeChallenge, setClaimingVolumeChallenge] = useState(false)
  const [volumeToday, setVolumeToday] = useState(0)
  const [volumeCompletion, setVolumeCompletion] = useState(0)
  const [volumeChallengePreviousLevel, setVolumeChallengePreviousLevel] = useState<{
    level: number
    minVolume: number
    rewardValue: number
  }>()
  const [volumeChallengeNextLevel, setVolumeChallengeNextLevel] = useState<{
    level: number
    minVolume: number
    rewardValue: number
  }>()

  const date = new Date()
  date.setUTCHours(23, 59, 59, 999)

  const [endDailyQuest, setEndDailyQuest] = useState(date)

  const activateCoupon = async (coupon: string) => {
    const response = await couponRequest(coupon, (err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? formatTextWithYOOH(err.response.data.message) : 'Impossible to check the coupon, please contact the support team')
      } else {
        console.log(err, isAxiosError(err))
        toast.error('Impossible to check the coupon, please contact the support team')
      }
    })

    if (response?.status === 200) {
      toast.success(formatTextWithYOOH(response.data.message))
    }
  }

  const checkDepositChallenge = async () => {
    if (!publicKey) return

    setCheckingDepositChallenge(true)

    const response = await checkDepositChallengeRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the deposit challenge status, please contact the support team')
      }
    })

    if (response?.status === 200) {
      setDepositChallengePreviousLevel(response.data.previousLevel)
      setDepositChallengeNextLevel(response.data.nextLevel)
      setDepositToday(response.data.depositToday)
      setDepositCompletion(response.data.completion)
    }

    setCheckingDepositChallenge(false)
  }

  const claimDepositChallenge = async () => {
    if (!publicKey) return

    setClaimingDepositChallenge(true)

    winSoundRewards.play()

    const response = await claimDepositChallengeRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to claim the next reward, please contact the support team')
      }
    })

    if (response?.status === 200) {
      if (hubState.showConfirm) {
        hubState.showConfirm({
          title: `Congratulations on winning a $YOOH${response.data.rewardValue / 100} coupon!`,
          text: `Activating it will add $YOOH${response.data.rewardValue / 100} to your account. Please note that you will need to fulfill a wagering requirement of x40 the credit amount before you can withdraw or use it in PvP gameplay.`,
          onAccept() {
            activateCoupon(response.data.coupon)
          },
          acceptText: 'activate',
          refuseText: 'trash coupon'
        })
      }

      checkDepositChallenge()
    }

    setClaimingDepositChallenge(false)
  }

  const checkVolumeChallenge = async () => {
    if (!publicKey) return

    setCheckingVolumeChallenge(true)

    const response = await checkVolumeChallengeRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the volume challenge status, please contact the support team')
      }
    })

    if (response?.status === 200) {
      setVolumeChallengePreviousLevel(response.data.previousLevel)
      setVolumeChallengeNextLevel(response.data.nextLevel)
      setVolumeToday(response.data.volumeToday)
      setVolumeCompletion(response.data.completion)
    }

    setCheckingVolumeChallenge(false)
  }

  const claimVolumeChallenge = async () => {
    if (!publicKey) return

    setClaimingVolumeChallenge(true)

    winSoundRewards.play()

    const response = await claimVolumeChallengeRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to claim the next reward, please contact the support team')
      }
    })

    if (response?.status === 200) {
      if (hubState.showConfirm) {
        hubState.showConfirm({
          title: `Congratulations on winning a $YOOH${response.data.rewardValue / 100} coupon!`,
          text: `Activating it will add $YOOH${response.data.rewardValue / 100} to your account. Please note that you will need to fulfill a wagering requirement of x40 the credit amount before you can withdraw or use it in PvP gameplay.`,
          onAccept() {
            activateCoupon(response.data.coupon)
          },
          acceptText: 'activate',
          refuseText: 'trash coupon'
        })
      }

      checkVolumeChallenge()
    }

    setClaimingVolumeChallenge(false)
  }

  useEffect(() => {
    if (showDailyLeaderboard) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [showDailyLeaderboard])

  useEffect(() => {
    checkDepositChallenge()
    checkVolumeChallenge()
  }, [])

  const openWallet = () => {
    dispatch({
      type: 'SET_SHOW_WALLET',
      showWallet: true
    })
  }

  return (
    <div className={formatClassName(styles, `rewards ${className}`)} {...props}>
      {/*<div className={formatClassName(styles, 'deposit-offer')}>
        <div className={formatClassName(styles, 'info')}>
          <div className={formatClassName(styles, 'name')}><YOOH /> 10 offered!</div>
          <div className={formatClassName(styles, 'description')}>
            for your first 60+ deposit
          </div>
        </div>
        <div className={formatClassName(styles, 'date')}>
          <Button className={formatClassName(styles, 'cta')} onClick={openWallet}>deposit</Button>
        </div>
      </div>*/}
      <div className={formatClassName(styles, 'repetitive-quests')}>
        <div className={formatClassName(styles, 'repetitive-quest')}>
          <div className={formatClassName(styles, 'info')}>
            <div className={formatClassName(styles, 'name')}>Daily Rewards</div>
            <div className={formatClassName(styles, 'description')}>
              <YOOH /> 220 distributed each 24 hours to players doing streaks of 3 (won or lost) <br />
              <div className={formatClassName(styles, 'tickets')}>
                {
                  showDailyRewardsDetails
                    ? <>
                      <a href="#" onClick={() => setShowDailyRewardsDetails(false)}>close</a><br />
                      1 ticket for bets less than <YOOH /> 3<br />
                      2 tickets for bets of <YOOH /> 3 to 5.99<br />
                      3 tickets for bets of <YOOH /> 6 to 9.99<br />
                      4 tickets for bets of <YOOH /> 10 or more
                    </>
                    : <a href="#" onClick={() => setShowDailyRewardsDetails(true)}>how to win tickets?</a>
                }
              </div>
            </div>
            <div className={formatClassName(styles, 'details')}>
              <ButtonIcon icon="trophy" className='no-color' onClick={() => setShowDailyLeaderboard(true)}>
                leaderboard
              </ButtonIcon>
              <div className={formatClassName(styles, 'detail')}>
                <strong>top 1-5</strong> <YOOH /> 100 to <YOOH /> 10
              </div>
              <div className={formatClassName(styles, 'detail')}>
                <strong>top 6-infinity</strong> 2 raffles of <YOOH /> 5
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'date')}>
            {/*Draw in
            <FlipDate date={endDailyQuest} onEnded={() => {
              const date = new Date()
              date.setUTCHours(24, 0, 0, 0)

              setEndDailyQuest(date)
            }} />*/}
            PAUSED
          </div>
        </div>
      </div>
      Free Credits (resets daily)

      <div className={formatClassName(styles, 'progress-reward')}>
        {checkingDepositChallenge && <LoadingOverlay className='blur' />}
        <div className={formatClassName(styles, 'header')}>
          <div className={formatClassName(styles, 'name')}>
            Deposit Rewards: level {(depositChallengeNextLevel?.level ?? 1)}  <PopoverHelp>
              <>
                <div>Deposit Rewards</div>
                <ul>
                  <li>
                    Deposit some <YOOH /> to unlock the next reward
                  </li>
                  <li>
                    Each day the rewards resets at 00:00 UTC
                  </li>
                </ul>
              </>
            </PopoverHelp>
          </div>
          {
            publicKey
              ? depositToday < (depositChallengeNextLevel?.minDeposit ?? 0)
                ? <Button onClick={openWallet} className='gray'>deposit</Button>
                : <Button loading={claimingDepositChallenge} onClick={claimDepositChallenge}>claim</Button>
              : <ConnectButton />
          }
        </div>
        <div className={formatClassName(styles, 'progress')}>
          <div className={formatClassName(styles, 'bar-container')}>
            <div className={formatClassName(styles, 'actual-value')}><YOOH />{(depositChallengePreviousLevel?.minDeposit ?? 0) / 100}</div>
            <div className={formatClassName(styles, 'top-info')}>next reward <YOOH />{(depositChallengeNextLevel?.rewardValue ?? 0) / 100}</div>
            <div className={formatClassName(styles, 'bar')} style={{width: `${depositCompletion}%`}}>
              <div className={formatClassName(styles, 'bg-container')}>
                <div className={formatClassName(styles, 'bg')}></div>
              </div>
              <div className={formatClassName(styles, 'bottom-info')}>{Math.min(100, depositCompletion).toFixed(2)}% (you deposited <YOOH />{depositToday / 100})</div>
            </div>
            <div className={formatClassName(styles, 'next-value')}><YOOH />{(depositChallengeNextLevel?.minDeposit ?? 0) / 100}</div>
          </div>
        </div>
      </div>

      <div className={formatClassName(styles, 'progress-reward')}>
        {checkingVolumeChallenge && <LoadingOverlay className='blur' />}
        <div className={formatClassName(styles, 'header')}>
          <div className={formatClassName(styles, 'name')}>
            Volume Rewards: level {(volumeChallengeNextLevel?.level ?? 1)} <PopoverHelp>
              <>
                <div>Volume Rewards</div>
                <ul>
                  <li>
                    Wager some <YOOH /> with 1:1 risk to unlock the next reward
                  </li>
                  <li>
                    Risk depends on the game and parameters you are using, low risk = smaller volume, high risk = bigger volume
                  </li>
                  <li>
                    Each day the rewards resets at 00:00 UTC
                  </li>
                </ul>
              </>
            </PopoverHelp>
          </div>
          {
            publicKey
              ? volumeToday < (volumeChallengeNextLevel?.minVolume ?? 0)
                ? <Button onClick={() => navigate('/dashboard/games')} className='gray'>play</Button>
                : <Button loading={claimingVolumeChallenge} onClick={claimVolumeChallenge}>claim</Button>
              : <ConnectButton />
          }
        </div>
        <div className={formatClassName(styles, 'progress')}>
          <div className={formatClassName(styles, 'bar-container')}>
            <div className={formatClassName(styles, 'actual-value')}><YOOH />{(volumeChallengePreviousLevel?.minVolume ?? 0) / 100}</div>
            <div className={formatClassName(styles, 'top-info')}>next reward <YOOH />{(volumeChallengeNextLevel?.rewardValue ?? 0) / 100}</div>
            <div className={formatClassName(styles, 'bar')} style={{width: `${volumeCompletion}%`}}>
              <div className={formatClassName(styles, 'bg-container')}>
                <div className={formatClassName(styles, 'bg')}></div>
              </div>
              <div className={formatClassName(styles, 'bottom-info')}>{Math.min(100, volumeCompletion).toFixed(2)}% (you wagered <YOOH />{volumeToday / 100})</div>
            </div>
            <div className={formatClassName(styles, 'next-value')}><YOOH />{(volumeChallengeNextLevel?.minVolume ?? 0) / 100}</div>
          </div>
        </div>
      </div>

      <DailyLeaderboard show={showDailyLeaderboard} onHide={() => setShowDailyLeaderboard(false)} onClickBackground={() => setShowDailyLeaderboard(false)} />
    </div>
  )
}