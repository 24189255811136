import React, {ReactElement} from 'react'
import {YOOH} from '../svg/token'

export const isPhantomInstalled = (window as any).phantom?.solana?.isPhantom

const regex = /(undefined|false)/g

export const formatClassName = (styles: any, className: string) =>
  className.replaceAll(regex, '').trim().split(/\s+/).reduce<string>((acc, className) => {
    return `${acc ? `${acc} ` : ''}${styles[className] ?? className}`
  }, '')

export const formatTextWithYOOH = (message = '') => <>
  {message.split('$YOOH').reduce((acc, el) => [...acc, <>{el}</>, <YOOH />], [] as ReactElement[]).slice(0, -1)}
</>

export const shuffle = (array: any[]) => {
  let currentIndex = array.length, randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}