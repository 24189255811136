import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {sound} from '@pixi/sound'
import {Howler} from 'howler'
import {bubbleColyseusClientURL} from 'pirate-squad-bubble'
import {revealColyseusClientURL} from 'pirate-squad-reveal-app'
import React, {useEffect, useState} from 'react'
import {NavLink, Outlet, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {VERSION} from '../../../../version'
import {YOOH} from '../../../svg/token'
import {HUB_CDN_URL, PFP_CDN_URL} from '../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../utils/global'
import {retrieveInfo, retrieveTokens} from '../../api/hub'
import {getTwitterUser} from '../../api/twitter'
import {ButtonIcon} from '../../components/buttons/button'
import {ConnectButton} from '../../components/buttons/connect-button'
import {Chat} from '../../components/chat/chat'
import {ConnectModal} from '../../components/connect-modal/connect-modal'
import {ProfileModal} from '../../components/profile-modal/profile-modal'
import {Wallet} from '../../components/wallet/wallet'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import styles from './dashboard.module.scss'
import {changeUsername} from './sub-pages/profile/utils'

const navLinkClassName: (props: {
  isActive: boolean
  isPending: boolean
  isDisabled?: boolean
}) => string = ({isActive, isDisabled}) => formatClassName(styles, `item ${(isActive && !isDisabled) && 'active'} ${isDisabled && 'disabled'}`)

export const DashboardPage = () => {
  const location = useLocation()
  const {state: {pfp, tokens, username, chatOpen, sessionToken, pauseRefreshTokens, showWallet, showConnectModal, showProfileModal}, dispatch} = useHubContext()

  const [menuOpened, setMenuOpened] = useState(false)
  const [title, setTitle] = useState<string>()
  const [soundMuted, setSoundMuted] = useState(false)

  useEffect(() => {
    let title = location.pathname.split('/').pop()

    if (!title || title.length === 0) title = 'Home'

    setTitle(`${title.charAt(0).toUpperCase()}${title.substring(1)}`)

    const playerView = /\/games\/.+/.test(location.pathname)

    if (!playerView && hubState.clearGame) {
      hubState.clearGame()
    }

    if (hubState.showLoading) hubState.showLoading(false)
  }, [location])

  useEffect(() => {
    //hubState.setTokens = (tokens: {
    //  yaah: number
    //  yooh: number
    //}) => {
    //  console.log('SET TOKENS ', tokens)
    //  dispatch({
    //    type: 'SET_TOKENS',
    //    tokens
    //  })
    //}

    hubState.refreshTokens = async () => {
      const tokens = await retrieveTokens() ?? {
        yaah: undefined,
        yooh: undefined
      }

      dispatch({
        type: 'SET_TOKENS',
        tokens
      })

      return tokens
    }

    hubState.refreshInfo = async (pauseRefreshTokens = false) => {
      let response = await retrieveInfo()

      if (!response) {
        response = {
          tokens: {
            yaah: undefined,
            yooh: undefined
          },
          notifications: [],
          referral: {
            toBeClaimed: 0
          },
          rackbackAvailable: 0,
          revenueSharing: 0
        }
      }

      if (!pauseRefreshTokens) {
        dispatch({
          type: 'SET_TOKENS',
          tokens: response.tokens
        })
      }

      dispatch({
        type: 'SET_REVENUE_SHARING',
        revenueSharing: response.revenueSharing
      })

      dispatch({
        type: 'SET_REFERRAL_TO_BE_CLAIMED',
        toBeClaimed: response.referral.toBeClaimed
      })

      dispatch({
        type: 'SET_RACKBACK_AVAILABLE',
        rackbackAvailable: response.rackbackAvailable
      })

      if (response.referral.referral) {
        dispatch({
          type: 'SET_REFERRAL',
          referral: response.referral.referral
        })
      }

      if (response.referral.activatedReferral) {
        dispatch({
          type: 'SET_ACTIVATED_REFERRAL',
          activatedReferral: response.referral.activatedReferral
        })
      }

      const orderedNotifications = response?.notifications.sort((a, b) => a.createdAt - b.createdAt)

      orderedNotifications.forEach(notification => {
        toast(formatTextWithYOOH(notification.message), {
          type: notification.type
        })
      })

      return response
    }
  }, [])

  useEffect(() => {
    if (!sessionToken) return

    if (hubState.refreshInfo) hubState.refreshInfo(pauseRefreshTokens)

    const interval = setInterval(() => {
      if (hubState.refreshInfo) hubState.refreshInfo(pauseRefreshTokens)
    }, 2500)

    return () => {
      clearInterval(interval)
    }
  }, [pauseRefreshTokens, sessionToken])

  useEffect(() => {
    if (!sessionToken) return

    getTwitterUser().then((data) => {
      if (data) {
        dispatch({
          type: 'SET_TWITTER_USERNAME',
          twitterUsername: data.twitterUsername
        })
      }
    })
  }, [dispatch, sessionToken])

  useEffect(() => {
    if (sessionToken && (!username || username.length === 0)) {
      changeUsername(dispatch)
    }
  }, [sessionToken, username])

  const switchMenuOpen = () => {
    setMenuOpened(!menuOpened)
  }

  const toggleSound = () => {
    Howler.mute(!soundMuted)

    if (!soundMuted) {
      sound.muteAll()
    } else {
      sound.unmuteAll()
    }

    setSoundMuted(!soundMuted)
  }

  if ((chatOpen || menuOpened || showWallet) && (window?.innerWidth ?? 0) < 768) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }

  const bubbleTest = bubbleColyseusClientURL.includes('test-server') ? 'test' : bubbleColyseusClientURL.includes('localhost') ? 'localhost' : undefined
  const revealTest = revealColyseusClientURL.includes('test-server') ? 'test' : revealColyseusClientURL.includes('localhost') ? 'localhost' : undefined

  const manageWallet = (show: boolean) => {
    dispatch({
      type: 'SET_SHOW_WALLET',
      showWallet: show
    })
  }

  const closeConnectModal = () => {
    dispatch({
      type: 'SET_SHOW_CONNECT_MODAL',
      showConnectModal: false
    })
  }

  const closeProfileModal = () => {
    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: false
    })
  }

  return (
    <div className={formatClassName(styles, 'dashboard')}>
      <div className={formatClassName(styles, `menu ${menuOpened && 'opened'}`)} onClick={switchMenuOpen}>
        <div className={formatClassName(styles, 'menu-content')}>
          <div className={formatClassName(styles, 'logo')}>
            <NavLink
              to="/dashboard/home"
            >
              <img src={`${HUB_CDN_URL}/logo.png`} />
            </NavLink>
            <span>v{VERSION}</span>
            <span>©2023</span>
            <a
              title='Terms & Conditions'
              target="_blank"
              rel="noopener noreferrer"
              href="https://whitepaper.piratesquadnft.com/more-info/legal-documents"
            >terms</a>
          </div>
          <div className={formatClassName(styles, 'items')}>
            <NavLink
              to="/dashboard/games"
              className={navLinkClassName}
            >
              <div className={formatClassName(styles, 'icon')}>
                <FontAwesomeIcon icon="gamepad" />
              </div>
              <div className={formatClassName(styles, 'text')}>
                Games
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/rewards"
              className={navLinkClassName}
            >
              <div className={formatClassName(styles, 'icon')}>
                <FontAwesomeIcon icon="gift" />
              </div>
              <div className={formatClassName(styles, 'text')}>
                Rewards
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/rev-share"
              className={navLinkClassName}
            >
              <div className={formatClassName(styles, 'icon')}>
                <FontAwesomeIcon icon="hand-holding-dollar" />
              </div>
              <div className={formatClassName(styles, 'text')}>
                NFT
              </div>
            </NavLink>
            {sessionToken &&
              <NavLink
                to="/dashboard/profile"
                className={(props) => formatClassName(styles, `profile ${navLinkClassName(props)}`)}
              >
                <div className={formatClassName(styles, 'pfp')}>
                  <img src={`${PFP_CDN_URL}/${pfp}`} />
                </div>
                <div className={formatClassName(styles, 'text')}>
                  {username ?? 'Profile'}
                </div>
              </NavLink>
            }
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, `container ${(location.pathname?.includes('apps/reveal') || location.pathname?.includes('games/')) ? 'limited-height' : ''}`)}>
        <div className={formatClassName(styles, 'toolbar')}>
          <ButtonIcon
            className={formatClassName(styles, 'menu-button no-color no-padding')}
            onClick={switchMenuOpen}
            icon='bars'
            size='2x'
          />
          <div className={formatClassName(styles, 'actions')}>
            {
              sessionToken
                ? <>
                  <div className={formatClassName(styles, 'action sound')}>
                    <ButtonIcon className='no-color' icon={soundMuted ? 'volume-xmark' : 'volume-low'} onClick={toggleSound}></ButtonIcon>
                  </div>
                  <div className={formatClassName(styles, 'action yooh')} title={`You have ${(tokens?.yooh ?? 0) / 100} $YOOH`} onClick={() => manageWallet(true)}>
                    <div className={formatClassName(styles, 'yooh-container')}>
                      <YOOH />
                      <span>
                        {tokens?.yooh !== undefined ? tokens?.yooh / 100 : '...'}
                      </span>
                    </div>
                    <div className={formatClassName(styles, 'separator')}></div>
                    <img className={formatClassName(styles, 'wallet')} src={`${HUB_CDN_URL}/tokens/wallet.svg`} alt="wallet" />
                  </div>
                </>
                : <ConnectButton className={formatClassName(styles, 'action connect')} />
            }
            <div className={formatClassName(styles, `chat-action-container ${chatOpen ? 'hidden' : 'show'}`)}>
              <div className={formatClassName(styles, 'action chat-action')} onClick={() => dispatch({
                type: 'SET_CHAT_OPEN',
                chatOpen: !chatOpen
              })}>
                <FontAwesomeIcon icon="comments" />
              </div>
            </div>
          </div>
          <ButtonIcon
            className={formatClassName(styles, 'menu-button invisible no-color no-padding')}
            onClick={switchMenuOpen}
            icon='bars'
            size='2x'
          />
        </div>
        <div className={formatClassName(styles, `content ${(location.pathname?.includes('apps/reveal') || location.pathname?.includes('games/')) ? 'overflow-hidden phone-without-padding' : ''}`)}>
          <Outlet />
        </div>
      </div>
      <Wallet show={showWallet} onHide={() => manageWallet(false)} onClickBackground={() => manageWallet(false)} />
      <ConnectModal show={showConnectModal} onHide={() => closeConnectModal()} onClickBackground={() => closeConnectModal()} />
      <ProfileModal show={showProfileModal} onHide={() => closeProfileModal()} onClickBackground={() => closeProfileModal()} />
      {
        (bubbleTest || revealTest) && <div className={formatClassName(styles, 'testing')}>
          {bubbleTest && <div>bubble: {bubbleTest}</div>}
          {revealTest && <div>reveal: {revealTest}</div>}
        </div>
      }
      <div className={formatClassName(styles, `chat-container ${chatOpen ? 'open' : 'close'}`)}>
        <div className={formatClassName(styles, 'chat-animated-container')}>
          <Chat className={formatClassName(styles, 'chat')} />
        </div>
      </div>
    </div>
  )
}