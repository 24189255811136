import {Decimal} from 'decimal.js'

export const computeBombzMultiplier = (step: number, bombs: number) => {
  let multiplier = 1

  const coins = 16 - bombs

  for (let i = 0; i < step; i++) {
    multiplier *= 1 + (bombs / (coins - i))
  }

  return Math.max(1, Number(new Decimal(new Decimal(multiplier).toFixed(2)).mul(1 - 0.05).toFixed(2)))
}