import {revealState} from '../state'

export const getGameWidth = () => {
  if (!revealState.app) return 0

  return (revealState.app.view.parentNode as HTMLElement).offsetWidth
}

export const getGameHeight = () => {
  if (!revealState.app) return 0

  return (revealState.app.view.parentNode as HTMLElement).offsetHeight
}