import {WalletDisconnectButton, WalletMultiButton} from '@solana/wallet-adapter-react-ui'
import React, {useEffect} from 'react'
import {isAndroid, isIOS} from 'react-device-detect'
import {formatClassName, isPhantomInstalled} from '../../../utils/global'
import {isSessionTokenValid} from '../../../utils/session'
import {useSolanaWallet} from '../../hooks/use-solana-wallet'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import {OverlayModal, OverlayModalProps} from '../modal/modal'
import {Toggle} from '../toggle/toggle'
import styles from './connect-modal.module.scss'

export type ConnectModalProps = OverlayModalProps & {
  onHide: () => void
}

export const ConnectModal = ({className, onHide, ...props}: ConnectModalProps) => {
  const {dispatch, state: {sessionToken, isLedger}} = useHubContext()
  //const navigate = useNavigate()
  const {connected, connect, walletConnected, loading, disconnect, signMessage, signTransaction} = useSolanaWallet()

  useEffect(() => {
    if (sessionToken || !connected || isSessionTokenValid()) return

    connect()
  }, [connect, connected, sessionToken])

  useEffect(() => {
    onHide()
  }, [sessionToken])

  useEffect(() => {
    hubState.sessionConnect = connect
    hubState.signMessage = signMessage
    hubState.signTransaction = signTransaction
  }, [connect])

  //const selectChain = () => {
  //  dispatch({type: 'SET_CHAIN', chain: undefined})
  //  navigate('/select-chain')
  //}

  useEffect(() => {
    dispatch({type: 'SET_DISCONNECT_WALLET', disconnectWallet: disconnect})
  }, [disconnect])

  return <OverlayModal className={formatClassName(styles, `connect-modal-overlay ${className}`)} {...props}>
    <div className={formatClassName(styles, 'connect-modal')}>
      <div className={formatClassName(styles, 'wallet')}>
        {!isPhantomInstalled
          ? <div className={formatClassName(styles, 'install-wallet')}>
            {isAndroid ? (
              <>
                <p>To connect, please use the Phantom App browser</p>
                <a href="https://play.google.com/store/apps/details?id=app.phantom">
                  Open Android app
                </a>
              </>
            ) : isIOS ? (
              <>
                <p>To connect, please use the Phantom App browser</p>
                <a href="https://apps.apple.com/app/phantom-solana-wallet/1598432977">
                  Open iOS app
                </a>
              </>
            ) : <>
              <p>To connect, please use the Phantom wallet extension</p>
              <a href="https://phantom.app/">
                Phantom Website
              </a>
            </>
            }
          </div>
          : <div className={formatClassName(styles, 'form')}>
            {loading && connected
              ? <p>Waiting authorization, <span className={formatClassName(styles, 'purple')}>please sign and wait...</span></p>
              : <p>Connect a <span className={formatClassName(styles, 'purple')}>Solana wallet</span></p>
            }
            <WalletMultiButton className={formatClassName(styles, 'solana-button')}>{walletConnected ? 'Connect' : 'Select wallet'}</WalletMultiButton>
            {walletConnected && <WalletDisconnectButton className={formatClassName(styles, 'solana-button')}>Change wallet</WalletDisconnectButton>}
            {/*<Button className={formatClassName(styles, 'change-button gray')} onClick={selectChain}>Change chain</Button>*/}
            <span className={formatClassName(styles, 'ledger-toggle')} onClick={() => dispatch({type: 'SET_IS_LEDGER', isLedger: !isLedger})} >
              <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={isLedger ?? false} readOnly />
              <span>Ledger Wallet</span>
            </span>
          </div>
        }
      </div>
    </div>
  </OverlayModal >
}