import {IconProp} from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import {useHubContext} from '../../state/context'
import {ButtonIcon, ButtonIconProps} from './button'

export type ConnectButtonProps = Omit<ButtonIconProps, 'icon'> & {
  icon?: IconProp
}

export const ConnectButton = (props: ConnectButtonProps) => {
  const {dispatch} = useHubContext()

  const openConnectModal = (show: boolean) => {
    dispatch({
      type: 'SET_SHOW_CONNECT_MODAL',
      showConnectModal: show
    })
  }

  return <ButtonIcon {...props} icon="right-to-bracket" onClick={() => openConnectModal(true)}>connect</ButtonIcon>
}