import {slotsState} from './state'

export const getSlotsGameWidth = () => {
  if (!slotsState.app) return 0

  return (slotsState.app.view.parentNode as HTMLElement).offsetWidth
}

export const getSlotsGameHeight = () => {
  if (!slotsState.app) return 0

  return (slotsState.app.view.parentNode as HTMLElement).offsetHeight
}