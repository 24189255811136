@import "scss-variables";

.bubble-room-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;

  .room-info {
    background-color: $color-violet;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .players {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    .player {
      display: flex;

      .player-name {
        flex: 1;
      }

      .player-ready {
      }
    }
  }

  .timer {
    text-align: center;
  }

  .actions {
    display: flex;
    gap: 1rem;

    .exit-button {
      color: #333;

      &:hover:not(:disabled),
      &.selected {
        background-color: #33333311 !important;
      }
    }

    .ready-button {
      flex: 1;
    }
  }
}
