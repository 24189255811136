@import "scss-variables";

.popover-help {
  .popover-icon {
    margin-left: 0.25rem;
    cursor: pointer;
    opacity: 0.4;
    font-size: 1rem;

    &:hover {
      opacity: 1;
    }
  }
}

.popover-container {
  z-index: 1000;
  max-width: 300px;
}

.popover-content {
  background-color: $color-lighter-gray;
  color: white;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 14px;
  font-family: "Baloo Thambi";
  font-weight: 500;
  max-width: 300px;

  ul {
    margin: 0;
    padding: 0 1rem;
  }

  img {
    height: 1em;
  }
}
