import {sound} from '@pixi/sound'
import {Room} from 'colyseus.js'
import Decimal from 'decimal.js'
import FontFaceObserver from 'fontfaceobserver'
import gsap from 'gsap'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {Assets, Container} from 'pixi.js'
import {getRevealState} from './api/reveal'
import {exitRoom, initServerMessage, onError, onJoinError, onLeave} from './server/server-handler'
import {revealState} from './state'
import {State} from './types'
import {getGameHeight, getGameWidth} from './utils'
import {HUB_CDN_URL} from './utils/constants'
import {initViewer} from './viewer'

export const initRevealApp = async (
  canvas: HTMLCanvasElement | undefined,
  revealId: string,
  room: Room,
  config?: State['external'],
  onLoaderProgress?: (progress: number, exited?: boolean) => void
) => {
  if (!canvas) return

  revealState.external = {...config}

  if (revealState.external.showLoading) {
    revealState.external.showLoading(true, undefined, 'Loading data, please wait...')
  }

  revealState.room = room

  initRoom(room)

  //PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR
  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2

  const parent = canvas.parentElement!

  const app = new PIXI.Application({
    view: canvas,
    resolution: Math.max(2, window.devicePixelRatio),
    autoDensity: true,
    antialias: true,
    resizeTo: parent,
    backgroundAlpha: 0
  })

  revealState.app = app

  app.stage.interactive = true

  app.ticker.stop()

  const gsapTicker = () => {
    app.ticker.update()
  }

  // Now, we use 'tick' from gsap
  gsap.ticker.add(gsapTicker)

  revealState.external.clearGame = async () => {
    gsap.ticker.remove(gsapTicker)
    Assets.unloadBundle('reveal')
    sound.removeAll()
    app.destroy()

    if (revealState.room) {
      await exitRoom()
    }

    revealState.external.clearGame = undefined
  }

  const revealData = (await getRevealState(revealId))?.data

  if (revealState.external.showLoading) {
    revealState.external.showLoading(false)
  }

  if (!revealData) {
    if (revealState.external.showModal) {
      revealState.external.showModal({
        title: 'Impossible to retrieve the image info',
        text: 'Please contact the support team.'
      })
    }

    if (revealState.external.clearGame) {
      revealState.external.clearGame()
    }

    return
  }

  const loader = PIXI.Assets

  loader.addBundle('reveal', {
    fullLogo: HUB_CDN_URL + '/full-logo.png'
  })

  let resources

  try {
    if (onLoaderProgress) {
      onLoaderProgress(0)

      let soundProgress = 0
      let loaderProgress = 0

      const updateProgress = (newSoundProgress?: number, newLoaderProgress?: number) => {
        if (newSoundProgress !== undefined) {
          soundProgress = newSoundProgress
        }

        if (newLoaderProgress !== undefined) {
          loaderProgress = newLoaderProgress
        }

        onLoaderProgress(soundProgress * 0.5 + loaderProgress * 0.5, revealState.exited)
      }

      resources = await loader.loadBundle('bubble', (progress) => updateProgress(undefined, progress))

      updateProgress(undefined, 100)
    } else {
      resources = await loader.loadBundle('bubble')
    }
  } catch (err) {
    console.error(err)

    if (!revealState.exited && revealState.external.clearGame) {
      revealState.exited = true

      revealState.external.clearGame()

      if (revealState.external.showLoading) revealState.external.showLoading(false)

      if (revealState.external.showModal) revealState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the graphic resources, please check your internet connection and refresh the page'
      })
    }

    return
  }

  const rifficFont = new FontFaceObserver('Riffic')
  const balooThambiFont = new FontFaceObserver('Baloo Thambi')


  revealState.resources = resources

  Promise.all([
    rifficFont.load(),
    balooThambiFont.load()
  ]).then(() => {
    if (onLoaderProgress) onLoaderProgress(100)

    app.resize()

    initViewer(revealData)

    app.ticker.add((delta) => {
      const roundedDelta = new Decimal(delta).mul(10).round().div(10).toNumber()

      update(roundedDelta)
    })
  }).catch((err) => {
    console.error('loading failed', err)
  })

  return revealState.external.clearGame
}

const initRoom = (room: Room) => {
  try {
    console.log(room.sessionId, ' joined reveal room ', room.name)

    initServerMessage(room)

    room.onStateChange(async (state) => {
      console.log('STATE', state)
    })

    room.onLeave((code) => {
      onLeave(code)

      console.log('leave', code)
    })

    room.onError((code, message) => {
      onError(code, message)

      console.log('ERROR: ', code, ' [', message, ']')
    })

    return room
  } catch (err) {
    onJoinError(err)
  }
}

export const createViewport = () => {
  if (!revealState.app || !revealState.resources) return

  if (revealState.viewport) {
    revealState.viewport.destroy()
  }

  revealState.app.stage.removeChildren()
  revealState.app.stage.removeAllListeners()

  const viewport = new Viewport({
    screenWidth: getGameWidth(),
    screenHeight: getGameHeight(),
    events: revealState.app.renderer.events
  })

  viewport
    .drag()
    .pinch()
    .wheel()
    .decelerate()
    .clampZoom({
      maxScale: 1
    })

  //let lastTapTime = 0
  //viewport.on('pointertap', (event: InteractionEvent) => {
  //  event.stopPropagation()

  //  const double = Date.now() - lastTapTime < 200

  //  lastTapTime = Date.now()

  //  if (double) {
  //    viewport.fitWorld().moveCenter(0, 0)
  //  }
  //})

  revealState.dragged = false
  viewport.on('pointermove', () => {
    revealState.dragged = true
  })

  viewport.on('pointerdown', () => {
    revealState.dragged = false
  })

  viewport.sortableChildren = true

  revealState.app.stage.addChild(viewport)

  revealState.app.renderer.on('resize', () => {
    if (!revealState.app || !revealState.references.image) return

    viewport.resize(getGameWidth(), getGameHeight())

    let width = viewport.worldWidth
    let height = viewport.worldHeight

    if (width <= revealState.references.image.width) {
      width += 50
    }

    if (height <= revealState.references.image.height) {
      height += 50
    }

    viewport.fit(undefined, width, height).moveCenter(0, 0)
  })

  revealState.viewport = viewport

  // Particles

  const particlesContainer = new Container()

  particlesContainer.zIndex = 95

  revealState.particles.container = particlesContainer

  viewport.addChild(particlesContainer)

  // addWaterEffect(state.app.stage, new Sprite(state.resources.waterDisplacement.texture))

  // addShockwaveEffect(viewport, new Point(getGameWidth() / 2, getGameHeight() / 2), undefined, {duration: 5})
}

/**
 * Update the state of the game
 * @param delta The delta (time passed since previous update)
 */
const update = (delta: number) => {
  updateEmitters(delta)
}

const updateEmitters = (delta: number) => {
  revealState.particles.emitters?.forEach(emitter => {
    emitter.update(delta * 0.01)
  })
}