import {Room} from 'colyseus.js'
import {ease} from 'pixi-ease'
import {Sprite, Texture} from 'pixi.js'
import {revealState} from '../state'
import {Chunk, isNFTTreasure, ITreasure} from '../types'

export const initServerMessage = (room: Room) => {
  //room.onMessage<number>('tokens', (tokens) => {
  //  if (revealState.external.setTokens) {
  //    revealState.external.setTokens(tokens)
  //  }
  //})

  room.onMessage<number>('slow-down-reveal', () => {
    if (revealState.external.showToast) {
      revealState.external.showToast('warn', 'You should wait 1s between each reveal')
    }
  })

  room.onMessage<number>('not-enough-tokens', () => {
    if (revealState.external.onNotEnoughTokens) {
      revealState.external.onNotEnoughTokens()
    }
  })

  room.onMessage('sending-nft', () => {
    if (!revealState.external.showLoading) return

    revealState.external.showLoading(true, undefined, 'You won a NFT! We are now sending it to your wallet...')
  })

  room.onMessage<{
    treasure: ITreasure
    nft?: {
      name: string
      image: string
    }
  }>('treasure-won', async ({
    treasure,
    nft
  }) => {
    console.log('Treasure won: ', treasure)

    if (isNFTTreasure(treasure)) {
      if (!revealState.external.showModal || !revealState.external.showLoading) return

      revealState.external.showLoading(false)

      if (nft) {
        revealState.external.showModal({
          title: `${treasure.rarity[0].toUpperCase()}${treasure.rarity.substring(1)} treasure won!`,
          text: `You won the "${nft.name}" NFT!`,
          image: nft.image
        })
      }

      revealState.external.showLoading(false)
    } else if (revealState.external.showToast) {
      revealState.external.showToast(undefined, undefined, treasure)
    }
  })

  room.onMessage<number>('no-treasure-won', () => {
    if (revealState.external.showToast) {
      revealState.external.showToast(undefined, 'No treasure won, try again!')
    }
  })

  room.onMessage<{
    revealId: string
    chunkId: number
  }>('wl-treasure-won', ({
    revealId,
    chunkId
  }) => {
    if (revealState.external.onWLTreasureWon) {
      revealState.external.onWLTreasureWon(revealId, chunkId)
    }
  })

  const history: {
    winner: string,
    treasure: ITreasure
  }[] = []

  room.onMessage<{
    winner: string,
    treasure: ITreasure
  }>('treasure-history', (newHistory) => {
    console.log('Treasure history: ', newHistory)

    if (revealState.external.onTreasureHistory) {
      history.unshift(newHistory)

      revealState.external.onTreasureHistory([...history])
    }
  })

  room.onMessage<{
    index: number
    chunk: Chunk
    base64: string
  }>('chunk-reveal', ({index, chunk, base64}) => {
    if (!revealState.references.image) return

    console.log('Chunk reveal : ', {
      index,
      chunk,
      base64
    })

    const image = new Image(chunk.width, chunk.height)

    image.src = base64

    image.onload = () => {
      if (!revealState.references.image) return

      const sprite = new Sprite(Texture.from(image))

      sprite.x = chunk.left - 0.5
      sprite.y = chunk.top - 0.5
      sprite.width += 0.5
      sprite.height += 0.5
      sprite.zIndex = 10
      sprite.alpha = 0

      revealState.references.image.addChild(sprite)

      ease.add(sprite, {alpha: 1}, {ease: 'linear', duration: 500})
    }
  })

  room.onMessage('ended', () => {
    if (!revealState.external.showModal) return

    revealState.external.showModal({
      title: 'Reveal ended',
      text: 'This reveal is ended, you can\'t reveal anything more'
    })
  })
}

export const onJoinError = (err: any) => {
  if (revealState.external.showLoading) {
    revealState.external.showLoading(false)
  }

  if (revealState.external.showModal) {
    revealState.external.showModal({
      title: 'Impossible to join the server',
      text: err.message ? `(code ${err.code}) ${err.message}` : 'You request has been refused, please try to reconnect'
    })
  }

  console.error(err)
}

export const onError = (code: number, message?: string) => {
  if (revealState.external.showLoading) {
    revealState.external.showLoading(false)
  }

  if (revealState.external.showModal) {
    revealState.room = undefined

    revealState.external.showModal({
      title: 'Server errr',
      text: `ERROR: ${code},  ['${message}']`,
      onClose() {
        window.location.href = '/dashboard/apps/reveal'
      }
    })
  }
}

export const onLeave = (code: number) => {
  if (code !== 1000 && revealState.external.showModal) {
    revealState.room = undefined

    revealState.external.showModal({
      title: 'Disconnected',
      text: 'You have been disconnected from the reveal server',
      onClose() {
        window.location.href = '/dashboard/apps/reveal'
      }
    })
  }
}

export const exitRoom = async () => {
  if (!revealState.room) return

  try {
    await revealState.room.leave(true)
  } catch (err) {
    console.error(err)
  }

  revealState.room = undefined
}