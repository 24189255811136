@import "scss-variables";

.bombz {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 0.5rem;
  overflow-y: auto;
  max-width: 1200px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media screen and (min-width: $sm-breakpoint) {
    padding: 0;
  }

  .game-container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    flex: none;

    .game {
      display: flex;
      background-color: $color-gray;
      border-radius: 0.5rem;
      flex-direction: column-reverse;

      .loading {
        backdrop-filter: blur(0.5rem);
      }

      @media screen and (min-width: $md-breakpoint) {
        flex-direction: row;
      }
    }
  }

  .settings {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    justify-content: space-between;

    @media screen and (min-width: $md-breakpoint) {
      padding: 1rem;
      gap: 1rem;
    }

    .setting {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        gap: 1rem;
      }

      .tabs {
        display: flex;
      }

      .title {
        font-size: 1rem;
        font-weight: 700;
      }

      .content {
        display: flex;
        align-items: end;
        flex: 1;

        &.parameters {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .separator {
            padding-top: 1rem;
          }

          .parameter {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            min-width: 270px;

            .name {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              font-weight: 500;

              .all-in {
                text-decoration: underline;
                cursor: pointer;
              }

              .label {
                img,
                svg {
                  width: 10px;
                  height: 10px;
                  margin: 0 0.2rem;
                }
              }
            }

            .input {
              display: flex;
              align-items: center;
              background-color: $color-lighten-gray;
              border-radius: 0.5rem;
              padding: 0.5rem;
              box-sizing: border-box;
              height: 2.5rem;
              gap: 0.5rem;

              img,
              svg {
                height: 1rem;
                opacity: 0.8;
              }

              input {
                flex: 1;
                background-color: transparent;
                width: 0;
                outline: 0;
                padding: 0;
                font-weight: 500;
                font-size: 1rem;

                /* Firefox */
                &[type="number"] {
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  -moz-appearance: textfield;
                }
              }

              .increment {
                display: flex;
                flex-direction: row-reverse;
                border-radius: 0.25rem;
                overflow: hidden;

                @media screen and (min-width: $sm-breakpoint) {
                  flex-direction: column;
                }

                button {
                  border-radius: 0;
                  padding: 0;
                  width: 1rem;
                }
              }

              button {
                height: 100%;
                background-color: $color-gray;
                font-weight: 600;

                &:hover {
                  background-color: darken($color: $color-gray, $amount: 4);
                }

                @media screen and (min-width: $sm-breakpoint) {
                  line-height: 1rem;
                }

                &.infinity {
                  width: 1rem;
                  border-radius: 0.25rem;

                  svg {
                    height: 0.8rem;
                  }
                }
              }
            }
          }

          .clean-program {
            width: 100%;
            height: 2rem;
          }
        }
      }

      &.create {
        position: relative;

        .confetti {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          pointer-events: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .viewer {
    display: flex;
    flex: 1;
    max-height: 500px;
    background: $color-light-gray;
    background: linear-gradient(180deg, $color-light-gray 0%, $color-light-gray 35%, $color-lighter-gray 100%);

    canvas {
      display: flex;
      flex: 1;
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 1/1;
    }
  }

  //.active-games {
  //  display: grid;
  //  gap: 0.5rem;
  //  grid-template-columns: 1fr;

  //  @media screen and (min-width: $xs-breakpoint) {
  //    gap: 1rem;
  //  }

  //  .participants {
  //    > div {
  //      display: flex;
  //      align-items: center;
  //      gap: 0.5rem;

  //      &.waiting {
  //        opacity: 0.4;
  //      }

  //      svg {
  //        width: 1rem;
  //        height: 1rem;
  //      }
  //    }
  //  }

  //  .title {
  //    display: flex;
  //    align-items: center;
  //    font-size: 22px;
  //    font-weight: 700;
  //    gap: 1rem;

  //    .active-games-toggle {
  //      display: flex;
  //      align-items: center;
  //      font-size: 14px;
  //      font-weight: normal;
  //      gap: 0.5rem;
  //      user-select: none;
  //      cursor: pointer;

  //      .toggle {
  //        pointer-events: none;
  //      }
  //    }
  //  }

  //  .active-game {
  //    display: grid;
  //    grid:
  //      "a c" 1fr
  //      "b b" 1fr /1fr 1fr;
  //    align-items: center;
  //    border: 1px solid rgba(0, 0, 0, 0.1);
  //    border-radius: 0.5rem;
  //    padding: 0.5rem;
  //    box-sizing: border-box;
  //    font-size: 0.9rem;
  //    font-weight: 600;
  //    background-color: white;
  //    gap: 0.5rem;

  //    animation-name: show;
  //    animation-duration: 1s;

  //    @media screen and (min-width: $xs-breakpoint) {
  //      padding: 1rem;
  //      grid: "a b c" 100% /1fr auto 1fr;
  //      gap: 1rem;

  //      img {
  //        max-height: 3rem;
  //      }
  //    }

  //    img {
  //      max-height: 2rem;
  //    }

  //    .middle {
  //      display: flex;
  //      align-items: center;
  //      justify-content: center;

  //      .join-options {
  //        display: flex;
  //        gap: 1rem;
  //      }

  //      .join-button,
  //      .cancel-button {
  //        border: 1px solid #2aacd6;
  //        height: 2rem;

  //        svg,
  //        img {
  //          max-height: 1.5rem;
  //          height: 100%;
  //          margin: 0 0.25rem;

  //          @media screen and (min-width: $xs-breakpoint) {
  //            margin: 0 0.5rem;
  //          }
  //        }

  //        &.cancel-button {
  //          border: 1px solid $color-warning;
  //          color: $color-warning;
  //        }
  //      }
  //    }

  //    .a {
  //      grid-area: a;
  //    }

  //    .b {
  //      display: flex;
  //      grid-area: b;

  //      button {
  //        width: 100%;
  //      }
  //    }

  //    .c {
  //      grid-area: c;
  //    }

  //    .total {
  //      display: flex;
  //      align-items: center;
  //      justify-content: end;
  //      gap: 0.5rem;

  //      img {
  //        height: 1rem;
  //      }
  //    }
  //  }
  //}

  .history-games {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (min-width: $xs-breakpoint) {
      gap: 1rem;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      gap: 1rem;

      .my-games-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        gap: 0.5rem;
        user-select: none;
        cursor: pointer;

        .toggle {
          pointer-events: none;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;

      @media screen and (min-width: $xs-breakpoint) {
        grid-template-columns: repeat(auto-fit, minmax(350, 1fr));
        gap: 1rem;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
      }

      .history {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $color-gray;
        padding: 0.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        gap: 1rem;

        animation-name: show;
        animation-duration: 1s;

        @media screen and (min-width: $xs-breakpoint) {
          padding: 1rem;
        }

        .profile {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-between;
          height: 100%;
          gap: 1rem;

          .username {
            gap: 0.5rem;
            font-size: 16px;
            font-weight: 600;
            width: fit-content;
            display: flex;
            align-items: center;

            &.won,
            &.lost {
              padding: 0.1rem 0.4rem;
              border-radius: 0.5rem;
              color: white;
            }

            &.won {
              background-color: rgba($color-success, 0.6);
            }

            &.lost {
              background-color: rgba($color-danger, 0.6);
            }

            img {
              height: 1rem;
            }
          }
        }

        .badge {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          width: 3rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;

          @media screen and (min-width: $xs-breakpoint) {
            top: 1rem;
            right: 1rem;
          }

          img {
            height: 2rem;
          }
        }

        .time-ago {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          display: flex;
          justify-content: center;
          width: 3rem;
          font-size: 11px;
          opacity: 0.6;

          @media screen and (min-width: $xs-breakpoint) {
            bottom: 1rem;
            right: 1rem;
          }
        }
      }
    }
  }

  .multi-button {
    display: flex;
    width: 100%;
    border-radius: 0.5rem;
    gap: 0.5rem;
    box-sizing: border-box;
    height: 2rem;

    > button {
      flex: 1;
      height: 100%;
      transition: all 300ms;

      img {
        height: 100%;
      }

      &.selected {
        background-color: $color-blue;
      }

      &:hover {
        background-color: $color-blue !important;

        &:not(.selected) {
          background-color: #fff1 !important;
        }
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
