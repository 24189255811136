import {Howl} from 'howler'
import {CDN_URL} from '../../../utils/constants'

export const randomSpinSoundSlots = () => {
  stopSpinSoundSlots()

  return slotSpinSoundsSlots[Math.floor(slotSpinSoundsSlots.length * Math.random())]
}

export const stopSpinSoundSlots = () => {
  slotSpinSoundsSlots.forEach(sound => sound.stop())
}

const slotSpinSoundsSlots = [
  new Howl({
    src: [`${CDN_URL}/games/slots/sounds/slot1_spin1.mp3`],
    volume: 0.1,
    preload: true,
    loop: true
  }),
  new Howl({
    src: [`${CDN_URL}/games/slots/sounds/slot1_spin2.mp3`],
    volume: 0.1,
    preload: true,
    loop: true
  }),
  new Howl({
    src: [`${CDN_URL}/games/slots/sounds/slot1_spin3.mp3`],
    volume: 0.1,
    preload: true,
    loop: true
  }),
  new Howl({
    src: [`${CDN_URL}/games/slots/sounds/slot1_spin4.mp3`],
    volume: 0.1,
    preload: true,
    loop: true
  }),
]

export const bgmSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/bgm.mp3`],
  volume: 0.10,
  preload: true,
  loop: true
})

export const simpleComboSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_simple.mp3`],
  volume: 0.2,
  preload: true
})

export const simpleLineSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_any.mp3`],
  volume: 0.25,
  preload: true
})

export const cherryLineSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_cherry.mp3`],
  volume: 0.3,
  preload: true
})

export const number777LineSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_number777.mp3`],
  volume: 0.3,
  preload: true
})

export const barLineSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_bar.mp3`],
  volume: 0.3,
  preload: true
})

export const clickAffirmativeSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_stopclick.mp3`],
  volume: 0.2,
  preload: true
})

export const reelStopSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_reelstop.mp3`],
  volume: 0.08,
  preload: true
})

export const reelStopEndSoundSlots = new Howl({
  src: [`${CDN_URL}/games/slots/sounds/slot1_reelstop_end.mp3`],
  volume: 0.08,
  preload: true
})

export const coinsSoundSlots = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-coin.mp3`],
  volume: 0.2,
  preload: true
})

export const selectSoundSlots = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-3.mp3`],
  volume: 0.2,
  preload: true
})

export const clickNegativeSoundSlots = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-negative.mp3`],
  volume: 0.2,
  preload: true
})