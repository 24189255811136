import React, {useEffect, useState} from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import {toast} from 'react-toastify'
import {io, Socket} from 'socket.io-client'
import {formatTextWithYOOH, shuffle} from '../../../utils/global'
import {useHubContext} from '../../state/context'
import {BombzClientPath, BombzClientURL} from './bombz'
import {bombzState} from './game/state'
import {clickAffirmativeSoundBombz, clickNegativeSoundBombz, winSoundBombz} from './sounds'
import {BombzClientToServerEvents, BombzGame, BombzRevealCellMessage, BombzServerToClientEvents} from './types'

let confettiKey = 0

export const useBombz = () => {
  const {state: {tokens, sessionToken, userUuid}, dispatch} = useHubContext()
  const [confetti, setConffeti] = useState<JSX.Element[]>([])

  const [mode, setMode] = useState<'normal' | 'auto'>('normal')
  const [autoMode, setAutoMode] = useState<'random' | 'program'>('random')

  const [bet, setBet] = useState('')
  const [bombs, setBombs] = useState('1')
  const [program, setProgram] = useState<number[]>([])
  const [repeat, setRepeat] = useState('1')
  const [maxProgramStep, setMaxProgramStep] = useState('')
  const [actualProgramStep, setActualProgramStep] = useState(0)
  const [actualRepeat, setActualRepeat] = useState(0)

  const [botPlaying, setBotPlaying] = useState(false)

  const [socket, setSocket] = useState<Socket<BombzServerToClientEvents, BombzClientToServerEvents>>()
  const [isConnected, setIsConnected] = useState(false)
  const [runningGame, setRunningGame] = useState<BombzGame>()
  const [history, setHistory] = useState<BombzGame[]>([])
  const [myHistory, setMyHistory] = useState<BombzGame[]>([])
  const [maxWin, setMaxWin] = useState<number>()
  const [waitingForCashout, setWaitingForCashout] = useState(false)
  const [creatingGame, setCreatingGame] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const initGame = (game: BombzGame) => {
    if (bombzState.external.initBombsGrid) {
      bombzState.external.initBombsGrid(game)
    } else {
      const interval = setInterval(() => {
        if (bombzState.external.initBombsGrid) {
          bombzState.external.initBombsGrid(game)

          clearInterval(interval)
        }
      }, 100)
    }
  }

  const createGame = (bet: number, bombs: number) => {
    if (!socket || creatingGame) return

    if (bet === 0) {
      clickNegativeSoundBombz.play()

      toast.error(formatTextWithYOOH('Minimal amount is $YOOH 1'))

      return
    }

    if ((tokens?.yooh ?? 0) >= bet * 100) {
      if (mode === 'auto') {
        if (autoMode === 'program' && program.length === 0) {
          toast.error('You need to have at least a step programed to start a programed game')

          return
        } else if (autoMode === 'random' && maxProgramStep === '') {
          toast.error('You need to define the maximum number of reveals to cashout when you use autoplay and random option')

          return
        } else if (Number(repeat) === 0) {
          toast.error('Number of games need to be at least 1 to start an autoplay')

          return
        } else {
          setBotPlaying(true)
        }
      }

      setCreatingGame(true)

      if (bombzState.external.creatingGame) {
        bombzState.external.creatingGame()
      }

      if (!botPlaying) {
        clickAffirmativeSoundBombz.play()
      }

      socket.emit('create-game', bet, bombs)

      if (tokens?.yooh !== undefined) {
        dispatch({
          type: 'ADD_TOKENS',
          tokens: {
            yooh: -bet * 100
          }
        })
      }

      setTimeout(() => {
        setCreatingGame(false)
      }, 1000)
    } else {
      clickNegativeSoundBombz.play()

      toast.error(formatTextWithYOOH('Not enough $YOOH on your account to create the game'))

      stopProgram()
    }
  }

  const startProgram = () => {
    if (bombzState.external.startProgram) {
      setProgram([])

      bombzState.external.startProgram()
    }
  }

  const exitProgram = () => {
    if (bombzState.external.exitProgram) {
      setProgram([])

      bombzState.external.exitProgram()
    }
  }

  const stopProgram = () => {
    setCreatingGame(false)
    setWaitingForCashout(false)
    setBotPlaying(false)
    setActualProgramStep(0)
    setActualRepeat(0)
  }

  const resetProgram = () => {
    if (bombzState.external.startProgram) {
      setProgram([])

      bombzState.external.startProgram()
    }
  }

  const sendRevealCell = async (cellIndex: number) => {
    if (!socket) return

    socket.emit('reveal-cell', cellIndex)
  }

  const cashout = () => {
    if (waitingForCashout || !socket) return

    setWaitingForCashout(true)

    socket.emit('cashout')
  }

  const generateRandomProgram = (maxStep: number) => {
    const program = shuffle(Array.from({length: 16}, (_, index) => index)).slice(0, maxStep)

    setProgram(program)

    return program
  }

  useEffect(() => {
    setSocket(undefined)
  }, [sessionToken])

  useEffect(() => {
    if (socket || !initialized) return

    let connected = false

    const newSocket: Socket<BombzServerToClientEvents, BombzClientToServerEvents> = io(BombzClientURL, {
      path: BombzClientPath
    })

    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (sessionToken) {
        newSocket.emit('authenticate', sessionToken)
      } else {
        newSocket.emit('history')
      }
    })

    newSocket.on('connected', () => {
      setIsConnected(true)

      connected = true
    })

    newSocket.on('max-win', (yooh) => {
      setMaxWin(yooh)
    })

    newSocket.on('disconnect', () => {
      setIsConnected(false)
      setRunningGame(undefined)

      connected = false
    })

    newSocket.on('history', (message) => {
      const parsedValue = JSON.parse(message)

      setHistory(parsedValue.history)

      if (parsedValue.myHistory) {
        setMyHistory(parsedValue.myHistory)
      }
    })

    newSocket.on('error', (message, code, data) => {
      if (data) {
        const parsedData = JSON.parse(data)

        if (parsedData.amount !== undefined && tokens) {
          dispatch({
            type: 'SET_TOKENS',
            tokens: {
              ...tokens,
              yooh: (tokens.yooh ?? 0) + (parsedData.amount ?? 0) * 100
            },
            force: true
          })
        }
      }

      setCreatingGame(false)
      setWaitingForCashout(false)
      setBotPlaying(false)
      setActualProgramStep(0)
      setActualRepeat(0)

      if (connected) {
        toast.error(formatTextWithYOOH(message))
      } else if (socket && sessionToken) {
        setTimeout(() => newSocket.emit('authenticate', sessionToken), 1000)
      }
    })
  }, [sessionToken, socket, autoMode, initialized])

  useEffect(() => {
    if (!socket) return

    return () => {
      socket.disconnect()
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return

    socket.removeListener('running-game')
    socket.on('running-game', (message) => {
      const game = JSON.parse(message)

      initGame(game)

      setRunningGame(game)

      setCreatingGame(false)

      bombzState.external.sendRevealCell = ((cellIndex) => {
        socket.emit('reveal-cell', cellIndex)
      })

      if (botPlaying) {
        let actualProgram = program

        if (autoMode === 'random') {
          actualProgram = generateRandomProgram(Number(maxProgramStep))
        }

        setActualProgramStep(1)

        socket.emit('reveal-cell', actualProgram[0])
      }
    })

    socket.removeListener('cashout')
    socket.on('cashout', (message, finalRate, finalWin) => {
      const game = JSON.parse(message) as BombzGame

      setRunningGame(undefined)
      setWaitingForCashout(false)

      winSoundBombz.play()

      setConffeti([...confetti.slice(-2), <ConfettiExplosion force={0.4} duration={2200} particleCount={30} width={400} key={confettiKey++} />])

      if (bombzState.external.cashout) {
        bombzState.external.cashout(game, finalRate, finalWin)
      }

      if (tokens) {
        dispatch({
          type: 'ADD_TOKENS',
          tokens: {
            yooh: finalWin
          }
        })
      }

      if (botPlaying) {
        if ((actualRepeat + 1 < Number(repeat) || Number(repeat) === -1)) {
          setTimeout(() => {
            createGame(Number(bet), Number(bombs))
          }, 200)

          setActualRepeat(actualRepeat + 1)
          setActualProgramStep(0)
        } else {
          setBotPlaying(false)
          setActualRepeat(0)
          setActualProgramStep(0)
        }
      }
    })

    socket.removeListener('reveal-cell')
    socket.on('reveal-cell', (message: string) => {
      const revealMessage = JSON.parse(message) as BombzRevealCellMessage

      if (revealMessage.game) {
        setRunningGame(undefined)

        if (botPlaying) {
          if ((actualRepeat + 1 < Number(repeat) || Number(repeat) === -1)) {
            setTimeout(() => {
              createGame(Number(bet), Number(bombs))
            }, 200)

            setActualRepeat(actualRepeat + 1)
            setActualProgramStep(0)
          } else {
            setBotPlaying(false)
            setActualRepeat(0)
            setActualProgramStep(0)
          }
        }
      } else if (botPlaying) {
        setTimeout(() => {
          if (actualProgramStep >= program.length) {
            cashout()
          } else {
            socket.emit('reveal-cell', program[actualProgramStep])

            setActualProgramStep(actualProgramStep + 1)
          }
        }, 300)
      }

      if (bombzState.external.revealCell) {
        bombzState.external.revealCell(revealMessage)
      }
    })

    socket.removeListener('add-history')
    socket.on('add-history', (message) => {
      const game = JSON.parse(message) as BombzGame

      setHistory([
        game,
        ...history.slice(0, 8)
      ])

      if (game.user.userUuid === userUuid) {
        setMyHistory([
          game,
          ...myHistory.slice(0, 8)
        ])
      }
    })
  }, [socket, tokens, userUuid, history, myHistory, confetti, botPlaying, autoMode, bet, bombs, repeat, actualProgramStep, actualRepeat, maxProgramStep])

  useEffect(() => {
    bombzState.external.programStep = ((cellIndex) => {
      setProgram([...program, cellIndex])
    })
  }, [program])

  useEffect(() => {
    setProgram([])
    setActualProgramStep(0)
    setActualRepeat(0)
    setMaxProgramStep('')
  }, [autoMode, mode])

  useEffect(() => {
    if (botPlaying) {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: true
      })
    } else {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: false
      })
    }
  }, [botPlaying])

  return {
    createGame,
    sendRevealCell,
    cashout,
    startProgram,
    exitProgram,
    resetProgram,
    stopProgram,
    setMode,
    setAutoMode,
    setInitialized,
    setBombs,
    setBet,
    setRepeat,
    setMaxProgramStep,
    setProgram,
    isConnected,
    runningGame,
    maxWin,
    waitingForCashout,
    history,
    myHistory,
    creatingGame,
    confetti,
    mode,
    autoMode,
    botPlaying,
    initialized,
    bombs,
    bet,
    repeat,
    maxProgramStep,
    program
  }
}