import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './alert.module.scss'

export type AlertProps = HtmlHTMLAttributes<HTMLDivElement> & {
  type: 'info' | 'success' | 'warning' | 'danger' | 'normal'
  localStorageKey?: string
  show?: boolean
}

export const Alert = ({children, className, type, localStorageKey, show = true, ...props}: AlertProps) => {
  const initVisible = localStorageKey
    ? localStorage.getItem(localStorageKey) === 'visible' || !localStorage.getItem(localStorageKey) ? true : false
    : show

  const [visible, setVisible] = useState(initVisible)

  const switchVisible = () => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, `${!visible ? 'visible' : 'hidden'}`)
    }

    setVisible(!visible)
  }

  useEffect(() => {
    setVisible(initVisible)
  }, [initVisible])

  const icon: IconProp | undefined = type === 'success'
    ? 'circle-check'
    : type === 'info'
      ? 'circle-info'
      : type === 'danger' || type === 'warning'
        ? 'circle-exclamation' : undefined

  return <>
    {visible &&
      <div className={formatClassName(styles, `alert ${type} ${className}`)} {...props}>
        {icon &&
          <FontAwesomeIcon size='xl' icon={icon} />
        }
        <div className={formatClassName(styles, 'content')} >
          {children}
        </div>
        <FontAwesomeIcon className={formatClassName(styles, 'close')} size='xl' icon='xmark' onClick={switchVisible} />
      </div>
    }
  </>
}