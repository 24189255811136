.player {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;

  &.desktop {
    max-width: max(25vw, 480px);
    width: auto;
    aspect-ratio: 960 / 1198;
  }

  canvas {
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: unset;
  }

  .screen-size {
    padding: 1rem;
    text-align: center;
  }

  .game {
    width: 100%;
    height: 100%;

    &.hidden {
      visibility: hidden;
    }
  }
}
