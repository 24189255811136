import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {HtmlHTMLAttributes} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './loading.module.scss'

export type OverlayProps = HtmlHTMLAttributes<HTMLDivElement> & {
  text?: string
}

export type LoadingOverlayProps = HtmlHTMLAttributes<HTMLDivElement> & {
  text?: string
}

export const Overlay = ({className, children, ...props}: OverlayProps) =>
  <div className={formatClassName(styles, `loading-overlay ${className}`)} {...props}>
    {children}
  </div>

export const LoadingOverlay = ({text, ...props}: LoadingOverlayProps) =>
  <Overlay {...props}>
    {text}
    <FontAwesomeIcon spin icon="circle-notch" />
  </Overlay>