import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Decimal from 'decimal.js'
import React, {ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useState} from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import Countdown, {CountdownRendererFn, calcTimeDelta} from 'react-countdown'
import {toast} from 'react-toastify'
import {Socket, io} from 'socket.io-client'
import {YOOH} from '../../../svg/token'
import {CDN_URL} from '../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../utils/global'
import {Button, ButtonIcon} from '../../components/buttons/button'
import {ConnectButton} from '../../components/buttons/connect-button'
import {Input} from '../../components/input/input'
import {PopoverHelp} from '../../components/popover/popover'
import {Toggle} from '../../components/toggle/toggle'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import styles from './baddie-beast.module.scss'
import {clickAffirmativeSoundBaddieBeast, clickNegativeSoundBaddieBeast, coinsSoundBaddieBeast, loseSoundBaddieBeast, selectSoundBaddieBeast, winSoundBaddieBeast} from './sounds'
import {BaddieBeastClientToServerEvents, BaddieBeastGame, BaddieBeastGameMode, BaddieBeastServerToClientEvents, BaddieBeastSide} from './types'

const rendererLight: CountdownRendererFn = ({seconds}) =>
  <span className={formatClassName(styles, 'countdown')} >
    {seconds}
  </span>
const timeAgoRenderer = (createdAt: number) => {
  const {seconds, minutes, hours, days} = calcTimeDelta(createdAt, {overtime: true})

  return <span className={formatClassName(styles, 'countdown')}>
    {
      days > 0 || hours > 0
        ? 'hours ago'
        : minutes > 0
          ? `${minutes}m ago`
          : `${seconds}s ago`
    }
  </span>
}

export type BaddieBeastProps = HtmlHTMLAttributes<HTMLDivElement>

const MIN_GAMES = 1
const MAX_GAMES = 500

const MAX_BET = {
  'solo': 100,
  'pvp': Number.MAX_SAFE_INTEGER
}

let confettiKey = 0

//export const baddieBeastClientURL = 'ws://localhost:3020'
//export const baddieBeastClientPath = undefined
//export const baddieBeastClientURL = 'wss://test-server.piratesquadnft.com'
//export const baddieBeastClientPath = '/baddie-beast/socket.io'
//export const baddieBeastClientURL = 'wss://s1.piratesquadnft.com'
//export const baddieBeastClientPath = '/baddie-beast/socket.io'
export const baddieBeastClientURL = 'wss://s2.piratesquadnft.com'
export const baddieBeastClientPath = '/baddie-beast/socket.io'

export const BaddieBeast = ({className, ...props}: BaddieBeastProps) => {
  const {state: {tokens, sessionToken, userUuid}, dispatch} = useHubContext()
  const [opponent, setOpponent] = useState<BaddieBeastGameMode>('solo')
  const [side, setSide] = useState<BaddieBeastSide>('beast')
  const [numberOfGames, setNumberOfGames] = useState('1')
  const [bet, setBet] = useState('')
  const [myGamesChecked, setMyGamesChecked] = useState(false)
  const [botRunning, setBotRunning] = useState(false)
  const [botStep, setBotStep] = useState(0)
  const [confetti, setConffeti] = useState<JSX.Element[]>([])

  const numberOfGamesChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number)) {
      setNumberOfGames(value)
    } else {
      if (number < MIN_GAMES) {
        setNumberOfGames(String(MIN_GAMES))
      } else if (number > MAX_GAMES) {
        setNumberOfGames(String(MAX_GAMES))
      } else {
        setNumberOfGames(value.split(/[.,]/g)[0])
      }
    }
  }

  const betChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)
    if (isNaN(number)) {
      setBet(value)
    } else {
      if (number < 0) {
        setBet('0')
      } else if (number > MAX_BET[opponent]) {
        setBet(String(MAX_BET[opponent]))
      } else {
        setBet(value)
      }
    }
  }

  const allIn = () => {
    if (tokens?.yooh === undefined) return

    coinsSoundBaddieBeast.play()

    betChange({target: {value: String(tokens.yooh / 100)}} as any)
  }

  useEffect(() => {
    betChange({target: {value: String(bet)}} as any)
  }, [opponent])

  // WEBSOCKET

  const [socket, setSocket] = useState<Socket<BaddieBeastServerToClientEvents, BaddieBeastClientToServerEvents>>()
  const [isConnected, setIsConnected] = useState(false)
  const [countConnected, setCountConnected] = useState(0)
  const [history, setHistory] = useState<BaddieBeastGame[]>([])
  const [myHistory, setMyHistory] = useState<BaddieBeastGame[]>([])
  const [activeGames, setActiveGames] = useState<BaddieBeastGame[]>([])
  const [cancelingGames, setCancelingGames] = useState<BaddieBeastGame[]>([])

  useEffect(() => {
    if (socket) return

    const newSocket: Socket<BaddieBeastServerToClientEvents, BaddieBeastClientToServerEvents> = io(baddieBeastClientURL, {
      path: baddieBeastClientPath
    })

    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (sessionToken) {
        newSocket.emit('authenticate', sessionToken)
      } else {
        newSocket.emit('history')
      }
    })

    newSocket.on('connected', () => {
      setIsConnected(true)
    })

    newSocket.on('count_connected', (count) => {
      setCountConnected(count)
    })

    newSocket.on('disconnect', () => {
      setIsConnected(false)
    })
  }, [sessionToken, socket])

  useEffect(() => {
    if (!socket) return

    return () => {
      socket.disconnect()
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return

    socket.removeListener('new-game')
    socket.on('new-game', (message) => {
      const game = JSON.parse(message) as BaddieBeastGame

      const newActiveGames = [
        game,
        ...activeGames
      ]

      setActiveGames([
        ...newActiveGames.filter(activeGame => activeGame.mode === 'pvp'),
        ...newActiveGames.filter(activeGame => activeGame.mode === 'solo')
      ])
    })

    socket.removeListener('cancel-game')
    socket.on('cancel-game', (roundId, newAmount) => {
      const activeGameId = activeGames.findIndex(game => game.roundId === roundId)

      if (activeGameId !== -1) {
        setActiveGames([
          ...activeGames.slice(0, activeGameId),
          ...activeGames.slice(activeGameId + 1)
        ])
      }

      setCancelingGames(cancelingGames.filter(cancelingGame => cancelingGame.roundId !== roundId))

      if (newAmount !== undefined) {
        dispatch({
          type: 'SET_TOKENS',
          tokens: {
            ...tokens,
            yooh: newAmount
          },
          force: true
        })
      }
    })

    socket.removeListener('game-ended')
    socket.on('game-ended', (message) => {
      let game = JSON.parse(message) as BaddieBeastGame

      const activeGameId = activeGames.findIndex(activeGame => activeGame.roundId === game.roundId)

      if (activeGameId === -1) {
        game.endedAt = Date.now() + 3000

        const newActiveGames = [
          game,
          ...activeGames
        ]

        setActiveGames([
          ...newActiveGames.filter(activeGame => activeGame.mode === 'pvp'),
          ...newActiveGames.filter(activeGame => activeGame.mode === 'solo')
        ])
      } else {
        const copy = [
          ...activeGames
        ]

        copy[activeGameId] = {
          ...game,
          endedAt: Date.now() + 3000
        }

        game = copy[activeGameId]

        setActiveGames([
          ...copy.filter(activeGame => activeGame.mode === 'pvp'),
          ...copy.filter(activeGame => activeGame.mode === 'solo')
        ])
      }

      if (botRunning && game.mode === 'solo') {
        if (botStep < Number(numberOfGames) || Number(numberOfGames) === -1) {
          setBotStep(botStep + 1)

          setTimeout(createGame, 1000)
        } else {
          setBotStep(0)
          setBotRunning(false)
        }
      }
    })

    socket.removeListener('history')
    socket.on('history', (message) => {
      const parsedValue = JSON.parse(message)

      setHistory(parsedValue.history)
      setMyHistory(parsedValue.myHistory)
      setActiveGames(parsedValue.activeGames)
    })

    socket.removeListener('error')
    socket.on('error', (message, code, data) => {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: false
      })

      if (data) {
        const parsedData = JSON.parse(data)

        if (parsedData.amount !== undefined && tokens?.yooh !== undefined) {
          dispatch({
            type: 'ADD_TOKENS',
            tokens: {
              ...tokens,
              yooh: (parsedData.amount ?? 0) * 100
            }
          })
        }
      }

      if (isConnected) {
        toast.error(message)
      } else if (socket && sessionToken) {
        setTimeout(() => socket.emit('authenticate', sessionToken), 1000)
      }

      setBotRunning(false)
      setBotStep(0)
    })
  }, [socket, history, myHistory, tokens, activeGames, userUuid, myGamesChecked, isConnected, botRunning, botStep, numberOfGames, confetti])

  useEffect(() => {
    if (botRunning) {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: true
      })
    } else {
      dispatch({
        type: 'SET_PAUSE_REFRESH_TOKENS',
        pauseRefreshTokens: false
      })
    }
  }, [botRunning])

  const createGame = () => {
    if (!socket) return

    if (opponent === 'pvp' && Number(numberOfGames) > 1) {
      toast.error('You cannot create more than one game when user opponent is chosen')

      return
    }

    dispatch({
      type: 'SET_PAUSE_REFRESH_TOKENS',
      pauseRefreshTokens: true
    })

    if ((Number(numberOfGames) > 1 || Number(numberOfGames) === -1) && !botRunning) {
      setBotRunning(true)
      setBotStep(1)
    }

    const delayed = botRunning || (Number(numberOfGames) > 1 || Number(numberOfGames) === -1)

    if (Number(bet) === 0) {
      clickNegativeSoundBaddieBeast.play()

      toast.error(formatTextWithYOOH('Minimum bet is $YOOH 1'))

      return
    }

    if ((tokens?.yooh ?? 0) >= Number(bet) * 100) {
      clickAffirmativeSoundBaddieBeast.play()

      setTimeout(() => socket.emit('create-game', Number(bet), opponent, side), delayed ? 100 : 0)

      dispatch({
        type: 'ADD_TOKENS',
        tokens: {
          ...tokens,
          yooh: -Number(bet) * 100
        }
      })
    } else {
      clickNegativeSoundBaddieBeast.play()

      toast.error(formatTextWithYOOH('Not enough $YOOH on your account to create the game'))
    }
  }

  const joinGame = (game: BaddieBeastGame) => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Joining the game?',
        text: <div className={formatClassName(styles, 'confirm-join')}>
          It will cost you <YOOH /> {game.formattedAmount / 100}
        </div>,
        acceptText: 'join',
        refuseText: 'leave',
        onAccept() {
          if (!socket) return

          dispatch({
            type: 'SET_PAUSE_REFRESH_TOKENS',
            pauseRefreshTokens: true
          })

          if (tokens?.yooh !== undefined && tokens.yooh >= game.formattedAmount) {
            dispatch({
              type: 'ADD_TOKENS',
              tokens: {
                ...tokens,
                yooh: -game.formattedAmount
              }
            })

            clickAffirmativeSoundBaddieBeast.play()

            socket.emit('join-game', game.roundId)
          } else {
            toast.error(formatTextWithYOOH('Not enough $YOOH on your account to join the game'))
          }
        }
      })
    }
  }

  const cancelGame = (game: BaddieBeastGame) => {
    if (!socket) return

    selectSoundBaddieBeast.play()

    setCancelingGames([
      ...cancelingGames,
      game
    ])

    socket.emit('cancel-game', game.roundId)
  }

  const removeGameFromActiveList = (game: BaddieBeastGame) => {
    setHistory([
      game,
      ...history.slice(0, 8)
    ])

    if (game.participants.baddie?.userUuid === userUuid || game.participants.beast?.userUuid === userUuid) {
      setMyHistory([
        game,
        ...myHistory.slice(0, 8)
      ])
    }

    setActiveGames([
      ...activeGames.filter(activeGame => activeGame.roundId !== game.roundId)
    ])

    const myGame = game.participants.beast?.userUuid === userUuid || game.participants.baddie?.userUuid === userUuid
    const won = game.result?.winnerUserUuid === userUuid

    if (myGame && game.result) {
      if (won) {
        dispatch({
          type: 'ADD_TOKENS',
          tokens: {
            ...tokens,
            yooh: (game.result.winnerAmount ?? 0)
          }
        })

        setConffeti([...confetti.slice(-1), <ConfettiExplosion force={0.4} duration={2200} particleCount={30} width={400} key={confettiKey++} />])

        winSoundBaddieBeast.play()
      } else {
        loseSoundBaddieBeast.play()
      }
    }
  }

  const flipCoin = (game: BaddieBeastGame) => {
    if (game.flipping) return

    game.flipping = true

    setActiveGames([...activeGames])

    setTimeout(() => {
      game.flipped = true
    }, 3000)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      activeGames.forEach((activeGame) => {
        if (activeGame.flipped) {
          removeGameFromActiveList(activeGame)
        }
      })
    })

    return () => {
      clearInterval(interval)
    }
  }, [activeGames])

  const myGamesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMyGamesChecked(event.target.checked)
  }

  const openProfile = (userUuid?: string) => {
    if (!userUuid || userUuid === 'PirateSquad') return

    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  const stopBot = () => {
    setBotRunning(false)
  }

  return (
    <div className={formatClassName(styles, `baddie-beast ${className ? className : ''}`)} {...props}>
      <div className={formatClassName(styles, 'marketing')}>
        <div className={formatClassName(styles, 'offer')}>
          {/*<Countdown date={new Date('02/17/2023')} renderer={rendererFull} ></Countdown>*/}
        </div>
        <img src={`${CDN_URL}/games/baddie-beast/marketing/bsl.jpg`} alt="random" />
      </div>
      <div className={formatClassName(styles, 'game')}>
        <div className={formatClassName(styles, 'settings')}>
          <div className={formatClassName(styles, 'setting')}>
            <div className={formatClassName(styles, 'title')}>
              Choose Opponent <PopoverHelp>
                <>
                  <div>How to play</div>
                  <ul>
                    <li>
                      Choose your Opponent, House (Pirate Squad Bot) or User (PVP)
                    </li>
                    <li>
                      Choose your side Beast, Baddie or Random
                    </li>
                    <li>
                      Add desired wagering amount in $YOOH (limited to 100$ against house)
                    </li>
                    <li>
                      Click on “Let's Go!”
                    </li>
                  </ul>
                  <div>
                    For PVP mode wait till users join your game.<br />
                    For House mode wait for the result!<br />
                    Good luck!<br />
                  </div>
                </>
              </PopoverHelp>
            </div>
            <div className={formatClassName(styles, 'content')}>
              <div className={formatClassName(styles, 'multi-button')}>
                <Button className={formatClassName(styles, `no-color ${opponent === 'solo' && 'selected'}`)} onClick={() => {
                  setOpponent('solo')
                  selectSoundBaddieBeast.play()
                }}>House</Button>
                <Button className={formatClassName(styles, `no-color ${opponent === 'pvp' && 'selected'}`)} onClick={() => {
                  setOpponent('pvp')
                  numberOfGamesChange({target: {value: '1'}} as any)
                  selectSoundBaddieBeast.play()
                }}>User</Button>
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'setting')}>
            <div className={formatClassName(styles, 'title')}>
              Choose Your Side
            </div>
            <div className={formatClassName(styles, 'content tokens')}>
              <div className={formatClassName(styles, `token beast ${side === 'beast' && 'selected'}`)} onClick={() => {
                setSide('beast')
                selectSoundBaddieBeast.play()
              }}>
                <img src={`${CDN_URL}/games/baddie-beast/tokens/beast.svg`} alt="beast" />
                BEAST
              </div>
              <div className={formatClassName(styles, `token baddie ${side === 'baddie' && 'selected'}`)} onClick={() => {
                setSide('baddie')
                selectSoundBaddieBeast.play()
              }}>
                <img src={`${CDN_URL}/games/baddie-beast/tokens/baddie.svg`} alt="baddie" />
                BADDIE
              </div>
              <div className={formatClassName(styles, `token random ${side === 'random' && 'selected'}`)} onClick={() => {
                setSide('random')
                selectSoundBaddieBeast.play()
              }}>
                <img src={`${CDN_URL}/games/baddie-beast/tokens/rand.svg`} alt="random" />
                RANDOM
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'setting')}>
            <div className={formatClassName(styles, 'title')}>
              Parameters
            </div>
            <div className={formatClassName(styles, 'content parameters')}>
              <div className={formatClassName(styles, 'parameter')}>
                <div className={formatClassName(styles, 'name')}>Number of Games</div>
                <div className={formatClassName(styles, `input ${opponent === 'pvp' ? 'disabled' : ''}`)}>
                  <FontAwesomeIcon icon="refresh" />
                  <Input type="number" max={MAX_GAMES} min={MIN_GAMES} value={numberOfGames} onChange={numberOfGamesChange} readOnly={opponent === 'pvp'} />
                  <div className={formatClassName(styles, 'increment')}>
                    <Button disabled={opponent === 'pvp'} onClick={() => {
                      numberOfGamesChange({target: {value: String(new Decimal(numberOfGames).add(1).toNumber())}} as any)
                      selectSoundBaddieBeast.play()
                    }}>+</Button>
                    <Button disabled={opponent === 'pvp'} onClick={() => {
                      numberOfGamesChange({target: {value: String(new Decimal(numberOfGames).minus(1).toNumber())}} as any)
                      selectSoundBaddieBeast.play()
                    }}>-</Button>
                  </div>
                </div>
              </div>
              <div className={formatClassName(styles, 'separator')}>
                x
              </div>
              <div className={formatClassName(styles, 'parameter')}>
                <div className={formatClassName(styles, 'name')}>
                  <div>Bet Amount</div>
                  <div className={formatClassName(styles, 'all-in')} onClick={allIn}>all-in</div>
                </div>
                <div className={formatClassName(styles, 'input')}>
                  <YOOH />
                  <Input type="number" placeholder='0.00' min={0} max={MAX_BET[opponent]} value={bet} onChange={betChange} />
                  <div className={formatClassName(styles, 'increment')}>
                    <Button onClick={() => {
                      betChange({target: {value: new Decimal(Number(bet)).add(1).toString()}} as any)
                      coinsSoundBaddieBeast.play()
                    }}>+</Button>
                    <Button onClick={() => {
                      betChange({target: {value: new Decimal(Number(bet)).minus(1).toString()}} as any)
                      coinsSoundBaddieBeast.play()
                    }}>-</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'setting create')}>
            {
              !sessionToken
                ? <ConnectButton />
                : botRunning
                  ? <ButtonIcon icon="right-from-bracket" onClick={stopBot}>
                    Stop autoplay
                  </ButtonIcon>
                  : <ButtonIcon icon="coins" loading={!isConnected || !tokens} loadingWithChildren onClick={createGame}>
                    {!isConnected ? 'Connecting' : !tokens ? 'Loading tokens' : 'Double or Nothing!'}
                  </ButtonIcon>
            }
            <div className={formatClassName(styles, 'confetti')}>
              {confetti}
            </div>
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, 'active-games')}>
        <div className={formatClassName(styles, 'title')}>
          Active Games {activeGames.length > 0 && <>({activeGames.length})</>}
        </div>
        {
          activeGames.map(game => {
            const isCanceling = !!cancelingGames.find(cancelingGame => cancelingGame.roundId === game.roundId)

            return <div key={`active-game-${game.roundId}`} className={formatClassName(styles, `active-game ${game.flipping ? 'flipping' : ''}`)}>
              <div className={formatClassName(styles, 'participant a')}>
                <img src={`${CDN_URL}/games/baddie-beast/tokens/beast.svg`} alt="beast" />
                <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants.beast?.userUuid)}>{game.participants.beast?.username ?? ''}</span>
              </div>
              <div className={formatClassName(styles, 'middle b')}>
                {
                  game.flipping
                    ? <div className={formatClassName(styles, `flip ${game.result?.winner}`)}>
                      <div className={formatClassName(styles, 'beast-side')}>
                        <img src={`${CDN_URL}/games/baddie-beast/tokens/beast.svg`} alt="beast" />
                      </div>
                      <div className={formatClassName(styles, 'beast-backside')}></div>
                      <div className={formatClassName(styles, 'baddie-backside')}></div>
                      <div className={formatClassName(styles, 'baddie-side')}>
                        <img src={`${CDN_URL}/games/baddie-beast/tokens/baddie.svg`} alt="baddie" />
                      </div>
                    </div>
                    : game.endedAt !== undefined
                      ? <Countdown date={game.endedAt} renderer={rendererLight} onComplete={() => flipCoin(game)}></Countdown>
                      : userUuid && (game.participants.baddie?.userUuid === userUuid || game.participants.beast?.userUuid === userUuid)
                        ? <Button className={formatClassName(styles, 'no-color cancel-button')} loading={isCanceling} onClick={() => cancelGame(game)}>cancel <YOOH /> {(game.formattedAmount ?? 0) / 100}</Button>
                        : sessionToken && <Button className={formatClassName(styles, 'no-color join-button')} loading={!isConnected} onClick={() => joinGame(game)}>join <YOOH /> {(game.formattedAmount ?? 0) / 100}</Button>
                }
              </div>
              <div className={formatClassName(styles, 'participant c')}>
                <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants.baddie?.userUuid)}>{game.participants.baddie?.username ?? ''}</span>
                <img src={`${CDN_URL}/games/baddie-beast/tokens/baddie.svg`} alt="baddie" />
              </div>
            </div>
          })
        }
      </div>
      <div className={formatClassName(styles, 'history-games')}>
        <div className={formatClassName(styles, 'title')}>
          History
          <span className={formatClassName(styles, 'my-games-toggle')} onClick={() => myGamesChange({target: {checked: !myGamesChecked}} as any)} >
            <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={myGamesChecked} readOnly />
            <span>only my games</span>
          </span>
        </div>
        <div className={formatClassName(styles, 'list')}>
          {
            (myGamesChecked ? myHistory : history).slice(0, 9).map(game => {
              const winnerSide = game.result?.winner as 'baddie' | 'beast'
              const myGame = game.participants.beast?.userUuid === userUuid || game.participants.baddie?.userUuid === userUuid
              const won = game.result && game.participants[winnerSide]?.userUuid === userUuid

              return <div key={`history-${game.roundId}`} className={formatClassName(styles, `history ${myGame ? won ? 'won' : 'lost' : ''} ${game.result?.winner}`)}>
                <div className={formatClassName(styles, 'info')}>
                  {myGame
                    ? <>
                      <div className={formatClassName(styles, `username ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                        You {won ? <>won <YOOH /> {(game.result?.winnerAmount ?? 0) / 100}</> : <>lost <YOOH /> {(game.formattedAmount ?? 0) / 100}</>}
                      </div>
                      <div>
                        vs <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[won ? winnerSide === 'beast' ? 'baddie' : 'beast' : winnerSide]?.userUuid)}>
                          {game.participants[won ? winnerSide === 'beast' ? 'baddie' : 'beast' : winnerSide]?.username}
                        </span>
                      </div>
                    </>
                    : <>
                      <div className={formatClassName(styles, 'username')}>
                        <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[winnerSide]?.userUuid)}>
                          {game.participants[winnerSide]?.username}
                        </span>
                        won <YOOH /> {(game.result?.winnerAmount ?? 0) / 100}
                      </div>
                      <div>
                        vs <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.participants[winnerSide === 'baddie' ? 'beast' : 'baddie']?.userUuid)}>
                          {game.participants[winnerSide === 'baddie' ? 'beast' : 'baddie']?.username}
                        </span>
                      </div>
                    </>
                  }

                </div>
                <div className={formatClassName(styles, 'badge')}>
                  {game.result?.winner === 'baddie'
                    ? <img src={`${CDN_URL}/games/baddie-beast/tokens/baddie.svg`} alt="baddie" />
                    : <img src={`${CDN_URL}/games/baddie-beast/tokens/beast.svg`} alt="beast" />
                  }
                </div>
                <div className={formatClassName(styles, 'time-ago')}>
                  <Countdown date={game.endedAt} renderer={() => timeAgoRenderer(game.endedAt ?? game.createdAt)} overtime></Countdown>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}