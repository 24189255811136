@import "scss-variables";

.classic-leaderboard {
  display: flex;
  position: absolute;
  padding: 1rem;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5rem);
  background: #ffffff88;
  box-sizing: border-box;
  gap: 1rem;
  color: $text-color;

  .leaderboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;

    .rank {
      font-weight: normal;
      color: white;
      text-align: center;
      background-color: $color-violet;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }

    .leaders {
      flex: 1;

      .leaders-grid {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: 0.5rem;
        overflow-y: auto;

        .header {
          font-style: italic;
        }

        .you {
          color: $color-warning;
        }
      }
    }

    .actions {
      display: flex;

      .exit-button {
        flex: 1;

        color: #333;

        &:hover:not(:disabled),
        &.selected {
          background-color: #33333311 !important;
        }
      }
    }
  }
}
