import {SkeletonData, Spine} from 'pixi-spine'
import {initBubble} from './bubble'
import {BubbleSkinName} from './enums'
import {bubbleState} from './state'
import {getGameHeight, getRatio} from './utils'

//const loader = new Loader()

/**
 * Creating the pirate sprite
 * @returns The created pirate Spine object if data are available
 */
export const initPirate = (deletePrevious = false): Spine | undefined => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.resources?.pirate.spineData) return

  const ratio = getRatio()

  if (deletePrevious && bubbleState.references.pirate) bubbleState.references.pirate.destroy(true)

  const pirate = new Spine(bubbleState.resources.pirate.spineData as SkeletonData)

  pirate.x = (pirate.children[0] as any).width * 0.25
  pirate.y = getGameHeight() - 20 * ratio

  pirate.scale.set(0.25 * ratio, 0.25 * ratio)

  bubbleState.viewport.addChild(pirate)

  pirate.skeleton.setSkinByName(BubbleSkinName.BlueOne)
  pirate.state.setAnimation(0, 'IdleAnimation', true)

  bubbleState.references.pirate = pirate

  return pirate
}

/**
 * Throw the bubble to the canon
 */
export const throwBubble = (throwAnimationCallback?: () => void, newBubbleEventCallback?: () => void) => {
  if (!bubbleState.references.pirate || bubbleState.references.pirate.skeleton.skin?.name === 'EmptySkin') return

  if (newBubbleEventCallback) {
    bubbleState.references.pirate.state.addListener({
      event(_, event) {
        if (event.data.name === 'NewBubbleEvent') {
          newBubbleEventCallback()
        }
      }
    })
  }

  bubbleState.references.pirate.state.timeScale = 1.4

  bubbleState.references.pirate.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'ThrowAnimation' && bubbleState.references.pirate) {
        if (throwAnimationCallback) throwAnimationCallback()

        bubbleState.references.pirate.state.timeScale = 1

        bubbleState.references.pirate.state.clearListeners()
      }
    }
  })

  bubbleState.references.pirate.state.setAnimation(0, 'ThrowAnimation')
}

export const loadEmptyBubble = () => {
  if (!bubbleState.resources || !bubbleState.references.pirate) return

  bubbleState.references.pirate.state.addAnimation(0, 'IdleAnimation', true)

  bubbleState.references.pirate.skeleton.setSkinByName('EmptySkin')

  bubbleState.references.pirate.skeleton.slots[7].setAttachment(null)
}

/**
 * Generate the next bubble in hands of the pirate
 */
export const loadBubbleIntoHands = (skin: BubbleSkinName) => {
  if (!bubbleState.resources || !bubbleState.references.pirate) return

  bubbleState.references.pirate.state.addAnimation(0, 'IdleAnimation', true)

  bubbleState.references.pirate.skeleton.setSkinByName(skin)

  bubbleState.references.bubbleInHand = initBubble(skin)
}

//export const savePirate = async (pirateCharacter: PirateCharacter) => {
//  if (!bubbleState.gameState || bubbleState.gameState.pirateCharacter === pirateCharacter) return

//  bubbleState.gameState = {
//    ...bubbleState.gameState,
//    ...(await changePirateCharacterRequest(pirateCharacter))
//  }
//}

//export const changePirate = (pirateCharacter?: PirateCharacter, init = false) => {
//  if (!bubbleState.gameState || bubbleState.gameState.pirateCharacter === pirateCharacter) return

//  const globalLoader = Loader.shared

//  loader.reset()

//  loader.add('pirate', `${BUBBLE_CDN_URL}/animations/pirates/${pirateCharacter ?? PirateCharacter.PirateOne}.json`).load((_, resources) => {
//    Object.assign(globalLoader.resources, resources)

//    if (init) initPirate(true)
//  })
//}