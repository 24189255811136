import {sound} from '@pixi/sound'
import Decimal from 'decimal.js'
import FontFaceObserver from 'fontfaceobserver'
import gsap, {Linear} from 'gsap'
import {PixiPlugin} from 'gsap/PixiPlugin'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {Container, Graphics, Sprite, Text, TextMetrics, TextStyle} from 'pixi.js'
import {CDN_URL} from '../../../../utils/constants'
import {shuffle} from '../../../../utils/global'
import {SLOTS_REELS_ITEMS} from '../constants'
import {barLineSoundSlots, cherryLineSoundSlots, number777LineSoundSlots, randomSpinSoundSlots, reelStopEndSoundSlots, simpleComboSoundSlots, simpleLineSoundSlots, stopSpinSoundSlots} from '../sounds'
import {SlotsGame} from '../types'
import {SlotsSymbolName} from './enums'
import {ReelSymbol} from './reel-symbol'
import {circleParticleEmitter, randomEmitter} from './special-effects'
import {slotsState} from './state'
import {SlotsState} from './types'
import {getSlotsGameHeight, getSlotsGameWidth} from './utils'

// register the plugin
gsap.registerPlugin(PixiPlugin)

// give the plugin a reference to the PIXI object
PixiPlugin.registerPIXI(PIXI)

/**
 * Initialize the game into a canvas DOM object
 * @param canvas The canvas DOM object to inject the game into
 * @returns The application object
 */
export const initSlotsGame = async (
  canvas: HTMLCanvasElement | undefined,
  config?: SlotsState['external'],
  onLoaderProgress?: (progress: number, exited?: boolean) => void
) => {
  if (!canvas) return

  slotsState.external = {
    ...config,
    spin,
    endSpin,
    showLineIndicators
  }

  slotsState.exited = false

  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2

  const parent = canvas.parentElement!

  const app = new PIXI.Application({
    view: canvas,
    resolution: Math.max(2, window.devicePixelRatio),
    autoDensity: true,
    antialias: true,
    resizeTo: parent,
    backgroundAlpha: 0
  })

  slotsState.app = app

  app.stage.interactive = true

  app.ticker.stop()

  const loader = PIXI.Assets

  const gsapTicker = () => {
    app.ticker.update()
  }

  // Now, we use 'tick' from gsap
  gsap.ticker.add(gsapTicker)

  slotsState.external.clearGame = async () => {
    slotsState.exited = true

    gsap.ticker.remove(gsapTicker)
    loader.unloadBundle('slots')
    sound.removeAll()
    app.destroy()

    viewportReset()

    slotsState.external.clearGame = undefined
  }

  const rifficFont = new FontFaceObserver('Riffic')
  const balooThambiFont = new FontFaceObserver('Baloo Thambi')

  loader.addBundle('slots', {
    number7: CDN_URL + '/games/slots/images/number7.png',
    number777: CDN_URL + '/games/slots/images/number777.png',
    //letterA: CDN_URL + '/games/slots/images/a.png',
    //letterJ: CDN_URL + '/games/slots/images/j.png',
    //letterK: CDN_URL + '/games/slots/images/k.png',
    //letterQ: CDN_URL + '/games/slots/images/q.png',
    bar: CDN_URL + '/games/slots/images/bar.png',
    //bell: CDN_URL + '/games/slots/images/bell.png',
    //bigDiamond: CDN_URL + '/games/slots/images/big_diamond.png',
    //club: CDN_URL + '/games/slots/images/club.png',
    //coin: CDN_URL + '/games/slots/images/coin.png',
    //crown: CDN_URL + '/games/slots/images/crown.png',
    //diamond: CDN_URL + '/games/slots/images/diamond.png',
    //dynamite: CDN_URL + '/games/slots/images/dynamite.png',
    apple: CDN_URL + '/games/slots/images/apple.png',
    citrus: CDN_URL + '/games/slots/images/citrus.png',
    plum: CDN_URL + '/games/slots/images/plum.png',
    cherry: CDN_URL + '/games/slots/images/cherry.png',
    //grape: CDN_URL + '/games/slots/images/grape.png',
    //heart: CDN_URL + '/games/slots/images/heart.png',
    //luck: CDN_URL + '/games/slots/images/luck.png',
    //moneyBag: CDN_URL + '/games/slots/images/money_bag.png',
    //spade: CDN_URL + '/games/slots/images/spade.png'
    //star: CDN_URL + '/games/slots/images/star.png'
    // Emitter
    particle: CDN_URL + '/games/slots/images/particle.png',
  })

  let resources

  try {
    resources = await loader.loadBundle('slots')
  } catch (err) {
    console.error(err)

    if (!slotsState.exited && slotsState.external.clearGame) {
      slotsState.exited = true

      slotsState.external.clearGame()
    }

    return
  }

  slotsState.resources = resources

  try {
    await Promise.all([
      rifficFont.load(),
      balooThambiFont.load()
    ])

    if (onLoaderProgress) onLoaderProgress(100)

    app.resize()

    createViewport()

    initReels()

    app.ticker.add((delta) => {
      const roundedDelta = new Decimal(delta).mul(10).round().div(10).toNumber()

      update(roundedDelta)
    })
  } catch (err) {
    console.error('loading failed', err)
  }

  return slotsState.external.clearGame
}

const initReels = () => {
  if (!slotsState.viewport) return

  const rowHeight = 500 / 3
  const columnWidth = 500 / 3

  const minRows = Math.min(
    SLOTS_REELS_ITEMS.reelA.length,
    SLOTS_REELS_ITEMS.reelB.length,
    SLOTS_REELS_ITEMS.reelC.length
  )

  const reels = [
    shuffle(JSON.parse(JSON.stringify(SLOTS_REELS_ITEMS.reelA))),
    shuffle(JSON.parse(JSON.stringify(SLOTS_REELS_ITEMS.reelB))),
    shuffle(JSON.parse(JSON.stringify(SLOTS_REELS_ITEMS.reelC)))
  ]

  for (let row = -minRows + 3; row < 3; row++) {
    for (let column = 0; column < 3; column++) {
      const reelSymbol = new ReelSymbol(reels[column][row + minRows - 3])

      reelSymbol.sprite.scale.set(0.4, 0.4)

      reelSymbol.sprite.x = columnWidth * 0.5
      reelSymbol.sprite.y = rowHeight * row + rowHeight * 0.5
      reelSymbol.sprite.alpha = 0

      reelSymbol.reel = column

      slotsState.references.reelsContainers[column].addChild(reelSymbol.sprite)

      slotsState.references.reels[column].push(reelSymbol)

      gsap.to(reelSymbol.sprite, {
        duration: 0.5,
        alpha: 1
      })
    }
  }

  // Lines indicators

  const line1 = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(columnWidth / 2, -4, 500 - columnWidth, 8, 8)
    .endFill()
  line1.position.set(0, rowHeight / 2)
  line1.alpha = 0
  line1.zIndex = 0

  const line2 = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(columnWidth / 2, -4, 500 - columnWidth, 8, 8)
    .endFill()
  line2.position.set(0, rowHeight + rowHeight / 2)
  line2.alpha = 0
  line2.zIndex = 0

  const line3 = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(columnWidth / 2, -4, 500 - columnWidth, 8, 8)
    .endFill()
  line3.position.set(0, rowHeight * 2 + rowHeight / 2)
  line3.alpha = 0
  line3.zIndex = 0

  const diagUp = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(0, -4, (500 - columnWidth) * Math.SQRT2, 8, 8)
    .endFill()
  diagUp.position.set(columnWidth / 2, 500 - rowHeight / 2)
  diagUp.alpha = 0
  diagUp.zIndex = 0
  diagUp.pivot.set(0, 0)
  diagUp.angle = -45

  const diagDown = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(0, -4, (500 - columnWidth) * Math.SQRT2, 8, 8)
    .endFill()
  diagDown.position.set(columnWidth / 2, rowHeight / 2)
  diagDown.alpha = 0
  diagDown.zIndex = 0
  diagDown.pivot.set(0, 0)
  diagDown.angle = 45

  slotsState.references.indicators = {
    line1,
    line2,
    line3,
    diagUp,
    diagDown
  }

  slotsState.viewport.addChild(line1, line2, line3, diagUp, diagDown)
}

const spin = (lines: number) => {
  console.log('STARTING SPIN')

  if (slotsState.external.pauseRefreshToken) {
    slotsState.external.pauseRefreshToken(true)
  }

  if (slotsState.external.setSpinning) {
    slotsState.external.setSpinning(true)
  }

  Object.values(slotsState.references.indicators).forEach((indicator, index) => {
    if (!indicator) return

    gsap.killTweensOf(indicator)

    if (index === 0) {
      index = 1
    } else if (index === 1) {
      index = 0
    }

    indicator.tint = 0xffffff
    indicator.alpha = index < lines ? 0.05 : 0
  })

  randomSpinSoundSlots().play()

  Object.values(slotsState.references.reels)
    .forEach((reelSymbols, index) => {
      const reelsContainer = slotsState.references.reelsContainers[index]
      reelsContainer.filters = []

      setTimeout(() => {
        //addMotionBlur(reelsContainer, [5, 15])

        reelSymbols.forEach(reelSymbol => {
          reelSymbol.line = undefined
          reelSymbol.speed.vy = 30

          gsap.killTweensOf(reelSymbol.sprite)

          reelSymbol.sprite.scale.set(0.4, 0.4)
        })
      }, Math.random() * 200)
    })
}

const endSpinReel = (game: SlotsGame, reel: ReelSymbol[], reelIndex: number) => {
  const minY = -200 - (1000 * Math.random())

  return Object.values(reel)
    .filter((reelSymbol) => reelSymbol.sprite.y < minY)
    .sort((a, b) => b.sprite.y - a.sprite.y)
    .slice(0, 3)
    .reverse()
    .forEach((reelSymbol, index) => {
      if (!game.results) return

      const name = game.results.lines[`line${(index + 1) as 1 | 2 | 3}`].items[reelIndex] as SlotsSymbolName

      const sprite = new ReelSymbol(SlotsSymbolName[name]).sprite

      reelSymbol.stopNextLoop = index === 2

      reelSymbol.swapSprite(SlotsSymbolName[name], sprite)

      reelSymbol.line = index + 1

      sprite.scale.set(0.4, 0.4)
    })
}

const endSpin = (game: SlotsGame) => {
  console.log(String(game.won), game)

  slotsState.game = game

  setTimeout(() => {
    endSpinReel(game, slotsState.references.reels[0], 0)
    endSpinReel(game, slotsState.references.reels[1], 1)
    endSpinReel(game, slotsState.references.reels[2], 2)
  }, 200)
}

const showLineIndicators = (lines: number) => {
  const order = [
    slotsState.references.indicators.line2,
    slotsState.references.indicators.line1,
    slotsState.references.indicators.line3,
    slotsState.references.indicators.diagUp,
    slotsState.references.indicators.diagDown
  ]

  order.forEach((indicator, index) => {
    if (!indicator) return

    indicator.alpha = index < lines ? 0.05 : 0
  })
}

const highlightCombo = () => {
  if (!slotsState.game?.results || !slotsState.viewport) return

  let maxMultiplier = 0

  Object.entries(slotsState.game.results?.lines).forEach(([line, result]) => {
    const lineName = line as 'line1' | 'line2' | 'line3' | 'diagUp' | 'diagDown'

    const graphics = slotsState.references.indicators[lineName]

    if (!result.wonMultiplier || !graphics) return

    const columnWidth = 500 / 3

    maxMultiplier = Math.max(maxMultiplier, result.wonMultiplier)

    if (result.wonMultiplier === 1 || result.wonMultiplier === 3) {
      const items = getItemsInLine(lineName, SlotsSymbolName.cherry)

      items.forEach(item => {
        gsap.to(item.sprite, {
          duration: 0.3,
          pixi: {
            scale: 0.55
          },
          repeat: 5,
          yoyo: true
        })
      })
    } else if (result.wonMultiplier >= 10) {
      graphics.tint = 0xff0000

      gsap.to(graphics, {
        duration: 0.3,
        alpha: 0.5,
        ease: Linear.easeNone,
        repeat: 4,
        yoyo: true
      })

      const items = getItemsInLine(lineName, result.items[0])

      items.forEach(item => {
        if (slotsState.particles.backContainer) {
          const emitter = circleParticleEmitter(slotsState.particles.backContainer, {
            x: (item.reel ?? 0) * columnWidth + columnWidth / 2,
            y: item.sprite.y
          })

          if (!slotsState.particles.emitters) {
            slotsState.particles.emitters = []
          }

          slotsState.particles.emitters.push(emitter)
        }

        gsap.to(item.sprite, {
          duration: 0.3,
          pixi: {
            scale: 0.55
          },
          repeat: 5,
          yoyo: true
        })
      })
    }
  })

  if (maxMultiplier > 0 && maxMultiplier < 10) { // any
    simpleComboSoundSlots.play()

    celebration(slotsState.game?.results.wonMultiplier)
  }

  if (maxMultiplier === 10) { // any
    simpleLineSoundSlots.play()

    celebration(slotsState.game?.results.wonMultiplier)
  }

  if (maxMultiplier === 20) { // cherry
    cherryLineSoundSlots.play()

    celebration(slotsState.game?.results.wonMultiplier)
  }

  if (maxMultiplier === 40) { // 7
    number777LineSoundSlots.play()

    celebration777()
  }

  if (maxMultiplier === 60) { // bar
    barLineSoundSlots.play()

    celebrationBar(slotsState.game?.results.wonMultiplier)
  }
}

const celebrationBar = (wonMultiplier: number) => {
  celebration(wonMultiplier, 2, 101)

  if (slotsState.particles.frontContainer) {
    const emitter = randomEmitter(slotsState.particles.frontContainer, {
      x: 250,
      y: 250
    }, [slotsState.resources.bar])

    if (!slotsState.particles.emitters) {
      slotsState.particles.emitters = []
    }

    slotsState.particles.emitters.push(emitter)
  }
}

const celebration777 = () => {
  if (!slotsState.viewport) return

  const container = new Container()

  const sprite = new Sprite(slotsState.resources.number777)
  sprite.pivot.set(sprite.width / 2, sprite.height / 2)
  sprite.scale.set(0.3, 0.3)
  sprite.position.set(250, 250)
  sprite.alpha = 0

  const timeline = gsap.timeline()

  timeline
    .to(sprite, {
      duration: 0.4,
      pixi: {
        alpha: 1,
      }
    })
    .to(sprite, {
      duration: 1,
      pixi: {
        alpha: 0,
        scale: 0.5
      },
      onComplete() {
        container.destroy()
      }
    })

  container.addChild(sprite)

  container.zIndex = 15

  slotsState.viewport.addChild(container)
}

const celebration = (wonMultiplier: number, timeMultiplier = 1, zIndex?: number) => {
  if (!slotsState.viewport) return

  const container = new Container()

  const style = new TextStyle({
    fontFamily: 'Riffic',
    fill: 0xFFFFFF,
    fontSize: Math.min(62, 32 + wonMultiplier),
    stroke: 0xe5a413,
    strokeThickness: 2
    //dropShadow: true,
    //dropShadowAngle: 90,
    //dropShadowColor: 0xc7a722,
    //dropShadowAlpha: 1
  })

  const multiplierText = new Text(`x${wonMultiplier}`, style)

  const textMetrics = TextMetrics.measureText(multiplierText.text, style)

  multiplierText.x = 250
  multiplierText.y = (500 * 2 / 3)
  multiplierText.pivot.set(textMetrics.width / 2, textMetrics.height / 2)
  multiplierText.alpha = 0

  const timeline = gsap.timeline()

  timeline
    .to(multiplierText, {
      duration: 0.4,
      pixi: {
        alpha: 1,
      }
    })
    .to(multiplierText, {
      duration: 1 * timeMultiplier,
      pixi: {
        alpha: 0,
        scale: 0.7
      },
      onComplete() {
        container.destroy()
      }
    })

  //gsap.fromTo(multiplierText, {
  //  pixi: {
  //    x: multiplierText.x - 20,
  //  }
  //}, {
  //  duration: 1.2,
  //  pixi: {
  //    x: multiplierText.x + 20,
  //    scale: 0.7,
  //  },
  //  onComplete() {
  //    container.destroy()
  //  }
  //})

  container.addChild(multiplierText)
  container.zIndex = zIndex ?? 15

  slotsState.viewport.addChild(container)
}

const getItemsInLine = (lineName: 'line1' | 'line2' | 'line3' | 'diagUp' | 'diagDown', itemName: SlotsSymbolName) => {
  const items: ReelSymbol[] = []

  if (lineName === 'line1' || lineName === 'line2' || lineName === 'line3') {
    const index = Number(lineName.slice(-1))

    slotsState.references.reels.forEach(reel => {
      const item = reel.find(reelSymbol => (reelSymbol.line ?? -1) === index)

      if (item && item.name === itemName) {
        items.push(item)
      }
    })
  } else if (lineName === 'diagUp') {
    slotsState.references.reels.forEach((reel, index) => {
      const item = reel.find(reelSymbol => (reelSymbol.line ?? -1) === 3 - index)

      if (item && item.name === itemName) {
        items.push(item)
      }
    })
  } else {
    slotsState.references.reels.forEach((reel, index) => {
      const item = reel.find(reelSymbol => (reelSymbol.line ?? -1) === index + 1)

      if (item && item.name === itemName) {
        items.push(item)
      }
    })
  }

  return items
}

export const createViewport = () => {
  if (!slotsState.app) return

  //if (slotsState.viewport) {
  //  slotsState.viewport.destroy()
  //}

  slotsState.app.stage.removeChildren()
  slotsState.app.stage.removeAllListeners()

  const viewport = new Viewport({
    screenWidth: getSlotsGameWidth(),
    screenHeight: getSlotsGameHeight(),

    events: slotsState.app.renderer.events as any
  })

  viewport.sortableChildren = true

  slotsState.app.stage.addChild(viewport)

  slotsState.app.renderer.on('resize', () => {
    viewport.resize(getSlotsGameWidth(), getSlotsGameHeight())

    viewport.fit(undefined, 500, 500).moveCenter(250, 250)
  })

  viewport.resize(getSlotsGameWidth(), getSlotsGameHeight())

  viewport.fit(undefined, 500, 500).moveCenter(250, 250)

  slotsState.viewport = viewport

  // Reels Container

  const columnWidth = 500 / 3

  slotsState.references.reelsContainers = []

  for (let column = 0; column < 3; column++) {
    const reelsContainer = new Container()

    reelsContainer.position.set(column * columnWidth, 0)
    reelsContainer.width = columnWidth
    reelsContainer.height = 500
    reelsContainer.zIndex = 10

    slotsState.references.reelsContainers.push(reelsContainer)

    viewport.addChild(reelsContainer)
  }

  // Particles

  const particlesBackContainer = new Container()

  slotsState.particles.backContainer = particlesBackContainer

  viewport.addChild(particlesBackContainer)

  const particlesFrontContainer = new Container()

  particlesFrontContainer.zIndex = 100

  slotsState.particles.frontContainer = particlesFrontContainer

  viewport.addChild(particlesFrontContainer)
}

/**
 * Update the state of the game
 * @param delta The delta (time passed since previous update)
 */
const update = (delta: number) => {
  updateEmitters(delta)

  Object.values(slotsState.references.reels)
    .forEach((reelSymbols, reelIndex) => [...reelSymbols]
      .sort((a, b) => b.sprite.y - a.sprite.y) // We start by checking the symbol close to bottom to update speed of all reel
      .forEach((reelSymbol) => {
        const rowHeight = 500 / 3

        const bottomPoint = rowHeight * 3 - rowHeight / 2

        if (reelSymbol.stopNextLoop) {
          if (Math.ceil(reelSymbol.sprite.y) >= bottomPoint) {
            reelStopEndSoundSlots.play()

            reelSymbol.stopNextLoop = false

            reelSymbols.forEach(rs => {
              rs.speed.vy = 0
            })

            if (slotsState.references.reels[0][0].speed.vy === 0 && slotsState.references.reels[1][0].speed.vy === 0 && slotsState.references.reels[2][0].speed.vy === 0) {
              stopSpinSoundSlots()

              highlightCombo()

              if (slotsState.external.pauseRefreshToken) {
                slotsState.external.pauseRefreshToken(false)
              }

              if (slotsState.external.setSpinning) {
                slotsState.external.setSpinning(false)
              }
            }

            slotsState.references.reelsContainers[reelIndex].filters = []
          } else if (reelSymbol.sprite.y > 0) {
            const ratio = Math.max(0, Math.min(1, Math.abs(reelSymbol.sprite.y - 500) / 500))

            const newSpeed = 30 * ratio

            slotsState.references.reelsContainers[reelIndex].filters?.forEach((filter: any) => {
              filter.velocity.set(
                Math.max(1, filter.velocity.x * ratio),
                Math.max(1, filter.velocity.y * ratio)
              )
            })

            reelSymbols.forEach(rs => {
              rs.speed.vy = newSpeed
            })
          }
        } else if (reelSymbol.sprite.y > 500 + rowHeight) {
          const farSymbol = reelSymbols.sort((a, b) => a.sprite.y - b.sprite.y)[0]

          reelSymbol.sprite.y = farSymbol.sprite.y - rowHeight
        }

        reelSymbol.update(delta)
      }))
}

const updateEmitters = (delta: number) => {
  slotsState.particles.emitters?.forEach(emitter => {
    emitter.update(delta * 0.01)
  })
}

const viewportReset = () => {
  const viewport = slotsState.viewport

  if (viewport) {
    viewport.removeChildren()

    slotsState.references.celebrations = []
    slotsState.references.indicators = {}
    slotsState.references.reels = [
      [],
      [],
      []
    ]
    slotsState.references.reelsContainers = []
  }
}