// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {Schema, type} from '@colyseus/schema'


export class PlayerSchema extends Schema {
  @type('string') public name!: string
  @type('string') public clientId!: string
  @type('boolean') public ready!: boolean
  @type('boolean') public connected!: boolean
}

