import {Emitter} from '@pixi/particle-emitter'
import {Container, Texture} from 'pixi.js'

//export const glitch = (container: Container, options?: Partial<GlitchFilterOptions>, vars?: GSAPTweenVars) => {
//  const glitchFilter = new GlitchFilter({
//    seed: 0.5,
//    slices: 20,
//    offset: 10,
//    direction: 0,
//    red: new Point(2, 2),
//    green: new Point(10, -4),
//    blue: new Point(-10, 4),
//    fillMode: 2,
//    ...options
//  })

//  glitchFilter.resolution = 5

//  gsap.to(glitchFilter, {
//    seed: 8,
//    yoyo: true,
//    duration: 2,
//    ease: RoughEase.config({
//      strength: 1,
//      points: 20,
//      template: 'inOut',
//      randomize: true,
//      clamp: false
//    }),
//    repeat: -1,
//    ...vars,
//    onComplete(args) {
//      if (!container.filters) return

//      container.filters = container.filters.filter(filter => filter !== glitchFilter as unknown as Filter)

//      if (vars?.onComplete) vars.onComplete(args)
//    }
//  })

//  if (!container.filters) container.filters = []

//  container.filters.push(glitchFilter as unknown as Filter)

//  return glitchFilter as unknown as Filter
//}

//export const addWaterEffect = (container: Container, displacementSprite: Sprite, vars?: GSAPTweenVars) => {
//  const displacementFilter = new filters.DisplacementFilter(displacementSprite, 0)

//  displacementFilter.resolution = 5

//  displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT

//  const obj = {currentFrame: 0}

//  gsap.to(obj, {
//    currentFrame: 1,
//    duration: 0.3,
//    ease: Linear.easeNone,
//    onUpdate() {
//      displacementFilter.scale.set(20 * obj.currentFrame, 20 * obj.currentFrame)
//    }
//  })

//  gsap.to(displacementSprite, {
//    duration: 2,
//    repeat: -1,
//    ease: Linear.easeNone,
//    x: displacementSprite.width,
//    ...vars,
//    onComplete(args) {
//      if (!container.filters) return

//      container.filters = container.filters.filter(filter => filter !== displacementFilter as unknown as Filter)
//      container.removeChild(displacementSprite)

//      if (vars?.onComplete) vars.onComplete(args)
//    }
//  })

//  if (!container.filters) container.filters = []

//  container.addChild(displacementSprite)

//  container.filters.push(displacementFilter as unknown as Filter)

//  return displacementFilter as unknown as Filter
//}

//export const addShockwaveEffect = (container: Container, point: Point, options?: Partial<ShockwaveFilter>, vars?: GSAPTweenVars) => {
//  const shockwaveFilter = new ShockwaveFilter(point, {
//    wavelength: 30,
//    ...options
//  })

//  shockwaveFilter.resolution = 5

//  gsap.to(shockwaveFilter, {
//    duration: 0.8,
//    ease: Linear.easeNone,
//    time: 0.2,
//    amplitude: 0,
//    ...vars,
//    onComplete(args) {
//      if (!container.filters) return

//      container.filters = container.filters.filter(filter => filter !== shockwaveFilter as unknown as Filter)

//      if (vars?.onComplete) vars.onComplete(args)
//    }
//  })

//  if (!container.filters) container.filters = []

//  container.filters.push(shockwaveFilter as unknown as Filter)

//  return shockwaveFilter as unknown as Filter
//}

export const fireParticles = (container: Container, x: number, y: number, ratio: number, textures: (Texture | undefined)[]) => {
  //const triangle = new Graphics()
  //  .beginFill(0xe8e8e8)
  //  .drawPolygon(x, y, x - 100 * ratio, y - 200 * ratio, x + 100 * ratio, y - 200 * ratio)
  //  .endFill()

  //container.addChild(triangle)

  return new Emitter(
    container,
    {
      lifetime: {
        min: 0.25 * ratio,
        max: 0.45 * ratio
      },
      frequency: 0.001,
      emitterLifetime: 0.4,
      maxParticles: 1000,
      addAtBack: false,
      pos: {
        x,
        y
      },
      behaviors: [
        {
          type: 'alpha',
          config: {
            alpha: {
              list: [
                {
                  time: 0,
                  value: 0.62
                },
                {
                  time: 1,
                  value: 0
                }
              ]
            }
          }
        },
        {
          type: 'moveSpeedStatic',
          config: {
            min: 500,
            max: 500
          }
        },
        {
          type: 'scale',
          config: {
            scale: {
              list: [
                {
                  time: 0,
                  value: 0.25
                },
                {
                  time: 1,
                  value: 0.75
                }
              ]
            },
            minMult: 1
          }
        },
        {
          type: 'color',
          config: {
            color: {
              list: [
                {
                  time: 0,
                  value: 'fff191'
                },
                {
                  time: 1,
                  value: 'ff622c'
                }
              ]
            }
          }
        },
        {
          type: 'rotation',
          config: {
            accel: 0,
            minSpeed: 0,
            maxSpeed: 50,
            minStart: 250,
            maxStart: 290
          }
        },
        {
          type: 'textureRandom',
          config: {
            textures
          }
        },
        //{
        //  type: 'spawnBurst',
        //  config: {
        //    spacing: 5,
        //    start: 0,
        //    distance: 1,
        //  }
        //},
        {
          type: 'spawnShape',
          config: {
            type: 'torus',
            data: {
              x: 0,
              y: 0,
              radius: 10,
              innerRadius: 0,
              affectRotation: false
            }
          }
        }
      ]
    }
  )
}