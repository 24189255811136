import {Room} from 'colyseus.js'
import React, {createContext, useContext, useReducer} from 'react'
import {isDesktop} from 'react-device-detect'
import {Chain} from '../../types'
import {getSessionInfo, questTutoVisible} from '../../utils/session'
import {NewRoomAvailable} from '../pages/dashboard/sub-pages/games/player/bubble/lobby/bubble-lobby'
import {Actions} from './actions'
import {hubState} from './hub'
import {hubReducer} from './reducer'

export type UserRole = 'administrator' | 'moderator' | 'streamer'

export type HubContextType = {
  tokens?: {
    yaah?: number
    yooh?: number
  },
  revenueSharing?: number
  isLedger?: boolean
  consent?: boolean
  chatOpen?: boolean
  showWallet?: boolean
  showConnectModal?: boolean
  showProfileModal?: boolean
  profileUuid?: string
  chain?: Chain
  sessionToken?: string
  twitterUsername?: string
  username?: string
  publicKey?: string
  questTutoEnabled?: boolean
  room?: Room
  roomMetadata?: any
  roomSession?: {
    sessionId: string
    roomId: string
    roomAvailable: NewRoomAvailable
  }
  pfp?: string
  userUuid?: string
  referral?: string
  activatedReferral?: string
  referralToBeClaimed?: number
  rackbackAvailable?: number
  role?: UserRole
  pauseRefreshTokens?: boolean
  disconnectWallet?: () => Promise<void>
}

let initialState: HubContextType = {
  chain: 'solana',
  chatOpen: isDesktop ? true : false,
  questTutoEnabled: questTutoVisible()
}

const info = getSessionInfo()

if (info) {
  initialState = {
    ...initialState,
    ...info
  }

  hubState.sessionToken = info.sessionToken
}

export const HubContext = createContext<{
  state: HubContextType
  dispatch: React.Dispatch<Actions>
}>({
  state: initialState,
  dispatch: () => null
})

export const HubProvider = ({children}: {
  children: JSX.Element
}) => {
  const [state, dispatch] = useReducer(hubReducer, initialState)

  return (
    <HubContext.Provider value={{state, dispatch}}>
      {children}
    </HubContext.Provider>
  )
}

export const useHubContext = () => {
  return useContext(HubContext)
}