import {Client} from 'colyseus.js'

export const revealColyseusClientURL = 'wss://s2.piratesquadnft.com/reveal'
//export const bubbleColyseusClientURL = 'ws://localhost:3001'

const revealColyseusClient = new Client(revealColyseusClientURL)

export const getRevealColyseusClient = () => revealColyseusClient

//(async () => {
//  const room = await getRevealColyseusClient().joinOrCreate('reveal', {
//    roomName: 'reveal-dev',
//    imageName: 'image.png'
//  })

//  await initRevealApp(document.getElementById('reveal') as HTMLCanvasElement, 'reveal-dev', room, {
//    tryOrReconnect: fakeTryOrReconnect
//  })
//})()

