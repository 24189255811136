@import "scss-variables";

.referral-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;
  font-size: 1rem;

  .referral-modal {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;

    .list {
      overflow: auto;

      img {
        height: 10px;
      }
    }
  }
}
