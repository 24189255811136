import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {isAxiosError} from 'axios'
import {Config, readAndCompressImage} from 'browser-image-resizer'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {PFP_CDN_URL} from '../../../../../utils/constants'
import {formatClassName} from '../../../../../utils/global'
import {getProfile, uploadProfilePicture} from '../../../../api/hub'
import {Alert} from '../../../../components/alert/alert'
import {ButtonIcon} from '../../../../components/buttons/button'
import {TwitterLinkButton} from '../../../../components/twitter/twitter-link-button'
import {useHub} from '../../../../hooks/use-hub'
import {useHubContext} from '../../../../state/context'
import styles from './profile.module.scss'
import {changeUsername} from './utils'

const numberFormat = new Intl.NumberFormat('en-US')

export type ProfileProps = HtmlHTMLAttributes<HTMLDivElement>

export const Profile = ({className, ...props}: ProfileProps) => {
  const {disconnect} = useHub()
  const {state: {publicKey, username, twitterUsername, pfp, userUuid}, dispatch} = useHubContext()

  const [changingImage, setChangingImage] = useState(false)

  const [profile, setProfile] = useState<{
    username: string
    pfp: string
    totalWagered: number
    wonGames: number
    lostGames: number
    bestStreak: number
    worstStreak: number
  }>()

  const refreshUserStats = async (userUuid: string) => {
    const response = await getProfile(userUuid, (err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the user stats, please contact the support team')
      } else {
        console.log(err, isAxiosError(err))
        toast.error('Impossible to retrieve the user stats, please contact the support team')
      }
    })

    if (response?.status === 200) {
      setProfile(response.data)
    }
  }

  useEffect(() => {
    if (!userUuid) return

    refreshUserStats(userUuid)
  }, [userUuid])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (file) {
        const config: Config = {
          quality: 1,
          maxWidth: 250,
          maxHeight: 250
        }

        const resizedImage = await readAndCompressImage(file, config)

        const profilePictureFile = new File([resizedImage], 'image')


        setChangingImage(true)

        try {
          const result = await uploadProfilePicture(profilePictureFile)

          if (result?.status !== 200 || !result.data.pfp) {
            throw 'Status !== 200'
          }

          dispatch({
            type: 'SET_PFP',
            pfp: result.data.pfp
          })

          toast.success('Image successfuly uploaded!')
        } catch (err) {
          console.error(err)

          toast.error('Impossible to upload the image, please try again later')
        }

        setChangingImage(false)
      }
    }
  })

  const totalGames = (profile?.wonGames ?? 0) + (profile?.lostGames ?? 0)

  return (
    <div className={formatClassName(styles, `profile ${className}`)} {...props}>
      <Alert type='warning' localStorageKey='twitterDisclaimer' className={formatClassName(styles, 'disclaimer')}>
        Connecting your Twitter account is only used for authenticating you as the owner. We cannot post anything on your behalf.
      </Alert>
      <div className={formatClassName(styles, 'settings')}>
        <div className={formatClassName(styles, 'preview')}>
          <div className={formatClassName(styles, 'change')}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <ButtonIcon loading={changingImage} icon="trash" />
            </div>
          </div>
          <img src={`${PFP_CDN_URL}/${pfp}`} />
        </div>
        <div className={formatClassName(styles, 'middle')}>
          <span className={formatClassName(styles, 'username')}>{username ?? 'no username'} <FontAwesomeIcon icon="pencil" onClick={() => changeUsername(dispatch)} /></span>
          <div className={formatClassName(styles, 'wallet-address')}>
            {publicKey}
          </div>
        </div>
        <ButtonIcon icon="right-from-bracket" onClick={disconnect} className='no-color'>Disconnect</ButtonIcon>
      </div>
      <div className={formatClassName(styles, 'twitter')}>
        <div className={formatClassName(styles, 'text')}>{twitterUsername ? <>You are connected with your twitter account <strong>{twitterUsername}</strong></> : 'Connect your Twitter account'}. This is is only used for authenticating you as the owner. We cannot post anything on your behalf.</div>
        <TwitterLinkButton value="change" />
      </div>
      <div className={formatClassName(styles, 'title')}>Statistics</div>
      <div className={formatClassName(styles, 'stats-container')}>
        <div className={formatClassName(styles, 'grouped unified-bg')}>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>TOTAL GAMES</div>
            <div className={formatClassName(styles, 'value')}>{numberFormat.format(totalGames)}</div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>GAMES WON</div>
            <div className={formatClassName(styles, 'value')}>
              <FontAwesomeIcon icon="award" />
              {numberFormat.format(profile?.wonGames ?? 0)}
            </div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>GAMES LOST</div>
            <div className={formatClassName(styles, 'value')}>
              <FontAwesomeIcon icon="skull" />
              {numberFormat.format(profile?.lostGames ?? 0)}
            </div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>WIN RATIO</div>
            <div className={formatClassName(styles, 'value')}>{Number((((profile?.wonGames ?? 0) / totalGames) * 100).toFixed(2))}%</div>
          </div>
        </div>

        <div className={formatClassName(styles, 'grouped')}>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>BEST & WORST STREAKS</div>
            <div className={formatClassName(styles, 'value')}><FontAwesomeIcon icon="award" />{(profile?.bestStreak ?? 0)} / <FontAwesomeIcon icon="skull" />{(profile?.worstStreak ?? 0)}</div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>TOTAL WAGERED</div>
            <div className={formatClassName(styles, 'value')}><YOOH />{numberFormat.format((profile?.totalWagered ?? 0) / 100)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}