
export enum BubbleSkinName {
  ColoredBubble = 'ColoredBubbleSkin',
  GoldenBubble = 'GoldenBubbleSkin',
  IronBubble = 'IronBubbleSkin',
  BlackOne = 'BlackOneSkin',
  BlueOne = 'BlueOneSkin',
  GreenOne = 'GreenOneSkin',
  PurpleOne = 'PurpleOneSkin',
  RedOne = 'RedOneSkin',
  YellowOne = 'YellowOneSkin',
  BlackTwo = 'BlackTwoSkin',
  BlueTwo = 'BlueTwoSkin',
  GreenTwo = 'GreenTwoSkin',
  PurpleTwo = 'PurpleTwoSkin',
  RedTwo = 'RedTwoSkin',
  YellowTwo = 'YellowTwoSkin',
  BlackThree = 'BlackThreeSkin',
  BlueThree = 'BlueThreeSkin',
  GreenThree = 'GreenThreeSkin',
  PurpleThree = 'PurpleThreeSkin',
  RedThree = 'RedThreeSkin',
  YellowThree = 'YellowThreeSkin',
  BlackFour = 'BlackFourSkin',
  BlueFour = 'BlueFourSkin',
  GreenFour = 'GreenFourSkin',
  PurpleFour = 'PurpleFourSkin',
  RedFour = 'RedFourSkin',
  YellowFour = 'YellowFourSkin',
  BlackFive = 'BlackFiveSkin',
  BlueFive = 'BlueFiveSkin',
  GreenFive = 'GreenFiveSkin',
  PurpleFive = 'PurpleFiveSkin',
  RedFive = 'RedFiveSkin',
  YellowFive = 'YellowFiveSkin',
  BlackSix = 'BlackSixSkin',
  BlueSix = 'BlueSixSkin',
  GreenSix = 'GreenSixSkin',
  PurpleSix = 'PurpleSixSkin',
  RedSix = 'RedSixSkin',
  YellowSix = 'YellowSixSkin',
  BlackSeven = 'BlackSevenSkin',
  BlueSeven = 'BlueSevenSkin',
  GreenSeven = 'GreenSevenSkin',
  PurpleSeven = 'PurpleSevenSkin',
  RedSeven = 'RedSevenSkin',
  YellowSeven = 'YellowSevenSkin',
  BlackEight = 'BlackEightSkin',
  BlueEight = 'BlueEightSkin',
  GreenEight = 'GreenEightSkin',
  PurpleEight = 'PurpleEightSkin',
  RedEight = 'RedEightSkin',
  YellowEight = 'YellowEightSkin',

  Empty = 'Empty'
}

export enum BubbleDestroyEffect {
  Bomb = 'Bomb',
  Fire = 'Fire'
}

export enum BubbleCollideEffect {
  Hammer = 'Hammer'
}

export enum PowerUpSkinName {
  Bomb = 'BombSkin',
  Coin = 'CoinSkin',
  Double = 'DoubleSkin',
  EmptyBubble = 'EmptyBubbleSkin',
  Fire = 'FireSkin',
  Hammer = 'HammerSkin',
  Heart = 'HeartSkin',
  Leaf = 'LeafSkin',
  Lightning = 'LightningSkin',
  Map = 'MapSkin',
  Shield = 'ShieldSkin',
  Snow = 'SnowSkin',
  Star = 'StarSkin',
  Water = 'WaterSkin'
}

export enum SoundName {
  // Ambiences
  music1 = 'music1',
  music2 = 'music2',
  music3 = 'music3',
  music4 = 'music4',
  music5 = 'music5',
  // Ambiences
  ambience1 = 'ambience1',
  ambience2 = 'ambience2',
  // Bubble Pop
  bubblePopNeutral1 = 'bubblePopNeutral1',
  bubblePopNeutral2 = 'bubblePopNeutral2',
  bubblePopNeutral3 = 'bubblePopNeutral3',
  bubblePopNeutral4 = 'bubblePopNeutral4',
  bubblePop1 = 'bubblePop1',
  bubblePop2 = 'bubblePop2',
  bubblePop3 = 'bubblePop3',
  // Combos
  combo1 = 'combo1',
  combo2 = 'combo2',
  combo3 = 'combo3',
  // Pirate
  pirateThrowBubble1 = 'pirateThrowBubble1',
  pirateThrowBubble2 = 'pirateThrowBubble2',
  // Cannon
  cannonLoadBubble = 'cannonLoadBubble',
  cannonFire1 = 'cannonFire1',
  // Effects
  powerBomb = 'powerBomb',
  powerCoin = 'powerCoin',
  powerHeart = 'powerHeart',
  powerHammer = 'powerHammer',
  powerFire = 'powerFire',
  // Game
  pauseButton = 'pauseButton',
  musicToggle = 'musicToggle',
  resumeButton = 'resumeButton',
  restartButton = 'restartButton',
  homeButton = 'homeButton',
  zeroStar = 'zeroStar',
  oneStar = 'oneStar',
  twoStars = 'twoStars',
  threeStars = 'threeStars',
  // Menu
  mainMenuPlay = 'mainMenuPlay',
  selectLevel = 'selectLevel',
}

export enum LevelStatus {
  Unavailable = 'unavailable',
  Locked = 'locked',
  Unlocked = 'unlocked',
  OneStar = '1-star',
  TwoStars = '2-stars',
  ThreeStars = '3-stars'
}

export enum PirateCharacter {
  PirateOne = 'PirateOne',
  PirateTwo = 'PirateTwo',
  PirateThree = 'PirateThree',
  PirateFour = 'PirateFour'
}

export enum CannonSkinName {
  CannonOne = 'CannonOneSkin',
  CannonTwo = 'CannonTwoSkin',
  CannonThree = 'CannonThreeSkin',
  CannonFour = 'CannonFourSkin'
}