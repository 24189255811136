export enum PowerUpSkinName {
  Bomb = 'BombSkin',
  Coin = 'CoinSkin',
  EmptyBubble = 'EmptyBubbleSkin'
}

export enum SoundName {
  // Bubble Pop
  bubblePopNeutral1 = 'bubblePopNeutral1',
  bubblePopNeutral2 = 'bubblePopNeutral2',
  bubblePopNeutral3 = 'bubblePopNeutral3',
  bubblePopNeutral4 = 'bubblePopNeutral4',
  // Combos
  //combo1 = 'combo1',
  //combo2 = 'combo2',
  //combo3 = 'combo3',
  // Game
  clickAffirmative = 'clickAffirmative',
  clickNegative = 'clickNegative',
  clickCoin = 'clickCoin',
  clickBomb = 'clickBomb'
}