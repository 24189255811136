import {JsonMetadata, Metadata, Metaplex, Nft, PublicKey, Sft} from '@metaplex-foundation/js'
import {useConnection} from '@solana/wallet-adapter-react'
import {isAxiosError} from 'axios'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {HUB_CDN_URL, NFT_CDN_URL} from '../../../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../../../utils/global'
import {RevenueSharingData, activateRevenueRequest, checkRevenueRequest, claimRevenueRequest} from '../../../../api/hub'
import {Button} from '../../../../components/buttons/button'
import {ConnectButton} from '../../../../components/buttons/connect-button'
import {AppCard} from '../../../../components/cards/app-card'
import {FlipDate} from '../../../../components/flip/flip'
import {LoadingOverlay} from '../../../../components/overlay/loading'
import {useHubContext} from '../../../../state/context'
import {hubState} from '../../../../state/hub'
import styles from './rev-share.module.scss'

export type RevShareProps = HtmlHTMLAttributes<HTMLDivElement>

export const RevShare = ({className, ...props}: RevShareProps) => {
  const {state: {publicKey, revenueSharing, role}, dispatch} = useHubContext()

  const {connection} = useConnection()

  const navigate = useNavigate()

  const [nfts, setNfts] = useState<(Metadata<JsonMetadata<string>> | Nft | Sft)[]>([])
  const [loading, setLoading] = useState(false)
  const [activating, setActivating] = useState(false)
  const [claiming, setClaiming] = useState(false)

  const [activatedNfts, setActivatedNfts] = useState<RevenueSharingData>({})

  const endDailyDate = new Date()
  endDailyDate.setUTCHours(23, 59, 59, 999)

  const [endDailyQuest, setEndDailyQuest] = useState(endDailyDate)

  const endClaimDate = new Date()

  endClaimDate.setUTCDate(endClaimDate.getDate() + (12 - endClaimDate.getDay()) % 7)
  endClaimDate.setUTCHours(23, 59, 59, 999)

  const [endClaim, setEndClaim] = useState(endClaimDate)

  const activateRevenue = async () => {

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Revenue Sharing',
        text: 'When activating the revenue sharing, your NFTs will need to stay on your actual wallet all the time to be entitled. If a NFT moved, the system will automatically cancel revenue sharing for this NFT.',
        acceptText: 'activate',
        refuseText: 'cancel',
        async onAccept() {
          setActivating(true)

          const response = await activateRevenueRequest((err) => {
            if (isAxiosError(err)) {
              toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to activate the revenue sharing, please contact the support team')
            }
          })

          if (response?.status === 200) {
            refreshNfts()

            toast.success('All your NFTs are now activated for revenue sharing')
          }

          setActivating(false)
        }
      })
    }
  }

  const claim = async () => {
    setClaiming(true)

    if (role === 'streamer' && hubState.showModal) {
      hubState.showModal({
        title: 'Claiming as a streamer',
        text: 'You cannot claim revenue on a streamer account'
      })
    } else {
      const response = await claimRevenueRequest((err) => {
        if (isAxiosError(err)) {
          toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to claim, please contact the support team'))
        }
      })

      if (response?.status === 200) {
        toast.success(formatTextWithYOOH(response.data?.claimedAmount !== undefined ? `You claimed $YOOH ${response.data.claimedAmount / 100}!` : 'Revenue claimed!'))

        if (hubState.showConfirm) {
          hubState.showConfirm({
            title: 'Try out our new Slots Game!',
            text: 'Spin & Win with our new game',
            acceptText: 'play',
            refuseText: 'later',
            image: `${HUB_CDN_URL}/cards/games/slots.jpg`,
            onAccept() {
              navigate('/dashboard/games/slots')
            }
          })
        }
      }
    }

    setClaiming(false)
  }

  const refreshNfts = async (showLoading = false) => {
    if (!publicKey) return

    setLoading(showLoading ? true : false)

    const metaplex = new Metaplex(connection)

    const result = await metaplex.nfts().findAllByOwner({owner: new PublicKey(publicKey)})

    const nfts = result
      .filter(nft => nft.creators.find(creator => creator.address.toString() === 'FuPpLRD648drkhXrmHW59RjzR7X6y6Rv9artzBZ4SLHU'))
      .map((nft) => ({
        ...nft,
        json: {
          image: `${NFT_CDN_URL}/${nft.name.split('#').pop()}.png`
        }
      }))
      .sort((a, b) => Number(a.name.split('#').pop()) - Number(b.name.split('#').pop()))

    setNfts(nfts)

    // revenue

    const response = await checkRevenueRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve revenue info, please contact the support team')
      }
    })

    setActivatedNfts(response?.data.nfts ?? {})

    setLoading(false)
  }

  useEffect(() => {
    refreshNfts(true)
  }, [publicKey])

  const numberOfActivatedNfts = nfts.reduce((acc, nft) => {
    if (activatedNfts[(nft as any).mintAddress.toString()]) {
      return acc + 1
    }

    return acc
  }, 0)

  const openWallet = () => {
    dispatch({
      type: 'SET_SHOW_WALLET',
      showWallet: true
    })
  }

  return (
    <div className={formatClassName(styles, `rev-share ${className}`)} {...props}>
      {
        loading && <LoadingOverlay text='FETCHING NFT' />
      }
      <div className={formatClassName(styles, 'repetitive-quests')}>
        <div className={formatClassName(styles, 'repetitive-quest deposit-offer')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'info')}>
            <div className={formatClassName(styles, 'name')}>Earn free credits!</div>
            <div className={formatClassName(styles, 'description')}>
              on volume and deposits
            </div>
          </div>
          <div className={formatClassName(styles, 'date')}>
            <Button className={formatClassName(styles, 'cta')} onClick={() => navigate('/dashboard/rewards')}>more info</Button>
          </div>
        </div>
        <div className={formatClassName(styles, 'repetitive-quest daily-reward')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'info')}>
            <div className={formatClassName(styles, 'name')}>Daily Rewards</div>
            <div className={formatClassName(styles, 'description')}>
              of <YOOH /> 220<a href="#" onClick={() => navigate('/dashboard/quests')}>more info</a>
            </div>
          </div>
          <div className={formatClassName(styles, 'date')}>
            <FlipDate date={endDailyQuest} showLabels={false} onEnded={() => {
              const date = new Date()
              date.setUTCHours(24, 0, 0, 0)

              setEndDailyQuest(date)
            }} />
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, 'grouped')}>
        <div className={formatClassName(styles, 'stats')}>
          <div className={formatClassName(styles, 'name')}>
            REV SHARE
            {publicKey && <Button onClick={activateRevenue} loading={activating} disabled={numberOfActivatedNfts === nfts.length || loading}>activate all</Button>}
          </div>
          <div className={formatClassName(styles, 'value')}>{loading ? '...' : numberOfActivatedNfts}/{nfts.length}</div>
        </div>
        <div className={formatClassName(styles, 'stats')}>
          <div className={formatClassName(styles, 'name')}>
            AVAILABLE
            {publicKey && <Button onClick={claim} disabled={loading || claiming}>claim</Button>}
          </div>
          <div className={formatClassName(styles, 'value')}><YOOH /> {((revenueSharing ?? 0) / 100).toFixed(2)}</div>
        </div>
        <div className={formatClassName(styles, 'stats')}>
          <div className={formatClassName(styles, 'name')}>NEXT REV SHARE</div>
          <div className={formatClassName(styles, 'value flip')}>
            {/*<FlipDate format={['d', 'h', 'm', 's']} date={endClaim} showLabels={false} onEnded={() => {
              const date = new Date()

              date.setUTCDate(date.getDate() + (12 - date.getDay()) % 7)
              date.setUTCHours(24, 0, 0, 0)

              setEndClaim(date)
            }} />*/}
            PAUSED
          </div>
        </div>
      </div>
      {!publicKey && <ConnectButton />}
      <div className={formatClassName(styles, 'grid')}>
        {
          nfts.map(nft => (
            <AppCard className={formatClassName(styles, 'nft')} key={nft.address.toString()} imageURL={nft.json?.image} labelType="label" label={`#${nft.name.split('#').pop()}`} />
          ))
        }
      </div>
    </div>
  )
}