import React, {CSSProperties, HtmlHTMLAttributes, ReactElement} from 'react'
import {HUB_CDN_URL} from '../../../utils/constants'
import {formatClassName} from '../../../utils/global'
import styles from './app-card.module.scss'

export type AppCardProps = HtmlHTMLAttributes<HTMLDivElement> & {
  imageURL?: string
  children?: ReactElement
  style?: CSSProperties
  labelType?: 'skill' | 'luck' | 'label'
  label?: string
}

export const AppCard = ({imageURL, style: coverStyle, labelType, label, className, children, ...props}: AppCardProps) => {
  const style: CSSProperties = {
    ...coverStyle
  }

  if (imageURL) {
    style.backgroundImage = `url(${imageURL})`
  }

  return (
    <div className={formatClassName(styles, `app-card ${className}`)} {...props}>
      <div className={formatClassName(styles, 'app-card__cover')} style={style}>
        {children}
        {labelType && <div className={formatClassName(styles, `badge ${labelType}`)}>
          {labelType !== 'label' &&
            <img src={`${HUB_CDN_URL}/icons/${labelType}.svg`} alt={labelType} />
          }
          {label ?? `${labelType[0].toUpperCase()}${labelType.substring(1)}`}
        </div>}
      </div>
    </div>
  )
}