@import "scss-variables";

.home {
  display: flex;
  flex: 1;
  font-family: "Riffic";
  font-size: 32px;
  max-width: 1200px;
  flex-direction: column;
  gap: 1rem;

  .banner {
    position: relative;
    height: 20rem;
    overflow: hidden;
    border-radius: 1rem;

    .slide {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center right;
      opacity: 0;

      animation: appear 1s ease forwards;
    }

    .description {
      z-index: 1;
      position: absolute;
      gap: 0.5rem;
      color: #fff;
      max-width: 500px;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 1rem;
      box-sizing: border-box;
      text-align: center;

      opacity: 0;

      animation: appear 1s ease forwards;

      .title {
        font-weight: 700;
        font-size: 2rem;
        text-shadow: #000c 0px 0px 3px;

        img {
          height: 2rem;
          padding-right: 0.5rem;
        }
      }

      .text {
        font-size: 1rem;
        text-shadow: #000c 0px 0px 3px;
        //display: none;

        img {
          height: 0.8rem;
        }
      }

      @media screen and (min-width: $xs-breakpoint) {
        justify-content: center;
        padding: 1rem 3rem;

        text-align: left;

        .title {
          font-size: 2.5rem;
        }

        .text {
          display: block;
        }

        .cta {
          max-width: 200px;
          font-weight: 700;
        }
      }
    }

    .cta {
      background-color: #e5a413;

      &:hover {
        background-color: #c78c0c;
      }
    }

    .shadow {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(40, 40, 40, 0.6) 0%, rgba(0, 0, 0, 0) 85.45%);

      opacity: 0;

      animation: appear 1s ease forwards;
    }
  }

  .repetitive-quest {
    display: flex;
    background-image: url(https://storage.googleapis.com/hub-cdn/hub/images/boat.jpeg);
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-direction: column;
    font-family: "Baloo Thambi";

    opacity: 0;

    animation: appear 1s ease 0.7s forwards;

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    .info {
      position: relative;
      padding-right: 2rem;
      padding: 1rem;
      gap: 1rem;
      font-size: 1rem;
      flex: 1;
      align-items: center;
      text-align: center;

      @media screen and (min-width: $sm-breakpoint) {
        text-align: left;
      }

      .name {
        position: relative;
        z-index: 1;
        font-family: "Riffic";
        font-size: 1.25rem;
        text-shadow: #000c 0px 0px 3px;
      }

      .description {
        text-shadow: #000c 0px 0px 3px;

        img {
          width: 10px;
          height: 10px;
        }

        a {
          color: $text-color;
          padding-left: 1rem;
        }
      }

      .details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-top: 1rem;

        button {
          height: auto;
          padding: 0.25rem 0.5rem;
          background-color: $color-light-gray;
          gap: 0.5rem;

          svg {
            height: 0.8rem;
          }

          &:hover {
            background-color: $color-gray;
          }
        }

        .detail {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          //background: $color-light-gray;
          border-radius: 0.5rem;
          gap: 0.25rem;
          white-space: nowrap;
          font-size: 0.75rem;
        }
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      width: auto;
      font-size: 1rem;

      > div {
        font-size: 1rem;
      }
    }
  }

  .special-offer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    background: $color-lighten-gray;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-family: "Baloo Thambi";

    opacity: 0;

    animation: appear 1s ease 0.7s forwards;

    .title {
      font-weight: 700;
    }

    .text {
      flex: 1;

      img,
      svg {
        height: 10px;
      }

      a {
        color: $text-color;
        padding-left: 1rem;
      }
    }

    .action {
      button {
        height: 2rem;

        background-color: #e5a413;

        &:hover {
          background-color: #c78c0c;
        }
      }

      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        width: auto;
        font-size: 1rem;

        > div {
          font-size: 1rem;
        }
      }
    }
  }

  .have-fun {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1rem;

    opacity: 0;

    animation: appear 1s ease 0.7s forwards;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;

    opacity: 0;

    animation: appear 1s ease 0.7s forwards;

    > * {
      width: 100%;
      aspect-ratio: 1/1;
      margin: 0 auto;
      cursor: pointer;
    }

    @media screen and (min-width: $sm-breakpoint) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $lg-breakpoint) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
