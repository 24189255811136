import {useConnection} from '@solana/wallet-adapter-react'
import {PublicKey, Transaction, TransactionInstruction} from '@solana/web3.js'
import base58 from 'bs58'
import {sha256} from 'js-sha256'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {MEMO_PROGRAM_ID} from '../../../../utils/constants'
import {formatClassName} from '../../../../utils/global'
import {consentRequest, getTerms} from '../../../api/hub'
import {useHubContext} from '../../../state/context'
import {hubState} from '../../../state/hub'
import {Button} from '../../buttons/button'
import {OverlayModal, OverlayModalProps} from '../../modal/modal'
import styles from './consent.module.scss'

export type ConsentProps = OverlayModalProps & {
  onRefuse: () => void
  showButtons?: boolean
}

export const Consent = ({className, onRefuse, showButtons = true, ...props}: ConsentProps) => {
  const {dispatch, state: {publicKey, isLedger}} = useHubContext()
  const {connection} = useConnection()

  const [terms, setTerms] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [consenting, setConsenting] = useState(false)

  useEffect(() => {
    (async () => {
      const terms = await getTerms()

      if (!terms) {
        toast.error('Impossible to load the terms and conditions, please try again later')
      } else {
        setTerms(terms)
      }

      setLoading(false)
    })()
  }, [])

  const signConsent = async () => {
    if (!terms || !publicKey) return

    setConsenting(true)

    const hash = sha256.create()
    hash.update(terms)

    const data = {
      isLedger
    } as any

    if (isLedger && hubState.signTransaction) {
      const tx = new Transaction()
      tx.add(
        new TransactionInstruction({
          programId: MEMO_PROGRAM_ID,
          keys: [],
          data: Buffer.from(hash.hex(), 'utf8'),
        })
      )

      const blockHash = (await connection.getLatestBlockhash('finalized')).blockhash

      tx.feePayer = new PublicKey(publicKey)
      tx.recentBlockhash = blockHash

      const signedTx = await hubState.signTransaction(tx)

      data.signedTx = signedTx.serialize().toString('base64')
    } else if (hubState.signMessage) {
      const signature = await hubState.signMessage(new TextEncoder().encode(hash.hex()))

      const encodedSignature = base58.encode(signature)

      data.signature = encodedSignature
    }

    const result = await consentRequest(data)

    if (result?.status !== 200) {
      toast.error('Impossible to validate the consent, please try again later')
    } else {
      dispatch({
        type: 'SET_CONSENT',
        consent: true
      })
    }

    setConsenting(false)
  }

  return <OverlayModal className={formatClassName(styles, `consent-overlay ${className}`)} containerClassName={formatClassName(styles, 'container-consent')} {...props}>
    <>
      <div className={formatClassName(styles, 'consent')} >
        {loading
          ? 'Loading...'
          : terms ? <p>{terms}</p> : 'Impossible to load the terms and conditions, please try again later'
        }
      </div>
      {showButtons &&
        <div className={formatClassName(styles, 'buttons')}>
          <Button disabled={consenting} className='gray' onClick={onRefuse}>refuse</Button>
          <Button loading={consenting} onClick={signConsent}>agree</Button>
        </div>
      }
    </>
  </OverlayModal>
}