@import "scss-variables";

.dashboard {
  display: flex;
  position: relative;
  color: $text-color;
  font-family: "Baloo Thambi";
  font-size: 20px;
  width: 100%;

  &.player-view {
    height: 100vh;
    .menu {
      display: none;
    }

    .container {
      .toolbar {
        display: none;
      }

      .content {
        padding: 0;
        overflow: hidden;
        max-height: 100vh;
      }
    }
  }

  .menu {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    z-index: 101;
    top: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background-color: #222c;
    backdrop-filter: blur(1rem);

    transition: all 300ms ease;

    @media screen and (min-width: $sm-breakpoint) {
      z-index: 16;
    }

    @media screen and (max-width: $sm-breakpoint) {
      &.opened {
        opacity: 1;
        visibility: visible;

        .menu-content {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .menu-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: all 300ms;
      padding: 0.5rem;
      width: 80vw;
      max-width: 350px;

      .logo {
        display: flex;
        flex-direction: column;
        opacity: 0.7;
        text-align: center;
        font-size: 0.75rem;
        align-items: center;
        justify-content: center;

        img {
          width: 3rem;
          filter: invert(1);
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      .items {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 1rem;
        width: 100%;
        padding-top: 2rem;
      }

      .item {
        position: relative;
        display: flex;
        align-items: center;
        height: 4rem;
        //padding: 0 3rem;
        transition: all 300ms ease;
        border-radius: 0.5rem;
        box-sizing: border-box;
        text-decoration: none;
        color: $text-color;
        overflow: hidden;
        padding: 1rem 2rem;

        &::before {
          content: "";
          position: absolute;
          left: -3px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 6px;
          height: 50%;
          border-radius: 6px;

          transition: all 300ms;

          //box-shadow: 2px 0px 10px 4px rgba($color-violet, 0.4), 4px 0px 35px 11px rgba($color-violet, 0.2);
        }

        &:hover {
          &:not(.disabled):not(.active) {
            background-color: $color-gray;
          }
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &.active {
          background-color: $color-gray;

          &::before {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: $color-blue;
            width: 6px;
            height: 50%;
            border-radius: 6px;

            box-shadow: 2px 0px 10px 4px rgba($color-blue, 0.4), 4px 0px 35px 11px rgba($color-blue, 0.2);
          }
        }

        &.profile {
          margin-top: auto;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;

          @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
            width: auto;
          }
        }

        .pfp {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          height: 100%;

          img {
            height: 100%;
            border-radius: 100%;
          }
        }

        .text {
          flex: 1;
          font-size: 1.05rem;
        }

        @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
          justify-content: center;
        }
      }

      @media screen and (min-width: $sm-breakpoint) {
        width: auto;
        min-width: 3rem;
        max-width: 250px;
        background-color: $color-dark-gray;

        .item {
          .text {
            width: 0;
            opacity: 0;
            transition: width 300ms, margin-left 300ms, opacity 300ms;
            flex: 0;
          }

          .icon,
          .pfp {
            width: 1rem;
            flex: 0;
          }
        }

        &:hover {
          max-width: 250px;

          .item .text {
            opacity: 1;
            flex: 0;
            width: 60px;
            margin-left: 2rem;
            transition: width 300ms ease 300ms, margin-left 300ms ease 300ms, opacity 300ms ease 400ms;
          }
        }
      }
    }

    @media screen and (min-width: $sm-breakpoint) {
      position: sticky;
      width: auto;
      opacity: 1;

      .menu-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0 auto;
    max-width: 100vw;
    min-height: 100vh;

    //@media screen and (min-width: $md-breakpoint) {
    //  max-width: 1200px;
    //}

    &.limited-height {
      overflow: hidden;
      max-height: 100vh;

      .toolbar {
        height: 2rem;
        padding: 0.5rem;

        .menu-button svg {
          font-size: 1rem;
        }

        .actions {
          right: 0.5rem;
        }
      }

      @media screen and (min-width: $sm-breakpoint) {
        .toolbar {
          height: 4rem;
          padding: 1rem;

          .actions {
            right: 1rem;
          }

          .menu-button svg {
            font-size: 2rem;
          }
        }
      }
    }

    .toolbar {
      position: sticky;
      top: 0;
      background-color: $color-gray;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 4rem;
      padding: 1rem;
      box-sizing: border-box;

      .title {
        visibility: hidden;
        display: flex;
        gap: 1rem;
        font-size: larger;
        align-items: center;
        font-weight: 600;

        @media screen and (min-width: $sm-breakpoint) {
          visibility: visible;
        }

        .help {
          cursor: pointer;
          opacity: 0.4;
          font-size: 1rem;

          &:hover {
            opacity: 1;
          }
        }
      }

      .actions {
        display: flex;
        position: absolute;
        right: 1rem;

        .action {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          gap: 0.75rem;
          font-size: 1.25rem;
          padding: 0 0.75rem;
          border-radius: 0.5rem;

          //@media screen and (min-width: $sm-breakpoint) {
          //  height: auto;
          //  gap: 0.75rem;
          //  font-size: 1.25rem;
          //}

          &.sound button {
            height: 100%;
          }

          &.yooh {
            font-weight: bold;
            color: #b94700;
            background: linear-gradient(260.09deg, #ffd834 4.24%, #fff4b1 97.3%);

            &:hover {
              box-shadow: 0 0 0 1px #b94700;
              cursor: pointer;
            }

            .separator {
              border: 1px solid #b94700;
              height: 55%;
            }

            .yooh-container {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;

              @media screen and (min-width: $sm-breakpoint) {
                gap: 0.75rem;
              }
            }

            img {
              height: 1rem;

              @media screen and (min-width: $sm-breakpoint) {
                height: 1.25rem;
              }
            }

            .wallet {
              height: 1rem;

              @media screen and (min-width: $sm-breakpoint) {
                height: 1.15rem;
              }
            }
          }

          &.yaah {
            font-weight: bold;
            color: #4b299a;
            background: linear-gradient(104.68deg, #e4d9fd 0.46%, #c8c0da 96.39%);
            margin-left: 0.5rem;

            @media screen and (min-width: $sm-breakpoint) {
              margin-left: 0.75rem;
            }

            img {
              height: 1rem;

              @media screen and (min-width: $sm-breakpoint) {
                height: 1.25rem;
              }
            }
          }

          &.chat-action {
            font-weight: bold;
            background: #33333311;
            margin-left: 0.5rem;

            @media screen and (min-width: $sm-breakpoint) {
              margin-left: 0.75rem;
            }

            &:hover {
              box-shadow: 0 0 0 1px #33333333;
              cursor: pointer;
            }
          }

          &.connect {
            font-size: 1rem;

            @media screen and (min-width: $sm-breakpoint) {
              height: 2rem;
            }
          }

          img {
            height: 1rem;
          }
        }

        .chat-action-container {
          display: flex;

          @media screen and (min-width: $lg-breakpoint) {
            transition: opacity 0.3s, width 0.6s;

            &.show {
              width: 60px;
            }

            &.hidden {
              width: 0;
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }

      .menu-button {
        color: $text-color;

        &.invisible {
          visibility: hidden;
        }

        @media screen and (min-width: $sm-breakpoint) {
          display: none;
        }
      }
    }

    .content {
      display: flex;
      flex: 1;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      justify-content: center;

      //max-height: calc(100% - 6rem);
      //overflow: visible;
      //overflow-y: auto;
    }
  }

  .chat-container {
    //display: flex;
    z-index: 17;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.3s;
    opacity: 0;
    max-height: 100vh;

    &.open {
      opacity: 1;
      width: 324px;
      max-width: 100%;
    }

    @media screen and (min-width: $lg-breakpoint) {
      position: sticky;
      height: auto;
      transition: opacity 0.3s, width 0.6s;

      &.open {
        transition: opacity 3s, width 1s;
      }
    }

    .chat-animated-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      background-color: $color-dark-gray;
      box-sizing: border-box;
    }
  }

  .testing {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: $color-danger;
    font-size: 1rem;
  }
}

@keyframes chat-opening {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
