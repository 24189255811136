@import "scss-variables";

.flip {
  position: relative;
  font-size: 1.6rem;

  .tick-label {
    font-size: 1rem;
  }
}
