import {sound} from '@pixi/sound'
import {ease, EaseParams} from 'pixi-ease'
import {SkeletonData, Spine} from 'pixi-spine'
import {Container, Graphics, LINE_CAP, LINE_JOIN, Point, Sprite, Text, TextMetrics, TextStyle} from 'pixi.js'
import {LevelStatus, SoundName} from './enums'
import {createViewport} from './game'
import {initLevel} from './level'
import {exitRoom} from './server/server-handler'
import {playMusic, stopAllMusicAndAmbience} from './sound'
import {bubbleState} from './state'
import {getGameHeight, getGameWidth, getRatio} from './utils'

// Fix problem when sliding after starting the slide on a flag / chest (the level is launched even if we slided)
let pointerMoved = false

export const initWorldScreen = async () => {
  await exitRoom()

  createViewport()

  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.app) return

  const ratio = getRatio()

  bubbleState.viewport
    .drag({
      direction: 'y'
    })
    .decelerate()
    .clamp({
      direction: 'y'
    })

  bubbleState.viewport.on('pointermove', () => {
    pointerMoved = true
  })

  bubbleState.viewport.on('pointerdown', () => {
    pointerMoved = false
  })

  initSounds()

  const resultPath = initPath()

  if (!resultPath) return

  const {path, levelPoints} = resultPath

  path.y = path.height + 80 * ratio

  const bg = new Graphics()
    .beginFill(0x0065A2)
    .drawRect(0, 0, getGameWidth(), path.height + 340 * ratio)
    .endFill()

  const points = initPoints(levelPoints)
  points.y = path.y

  const water = initWater(levelPoints)
  water.y = path.y

  const elements = initElements(levelPoints)
  elements.y = path.y

  const ship = new Sprite(bubbleState.resources.shipWS)
  ship.scale.set(0.3 * ratio, 0.3 * ratio)

  ship.x = bg.width / 2 - ship.width / 3
  ship.y = bg.height - ship.height * 1.4

  ease.add(ship, {
    scale: ship.scale.x - 0.015,
    x: ship.x + 1,
    y: ship.y + 10,
    skewX: 0.02,
    alpha: 0.9
  }, {
    repeat: -1,
    reverse: true,
    duration: 2000
  })

  bubbleState.viewport.addChild(
    bg,
    water,
    elements,
    path,
    points,
    ship
  )

  initTopBar()
  initBottomBar()

  if (bubbleState.external.refreshTokens) {
    bubbleState.external.refreshTokens()
  }

  bubbleState.viewport.moveCenter(getGameWidth() / 2, bg.height - getGameHeight() / 2)
}

const initPath = () => {
  if (!bubbleState.viewport || !bubbleState.gameState) return

  const ratio = getRatio()

  const numberOfLevels = Object.keys(bubbleState.gameState.levelStatuses).length

  const worldWidth = getGameWidth()

  const numberOfArcs = Math.ceil((numberOfLevels - 1) / 3)
  const radius = getGameWidth() * 0.2

  const marginHorizontal = worldWidth * 0.3

  const levelPoints: Point[] = []

  const path = new Graphics()
    .lineStyle({
      width: 12 * ratio,
      color: 0xFFFFFF,
      cap: LINE_CAP.ROUND
    })
    .arc(worldWidth / 2 - radius, 0, radius, 0, -Math.PI * 0.5, true)

  levelPoints.push(
    new Point(worldWidth / 2, 0),
  )

  for (let i = 1; i <= numberOfArcs; i++) {
    const cy = - radius * (i * 2)

    if (i % 2 === 1) {
      path.arc(marginHorizontal, cy, radius, Math.PI * 0.5, Math.PI * 1.5)

      levelPoints.push(
        new Point(marginHorizontal, cy + radius),
        new Point(marginHorizontal - radius, cy),
        new Point(marginHorizontal, cy - radius)
      )
    } else {
      path.arc(worldWidth - marginHorizontal, cy, radius, Math.PI * 0.5, -Math.PI * 0.5, true)

      levelPoints.push(
        new Point(worldWidth - marginHorizontal, cy + radius),
        new Point(worldWidth - marginHorizontal + radius, cy),
        new Point(worldWidth - marginHorizontal, cy - radius)
      )
    }
  }

  if (levelPoints.length >= numberOfLevels) levelPoints.splice(numberOfLevels)

  return {
    path,
    levelPoints
  }
}

const initPoints = (points: Point[]) => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.gameState || !bubbleState.levels) return new Container()

  const ratio = getRatio()

  const container = new Container()

  container.sortableChildren = true

  const dots = new Graphics().beginFill(0xFFFFFF)

  for (const [index, point] of points.entries()) {
    let pointertap

    if (bubbleState.levels[index]) {
      let texture

      let label = 'flag'

      if (bubbleState.levels[index].treasure) {
        label = 'chest'
      }

      switch (bubbleState.gameState.levelStatuses[index].status) {
        case LevelStatus.Unavailable:
        case LevelStatus.Locked:
          texture = bubbleState.resources[`${label}Cross`]
          break
        case LevelStatus.OneStar:
          texture = bubbleState.resources[`${label}OneStar`]
          break
        case LevelStatus.TwoStars:
          texture = bubbleState.resources[`${label}TwoStars`]
          break
        case LevelStatus.ThreeStars:
          texture = bubbleState.resources[`${label}ThreeStars`]
          break
        default:
          texture = bubbleState.resources[`${label}Empty`]
      }

      const element = new Sprite(texture)
      if (label === 'chest') {
        element.scale.set(0.38 * ratio, 0.38 * ratio)
        element.x = point.x - element.width / 2
        element.y = point.y - element.height + 6
      } else {
        element.scale.set(0.45 * ratio, 0.45 * ratio)
        element.x = point.x - element.width / 2
        element.y = point.y - element.height
      }

      element.zIndex = 10

      if (bubbleState.gameState.levelStatuses[index].status !== LevelStatus.Locked) {
        element.interactive = true

        element.cursor = 'pointer'

        pointertap = () => {
          if (!pointerMoved) launchLevel(index)

          pointerMoved = false
        }

        element.on('pointertap', pointertap)
      }

      container.addChild(element)
    } else {
      const label = 'flag'
      const texture = bubbleState.resources[`${label}Cross`]
      const element = new Sprite(texture)

      element.scale.set(0.45 * ratio, 0.45 * ratio)
      element.x = point.x - element.width / 2
      element.y = point.y - element.height

      element.zIndex = 10

      container.addChild(element)
    }

    if (index < bubbleState.levels.length && bubbleState.levels[index].name) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const text = bubbleState.levels[index].name!

      const style = new TextStyle({
        fontFamily: 'Baloo Thambi',
        fontWeight: '700',
        fill: 0xD02300,
        fontSize: 22 * ratio
      })

      const specialText = new Text(text, style)

      const rectangle = new Graphics()
        .beginFill(0xFFFFFF)
        .drawRoundedRect(0, 0, specialText.width * 1.2, specialText.height * 1.1, 10)
        .endFill()

      specialText.x += specialText.width * 0.1

      const textMetrics = TextMetrics.measureText(text, style)

      if (index < 3 || (index - 2) % 6 === 0) {
        rectangle.x = point.x + 40
        rectangle.y = point.y - rectangle.height * 1.4

        const triangle = new Graphics()
          .lineStyle({
            width: 10,
            color: 0xFFFFFF,
            join: LINE_JOIN.ROUND
          })
          .moveTo(rectangle.x + 7, rectangle.y + rectangle.height * 0.8)
          .lineTo(rectangle.x, rectangle.y + rectangle.height / 2)
          .lineTo(rectangle.x + 7, rectangle.y + rectangle.height * 0.2)

        container.addChild(triangle)
      } else if ((index - 2) % 3 !== 0) {
        rectangle.x = point.x - rectangle.width * 0.5
        rectangle.y = point.y - rectangle.height * 3.6

        const triangle = new Graphics()
          .lineStyle({
            width: 10,
            color: 0xFFFFFF,
            join: LINE_JOIN.ROUND
          })
          .moveTo(rectangle.x + rectangle.width * 0.5 - 8, rectangle.y + rectangle.height * 0.8)
          .lineTo(rectangle.x + rectangle.width * 0.5, rectangle.y + rectangle.height)
          .lineTo(rectangle.x + rectangle.width * 0.5 + 8, rectangle.y + rectangle.height * 0.8)

        container.addChild(triangle)
      } else {
        rectangle.x = point.x - textMetrics.width - 40
        rectangle.y = point.y - rectangle.height * 1.4

        const triangle = new Graphics()
          .lineStyle({
            width: 10,
            color: 0xFFFFFF,
            join: LINE_JOIN.ROUND
          })
          .moveTo(rectangle.x + rectangle.width - 7, rectangle.y + rectangle.height * 0.8)
          .lineTo(rectangle.x + rectangle.width, rectangle.y + rectangle.height / 2)
          .lineTo(rectangle.x + rectangle.width - 7, rectangle.y + rectangle.height * 0.2)

        container.addChild(triangle)
      }

      rectangle.zIndex = 50

      rectangle.addChild(specialText)

      if (pointertap) {
        rectangle.on('pointertap', pointertap)

        rectangle.interactive = true
        rectangle.cursor = 'pointer'
      }

      container.addChild(rectangle)
    }

    dots.drawCircle(point.x, point.y, 14 * ratio)
  }

  dots.endFill()

  dots.zIndex = 0

  container.addChild(dots)

  return container
}

const createWaterEffect = (arcIndex: number, point: Point, forceX?: number) => {
  if (!bubbleState.viewport || !bubbleState.resources) return new Container()

  const ratio = getRatio()

  const waterEffect = new Sprite(bubbleState.resources.waterEffectHS)
  waterEffect.scale.set(0.4 * ratio, 0.4 * ratio)

  waterEffect.x = forceX !== undefined ? forceX : getGameWidth() * (arcIndex % 2 === 1 ? 0.8 - (Math.random() * 0.2) : 0.2 + (Math.random() * 0.2)) - waterEffect.width / 2
  waterEffect.y = point.y - waterEffect.height / 2

  ease.add(waterEffect, {
    x: waterEffect.x + 5,
    alpha: 0.25,
  }, {
    repeat: -1,
    ease: 'easeInOutQuad',
    reverse: true,
    duration: Math.random() * 1000 + 1500
  })

  return waterEffect
}

const initWater = (points: Point[]) => {
  if (!bubbleState.viewport || !bubbleState.resources) return new Container()

  const waterContainer = new Container()

  for (const [index, point] of points.entries()) {
    if ((index + 1) % 3 !== 0) continue

    const arcIndex = (index + 1) / 3

    // Water effect
    const waterEffect = createWaterEffect(arcIndex, point)

    waterContainer.addChild(waterEffect)
  }

  const waterEffectBottom1 = createWaterEffect(0, new Point(points[0].x, points[0].y + 75), getGameWidth() * 0.1)
  const waterEffectBottom2 = createWaterEffect(0, new Point(points[0].x, points[0].y + 115), getGameWidth() - waterEffectBottom1.width * 1.2)
  waterContainer.addChild(waterEffectBottom1, waterEffectBottom2)

  return waterContainer
}

const initElements = (points: Point[]) => {
  if (!bubbleState.viewport || !bubbleState.resources) return new Container()

  const ratio = getRatio()

  const elementContainer = new Container()

  for (const [index, point] of points.entries()) {
    if ((index + 1) % 3 !== 0) continue

    const arcIndex = (index + 1) / 3

    let sprite
    let easeParams: ((sprite: Sprite) => EaseParams) | undefined

    switch (arcIndex % 13) {
      case 1:
        sprite = new Sprite(bubbleState.resources.islandTree)
        break
      case 3:
      case 7:
        sprite = new Sprite(bubbleState.resources.waterTyphon)

        easeParams = (sprite) => ({
          scale: sprite.scale.x - 0.05,
          x: sprite.x + sprite.width * 0.05,
          y: sprite.y + sprite.height * 0.05,
          alpha: 0.5
        })
        break
      case 6:
        sprite = new Sprite(bubbleState.resources.shipSinking)

        easeParams = (sprite) => ({
          scale: sprite.scale.x - 0.02,
          x: sprite.x + sprite.width * 0.02,
          y: sprite.y + sprite.height * 0.04
        })
        break
      case 9:
        sprite = new Sprite(bubbleState.resources.octopus)
        break
      case 5:
      case 12:
        sprite = new Sprite(bubbleState.resources.island)
        break
    }

    if (sprite) {
      sprite.scale.set(0.32 * ratio, 0.32 * ratio)

      sprite.x = getGameWidth() * (arcIndex % 2 === 1 ? 0.8 - (Math.random() * 0.4) : 0.2 + (Math.random() * 0.4)) - sprite.width / 2
      sprite.y = point.y - sprite.height / 2

      if (easeParams) {
        ease.add(sprite, easeParams(sprite), {
          repeat: -1,
          reverse: true,
          duration: Math.random() * 500 + 1500
        })
      }

      elementContainer.addChild(sprite)
    }
  }

  return elementContainer
}

const initTopBar = () => {
  if (!bubbleState.app) return

  const container = initGlobalContainer()

  if (!container) return

  initEnergy(container)
  //initCoin(container)
  initConnectionStatus(container)

  bubbleState.app.stage.addChild(container)
}

const initGlobalContainer = () => {
  if (!bubbleState.viewport) return undefined

  const ratio = getRatio()

  const container = new Container()

  const graphics = new Graphics()
  graphics
    .beginFill(0xC69C6D)
    .lineStyle(8 * ratio, 0x42210B)
    .drawRoundedRect(0, 0, getGameWidth() - (70 * ratio), 50 * ratio, 50 * ratio)
    .endFill()

  container.addChild(graphics)

  container.x = 10 * ratio
  container.y = 10 * ratio

  return container
}

const initEnergy = (topContainer: Container) => {
  if (!bubbleState.resources || !bubbleState.gameState) return undefined

  const ratio = getRatio()

  const container = new Container()

  // Background count
  const textContainer = new Container()

  const textBackground = new Graphics()
  textBackground
    .beginFill(0x42210B)
    .lineStyle(4 * ratio, 0xFFF8D0)
    .drawRoundedRect(0, 0, 130 * ratio, 30 * ratio, 30 * ratio)
    .endFill()

  textContainer.addChild(textBackground)

  textContainer.y = topContainer.height / 2 - textBackground.height / 2 - 2 // -2 to take border in account

  container.addChild(textContainer)

  // Plus

  const plus = new Sprite(bubbleState.resources.plus)

  plus.scale.set(0.32 * ratio, 0.32 * ratio)
  plus.x = textContainer.width - plus.width * 1.5
  plus.y = textContainer.height / 2 - plus.height / 2 - 2

  textContainer.addChild(plus)

  // Text

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFF8D0,
    fontSize: 22 * ratio
  })

  const counterText = new Text('', style)

  bubbleState.references.ui.worldScreen.energyCount = counterText

  textContainer.addChild(counterText)

  const energy = new Sprite(bubbleState.resources.energy)

  energy.scale.set(0.7 * ratio, 0.7 * ratio)
  energy.x = -9 * ratio
  energy.y = topContainer.height / 2 - energy.height / 2 - 2

  container.addChild(energy)

  container.interactive = true
  container.cursor = 'pointer'

  container.on('pointerdown', () => {
    container.scale.set(0.9, 0.9)
  })
  container.on('pointerup', () => {
    container.scale.set(1, 1)
    if (bubbleState.external.showModal) bubbleState.external.showModal({title: 'The shop page is under development, come back later 😊!'})
  })
  container.on('pointerout', () => {
    container.scale.set(1, 1)
  })

  container.pivot.set(container.width / 2, container.height / 2)
  container.x = container.width / 2
  container.y = container.height / 2

  topContainer.addChild(container)

  setWorldScreenEnergyCount(bubbleState.gameState.energy)
}

const initCoin = async (topContainer: Container) => {
  if (!bubbleState.resources || !bubbleState.gameState) return undefined

  const ratio = getRatio()

  // Background count
  const textContainer = new Container()

  const textBackground = new Graphics()
  textBackground
    .beginFill(0x42210B)
    .lineStyle(4 * ratio, 0xFFF8D0)
    .drawRoundedRect(0, 0, 125 * ratio, 30 * ratio, 35 * ratio)
    .endFill()

  textContainer.addChild(textBackground)

  textContainer.x = topContainer.width - 150 * ratio
  textContainer.y = topContainer.height / 2 - textBackground.height / 2 - 2 * ratio // -2 to take border in account

  topContainer.addChild(textContainer)

  // Text

  const counterText = new Text('', new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xFFF8D0,
    fontSize: 22 * ratio
  }))

  counterText.y = 2 * ratio

  bubbleState.references.ui.worldScreen.coinCount = counterText

  textContainer.addChild(counterText)

  // Spine coin object

  const coin = new Spine(bubbleState.resources.coin.spineData as SkeletonData)

  coin.scale.set(0.15 * ratio, 0.15 * ratio)
  coin.x = textContainer.x
  coin.y = topContainer.height / 2 - 4 * ratio

  topContainer.addChild(coin)

  setWorldScreenCoinCount((await bubbleState.external.refreshTokens?.())?.yaah ?? bubbleState.gameState.coins)
}

const initConnectionStatus = (topContainer: Container) => {
  if (!bubbleState.viewport) return

  const ratio = getRatio()

  const connectionStatus = new Graphics()
    .beginFill(0xaad000)
    .lineStyle({
      width: 2 * ratio,
      color: 0xffffff
    })
    .drawCircle(0, 0, 8 * ratio)
    .endFill()

  connectionStatus.interactive = true
  connectionStatus.cursor = 'pointer'

  connectionStatus.on('pointerdown', () => {
    connectionStatus.scale.set(0.9, 0.9)
  })
  connectionStatus.on('pointerup', () => {
    connectionStatus.scale.set(1, 1)
    if (bubbleState.external.showModal) bubbleState.external.showModal({
      title: 'This is the web3 connection status. If green, you are connected. If red, something went wrong and you should reconnect'
    })
  })
  connectionStatus.on('pointerout', () => {
    connectionStatus.scale.set(1, 1)
  })

  connectionStatus.x = (getGameWidth() - topContainer.width) / 2 + topContainer.width - connectionStatus.width / 2
  connectionStatus.y = topContainer.height / 2 - connectionStatus.height + topContainer.y + 4

  topContainer.addChild(connectionStatus)
}

export const setWorldScreenEnergyCount = (count: number) => {
  if (!bubbleState.references.ui.worldScreen.energyCount) return

  const ratio = getRatio()

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xffffff,
    fontSize: 22 * ratio
  })

  const textObj = bubbleState.references.ui.worldScreen.energyCount

  textObj.style = style
  //textObj.text = `${count < 10 ? '0' : ''}${count}/20`
  textObj.text = '∞/20'

  const textMetrics = TextMetrics.measureText(textObj.text, style)

  textObj.x = 115 * ratio / 2 - textMetrics.width / 2 + 10
  textObj.y = 2 * ratio
}

export const setWorldScreenCoinCount = (count: number) => {
  if (!bubbleState.references.ui.worldScreen.coinCount) return

  const ratio = getRatio()

  const counterText = bubbleState.references.ui.worldScreen.coinCount

  counterText.text = `${count / 100}`

  const textMetrics = TextMetrics.measureText(counterText.text, counterText.style as TextStyle)

  counterText.x = (125 * ratio) / 2 - textMetrics.width / 2 + 13 * ratio
}

const initBottomBar = () => {
  if (!bubbleState.app || !bubbleState.viewport || !bubbleState.resources) return

  const ratio = getRatio()

  const container = new Container()

  container.x = 0
  container.y = getGameHeight() - 70 * ratio

  const bg = new Graphics()
    .beginFill(0x0065a2)
    .drawRect(0, 0, getGameWidth(), 70 * ratio)
    .endFill()

  const bgShadow = new Graphics()
    .beginFill(0x4a92bd)
    .drawRect(0, 0, getGameWidth(), 4)
    .endFill()

  bgShadow.y = bg.y - 4

  // Settings

  const settingsContainer = new Container()

  const settingsBg = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, getGameWidth() * 0.175, 70 * ratio)
    .endFill()

  settingsBg.tint = 0x0065a2

  settingsContainer.addChild(settingsBg)

  const settingsButton = new Sprite(bubbleState.resources.settings)

  settingsButton.pivot.set(settingsButton.width / 2, settingsButton.height / 2)
  settingsButton.scale.set(0.45 * ratio, 0.45 * ratio)
  settingsButton.x = settingsContainer.width / 2
  settingsButton.y = settingsContainer.height / 2

  settingsContainer.interactive = true
  settingsContainer.cursor = 'pointer'

  settingsContainer.on('pointerdown', () => {
    settingsButton.scale.set(0.4 * ratio, 0.4 * ratio)
  })
  settingsContainer.on('pointerup', () => {
    settingsButton.scale.set(0.45 * ratio, 0.45 * ratio)
    if (bubbleState.external.showModal) bubbleState.external.showModal({
      title: 'The settings page is under development, come back later 😊!'
    })
  })
  settingsContainer.on('pointerover', () => {
    settingsBg.tint = 0x1b76ac
  })
  settingsContainer.on('pointerout', () => {
    settingsBg.tint = 0x0065a2
    settingsButton.scale.set(0.45 * ratio, 0.45 * ratio)
  })

  settingsContainer.addChild(settingsButton)

  // Star (character upgrade)

  const starContainer = new Container()

  const starBg = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, getGameWidth() * 0.175, 70 * ratio)
    .endFill()

  starBg.tint = 0x0065a2

  starContainer.addChild(starBg)
  starContainer.x = settingsContainer.width

  const starButton = new Sprite(bubbleState.resources.starWS)

  starButton.pivot.set(starButton.width / 2, starButton.height / 2)
  starButton.scale.set(0.45 * ratio, 0.45 * ratio)
  starButton.x = starContainer.width / 2
  starButton.y = starContainer.height / 2

  starContainer.interactive = true
  starContainer.cursor = 'pointer'

  starContainer.on('pointerdown', () => {
    starButton.scale.set(0.4 * ratio, 0.4 * ratio)
  })
  starContainer.on('pointerup', () => {
    starButton.scale.set(0.45 * ratio, 0.45 * ratio)
    if (bubbleState.external.showModal) bubbleState.external.showModal({
      title: 'The character\'s skill page is under development, come back later 😊!'
    })
  })
  starContainer.on('pointerover', () => {
    starBg.tint = 0x1b76ac
  })
  starContainer.on('pointerout', () => {
    starBg.tint = 0x0065a2
    starButton.scale.set(0.45 * ratio, 0.45 * ratio)
  })

  starContainer.addChild(starButton)

  // Weapons

  const weaponsContainer = new Container()

  const specialBg = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, getGameWidth() * 0.3, 70 * ratio)
    .endFill()

  specialBg.tint = 0xffb00d

  const specialBgShadow = new Graphics()
    .beginFill(0xffe43f)
    .drawRect(0, 0, specialBg.width, -4)
    .endFill()

  weaponsContainer.addChild(specialBg, specialBgShadow)
  weaponsContainer.x = starContainer.width + starContainer.x

  const weaponsButton = new Sprite(bubbleState.resources.weapons)

  weaponsButton.pivot.set(weaponsButton.width / 2, weaponsButton.height / 2)
  weaponsButton.scale.set(0.4 * ratio, 0.4 * ratio)
  weaponsButton.x = weaponsContainer.width / 2
  weaponsButton.y = weaponsContainer.height / 2

  weaponsContainer.interactive = true
  weaponsContainer.cursor = 'pointer'

  weaponsContainer.on('pointerdown', () => {
    weaponsButton.scale.set(0.35 * ratio, 0.35 * ratio)
  })
  weaponsContainer.on('pointerup', () => {
    weaponsButton.scale.set(0.4 * ratio, 0.4 * ratio)
    if (bubbleState.external.openLobbyScreen && bubbleState.viewport) {
      bubbleState.external.openLobbyScreen({
        clientWidth: getGameWidth(),
        clientHeight: getGameHeight()
      })
    }
  })
  weaponsContainer.on('pointerover', () => {
    specialBg.tint = 0xffc44a
  })
  weaponsContainer.on('pointerout', () => {
    specialBg.tint = 0xffb00d
    weaponsButton.scale.set(0.4 * ratio, 0.4 * ratio)
  })

  weaponsContainer.addChild(weaponsButton)

  // Special multi announcement

  if (!bubbleState.viewport) return

  const multiContainer = new Container()
  multiContainer.sortableChildren = true

  const style = new TextStyle({
    fontFamily: 'Baloo Thambi',
    fontWeight: '700',
    fill: 0xD02300,
    fontSize: 22 * ratio
  })

  const specialText = new Text('multiplayer', style)

  const rectangle = new Graphics()
    .beginFill(0xFFFFFF)
    .drawRoundedRect(0, 0, specialText.width * 1.2, specialText.height * 1.1, 10)
    .endFill()

  specialText.x += specialText.width * 0.1

  rectangle.addChild(specialText)
  rectangle.zIndex = 1

  multiContainer.addChild(rectangle)

  multiContainer.x = weaponsContainer.x + weaponsContainer.width / 2 - multiContainer.width / 2
  multiContainer.y = weaponsContainer.y - multiContainer.height - bgShadow.height * 2

  //const triangle = new Graphics()
  //  .lineStyle({
  //    width: 5,
  //    color: 0xFFFFFF,
  //    join: LINE_JOIN.ROUND
  //  })
  //  .moveTo(rectangle.x + rectangle.width * 0.5 - 8, rectangle.y + rectangle.height * 0.8)
  //  .lineTo(rectangle.x + rectangle.width * 0.5, rectangle.y + rectangle.height)
  //  .lineTo(rectangle.x + rectangle.width * 0.5 + 8, rectangle.y + rectangle.height * 0.8)

  //multiContainer.addChild(triangle)

  ease.add(multiContainer, {alpha: 0.5}, {ease: 'easeInOutQuad', duration: 2000, repeat: -1, reverse: true})

  // Trophy

  const trophyContainer = new Container()

  const trophyBg = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, getGameWidth() * 0.175, 70 * ratio)
    .endFill()

  trophyBg.tint = 0x0065a2

  trophyContainer.addChild(trophyBg)
  trophyContainer.x = weaponsContainer.x + weaponsContainer.width

  const trophyButton = new Sprite(bubbleState.resources.trophy)

  trophyButton.pivot.set(trophyButton.width / 2, trophyButton.height / 2)
  trophyButton.scale.set(0.45 * ratio, 0.45 * ratio)
  trophyButton.x = trophyContainer.width / 2
  trophyButton.y = trophyContainer.height / 2

  trophyContainer.interactive = true
  trophyContainer.cursor = 'pointer'

  trophyContainer.on('pointerdown', () => {
    trophyButton.scale.set(0.4 * ratio, 0.4 * ratio)
  })
  trophyContainer.on('pointerup', () => {
    trophyButton.scale.set(0.45 * ratio, 0.45 * ratio)
    if (bubbleState.external.showModal) bubbleState.external.showModal({
      title: 'The leaderboard page is under development, come back later 😊!'
    })
  })
  trophyContainer.on('pointerover', () => {
    trophyBg.tint = 0x1b76ac
  })
  trophyContainer.on('pointerout', () => {
    trophyBg.tint = 0x0065a2
    trophyButton.scale.set(0.45 * ratio, 0.45 * ratio)
  })

  trophyContainer.addChild(trophyButton)

  // Trophy

  const shopContainer = new Container()

  const shopBg = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, getGameWidth() * 0.175, 70 * ratio)
    .endFill()

  shopBg.tint = 0x0065a2

  shopContainer.addChild(shopBg)
  shopContainer.x = trophyContainer.x + trophyContainer.width

  const shopButton = new Sprite(bubbleState.resources.shop)

  shopButton.pivot.set(shopButton.width / 2, shopButton.height / 2)
  shopButton.scale.set(0.45 * ratio, 0.45 * ratio)
  shopButton.x = shopContainer.width / 2
  shopButton.y = shopContainer.height / 2

  shopContainer.interactive = true
  shopContainer.cursor = 'pointer'

  shopContainer.on('pointerdown', () => {
    shopButton.scale.set(0.4 * ratio, 0.4 * ratio)
  })
  shopContainer.on('pointerup', () => {
    shopButton.scale.set(0.45 * ratio, 0.45 * ratio)
    if (bubbleState.external.showModal) bubbleState.external.showModal({
      title: 'The shop page is under development, come back later 😊!'
    })
  })
  shopContainer.on('pointerover', () => {
    shopBg.tint = 0x1b76ac
  })
  shopContainer.on('pointerout', () => {
    shopBg.tint = 0x0065a2
    shopButton.scale.set(0.45 * ratio, 0.45 * ratio)
  })

  shopContainer.addChild(shopButton)

  container.addChild(
    bgShadow,
    bg,
    settingsContainer,
    starContainer,
    weaponsContainer,
    multiContainer,
    trophyContainer,
    shopContainer
  )

  bubbleState.app.stage.addChild(container)
}

const initSounds = () => {
  stopAllMusicAndAmbience()

  playMusic(SoundName.music1)
}

const launchLevel = (level: number) => {
  if (!bubbleState.viewport || !bubbleState.gameState || bubbleState.gameState.levelStatuses[level].status === LevelStatus.Locked || !bubbleState.levels) return

  sound.find(SoundName.selectLevel).play()

  bubbleState.viewport.removeAllListeners()

  bubbleState.gameState.actualLevel = level

  initLevel(bubbleState.levels[level])
}