import {useWallet, Wallet} from '@manahippo/aptos-wallet-adapter'
import React, {useCallback, useEffect, useState} from 'react'
import {formatClassName} from '../../../utils/global'
import {Button} from '../buttons/button'
import styles from './aptos-connect.module.scss'

export const AptosConnectButton: React.FC = () => {
  const {disconnecting} = useWallet()

  const [selectedWallet, setSelectedWallet] = useState<Wallet>()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setSelectedWallet(undefined)
  }, [disconnecting])

  const selectWallet = useCallback((wallet: Wallet | undefined) => setSelectedWallet(wallet), [])

  return <div className={formatClassName(styles, 'aptos-connect')}>
    {selectedWallet
      ? <Buttons wallet={selectedWallet} selectWallet={selectWallet} />
      : <Button onClick={() => setShowModal(true)}>Select Wallet</Button>
    }
    {showModal && !selectedWallet && <SelectWalletModal selectWallet={selectWallet} />}
  </div>
}

const Buttons: React.FC<{
  wallet: Wallet
  selectWallet: (wallet: Wallet | undefined) => void
}> = ({wallet, selectWallet}) => {
  const {disconnect, connected, connect} = useWallet()

  return <div className={formatClassName(styles, 'buttons')}>
    {
      !connected
        ? <>
          <Button
            onClick={() => {
              connect(wallet.adapter.name)
            }}
          >
            Connect
          </Button>
          <Button
            onClick={() => {
              selectWallet(undefined)
            }}
          >
            Change wallet
          </Button>
        </>
        : <Button className={formatClassName(styles, 'gray')}
          onClick={() => {
            disconnect()
          }}
        >
          Disconnect
        </Button>
    }
  </div>
}

const SelectWalletModal = ({selectWallet}: {
  selectWallet: (wallet: Wallet) => void
}) => {
  const {wallets} = useWallet()

  return <div className={formatClassName(styles, 'modal')}>
    <div className={formatClassName(styles, 'content')}>
      {
        wallets.map((wallet) => {
          const option = wallet.adapter
          return (
            <Button
              onClick={() => {
                selectWallet(wallet)
              }}
              id={option.name.split(' ').join('_')}
              key={option.name}>
              {option.name}
            </Button>
          )
        })
      }
    </div>
  </div>
}