@import "scss-variables";

.position-absolute {
  position: absolute !important;
}

.bubble-create-room-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1rem;
  background-color: #ffffffaa;
  color: #333;

  input {
    background-color: white;
    color: #666;
  }

  .form {
    display: flex;
    flex: 1;
    overflow-y: auto;

    .required {
      color: $color-danger;
    }

    .grid {
      display: grid;
      flex: 1;
      grid-template-columns: auto;
      gap: 1rem;
      justify-items: start;
      align-items: center;
      height: fit-content;
      text-align: left;

      > input {
        width: 100%;
      }

      > button {
        width: 100%;
      }

      .multi-button {
        display: flex;
        width: 100%;

        > button {
          flex: 1;
          color: #333 !important;
          background-color: transparent;

          &:hover:not(:disabled),
          &.selected {
            background-color: #33333311 !important;
          }

          img {
            height: 100%;
          }
        }

        > button:not(:only-child) {
          &:not(:first-child):not(:last-child) {
            border-radius: 0;
          }

          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 1rem;

    .cancel-button {
      color: #333;

      &:hover:not(:disabled),
      &.selected {
        background-color: #33333311 !important;
      }
    }

    .create-room-button {
      flex: 1;
    }
  }
}
