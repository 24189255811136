import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {YOOH} from '../../../../../../../../svg/token'
import {formatClassName} from '../../../../../../../../utils/global'
import {Button, ButtonIcon} from '../../../../../../../components/buttons/button'
import {Input} from '../../../../../../../components/input/input'
import {OverlayModal, OverlayModalProps} from '../../../../../../../components/modal/modal'
import {PopoverHelp} from '../../../../../../../components/popover/popover'
import styles from './bubble-create-room-modal.module.scss'
import {RoomInfo, TokenReward} from './types'

export type BubbleCreateRoomModalProps = OverlayModalProps & {
  onCreate: (roomInfo: RoomInfo, password?: string) => Promise<void>
  onClose: () => void
}

export const BubbleCreateRoomModal = ({onCreate, onClose, className, ...props}: BubbleCreateRoomModalProps) => {
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [mode, setMode] = useState<RoomInfo['mode']>('score')
  const [maxPlayers, setMaxPlayers] = useState<RoomInfo['maxPlayers']>(2)
  const [reward, setReward] = useState('without')
  const [quantity, setQuantity] = useState(1)
  const [rewardAutofocus, setRewardAutofocus] = useState(false)

  const [creating, setCreating] = useState(false)

  const createRoom = async () => {
    if (creating) {
      return
    }

    setCreating(true)

    const roomInfo: RoomInfo = {
      name,
      mode,
      maxPlayers
    }

    if (reward !== 'without') {
      roomInfo.reward = {
        name: 'token',
        token: reward,
        quantity
      } as TokenReward
    }

    await onCreate(roomInfo, password)

    setCreating(false)
  }

  const nameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value)
  }

  const passwordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value)
  }

  const quantityChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuantity(Number(event.target.value))
  }

  useEffect(() => {
    setRewardAutofocus(reward !== 'without')
  }, [reward])

  return <OverlayModal className={formatClassName(styles, 'position-absolute')} zIndex={15} containerClassName="full-size" {...props} >
    <div className={formatClassName(styles, 'bubble-create-room-modal')}>
      <h1>Create your room</h1>
      <div className={formatClassName(styles, 'form')}>
        <div className={formatClassName(styles, 'grid')}>
          <div>Room name<span className={formatClassName(styles, 'required')}>*</span></div>
          <Input type="text" maxLength={32} placeholder='Room name (min 6 chars)' value={name} onChange={nameChange} autoFocus />
          <div>Password</div>
          <Input type="password" maxLength={32} placeholder='Leave empty for a public room' value={password} onChange={passwordChange} />
          <div>Number of players<span className={formatClassName(styles, 'required')}>*</span></div>
          <div className={formatClassName(styles, 'multi-button')}>
            <Button className={formatClassName(styles, `no-color ${maxPlayers === 2 && 'selected'}`)} onClick={() => setMaxPlayers(2)}>2</Button>
            <Button className={formatClassName(styles, `no-color ${maxPlayers === 4 && 'selected'}`)} onClick={() => setMaxPlayers(4)}>4</Button>
            <Button className={formatClassName(styles, `no-color ${maxPlayers === 8 && 'selected'}`)} onClick={() => setMaxPlayers(8)}>8</Button>
          </div>
          <div>
            Entry price
            <PopoverHelp>
              <>
                <div>Entry price</div>
                <ul>
                  <li>
                    PvP reward for your room. Players will have to pay the price you'll define to enter. The winner wins all -3% fees
                  </li>
                </ul>
              </>
            </PopoverHelp>
          </div>
          <div className={formatClassName(styles, 'multi-button')}>
            <Button title='no reward' className={formatClassName(styles, `no-color ${reward === 'without' && 'selected'}`)} onClick={() => setReward('without')}>without</Button>
            <Button title='$YOOH' className={formatClassName(styles, `no-color ${reward === '$YOOH' && 'selected'}`)} onClick={() => setReward('$YOOH')}><YOOH /></Button>
            {/*<Button title='YAAH' className={`no-color ${reward === 'YAAH' && 'selected'}`} onClick={() => setReward('YAAH')}><YAAH /></Button>*/}
          </div>
          {
            reward !== 'without' && <>
              <div>
                Amount of {reward} to enter<span className={formatClassName(styles, 'required')}>*</span>
                <PopoverHelp>
                  <>
                    <div>{reward} quantity to enter the room</div>
                    <ul>
                      <li>
                        Set the {reward} quantity required for each player to enter the room
                      </li>
                      <li>
                        The winner wins all the {reward} paid by the room's players -3% fees
                      </li>
                    </ul>
                  </>
                </PopoverHelp>
              </div>
              <Input type="number" min={1} step={1} value={quantity} onChange={quantityChange} placeholder='quantity (min 1)' autoFocus={rewardAutofocus} />
            </>
          }
          <div>
            Mode
            <PopoverHelp>
              <>
                <div>Mode</div>
                <ul>
                  <li>
                    Highest score: highest score is wins. If exaequo, the fastest is chosen.
                  </li>
                  <li>
                    More modes coming soon...
                  </li>
                </ul>
              </>
            </PopoverHelp>
          </div>
          <Button disabled>highest score</Button>
          <div>
            Streaming
            <PopoverHelp>
              <>
                <div>Streaming</div>
                <ul>
                  <li>
                    Allow people to watch each player's game and tip for their favorite one
                  </li>
                </ul>
              </>
            </PopoverHelp>
          </div>
          <Button disabled>coming soon</Button>
        </div>
      </div>
      <div className={formatClassName(styles, 'actions')}>
        <ButtonIcon flip="horizontal" icon="right-from-bracket" className={formatClassName(styles, 'cancel-button no-color')} onClick={onClose} >cancel</ButtonIcon>
        <ButtonIcon icon="circle-plus" className={formatClassName(styles, 'create-room-button')} onClick={createRoom}>create</ButtonIcon>
      </div>
    </div>
  </OverlayModal>
}