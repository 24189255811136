@import "scss-variables";

.consent-overlay {
  position: absolute !important;

  .consent {
    font-family: "Baloo Thambi";
    overflow-y: auto;

    p {
      white-space: pre-line;
    }
  }

  .buttons {
    display: flex;
    width: 100%;

    button {
      flex: 1;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.container-consent {
  overflow: hidden;
}
