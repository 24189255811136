import {initBubbleGame} from 'pirate-squad-bubble'
import React, {HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import {isDesktop, isMobile} from 'react-device-detect'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../../utils/global'
import {tryOrReconnect} from '../../../../../api/hub'
import {OverlayModal} from '../../../../../components/modal/modal'
import {hubState} from '../../../../../state/hub'
import {ClassicLeaderboard} from './bubble/leaderboard/classic-leaderboard'
import {BubbleLobby} from './bubble/lobby/bubble-lobby'
import styles from './player.module.scss'

export type PlayerProps = HtmlHTMLAttributes<HTMLDivElement>

export const Player = ({className, ...props}: PlayerProps) => {
  const {game} = useParams()

  const gameRef = useRef<HTMLCanvasElement>(null)
  const gameContainerRef = useRef<HTMLDivElement>(null)

  const [showLobby, setShowLobby] = useState(false)
  const [lobby, setLobby] = useState<JSX.Element | undefined>()

  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const [leaderboard, setLeaderboard] = useState<JSX.Element | undefined>()

  const [showScreenSize, setShowScreenSize] = useState(false)
  const [showScreenOrientation, setShowScreenOrientation] = useState(false)

  const [ready, setReady] = useState(false)
  const [gameLaunched, setGameLaunched] = useState(false)

  const checkScreenSize = () => {
    if (!gameContainerRef.current) return

    if (gameContainerRef.current.offsetWidth > gameContainerRef.current.offsetHeight && isMobile) {
      setShowScreenOrientation(true)
    } else {
      setShowScreenOrientation(false)
    }

    if (gameContainerRef.current.offsetWidth < 300 || gameContainerRef.current.offsetHeight < 550 || gameContainerRef.current.offsetWidth > 10000 || gameContainerRef.current.offsetHeight > 10000) {
      setShowScreenSize(true)
    } else {
      setShowScreenSize(false)
    }

    setReady(true)
  }

  useEffect(() => {
    if (hubState.clearGame) hubState.clearGame()

    window.addEventListener('resize', checkScreenSize)

    checkScreenSize()

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  useEffect(() => {
    if (!gameRef.current || !ready || showScreenOrientation || showScreenSize || gameLaunched) return

    if (game === 'bubble') {
      if (hubState.showLoading) hubState.showLoading(true, 0)

      const closeLobby = () => {
        setShowLobby(false)
        setLobby(undefined)

        if (hubState.refreshTokens) {
          setTimeout(hubState.refreshTokens, 1000)
        }
      }

      const openLobby = (clientInfo: {
        clientWidth: number,
        clientHeight: number
      }) => {
        setShowLobby(true)
        setLobby(<BubbleLobby clientInfo={clientInfo} onClose={closeLobby} />)
      }

      //openLobby({
      //  clientHeight: 500,
      //  clientWidth: 500
      //})

      //hubState.showModal = () => false

      const closeClassicLeaderboard = () => {
        setShowLeaderboard(false)
        setLeaderboard(undefined)
      }

      const openClassicLeaderboard = () => {
        setShowLeaderboard(true)
        setLeaderboard(<ClassicLeaderboard onClose={closeClassicLeaderboard} />)
      }

      initBubbleGame(
        gameRef.current,
        {
          showLoading: hubState.showLoading,
          showModal: hubState.showModal,
          //setTokens: hubState.setTokens,
          refreshTokens: hubState.refreshTokens,
          openLobbyScreen: openLobby,
          closeLobbyScreen: closeLobby,
          openClassicLeaderboardScreen: openClassicLeaderboard,
          tryOrReconnect,
          sessionToken: hubState.sessionToken
        },
        (progress, exited) => {
          if (hubState.showLoading && !exited) {
            if (progress === 100) {
              hubState.showLoading(false)
            } else {
              hubState.showLoading(true, Math.floor(progress))
            }
          }
        }).then((clearGame) => {
          hubState.clearGame = () => {
            if (clearGame) clearGame()

            hubState.clearGame = undefined
          }
        }).catch(err => {
          toast.error('Error while retreiving the game state, please try again later or contact the support team')

          console.error(err)
        })

      setGameLaunched(true)
    }
  }, [gameRef, game, showScreenOrientation, showScreenSize, ready, gameLaunched])

  return (
    <div className={formatClassName(styles, `player ${isDesktop ? 'desktop' : ''} ${className}`)} {...props} onContextMenu={(event) => {
      event.preventDefault()
    }}>
      <OverlayModal show={showScreenOrientation}>
        <div className={formatClassName(styles, 'screen-size')}>
          <p>
            {'Please use your mobile in portrait orientation to play the game'}
          </p>
        </div>
      </OverlayModal>
      <OverlayModal show={showScreenSize && !showScreenOrientation}>
        <div className={formatClassName(styles, 'screen-size')}>
          <p>
            {'You screen is too small (width < 300px or height < 550px) or too big (width > 10000px, height > 10000px).'}
          </p>
          <p>
            {'To play the game, please modify the size of your window (desktop) or connect with a compatible device (mobile).'}
          </p>
        </div>
      </OverlayModal>
      <div ref={gameContainerRef} className={formatClassName(styles, `game ${showScreenSize ? 'hidden' : ''}`)}>
        {showLobby && lobby}
        {showLeaderboard && leaderboard}
        <canvas ref={gameRef}></canvas>
      </div>
    </div>
  )
}