import React from 'react'
import * as ReactDOM from 'react-dom/client'
import {Hub} from './hub/hub'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {
  faAlignLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faAward,
  faBan,
  faBars,
  faBookSkull,
  faBroom,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch, faCirclePlus, faCircleQuestion, faCircleStop, faClipboardList,
  faCodeBranch,
  faCog, faCoins, faComments, faCubes, faGamepad,
  faGift, faHandHoldingDollar, faHandshakeSimple,
  faHeart,
  faHouse, faInfinity, faPaperPlane, faPencil, faRefresh, faRepeat, faReply, faRetweet,
  faRightFromBracket,
  faRightToBracket,
  faShuffle,
  faSkull,
  faTrash,
  faTrophy,
  faUpDown,
  faUser,
  faUserPlus,
  faVolumeLow,
  faVolumeXmark,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faGamepad,
  faBars,
  faCubes,
  faBookSkull,
  faHandshakeSimple,
  faHouse,
  faTwitter,
  faHeart,
  faRetweet,
  faGift,
  faClipboardList,
  faCog,
  faRightFromBracket,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faXmark,
  faCircleNotch,
  faUserPlus,
  faCircleQuestion,
  faGift,
  faAlignLeft,
  faCirclePlus,
  faRightFromBracket,
  faComments,
  faAngleRight,
  faPaperPlane,
  faReply,
  faRefresh,
  faCoins,
  faTrash,
  faVolumeXmark,
  faVolumeLow,
  faUpDown,
  faUser,
  faArrowRight,
  faTrophy,
  faArrowLeft,
  faRightToBracket,
  faBan,
  faRepeat,
  faInfinity,
  faCodeBranch,
  faShuffle,
  faBroom,
  faCircleStop,
  faAward,
  faSkull,
  faPencil,
  faHandHoldingDollar
)

const app = document.getElementById('react')

if (app) {
  const root = ReactDOM.createRoot(app)

  root.render(
    <Hub />
  )
}
