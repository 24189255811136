// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {type} from '@colyseus/schema'
import {PowerUpSkinName} from '../../enums'
import {MovingEntitySchema} from './MovingEntitySchema'

export class PowerUpSchema extends MovingEntitySchema {
  @type('string') public uid!: string
  @type('string') public skin!: PowerUpSkinName
}
