import axios, {AxiosRequestConfig} from 'axios'
import {toast, TypeOptions} from 'react-toastify'
import {UserRole} from '../state/context'
import {hubState} from '../state/hub'

export const tryOrReconnect = async <T = object>(requestConfig: AxiosRequestConfig, onError?: (err: unknown) => void) => {
  let sessionToken = hubState.sessionToken

  if (!sessionToken) {
    toast.error('Impossible to retrieve the sessionToken, please refresh the page.')
    return
  }

  let result

  try {
    result = await axios.request<T>({
      ...requestConfig,
      headers: {
        'Authorization': `Bearer ${sessionToken}`
      }
    })
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 401 && hubState.sessionConnect) {
      try {
        const response = await axios.post<{sessionToken: string}>('/api/v1/hub/refresh', undefined, {
          withCredentials: true
        })

        if (response && hubState.setSessionToken) {
          sessionToken = response.data.sessionToken

          hubState.sessionToken = sessionToken
          hubState.setSessionToken(sessionToken)

          result = await axios.request<T>({
            ...requestConfig,
            headers: {
              'Authorization': `Bearer ${sessionToken}`
            }
          })

          return result
        }
      } catch (err) {
        console.log(err)
      }

      try {
        toast.info('Your token is out of date, please sign again the authentification message')

        if (hubState.disconnect) {
          hubState.disconnect()
        }
      } catch (err) {
        console.log(err)
        if (typeof err === 'string') {
          toast.error(err)
        } else {
          toast.error('Unknown error, please try again or contact the support team')
        }
      }
    } else if (onError) {
      onError(err)
    } else {
      if (axios.isAxiosError(err)) {
        toast.error(err.response?.data ?? 'Unknown error, please try again or contact the support team')
      } else {
        toast.error('Unknown error, please try again or contact the support team')
      }
    }
    console.log(err)
  }

  return result
}

export const generateNonce = async (ledger?: boolean, publicKey?: string) => {
  const response = await axios<{
    nonce: string
    tx?: string
  }>({
    url: '/api/v1/hub/nonce',
    method: 'get',
    params: {
      ledger,
      publicKey
    }
  })

  return response.data
}

export const getTerms = async () => {
  const response = await axios({
    url: '/api/v1/hub/terms',
    method: 'get'
  })

  return response.data?.termsConditions as string | undefined
}

export const connectToServer = async (params: {
  publicKey: string
  nonce: string
  signature?: string
  message?: string
  signedTx?: string
}) => {
  const {
    publicKey,
    nonce,
    signature,
    message,
    signedTx
  } = params

  const response = await axios.get<{
    sessionToken: string
    tokens: {
      yaah: number
      yooh: number
    },
    username: string
    userUuid: string
    consent: boolean
    pfp: string,
    role: UserRole
    twitter?: {
      username?: string
    }
  }>('/api/v1/hub/connect', {
    params: {
      publicKey,
      nonce,
      signature,
      message,
      signedTx
    }
  })

  return response
}
export const connectLedgerToServer = async (publicKey: string, signedTx: string, nonce: string) => {
  return await connectToServer({
    publicKey,
    signedTx,
    nonce
  })
}

export const retrieveTokens = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    tokens: {
      yaah?: number
      yooh?: number
    }
  }>({
    url: '/api/v1/hub/tokens',
    method: 'get'
  }, onError)

  if (!result || result.status !== 200) return

  if (!result.data.tokens.yaah) {
    result.data.tokens.yaah = 0
  }

  if (!result.data.tokens.yooh) {
    result.data.tokens.yooh = 0
  }

  return result.data?.tokens
}

export const retrieveInfo = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    tokens: {
      yaah?: number
      yooh?: number
    },
    notifications: {
      label: string
      message: string
      type: TypeOptions
      createdAt: number
    }[],
    referral: {
      referral?: string
      activatedReferral?: string
      toBeClaimed: number
    },
    rackbackAvailable: number
    revenueSharing: number
  }>({
    url: '/api/v1/hub/info',
    method: 'get'
  }, onError)

  if (!result || result.status !== 200) return

  if (!result.data.tokens.yaah) {
    result.data.tokens.yaah = 0
  }

  if (!result.data.tokens.yooh) {
    result.data.tokens.yooh = 0
  }

  return result.data
}

export const changeUsernameRequest = async (username: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/username',
    method: 'POST',
    data: {
      username
    }
  }, onError)

  return result
}

export const consentRequest = async (data: {
  isLedger: boolean
  signedTx?: string
  signature?: string
}, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/consent',
    method: 'POST',
    data
  }, onError)

  return result
}

export const uploadProfilePicture = async (image: File, onError?: (err: unknown) => void) => {
  const formData = new FormData()
  formData.append('image', image)

  const result = await tryOrReconnect<{
    pfp?: string
  }>({
    url: '/api/v1/hub/pfp',
    method: 'POST',
    data: formData
  }, onError)

  return result
}

export const couponRequest = async (code: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    message: string
    used: boolean
    info: {
      name: string
      reward: {
        type: 'yooh'
        amount: number
      }
    }
  }>({
    url: '/api/v1/hub/coupon',
    method: 'POST',
    data: {
      code
    }
  }, onError)

  return result
}

export const activateRevenueRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/activate-revenue',
    method: 'POST'
  }, onError)

  return result
}

export type RevenueSharingData = {
  [address: string]: {
    name: string
    revenuePoints: number
    activatedAt: number
  }
}

export const checkDepositChallengeRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    previousLevel: {
      level: number
      minDeposit: number
      rewardValue: number
    }
    nextLevel: {
      level: number
      minDeposit: number
      rewardValue: number
    }
    depositToday: number
    completion: number
  }>({
    url: '/api/v1/hub/check-deposit-challenge',
    method: 'GET'
  }, onError)

  return result
}

export const claimDepositChallengeRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    coupon: string
    rewardValue: number
    wagerMultiplier: number
  }>({
    url: '/api/v1/hub/claim-deposit-challenge',
    method: 'GET'
  }, onError)

  return result
}

export const checkVolumeChallengeRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    previousLevel: {
      level: number
      minVolume: number
      rewardValue: number
    }
    nextLevel: {
      level: number
      minVolume: number
      rewardValue: number
    }
    volumeToday: number
    completion: number
  }>({
    url: '/api/v1/hub/check-volume-challenge',
    method: 'GET'
  }, onError)

  return result
}

export const claimVolumeChallengeRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    coupon: string
    rewardValue: number
    wagerMultiplier: number
  }>({
    url: '/api/v1/hub/claim-volume-challenge',
    method: 'GET'
  }, onError)

  return result
}

export const checkRevenueRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    nfts: RevenueSharingData
  }>({
    url: '/api/v1/hub/check-revenue',
    method: 'GET'
  }, onError)

  return result
}

export const claimRevenueRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    claimedAmount: number
  }>({
    url: '/api/v1/hub/claim-revenue',
    method: 'GET'
  }, onError)

  return result
}

export const saveReferralRequest = async (referral: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/referral',
    method: 'POST',
    data: {
      referral
    }
  }, onError)

  return result
}

export const activateReferralRequest = async (referralToActivate: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/activate-referral',
    method: 'POST',
    data: {
      referralToActivate
    }
  }, onError)

  return result
}

export const claimReferralFees = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    claimedAmount: number
  }>({
    url: '/api/v1/hub/claim-referral',
    method: 'GET'
  }, onError)

  return result
}

export const claimRackbackRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    claimedAmount: number
  }>({
    url: '/api/v1/hub/claim-rackback',
    method: 'GET'
  }, onError)

  return result
}

export const getReferralFollowers = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    followers: {
      username: string
      deposits?: number
    }[]
  }>({
    url: '/api/v1/hub/referral-followers',
    method: 'GET'
  }, onError)

  return result
}

export const getProfile = async (userUuid: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    username: string
    pfp: string
    totalWagered: number
    wonGames: number
    lostGames: number
    bestStreak: number
    worstStreak: number
  }>({
    method: 'GET',
    url: '/api/v1/hub/profile',
    params: {
      userUuid
    }
  }, onError)

  return result
}

export const sendTipRequest = async (amount: number, userUuid: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/tip',
    method: 'POST',
    data: {
      amount,
      userUuid
    }
  }, onError)

  return result
}
