import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react'
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui'
import {BackpackWalletAdapter, CoinbaseWalletAdapter, GlowWalletAdapter, LedgerWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter} from '@solana/wallet-adapter-wallets'
import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom'
import {useHubContext} from '../../state/context'

export const SolanaWalletPage = () => {
  const {state: {sessionToken}} = useHubContext()

  const wallets = useMemo(
    () => [
      //new SolanaMobileWalletAdapter({
      //  appIdentity: {
      //    name: 'Pirate Squad - Bubble'
      //  },
      //  authorizationResultCache: createDefaultAuthorizationResultCache(),
      //  cluster: WalletAdapterNetwork.Mainnet,
      //  addressSelector: createDefaultAddressSelector(),
      //  onWalletNotFound: (adapter) => {

      //  }
      //}),
      new CoinbaseWalletAdapter(),
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new BackpackWalletAdapter()
    ],
    []
  )

  return (
    <ConnectionProvider endpoint="https://quiet-black-haze.solana-mainnet.discover.quiknode.pro/23a8159804103c2bed0c1e4c3fd777eb94c28f0c/">
      <WalletProvider wallets={wallets} autoConnect={!!sessionToken}>
        <WalletModalProvider>
          <Outlet />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}