import {Room} from 'colyseus.js'
import {Spine} from 'pixi-spine'
import {Point} from 'pixi.js'
import {destroyBubble, initBubble} from '../bubble'
import {bubbleDestroyEffects} from '../bubble-effects'
import {BubbleSkinName} from '../enums'
import {addPowerUp, drawBubbleOnGrid, endLevel, initLevelAfterServer, pushNewWave, remainingBubblesToFire, throwAndLoadBubble, updateBubbleSkin, updateScoreAndStars, updateStars} from '../level'
import {setBubbleCount, setHeartCount, setTimer, showScoreMenu, updateWavesLeftCount} from '../level-ui'
import {executeLevelComplete} from '../levels'
import {loadBubbleIntoHands, loadEmptyBubble} from '../pirate'
import {explodePowerUp} from '../power-up'
import {randomBubblePopNeutral} from '../sound'
import {bubbleState} from '../state'
import {Cluster, GameState} from '../types'
import {index2DFrom1D} from '../utils'
import {initWorldScreen, setWorldScreenCoinCount} from '../worldscreen'
import {BubbleSchema} from './schema/BubbleSchema'
import {LevelSchema} from './schema/LevelSchema'
import {PowerUpSchema} from './schema/PowerUpSchema'

export const onConnect = (room: Room, level: LevelSchema, reset: boolean) => {
  initServerListener(level)

  initServerMessage(room)

  initLevelAfterServer(level, reset)
}

const initServerListener = (level: LevelSchema) => {
  level.onChange = (changes) => {
    changes.forEach(change => {
      if (change.field === 'powerUp') return

      console.log('change', change)
    })
  }

  //level.tiles.forEach((tile, index) => {
  //  tile.listen('skin', (skin) => {
  //    onTileChange(skin, index)
  //  })
  //})

  level.powerUp.onAdd = (powerUp) => {
    console.log('add', powerUp)

    addPowerUp(powerUp)

    powerUp.onChange = (changes) => {
      changes.forEach(change => {
        //console.log('change powerup ', index, ': ', change)
        const index = bubbleState.references.powerUp?.findIndex((actualPowerUp) => actualPowerUp?.uid === powerUp.uid)

        if (!bubbleState.references.powerUp || index === undefined || index === -1) return

        const powerUpEntry = bubbleState.references.powerUp[index]

        if (powerUpEntry) {
          if (['x', 'y'].includes(change.field)) {
            powerUpEntry.spine[change.field as 'x' | 'y'] = change.value
          } else if (['dx', 'dy'].includes(change.field)) {
            powerUpEntry[change.field as 'dx' | 'dy'] = change.value
          }
        }
      })
    }
  }

  level.powerUp.onRemove = (powerUp) => {
    console.log('remove', powerUp)

    if (bubbleState.references.powerUp) {
      const index = bubbleState.references.powerUp.findIndex((actualPowerUp) => actualPowerUp?.uid === powerUp.uid)

      const clientPowerUp = bubbleState.references.powerUp[index]

      if (index !== -1 && clientPowerUp && !(clientPowerUp.spine.state.getCurrent(0).animation?.name === 'Explode')) {
        clientPowerUp.spine.destroy()

        bubbleState.references.powerUp = bubbleState.references.powerUp.slice(0, index).concat(bubbleState.references.powerUp.slice(index + 1))
      }
    }
  }

  level.listen('canShoot', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.canShoot = value
  })

  level.listen('score', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.score = value

    updateStars()
  })

  level.listen('ended', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.ended = value
  })

  level.listen('score', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.score = value

    updateStars()
  })

  level.listen('actualTopRowIndex', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.actualTopRowIndex = value

    updateWavesLeftCount()
  })

  level.listen('lives', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.lives = value

    setHeartCount(bubbleState.level.state.lives)
  })

  level.listen('numberOfBubblesSent', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.numberOfBubblesSent = value

    setBubbleCount(remainingBubblesToFire())

    updateWavesLeftCount()
  })

  level.listen('actualBubbleDestroyEffect', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.actualBubbleDestroyEffect = value
  })

  level.listen('nextBubbleDestroyEffect', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.nextBubbleDestroyEffect = value
  })

  level.listen('actualBubbleCollideEffect', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.actualBubbleCollideEffect = value
  })

  level.listen('nextBubbleCollideEffect', (value) => {
    if (!bubbleState.level) return

    bubbleState.level.state.nextBubbleCollideEffect = value
  })

  level.bubbleFired.onChange = ((changes) => {
    changes.forEach(change => {
      if (!bubbleState.references.bubbleFired) return

      if (change.field === 'x' || change.field === 'y') {
        if (change.field === 'y') {
          const serverDifference = bubbleState.references.bubbleFired.spine.y - change.value
          const computedDifference = 1 + (serverDifference / 500)

          bubbleState.references.bubbleFired.dx = bubbleState.references.bubbleFired.dxOriginal * computedDifference
          bubbleState.references.bubbleFired.dy = bubbleState.references.bubbleFired.dyOriginal * computedDifference

          //console.log({
          //  serverDifference: bubbleState.references.bubbleFired.spine.y - change.value
          //})
        }
      }
    })
  })
}

const initServerMessage = (room: Room) => {
  room.onMessage<{
    gameState: GameState
  }>('game-state', ({
    gameState
  }) => {
    bubbleState.gameState = gameState

    //changePirate(bubbleState.gameState?.pirateCharacter ?? PirateCharacter.PirateOne)

    console.log('game-state-message', gameState)
  })

  room.onMessage<{
    handBubbleSkin: BubbleSkinName
    cannonBubbleSkin: BubbleSkinName
  }>('load-bubble-in-cannon', ({
    handBubbleSkin,
    cannonBubbleSkin
  }) => {
    console.log('load-bubble-in-cannon-message', handBubbleSkin, cannonBubbleSkin)

    throwAndLoadBubble(() => {
      if (handBubbleSkin && handBubbleSkin !== BubbleSkinName.Empty) {
        loadBubbleIntoHands(handBubbleSkin)
      } else {
        loadEmptyBubble()
      }
    }, cannonBubbleSkin)
  })

  room.onMessage<{
    bubbles: {
      index: number,
      score: number,
      silent?: boolean,
      delay?: number
    }[]
  }>('destroy-bubbles', ({
    bubbles
  }) => {
    const destroyedBubbles: Cluster = []

    bubbles.forEach(({index, score, silent, delay}) => {
      if (!bubbleState.level || !bubbleState.references.tiles) return

      const {row, column} = index2DFrom1D(index, bubbleState.level?.numberOfBubblePerRow)

      const bubble = bubbleState.references.tiles[row]?.[column]

      if (bubble) {
        const spine = bubble.children[0] as Spine

        setTimeout(() => {
          destroyBubble(spine, score, silent)
        }, delay ?? 0)

        destroyedBubbles.push({
          row,
          column,
          bubble: spine
        })
      }
    })

    updateScoreAndStars(destroyedBubbles)

    console.log('destroy-bubbles-message', {bubbles})
  })

  room.onMessage<{
    wave: BubbleSchema[]
    delay: number
    actualTopRowIndex: number
  }>('new-wave', ({wave, delay, actualTopRowIndex}) => {
    if (!bubbleState.level || !bubbleState.references.tiles) return

    bubbleState.level.state.actualTopRowIndex = actualTopRowIndex

    //setTimeout(() => {
    pushNewWave(wave)
    //}, delay * 2)

    console.log('new-wave-message', {
      wave,
      actualTopRowIndex
    })
  })

  room.onMessage<{
    skin: BubbleSkinName
  }>('update-bubble-skin', ({skin}) => {
    updateBubbleSkin(skin)

    console.log('update-bubble-skin-message', {
      skin
    })
  })

  room.onMessage<{
    point: {
      x: number
      y: number
    }
    row?: number
    column?: number
    skin?: BubbleSkinName
  }>('fired-bubble-collided', ({
    point,
    row,
    column,
    skin
  }) => {
    if (bubbleState.references.bubbleFired) {
      bubbleState.references.bubbleFired.spine.destroy()
      bubbleState.references.bubbleFired = undefined
    }

    if (bubbleState.level?.state.actualBubbleDestroyEffect) {
      const effect = bubbleDestroyEffects[bubbleState.level.state.actualBubbleDestroyEffect]

      if (effect) {
        effect(point as Point)
      }
    }

    if (skin && row !== undefined && column !== undefined) {
      const bubble = initBubble(skin)

      if (bubble) {
        bubble.scale.set(bubbleState.level?.state.bubbleWidthRatio, bubbleState.level?.state.bubbleWidthRatio)

        drawBubbleOnGrid(row, column, bubble)

        randomBubblePopNeutral().play()
      }
    }

    console.log('fired-bubble-collided-message', {
      point,
      row,
      column,
      skin
    })
  })

  room.onMessage<{
    won: boolean
    score: number,
    stars: number,
    bubblesLeft?: number
  }>('end-of-game', ({
    won,
    score,
    stars,
    bubblesLeft
  }) => {
    if (won && bubblesLeft !== undefined && bubblesLeft > 0) {
      endLevel(bubblesLeft, () => {
        if (bubbleState.external.refreshTokens) {
          bubbleState.external.refreshTokens().then(tokens => setWorldScreenCoinCount(tokens.yaah ?? 0))
        }

        if (!bubbleState.level) return

        if (bubbleState.level.onLevelCompleted) {
          executeLevelComplete(bubbleState.level.onLevelCompleted, stars)
        }

        showScoreMenu(won, stars)
      })
    } else {
      showScoreMenu(won, stars)
    }

    console.log('end-of-game', {
      won,
      score,
      stars,
      bubblesLeft
    })
  })

  room.onMessage<{
    powerUp: PowerUpSchema
  }>('power-up-exploded', ({
    powerUp
  }) => {
    const index = bubbleState.references.powerUp?.findIndex((actualPowerUp) => actualPowerUp?.uid === powerUp.uid)

    if (!bubbleState.references.powerUp || index === undefined || index === -1) return

    const powerUpEntry = bubbleState.references.powerUp[index]

    if (powerUpEntry) {
      explodePowerUp(powerUpEntry.spine)

      // no need to delete, the model is already listening for delete on object schema
    }

    console.log('power-up-exploded', {
      index,
      powerUp
    })
  })

  room.onMessage<number>('timer', (timer) => {
    setTimer(timer)

    console.log('timer', timer)
  })
}

//export const onTileChange = (skin: BubbleSkinName, index: number) => {
//if (!bubbleState.level || !bubbleState.references.tiles || bubbleState.level.state.bubbleWidthRatio === undefined) return

//const coord2D = tiles1Dto2D(index)

//if (coord2D) {
//  const {row, column} = coord2D

//  let bubble = bubbleState.references.tiles[row][column]?.children[0] as (Spine | undefined)

//  if (!bubble && skin !== BubbleSkinName.Empty) {
//    bubble = initBubble(skin)!

//    bubble.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)

//    drawBubbleOnGrid(row, column, bubble)
//  } else if (bubble && skin === BubbleSkinName.Empty) {
//    destroyBubble(bubble, undefined, true)
//  } else if (bubble) {
//    bubble.skeleton.setSkinByName(skin)
//  }

//  console.log('Tile', index2DFrom1D(index, bubbleState.level.numberOfBubblePerRow), `changed to ${skin}`)
//}
//}

export const onJoinError = (err: any) => {
  if (bubbleState.external.showLoading) {
    bubbleState.external.showLoading(false)
  }

  if (bubbleState.external.showModal) {
    bubbleState.external.showModal({
      title: 'Impossible to connect to the game',
      text: err.message ? `(code ${err.code}) ${err.message}` : 'You request has been refused, please try to reconnect'
    })
  }

  console.error(err)
}

export const onLeave = (code: any) => {
  if (code !== 1000 && bubbleState.external.showModal) {
    bubbleState.room = undefined

    bubbleState.external.showModal({
      title: 'Disconnected',
      text: 'You have been disconnected from the Bubble game server'
    })

    initWorldScreen()
  }
}

export const exitRoom = async () => {
  if (!bubbleState.room) return

  try {
    await bubbleState.room.leave(true)
  } catch (err) {
    console.error(err)
  }

  bubbleState.room = undefined
}