import React, {HtmlHTMLAttributes} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './abc.module.scss'

export const ABCTokenA = ({className, ...props}: HtmlHTMLAttributes<HTMLOrSVGElement>) =>
  <svg className={formatClassName(styles, `svg-token ${className ?? ''}`)} {...props} width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3929 0.999999C12.1627 -0.333335 14.0872 -0.333333 14.857 1L25.2493 19C26.0191 20.3333 25.0569 22 23.5173 22H2.73265C1.19305 22 0.230805 20.3333 1.00061 19L11.3929 0.999999Z" />
  </svg>

export const ABCTokenB = ({className, ...props}: HtmlHTMLAttributes<HTMLOrSVGElement>) =>
  <svg className={formatClassName(styles, `svg-token ${className ?? ''}`)} {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 21 24">
    <path d="M20.9,6.4V5.6C20.9,2.5,18,0,14.5,0H8H1.9C1.1,0,0.5,0.5,0.5,1.2v21.6c0,0.7,0.6,1.2,1.4,1.2H8h6.5
	c3.6,0,6.5-2.5,6.5-5.6v-0.8c0-3.1-2.9-5.6-6.5-5.6C18,12,20.9,9.5,20.9,6.4z"/>
  </svg>

export const ABCTokenC = ({className, ...props}: HtmlHTMLAttributes<HTMLOrSVGElement>) =>
  <svg className={formatClassName(styles, `svg-token ${className ?? ''}`)} {...props} width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1665 4.92894C19.9476 4.14789 19.9553 2.86524 19.0554 2.22459C17.5732 1.16923 15.8627 0.459535 14.0502 0.160291C11.5764 -0.24814 9.03675 0.128579 6.788 1.23754C4.53926 2.3465 2.69436 4.13199 1.51242 6.34325C0.330473 8.5545 -0.129138 11.0804 0.198133 13.5663C0.525404 16.0522 1.62312 18.3731 3.3371 20.2031C5.05108 22.0331 7.29524 23.2803 9.75438 23.7694C12.2135 24.2586 14.7641 23.9651 17.0479 22.9304C18.7212 22.1722 20.1897 21.044 21.3484 19.6409C22.0517 18.7892 21.7123 17.5523 20.7557 17L14.3527 13.3032C13.2138 12.6457 13.0086 11.0868 13.9385 10.157L19.1665 4.92894Z" />
  </svg>

export const ABCTokenRandom = ({className, ...props}: HtmlHTMLAttributes<HTMLOrSVGElement>) =>
  <svg className={formatClassName(styles, `svg-token ${className ?? ''}`)} {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.41192 8.85185C7.77782 9.48596 6.74973 9.48596 6.11562 8.85185L0.634142 3.37037C0.337846 3.04938 0.189697 2.66667 0.189697 2.22222C0.189697 1.77778 0.337846 1.39506 0.634142 1.07407C0.95513 0.777778 1.33785 0.629629 1.78229 0.629629C2.22673 0.629629 2.60945 0.777778 2.93044 1.07407L8.41192 6.55556C9.04602 7.18966 9.04602 8.21775 8.41192 8.85185ZM16.0045 24C15.5601 24 15.1774 23.8395 14.8564 23.5185C14.5354 23.1975 14.3749 22.8148 14.3749 22.3704C14.3749 21.9259 14.5354 21.5432 14.8564 21.2222C15.1774 20.9012 15.5601 20.7407 16.0045 20.7407C16.7535 20.7407 17.127 19.8337 16.5952 19.3063L14.6945 17.4211C14.0483 16.7802 14.0461 15.7362 14.6897 15.0926C15.3332 14.4491 16.3773 14.4512 17.0182 15.0974L18.9186 17.0136C19.4268 17.5261 20.3008 17.1662 20.3008 16.4444C20.3008 16 20.4613 15.6173 20.7823 15.2963C21.1033 14.9753 21.486 14.8148 21.9304 14.8148C22.3749 14.8148 22.7576 14.9753 23.0786 15.2963C23.3996 15.6173 23.5601 16 23.5601 16.4444V22.2963C23.5601 22.7654 23.3936 23.1669 23.0608 23.5007C22.727 23.8336 22.3255 24 21.8564 24H16.0045ZM0.634142 22.9259C0.337846 22.6049 0.189697 22.2222 0.189697 21.7778C0.189697 21.3333 0.337846 20.9506 0.634142 20.6296L16.5996 4.69813C17.1312 4.16764 16.7555 3.25926 16.0045 3.25926C15.5601 3.25926 15.1774 3.09877 14.8564 2.77778C14.5354 2.45679 14.3749 2.07407 14.3749 1.62963C14.3749 1.18518 14.5354 0.802469 14.8564 0.481481C15.1774 0.160494 15.5601 0 16.0045 0H21.8564C22.3255 0 22.727 0.16642 23.0608 0.499259C23.3936 0.833086 23.5601 1.23457 23.5601 1.7037V7.55556C23.5601 8 23.3996 8.38272 23.0786 8.7037C22.7576 9.02469 22.3749 9.18519 21.9304 9.18519C21.486 9.18519 21.1033 9.02469 20.7823 8.7037C20.4613 8.38272 20.3008 8 20.3008 7.55556C20.3008 6.80454 19.3924 6.42884 18.8619 6.96045L2.93044 22.9259C2.60945 23.2222 2.22673 23.3704 1.78229 23.3704C1.33785 23.3704 0.95513 23.2222 0.634142 22.9259Z" />
  </svg>
