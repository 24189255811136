@import "scss-variables";

.chat {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 4rem;
    padding: 0 1rem;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 1rem;
    }

    .connected {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      gap: 0.5rem;
      color: #2ed573;
      font-size: 14px;
      font-weight: 600;
      height: 100%;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        height: 6px;
        border-radius: 6px;

        background: #69cf55;
        box-shadow: 0px -2px 17px 4px rgba(73, 206, 102, 0.6), 0px -4px 35px 11px rgba(60, 211, 110, 0.3);
      }
    }

    .close {
      justify-content: end;
      font-size: 1rem;

      svg {
        cursor: pointer;
      }
    }
  }

  .main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    gap: 1rem;
    min-width: 306px;

    .messages {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 1rem;
      gap: 0.5rem;
      overflow: auto;
      height: 100px;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .message {
        position: relative;
        display: flex;
        transform: translateZ(0);

        &.tip {
          .details {
            background: linear-gradient(260deg, #ffd83499 0%, #343434 100%);
          }
        }

        img {
          height: 10px;
        }

        .profile {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .reply-to {
          background-color: $color-lighten-gray;
          position: absolute;
          left: 0;
          right: 0.5rem;
          top: 0;
          z-index: 0;
          height: 3rem;
          border-radius: 0.5rem;
          display: flex;
          font-size: 1rem;
          /* align-items: center; */
          padding: 0 0.5rem;
          gap: 0.5rem;
          font-size: 14px;

          .text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          svg {
            padding-top: 0.25rem;
            width: 0.5rem;
          }
        }

        .details {
          display: flex;
          flex: 1;
          gap: 0.5rem;
          z-index: 1;
          border-radius: 0.5rem;
          background-color: $color-light-gray;
          padding: 0.5rem;

          &.space-top {
            margin-top: 1.2rem;
          }

          .pfp {
            width: 2.5rem;
            height: 2.5rem;

            img {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 2.5rem;
              box-sizing: border-box;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .content {
            display: flex;
            flex-direction: column;
            flex: 1;

            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 1rem;
              font-weight: 700;

              .tools {
                display: flex;
                gap: 0.4rem;
                font-size: 12px;

                .tool {
                  cursor: pointer;
                  opacity: 0.7;

                  &:hover {
                    opacity: 1;
                  }
                }
              }

              .username {
                display: flex;
                align-items: center;
                gap: 0.3rem;
                color: rgba($text-color, 0.7);

                .role {
                  padding: 0 0.3rem;
                  border-radius: 0.5rem;
                  color: white;
                  font-size: 11px;

                  &.administrator {
                    background-color: rgba($color-warning, 0.8);
                  }

                  &.moderator {
                    background-color: rgba($color-success, 0.8);
                  }

                  &.streamer {
                    background-color: rgba($color-gray, 0.8);
                  }
                }
              }
            }

            .text {
              font-size: 1rem;
              font-weight: 600;
              word-break: break-word;
            }
          }
        }
      }
    }

    .actions {
      position: relative;
      display: flex;
      padding: 0 1rem 1rem 1rem;
      align-items: center;

      .connect {
        width: 100%;
      }

      .reply-to {
        background-color: $color-lighten-gray;
        position: absolute;
        top: -1.5rem;
        left: 1rem;
        right: 1.5rem;
        z-index: 0;
        height: 3rem;
        border-radius: 0.5rem;
        display: flex;
        font-size: 1rem;
        /* align-items: center; */
        padding: 0 0.5rem;
        justify-content: space-between;

        svg {
          padding-top: 0.25rem;
          cursor: pointer;
        }
      }

      form {
        flex: 1;
        z-index: 1;

        button {
          position: absolute;
          top: 0.5rem;
          bottom: 0;
          height: calc(100% - 2rem);
          right: 1.5rem;
          padding: 0 0.5rem;
          font-size: 0.8rem;
        }

        textarea {
          flex: 1;
          padding-right: 2.5rem;
          width: 100%;
          height: 2.5rem;
          overflow: hidden;
        }
      }
    }
  }
}
