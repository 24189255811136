@import "scss-variables";

.rewards {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  max-width: 1200px;

  opacity: 0;

  animation: appear 1s ease forwards;

  .deposit-offer {
    display: flex;
    background-image: url(https://cdn.piratesquadnft.com/games/baddie-beast/marketing/bsl.jpg);
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-direction: column;
    font-family: "Baloo Thambi";

    @media screen and (min-width: $sm-breakpoint) {
      flex-direction: row;
    }

    button {
      background-color: #e5a413;

      &:hover {
        background-color: #c78c0c;
      }
    }

    .info {
      position: relative;
      padding-right: 2rem;
      padding: 1rem;
      gap: 1rem;
      font-size: 1rem;
      flex: 1;
      align-items: center;
      text-align: center;

      @media screen and (min-width: $sm-breakpoint) {
        text-align: left;
      }

      .name {
        position: relative;
        z-index: 1;
        font-weight: bold;
        font-size: 1rem;
        text-shadow: #000c 0px 0px 3px;

        @media screen and (min-width: $xs-breakpoint) {
          font-size: 1.25rem;
        }

        img {
          height: 1rem;
        }
      }

      .description {
        text-shadow: #000c 0px 0px 3px;

        img {
          width: 10px;
          height: 10px;
        }

        a {
          color: $text-color;
          padding-left: 1rem;
        }
      }

      .details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-top: 1rem;

        button {
          height: auto;
          padding: 0.25rem 0.5rem;
          background-color: $color-light-gray;
          gap: 0.5rem;

          svg {
            height: 0.8rem;
          }

          &:hover {
            background-color: $color-gray;
          }
        }

        .detail {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          //background: $color-light-gray;
          border-radius: 0.5rem;
          gap: 0.25rem;
          white-space: nowrap;
          font-size: 0.75rem;
        }
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      width: auto;
      font-size: 1rem;

      > div {
        font-size: 1rem;
      }
    }
  }

  .repetitive-quests {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 1rem 0;

    @media screen and (min-width: $sm-breakpoint) {
      grid-template-columns: 1fr;
    }

    img {
      height: 10px;
    }

    .repetitive-quest {
      display: flex;
      background-color: $color-gray;
      border-radius: 0.5rem;
      overflow: hidden;
      flex-direction: column;

      @media screen and (min-width: $sm-breakpoint) {
        flex-direction: row;
      }

      .info {
        position: relative;
        padding-right: 2rem;
        padding: 1rem;
        background-color: $color-lighter-gray;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 0.5rem;
        font-size: 1rem;

        .name {
          position: relative;
          z-index: 1;
          font-weight: bold;
          font-size: 1rem;

          @media screen and (min-width: $xs-breakpoint) {
            font-size: 1.25rem;
          }
        }

        .description {
          .tickets {
            opacity: 0.8;
            font-size: 0.85rem;

            a {
              color: $text-color;
            }
          }
        }

        .details {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.5rem;

          button {
            height: auto;
            padding: 0.25rem 0.5rem;
            background-color: $color-light-gray;
            gap: 0.5rem;

            svg {
              height: 0.8rem;
            }

            &:hover {
              background-color: $color-gray;
            }
          }

          .detail {
            display: flex;
            align-items: center;
            padding: 0.25rem 0.5rem;
            //background: $color-light-gray;
            border-radius: 0.5rem;
            gap: 0.25rem;
            white-space: nowrap;
            font-size: 0.75rem;
          }
        }
      }

      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        width: auto;
        font-size: 1rem;
      }
    }
  }

  .progress-reward {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem 1.5rem 1rem;
    background-color: $color-gray;
    border-radius: 0.5rem;
    overflow: hidden;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        font-size: 1rem;
        font-weight: bold;

        @media screen and (min-width: $xs-breakpoint) {
          font-size: 1.25rem;
        }
      }

      button {
        height: auto;
        padding: 0 0.5rem;
      }
    }

    .progress {
      display: flex;
      font-size: 1rem;
      gap: 1rem;
      align-items: center;
      padding-top: 0.5rem;

      img,
      svg {
        height: 10px;
        margin-right: 0.2rem;
      }

      .bar-container {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        height: 1rem;
        background-color: $color-dark-gray;
        border-radius: 1rem;
        padding: 0 0.2rem;

        .actual-value,
        .next-value {
          position: absolute;
          text-align: center;
          top: -1.3rem;
          font-size: 0.8rem;

          img,
          svg {
            height: 8px;
          }

          @media screen and (min-width: $xs-breakpoint) {
            font-size: 1rem;

            img,
            svg {
              height: 10px;
            }
          }
        }

        .next-value {
          right: 0;
        }

        .top-info {
          position: absolute;
          top: -1.2rem;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 0.8rem;
          white-space: nowrap;

          img,
          svg {
            height: 8px;
          }
        }

        .bar {
          position: relative;
          height: 0.6rem;
          border-radius: 0.6rem;
          width: 0;

          transition: all 500ms;

          .bg-container {
            width: 100%;
            height: 100%;
            border-radius: 0.6rem;
            overflow: hidden;

            .bg {
              width: calc(100% + 30px);
              height: 100%;
              background-image: linear-gradient(
                45deg,
                #b2a2cd 25%,
                #5f5370 25%,
                #5f5370 50%,
                #b2a2cd 50%,
                #b2a2cd 75%,
                #5f5370 75%,
                #5f5370 100%
              );
              background-size: 28.28px 28.28px;

              animation-name: move-bg;
              animation-duration: 1.2s;
              animation-timing-function: linear;
              animation-iteration-count: infinite;
            }
          }

          .bottom-info {
            position: absolute;
            bottom: -1.4rem;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 0.8rem;
            white-space: nowrap;

            img,
            svg {
              height: 8px;
            }
          }
        }
      }
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-bg {
  from {
    transform: translateX(-28.28px);
  }
  to {
    transform: translateX(0);
  }
}
