import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {createTransferInstruction, TOKEN_PROGRAM_ID} from '@solana/spl-token'
import {useConnection, useWallet, WalletNotSelectedError} from '@solana/wallet-adapter-react'
import {LAMPORTS_PER_SOL, PublicKey, TokenAmount, TransactionMessage, VersionedTransaction} from '@solana/web3.js'
import axios from 'axios'
import {Decimal} from 'decimal.js'
import React, {ChangeEventHandler, HtmlHTMLAttributes, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {HUB_CDN_URL} from '../../../../../utils/constants'
import {formatClassName} from '../../../../../utils/global'
import {useDebounce} from '../../../../../utils/hooks'
import {useHub} from '../../../../hooks/use-hub'
import {useHubContext} from '../../../../state/context'
import {hubState} from '../../../../state/hub'
import {Alert} from '../../../alert/alert'
import {Button} from '../../../buttons/button'
import {Input} from '../../../input/input'
import {getTokenAccountInfo, PIRATE_ATA_USDC_ADDRESS, USDC_MINT_ADDRESS, walletAvailableTokens} from '../utils'
import styles from './deposit.module.scss'

export type DepositProps = HtmlHTMLAttributes<HTMLDivElement>

export const Deposit = ({className, ...props}: DepositProps) => {
  const {disconnect} = useHub()
  const {connection} = useConnection()
  const {state: {publicKey}} = useHubContext()
  const {sendTransaction} = useWallet()

  const navigate = useNavigate()

  const [isDepositing, setIsDepositing] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [fromAccountMissing, setFromAccountMissing] = useState(false)
  const [fromTokenAccount, setFromTokenAccount] = useState<PublicKey>()
  const [fromToken, setFromToken] = useState<keyof typeof walletAvailableTokens>('SOL')
  const [fromAmount, setFromAmount] = useState('')
  const [availableFromToken, setAvailableFromToken] = useState<TokenAmount>()

  const [toAmount, setToAmount] = useState(0)

  const debouncedFromAmount = useDebounce(fromAmount, 500)

  const fromAmountChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFromAmount(event.target.value)

    if (fromToken === 'USDC') {
      setToAmount(Math.floor(Number(event.target.value) * 100) / 100)
    }
  }

  useEffect(() => {
    (async () => {
      if (!publicKey) return

      if (fromToken === 'SOL') {
        const balance = await connection.getBalance(new PublicKey(publicKey))

        setFromAccountMissing(false)
        setAvailableFromToken({
          amount: String(Math.max(balance - 5000000, 0)),
          decimals: 9,
          uiAmount: Math.max(balance - 5000000, 0) / LAMPORTS_PER_SOL
        })
        setFromTokenAccount(new PublicKey(publicKey))
      } else {
        const result = await getTokenAccountInfo(connection, new PublicKey(publicKey), walletAvailableTokens[fromToken].mint)

        if (!result) {
          setFromAccountMissing(true)
        } else {
          setFromAccountMissing(false)
          setAvailableFromToken(result.tokenAmount)
          setFromTokenAccount(result.tokenAssociatedAccount)
        }
      }
    })()
  }, [connection, publicKey, fromToken])

  useEffect(() => {
    if (!debouncedFromAmount || isNaN(Number(debouncedFromAmount)) || Number(debouncedFromAmount) === 0 || !availableFromToken) {
      setToAmount(0)

      return
    }

    if (fromToken === 'USDC') {
      return
    }

    (async () => {
      setIsRefreshing(true)

      try {
        const {data} = await (
          await axios.get('https://quote-api.jup.ag/v4/quote', {
            params: {
              inputMint: walletAvailableTokens[fromToken].mint.toString(),
              outputMint: USDC_MINT_ADDRESS.toString(),
              amount: new Decimal(debouncedFromAmount).mul(Math.pow(10, availableFromToken.decimals)).toNumber(),
              slippageBps: 10
            }
          })
        ).data

        const routes = data

        setToAmount(Math.floor(routes[0].outAmount / Math.pow(10, 6) * 100) / 100)
      } catch (err) {
        setToAmount(0)

        toast.error('Impossible to find a route, please try refreshing your page or contact the support team')

        console.error(err)
      }

      setIsRefreshing(false)
    })()
  }, [debouncedFromAmount, availableFromToken, fromToken])

  const changeFromToken = (token: keyof typeof walletAvailableTokens) => {
    setFromToken(token)
    setFromAmount('')
    setToAmount(0)
  }

  const deposit = async () => {
    if (!publicKey) {
      toast.warning('Please reconnect')

      return
    }


    if (!toAmount || Number(toAmount) < 1 || !availableFromToken) {
      toast.warning('Minimum deposit is 1 USDC')

      return
    }

    if (!fromTokenAccount) {
      toast.warning(`No ${fromToken} detected on your wallet`)

      return
    }

    if (!publicKey || !sendTransaction) {
      toast.warning('Please try again later or reload the page')

      return
    }

    try {
      setIsDepositing(true)

      let USDCAmountToSend = Number(toAmount) * Math.pow(10, 6)

      if (fromToken !== 'USDC') {
        if (!publicKey) return

        const {data} = await (
          await axios.get('https://quote-api.jup.ag/v4/quote', {
            params: {
              inputMint: walletAvailableTokens[fromToken].mint.toString(),
              outputMint: USDC_MINT_ADDRESS.toString(),
              amount: new Decimal(fromAmount).mul(Math.pow(10, availableFromToken.decimals)).toNumber(),
              slippageBps: 10
            }
          })
        ).data

        const route = data[0]

        const transactions = await (
          await axios.post('https://quote-api.jup.ag/v4/swap', {
            route,
            userPublicKey: publicKey
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        ).data

        const {swapTransaction} = transactions

        // deserialize the transaction
        const swapTransactionBuf = Buffer.from(swapTransaction, 'base64')
        const transaction = VersionedTransaction.deserialize(swapTransactionBuf)

        //// get address lookup table accounts
        //const addressLookupTableAccounts = await Promise.all(
        //  transaction.message.addressTableLookups.map(async (lookup) => {
        //    return new AddressLookupTableAccount({
        //      key: lookup.accountKey,
        //      state: AddressLookupTableAccount.deserialize(await connection.getAccountInfo(lookup.accountKey).then((res) => res?.data as any)),
        //    })
        //  })
        //)

        //// decompile transaction message and add transfer instruction
        //const message = TransactionMessage.decompile(transaction.message, {addressLookupTableAccounts: addressLookupTableAccounts})

        //message.instructions.push(
        //  createTransferInstruction(
        //    fromTokenAccount,
        //    new PublicKey(PIRATE_ATA_USDC_ADDRESS),
        //    new PublicKey(publicKey),
        //    // USDC decimals
        //    route.outAmount,
        //    [],
        //    TOKEN_PROGRAM_ID
        //  )
        //)

        //transaction.message = message.compileToV0Message(addressLookupTableAccounts)

        // sign the transaction
        const signature = await sendTransaction(transaction, connection, {
          skipPreflight: false
        })

        try {
          const latestBlockhash = await connection.getLatestBlockhash()

          await connection.confirmTransaction({
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
            signature
          })

          const result = await getTokenAccountInfo(connection, new PublicKey(publicKey), walletAvailableTokens.USDC.mint)

          if (result) {
            USDCAmountToSend = Math.floor((result.tokenAmount < route.outAmount ? result.tokenAmount : route.outAmount) * 100) / 100
          }
        } catch (err) {
          toast.error('Error while depositing, please try again')

          console.error(err)
        }

        console.log(`https://solscan.io/tx/${signature}`)
      }


      let effectiveTokenAccount = fromTokenAccount

      if (fromToken === 'SOL') {
        const result = await getTokenAccountInfo(connection, new PublicKey(publicKey), walletAvailableTokens.USDC.mint)

        if (result) {
          effectiveTokenAccount = result.tokenAssociatedAccount
        }
      }

      const instructions = [
        createTransferInstruction(
          effectiveTokenAccount,
          new PublicKey(PIRATE_ATA_USDC_ADDRESS),
          new PublicKey(publicKey),
          BigInt(USDCAmountToSend),
          [],
          TOKEN_PROGRAM_ID
        )
      ]

      // Get the lates block hash to use on our transaction and confirmation
      const latestBlockhash = await connection.getLatestBlockhash()

      // Create a new TransactionMessage with version and compile it to legacy
      const messageLegacy = new TransactionMessage({
        payerKey: new PublicKey(publicKey),
        recentBlockhash: latestBlockhash.blockhash,
        instructions,
      }).compileToLegacyMessage()

      // Create a new VersionedTransacction which supports legacy and v0
      const transaction = new VersionedTransaction(messageLegacy)

      // Send transaction and await for signature
      const signature = await sendTransaction(transaction, connection, {
        skipPreflight: false
      })

      toast.info('Waiting for deposit confirmation...')

      await connection.confirmTransaction({
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
        signature,
      })

      setAvailableFromToken(undefined)

      getTokenAccountInfo(connection, new PublicKey(publicKey), USDC_MINT_ADDRESS).then(result => {
        if (!result) {
          setFromAccountMissing(true)
        } else {
          setFromAccountMissing(false)
          setAvailableFromToken(result.tokenAmount)
          setFromTokenAccount(result.tokenAssociatedAccount)
        }
      })

      fromAmountChange({target: {value: 0}} as any)

      toast.info('Transaction sent, your $YOOH will be credited within the next 30 seconds')
    } catch (e) {
      console.error(e)

      if (e instanceof WalletNotSelectedError && hubState.showConfirm) {
        hubState.showConfirm({
          title: 'Error',
          text: 'You switched wallet or close phantom app, please reconnect your wallet to deposit',
          acceptText: 'reconnect',
          onAccept: disconnect
        })
      } else {
        if (hubState.showModal) {
          hubState.showModal({
            title: 'Error',
            text: typeof e === 'string' ? e : JSON.stringify(e)
          })
        }
      }

      toast.error('Transaction failed. If you switched wallet, please disconnect and connect again')
    }

    setIsDepositing(false)
  }

  return <div className={formatClassName(styles, `deposit ${className}`)} {...props}>
    <div className={formatClassName(styles, 'content')}>
      <Alert type='warning' show={fromAccountMissing}>{fromToken} account not detected on your wallet, please transfer some {fromToken} to use the deposit tool</Alert>
      {Date.now() < 1682892000000 &&
        <div className={formatClassName(styles, 'special-offer')}>
          <div className={formatClassName(styles, 'title')}>
            🔥 Deposit Rewards:
          </div>
          <div className={formatClassName(styles, 'text')}>
            win free credits <a href="#" onClick={() => navigate('/dashboard/rewards')}>more info</a>
          </div>
        </div>
      }
      <div className={formatClassName(styles, 'form')}>
        <div className={formatClassName(styles, 'input-icon')} >
          <div className={formatClassName(styles, 'left')}>
            <FontAwesomeIcon icon="up-down" className={formatClassName(styles, 'selector')} />
            {
              isRefreshing
                ? <FontAwesomeIcon spin icon="circle-notch" />
                : <img className={formatClassName(styles, 'clickable')} src={`${HUB_CDN_URL}/tokens/${fromToken}.png`} alt={fromToken} onClick={() => changeFromToken(fromToken === 'SOL' ? 'USDC' : 'SOL')} />
            }
          </div>
          <Input
            type="text"
            placeholder={`${fromToken} amount`}
            value={fromAmount ?? ''}
            onChange={fromAmountChange}
            maxLength={8}
            disabled={fromAccountMissing}
          />
          <Button loading={availableFromToken === undefined} className='no-color' onClick={() => fromAmountChange({target: {value: availableFromToken?.uiAmount ?? 0}} as any)} disabled={fromAccountMissing}>max</Button>
        </div>
        <div className={formatClassName(styles, 'input-icon to-amount')} >
          <div className={formatClassName(styles, 'left')}>
            <YOOH />
          </div>
          <Input
            type="text"
            readOnly
            placeholder='0'
            value={toAmount ?? '0'}
            maxLength={8}
          />
        </div>
      </div>
      <Alert type='info' show={true}>1 USDC = 1 $YOOH. You need to wager 1x your deposits to be able to withdraw.</Alert>
    </div>
    <Button loading={isDepositing} onClick={deposit}>deposit</Button>
  </div>
}