import {sound} from '@pixi/sound'
import {CDN_URL} from '../../../../utils/constants'
import {SoundName} from './enums'
import {bombzState} from './state'

//export const playCombo = (soundName: SoundName) => {
//  Object.entries(SoundName).filter((entry) => entry[0].includes('combo') && entry[0] !== soundName).forEach((entry) => {
//    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
//  })

//  const currentCombo = sound.find(soundName)

//  if (!currentCombo.isPlaying && currentCombo.isLoaded) currentCombo.play()
//}

export const randomBubblePopNeutral = () => {
  const choices = [
    SoundName.bubblePopNeutral1,
    SoundName.bubblePopNeutral2,
    SoundName.bubblePopNeutral3,
    SoundName.bubblePopNeutral4,
  ]

  return sound.find(choices[Math.floor(Math.random() * choices.length)])
}

export const loadSounds = () => new Promise<void>((resolve) => {
  const nbSounds = Object.keys(SoundName).length
  let soundLoaded = 0

  const loaded = (error: Error) => {
    if (bombzState.exited) return

    if (error && !bombzState.exited && bombzState.external.clearGame) {
      bombzState.exited = true

      bombzState.external.clearGame()

      if (bombzState.external.showLoading) bombzState.external.showLoading(false)

      if (bombzState.external.showModal) bombzState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the sound resources, please check your internet connection and refresh the page'
      })
    }

    ++soundLoaded

    if (soundLoaded === nbSounds) {
      resolve()
    }
  }

  loadBubblePop(loaded)
  loadUI(loaded)
})

//const loadCombos = (loaded: (error: Error) => void) => {
//  sound.add(SoundName.combo1, {
//    preload: true,
//    url: CDN_URL + '/games/bubble/sounds/combos/combo-1.mp3',
//    volume: 0.4,
//    loaded
//  })

//  sound.add(SoundName.combo2, {
//    preload: true,
//    url: CDN_URL + '/games/bubble/sounds/combos/combo-2.mp3',
//    volume: 0.4,
//    loaded
//  })

//  sound.add(SoundName.combo3, {
//    preload: true,
//    url: CDN_URL + '/games/bubble/sounds/combos/combo-3.mp3',
//    volume: 0.4,
//    loaded
//  })
//}


const loadBubblePop = (loaded: (error: Error) => void) => {
  sound.add(SoundName.bubblePopNeutral1, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/bubbles/neutral-1.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral2, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/bubbles/neutral-2.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral3, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/bubbles/neutral-3.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.bubblePopNeutral4, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/bubbles/neutral-4.mp3',
    volume: 0.2,
    loaded
  })
}


const loadUI = (loaded: (error: Error) => void) => {
  sound.add(SoundName.clickAffirmative, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/ui/clicks/click-affirmative.mp3',
    volume: 0.15,
    loaded
  })

  sound.add(SoundName.clickNegative, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/ui/clicks/click-negative.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.clickCoin, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/ui/clicks/click-coin.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.clickBomb, {
    preload: true,
    url: CDN_URL + '/games/bubble/sounds/powers/bomb.mp3',
    volume: 0.2,
    loaded
  })
}