import {SkeletonData, Spine} from 'pixi-spine'
import {Point} from 'pixi.js'
import {BubbleCollideEffect, BubbleDestroyEffect, SoundName} from './enums'
import {destroyCluster} from './level'
import {playPower} from './sound'
import {fireParticles} from './special-effects'
import {bubbleState} from './state'
import {DestroyClusterData} from './types'
import {getRatio} from './utils'

const bomb = (point: Point) => {
  if (!bubbleState.viewport || !bubbleState.level?.state.bubbleWidth || !bubbleState.references.tiles || !bubbleState.resources?.explosion.spineData) return

  const explosion = new Spine(bubbleState.resources.explosion.spineData as SkeletonData)

  explosion.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'Explosion') {
        setTimeout(() => {
          // Catch potential errors and mute them (garbage collector can be executed before this)
          try {
            explosion.destroy()
            // eslint-disable-next-line no-empty
          } catch {}
        })
      }
    }
  })

  const ratio = (4 * bubbleState.level.state.bubbleWidth) / explosion.width

  explosion.scale.set(ratio, ratio)

  explosion.state.setAnimation(0, 'Explosion', false)

  const {x, y} = point

  explosion.x = x
  explosion.y = y

  bubbleState.viewport.addChild(explosion)

  //const cluster: Cluster = []

  //for (row = bubbleState.references.tiles.length - 1; row >= 0; row--) {
  //  for (column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
  //    const actualBubble = bubbleState.references.tiles[row][column]?.children[0] as Spine

  //    if (!actualBubble) continue

  //    const {x: x2, y: y2} = actualBubble.getGlobalPosition()

  //    if (distanceBetween(x, y, x2, y2) <= explosion.width) {
  //      cluster.push({
  //        row,
  //        column,
  //        bubble: actualBubble
  //      })
  //    }
  //  }
  //}

  //const result = destroyCluster(cluster, true, bubble, true, 1)

  //destroyBubble(bubble, undefined, false)

  playPower(SoundName.powerBomb)

  //result.destroyedBubbles.push({
  //  row: -1,
  //  column: -1,
  //  bubble
  //})
}

const fire = (point: Point) => {
  if (!bubbleState.viewport || !bubbleState.references.tiles || !bubbleState.level || !bubbleState.resources?.explosion.spineData || !bubbleState.particles.container) return {
    destroyedBubbles: [],
    maxExplosionDelay: 0
  }

  const gameWidthRatio = getRatio()

  const emitter = fireParticles(
    bubbleState.particles.container,
    point.x,
    point.y,
    gameWidthRatio,
    [bubbleState.resources.fireParticle, bubbleState.resources.circleParticle]
  )

  bubbleState.particles.emitters?.push(
    emitter
  )

  emitter.playOnceAndDestroy(() => {
    bubbleState.particles.emitters = bubbleState.particles.emitters?.filter(e => e !== emitter)
  })

  //const p1 = new Point(p.x, p.y + bubble.height)
  //const p2 = new Point(p.x - 100 * gameWidthRatio, p.y - 200 * gameWidthRatio)
  //const p3 = new Point(p.x + 100 * gameWidthRatio, p.y - 200 * gameWidthRatio)

  //const cluster: Cluster = []

  //for (let row = bubbleState.references.tiles.length - 1; row >= 0; row--) {
  //  for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
  //    const actualBubble = bubbleState.references.tiles[row][column]?.children[0] as Spine

  //    if (!actualBubble) continue

  //    const p = actualBubble.getGlobalPosition()

  //    if (pointIsInTriangle(p, p1, p2, p3)) {
  //      cluster.push({
  //        row,
  //        column,
  //        bubble: actualBubble
  //      })
  //    }
  //  }
  //}

  //const result = destroyCluster(cluster, true, bubble, true)

  //destroyBubble(bubble, undefined, true)

  playPower(SoundName.powerFire)

  //result.destroyedBubbles.push({
  //  row: -1,
  //  column: -1,
  //  bubble
  //})

  //return result
}

const hammer = (row: number, column: number): DestroyClusterData => {
  if (!bubbleState.viewport || !bubbleState.references.tiles || !bubbleState.resources?.explosion.spineData) return {
    destroyedBubbles: [],
    maxExplosionDelay: 0
  }

  let result: DestroyClusterData = {
    destroyedBubbles: [],
    maxExplosionDelay: 0
  }

  const collidedBubbleContainer = bubbleState.references.tiles[row][column]

  if (collidedBubbleContainer) {
    const collidedBubble = collidedBubbleContainer?.children[0] as Spine

    result = destroyCluster([{
      row,
      column,
      bubble: collidedBubble
    }], true, undefined, undefined, 0)
  }

  return result
}

export const bubbleDestroyEffects: {
  [key in BubbleDestroyEffect]?: (point: Point) => void
} = {
  Bomb: bomb,
  Fire: fire
}

export const bubbleCollideEffects: {
  [key in BubbleCollideEffect]?: (row: number, column: number) => DestroyClusterData
} = {
  Hammer: hammer
}