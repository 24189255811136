@import "scss-variables";

.wallet-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;

  .wallet {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;

    .menu {
      display: flex;
      gap: 1rem;
      max-width: 100%;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      button {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        text-transform: uppercase;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        position: relative;
        white-space: nowrap;

        &::before {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          right: 0;
          margin: auto;
          width: 50%;
          height: 4px;
          border-radius: 4px;
          background: $color-blue;
          transition: all 300ms;
          opacity: 0;
        }

        &.active {
          background-color: $color-light-gray;

          &::before {
            box-shadow: 0px -1px 10px 2px rgba($color-blue, 0.4), 0px -2px 20px 5px rgba($color-blue, 0.2);
            opacity: 1;
          }
        }
      }
    }

    .content {
      font-size: 1rem;
      flex: 1;
      overflow: hidden;
      position: relative;

      .consent {
        font-size: 1rem;
      }
    }
  }
}
