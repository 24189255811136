import React, {Fragment, HtmlHTMLAttributes, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {HUB_CDN_URL} from '../../../../../utils/constants'
import {formatClassName} from '../../../../../utils/global'
import {liquidityHistory, LiquidityHistoryResponseType} from '../../../../api/liquidity'
import {Button} from '../../../buttons/button'
import styles from './history.module.scss'

export type HistoryProps = HtmlHTMLAttributes<HTMLDivElement>

export const History = ({className, ...props}: HistoryProps) => {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [depositHistory, setDepositHistory] = useState<LiquidityHistoryResponseType['depositHistory']>([])
  const [withdrawHistory, setWithdrawHistory] = useState<LiquidityHistoryResponseType['withdrawHistory']>([])

  const refresh = async () => {
    setIsRefreshing(true)

    const response = await liquidityHistory()

    if (response?.status !== 200) {
      toast.error('Impossible to retrieve your history, please try again later')
    } else {
      setDepositHistory(response.data.depositHistory)
      setWithdrawHistory(response.data.withdrawHistory)
    }

    setIsRefreshing(false)
  }

  useEffect(() => {
    refresh()
  }, [])

  return <div className={formatClassName(styles, `history ${className}`)} {...props}>
    <div className={formatClassName(styles, 'content')}>
      <div className={formatClassName(styles, 'column')}>
        <div className={formatClassName(styles, 'header')}>deposits</div>
        <div className={formatClassName(styles, 'list')}>
          {depositHistory.map((history, index) => <Fragment key={`deposit-${index}`}>
            <div className={formatClassName(styles, 'amount')}>{history.amount} $YOOH</div>
            <div className={formatClassName(styles, 'date')}>{new Date(history.createdAt).toLocaleDateString()}</div>
            <div className={formatClassName(styles, 'actions')}>
              <a
                title='Solana FM'
                target="_blank"
                rel="noopener noreferrer"
                href={`https://solana.fm/tx/${history.signature}`}
              >
                <img src={`${HUB_CDN_URL}/icons/solana-fm.png`} />
              </a>
            </div>
          </Fragment>)}
        </div>
      </div>
      <div className={formatClassName(styles, 'column')}>
        <div className={formatClassName(styles, 'header')}>withdrawals</div>
        <div className={formatClassName(styles, 'list')}>
          {withdrawHistory.map((history, index) => <Fragment key={`withdraw-${index}`}>
            <div className={formatClassName(styles, 'amount')}>{history.amount} $YOOH</div>
            <div className={formatClassName(styles, 'date')}>{new Date(history.createdAt).toLocaleDateString()}</div>
            <div className={formatClassName(styles, 'actions')}>
              <a
                title='Solana FM'
                target="_blank"
                rel="noopener noreferrer"
                href={`https://solana.fm/tx/${history.signature}`}
              >
                <img src={`${HUB_CDN_URL}/icons/solana-fm.png`} />
              </a>
            </div>
          </Fragment>)}
        </div>
      </div>
    </div>
    <Button loading={isRefreshing} onClick={refresh}>refresh</Button>
  </div>
}