import {isAxiosError} from 'axios'
import React, {ChangeEventHandler, HtmlHTMLAttributes, ReactElement, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {YOOH} from '../../../../../svg/token'
import {formatClassName, formatTextWithYOOH} from '../../../../../utils/global'
import {activateReferralRequest, claimRackbackRequest, claimReferralFees, couponRequest, getReferralFollowers, saveReferralRequest} from '../../../../api/hub'
import {useHubContext} from '../../../../state/context'
import {hubState} from '../../../../state/hub'
import {Alert} from '../../../alert/alert'
import {Button} from '../../../buttons/button'
import {Input} from '../../../input/input'
import {PopoverHelp} from '../../../popover/popover'
import styles from './coupons.module.scss'
import {ReferralModal} from './referral-modal/referral-modal'

export type CouponsProps = HtmlHTMLAttributes<HTMLDivElement>

export const Coupons = ({className, ...props}: CouponsProps) => {
  const {state: {publicKey, referral: actualReferral, activatedReferral, referralToBeClaimed, rackbackAvailable, role}} = useHubContext()

  const [isChecking, setIsChecking] = useState(false)
  const [isChangingReferral, setIsChangingReferral] = useState(false)
  const [isChangingActivatedReferral, setIsChangingActivatedReferral] = useState(false)
  const [isClaiming, setIsClaiming] = useState(false)
  const [isClaimingRackback, setIsClaimingRackback] = useState(false)

  const [alerts, setAlerts] = useState<ReactElement[]>([])

  const [code, setCode] = useState('')
  const [referral, setReferral] = useState(actualReferral ?? '')
  const [referralToActivate, setReferralToActivate] = useState(activatedReferral ?? '')

  const [referralFollowers, setReferralFollowers] = useState<{
    username: string
    deposits?: number
  }[]>()
  const [showReferralFollowers, setShowReferralFollowers] = useState(false)

  useEffect(() => {
    if (!actualReferral) return

    refreshReferralFollowers()
  }, [actualReferral])

  const refreshReferralFollowers = async () => {
    setReferralFollowers(undefined)

    const response = await getReferralFollowers((err) => {
      if (isAxiosError(err)) {
        toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the referral active users, please contact the support team'))
      }
    })

    if (response?.status === 200) {
      setReferralFollowers(response.data.followers ?? [])
    }
  }

  const inputChange: (setter: React.Dispatch<React.SetStateAction<string>>) => ChangeEventHandler<HTMLInputElement> = (setter) => (event) => {
    setter(event.target.value)
  }

  const check = async () => {
    if (!publicKey || !code) {
      toast.error('Please enter a valid code')

      return
    }

    setIsChecking(true)

    const response = await couponRequest(code, (err) => {
      if (isAxiosError(err)) {
        setAlerts([
          ...alerts,
          <Alert show type="danger">
            {formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to check the coupon, please contact the support team')}
          </Alert>
        ])

        toast.error(err.response?.data?.message ? formatTextWithYOOH(err.response.data.message) : 'Impossible to check the coupon, please contact the support team')
      } else {
        console.log(err, isAxiosError(err))
        toast.error('Impossible to check the coupon, please contact the support team')
      }
    })

    if (response?.status === 200) {
      setAlerts([
        ...alerts,
        <Alert show type="success">{formatTextWithYOOH(response.data.message)}</Alert>
      ])

      toast.success(formatTextWithYOOH(response.data.message))
    }

    setIsChecking(false)
  }

  const onKeyDown: (callback: () => void) => React.KeyboardEventHandler<HTMLInputElement> = (callback) => (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      callback()
    }
  }

  const saveReferral = async () => {
    setIsChangingReferral(true)

    const response = await saveReferralRequest(referral, (err) => {
      if (isAxiosError(err)) {
        toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to save the referral, please contact the support team'))
      }
    })

    if (response?.status === 200) {
      toast.success('Referral saved!')
    }

    setIsChangingReferral(false)
  }

  const activateReferral = async () => {
    setIsChangingActivatedReferral(true)

    const response = await activateReferralRequest(referralToActivate, (err) => {
      if (isAxiosError(err)) {
        toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to save the referral, please contact the support team'))
      }
    })

    if (response?.status === 200) {
      toast.success('Referral activated!')
    }

    setIsChangingActivatedReferral(false)
  }

  const claim = async () => {
    setIsClaiming(true)

    if (role === 'streamer' && hubState.showModal) {
      hubState.showModal({
        title: 'Claiming as a streamer',
        text: 'To claim fees please send us a request (Discord or dm if you have a contact in the team) and we will send USDC directly to your wallet'
      })
    } else {
      const response = await claimReferralFees((err) => {
        if (isAxiosError(err)) {
          toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to claim, please contact the support team'))
        }
      })

      if (response?.status === 200) {
        toast.success(formatTextWithYOOH(response.data?.claimedAmount !== undefined ? `You claimed $YOOH ${response.data.claimedAmount / 100}!` : 'Fees claimed!'))
      }
    }

    setIsClaiming(false)
  }

  const claimRackback = async () => {
    setIsClaimingRackback(true)

    const response = await claimRackbackRequest((err) => {
      if (isAxiosError(err)) {
        toast.error(formatTextWithYOOH(err.response?.data?.message ? err.response.data.message : 'Impossible to claim, please contact the support team'))
      }
    })

    if (response?.status === 200) {
      toast.success(formatTextWithYOOH(response.data?.claimedAmount !== undefined ? `You claimed $YOOH ${response.data.claimedAmount / 100}!` : 'Rakeback claimed!'))
    }

    setIsClaimingRackback(false)
  }

  const toBeClaimed = String((referralToBeClaimed ?? 0) / 100)
  const toBeClaimedDecimals = toBeClaimed.split('.')[1]
  const toBeClaimedValue = toBeClaimed.split('.')[0]

  const rackback = String((rackbackAvailable ?? 0) / 100)
  const rackbackDecimals = rackback.split('.')[1]
  const rackbackValue = rackback.split('.')[0]

  return <div className={formatClassName(styles, `coupons ${className}`)} {...props}>
    <div className={formatClassName(styles, 'form')}>
      <div className={formatClassName(styles, 'name')}>Coupons</div>
      <div className={formatClassName(styles, 'entry')}>
        <Input
          type="text"
          placeholder='Enter coupon code here'
          value={code ?? ''}
          onChange={(e) => inputChange(setCode)(e)}
          maxLength={32}
          onKeyDown={(e) => onKeyDown(check)(e)}
        />
        <Button loading={isChecking} onClick={check}>submit</Button>
      </div>
      <div className={formatClassName(styles, 'name')}>
        Activate referral (5% rakeback for 48h)
        <PopoverHelp>
          <>
            By activating a referral code, you'll receive a rakeback of 5% of the fees collected by the house during 48h.
          </>
        </PopoverHelp>
      </div>
      <div className={formatClassName(styles, 'entry')}>
        <Input
          type="text"
          placeholder='Enter referral code here'
          value={referralToActivate ?? ''}
          onChange={(e) => inputChange(setReferralToActivate)(e)}
          maxLength={32}
          onKeyDown={(e) => onKeyDown(activateReferral)(e)}
        />
        <Button loading={isChangingActivatedReferral} onClick={activateReferral}>activate</Button>
      </div>
      <div className={formatClassName(styles, 'name')}>
        Create referral (earn 5% of house fees)
        <PopoverHelp>
          <>
            You can create your own referral code. It should be unique<br />
            You will win 5% of the fees collected by the house for each player with your code active.
          </>
        </PopoverHelp>
      </div>
      <div className={formatClassName(styles, 'entry')}>
        <Input
          type="text"
          placeholder='Create your own referral code to share with others'
          value={referral ?? ''}
          onChange={(e) => inputChange(setReferral)(e)}
          maxLength={32}
          onKeyDown={(e) => onKeyDown(saveReferral)(e)}
        />
        <Button loading={isChangingReferral} onClick={saveReferral}>create</Button>
      </div>
      {actualReferral &&
        <div className={formatClassName(styles, 'used-by')}>
          used by {referralFollowers ? referralFollowers.length : '...'} pirate{referralFollowers && referralFollowers.length > 1 ? 's' : ''} - <a href="#" onClick={() => setShowReferralFollowers(true)}>view list</a> - <a href="#" onClick={refreshReferralFollowers}>refresh</a>
        </div>
      }
      <div className={formatClassName(styles, 'claim-container')}>
        <div className={formatClassName(styles, 'claim')}>
          <div className={formatClassName(styles, 'value')}><YOOH /> {rackbackValue}{rackbackDecimals && <small>.{rackbackDecimals.substring(0, 4)}</small>}</div>
          <Button loading={isClaimingRackback} disabled={Number(rackbackValue) < 1} onClick={claimRackback}>claim rakeback</Button>
        </div>
        <div className={formatClassName(styles, 'claim')}>
          <div className={formatClassName(styles, 'value')}><YOOH /> {toBeClaimedValue}{toBeClaimedDecimals && <small>.{toBeClaimedDecimals.substring(0, 4)}</small>}</div>
          <Button loading={isClaiming} disabled={Number(toBeClaimedValue) < 1} onClick={claim}>claim referral fees</Button>
        </div>
      </div>
    </div>

    <ReferralModal show={showReferralFollowers} followers={referralFollowers} onHide={() => setShowReferralFollowers(false)} onClickBackground={() => setShowReferralFollowers(false)} />

    {
      alerts
    }
  </div>
}