import {Howl} from 'howler'
import {CDN_URL} from '../../../utils/constants'

export const clickAffirmativeSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-2.mp3`],
  volume: 0.15,
  preload: true
})

export const coinsSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-coin.mp3`],
  volume: 0.2,
  preload: true
})

export const selectSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-3.mp3`],
  volume: 0.2,
  preload: true
})

export const clickNegativeSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/clicks/click-negative.mp3`],
  volume: 0.2,
  preload: true
})

export const winSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/win_nomusic_nopirate.mp3`],
  volume: 0.25,
  preload: true
})

export const loseSoundBaddieBeast = new Howl({
  src: [`${CDN_URL}/games/bubble/sounds/ui/game/losing.mp3`],
  volume: 0.2,
  preload: true
})