import {sound} from '@pixi/sound'
import {ease} from 'pixi-ease'
import {Spine} from 'pixi-spine'
import * as PIXI from 'pixi.js'
import {Filter, Graphics, Text, TextMetrics, TextStyle} from 'pixi.js'
import {VERSION} from '../version'
import {PowerUpSkinName, SoundName} from './enums'
import {dropShadowBlack} from './filters'
import {createViewport} from './game'
import {explodePowerUp, initPowerUp} from './power-up'
import {exitRoom} from './server/server-handler'
import {playMusic, randomBubblePop} from './sound'
import {bubbleState} from './state'
import {getGameHeight, getGameWidth, radialGradient} from './utils'
import {initWorldScreen} from './worldscreen'

export const initHomeScreen = async () => {
  await exitRoom()

  createViewport()

  if (!bubbleState.viewport || !bubbleState.resources) return

  bubbleState.level = undefined

  initSounds()

  const bg = new Graphics()
    .beginFill(0x0065A2)
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  // Water effect
  const waterEffect = new PIXI.Sprite(bubbleState.resources.waterEffectHS)
  waterEffect.scale.set(0.55, 0.55)

  waterEffect.x = getGameWidth() / 4
  waterEffect.y = getGameHeight() - waterEffect.height * 2

  ease.add(waterEffect, {
    x: waterEffect.x + 5,
    alpha: 0.25,
  }, {
    repeat: -1,
    ease: 'easeInOutQuad',
    reverse: true,
    duration: 2000
  })

  // Ground part
  const ground = new PIXI.Sprite(bubbleState.resources.groundPartHS)

  const groundWidthRatio = getGameWidth() / ground.width
  ground.scale.set(groundWidthRatio, groundWidthRatio)
  ground.x = getGameWidth() / 2 - ground.width / 2
  ground.y = getGameHeight() - ground.height

  // Cannon
  const cannon = new PIXI.Sprite(bubbleState.resources.cannonHS)
  cannon.scale.set(0.4, 0.4)

  cannon.x = 0
  cannon.y = getGameHeight() - cannon.height

  // Bubble flying
  const bubbleFlying = new PIXI.Sprite(bubbleState.resources.bubbleFlyingHS)

  bubbleFlying.x = cannon.width * 1.4
  bubbleFlying.y = -cannon.height * 2

  bubbleFlying.alpha = 0.5

  cannon.addChild(bubbleFlying)

  // Pirate
  const pirate = new PIXI.Sprite(bubbleState.resources.pirateHS)
  pirate.scale.set(0.35, 0.35)

  pirate.x = getGameWidth() - pirate.width
  pirate.y = getGameHeight() - pirate.height

  // Ship
  const ship = new PIXI.Sprite(bubbleState.resources.shipHS)
  ship.scale.set(0.35, 0.35)

  ship.x = getGameWidth() - ship.width
  ship.y = getGameHeight() * 0.55 - ship.height

  ship.alpha = 0.8

  // Power up
  const heart = initPowerUp(PowerUpSkinName.Heart)
  const double = initPowerUp(PowerUpSkinName.Double)
  const coin = initPowerUp(PowerUpSkinName.Coin)
  const bomb = initPowerUp(PowerUpSkinName.Bomb)

  if (!heart || !double || !bomb || !coin) return

  const explodeOnTouch = (spine: Spine) => {
    spine.interactive = true

    spine.state.timeScale = Math.random() * 0.5 + 0.5

    spine.on('pointertap', () => {
      console.log('ok')
      explodePowerUp(spine, randomBubblePop())
    })

    spine.alpha = 0

    const scaleTmp = spine.scale.x
    spine.scale.x *= 0.1

    ease.add(spine, {
      alpha: 1,
      scale: scaleTmp
    }, {
      ease: 'easeOutQuad'
    })
  }

  heart.scale.set(0.25, 0.25)
  heart.x = heart.width * 0.75
  heart.y = getGameHeight() * 0.07

  explodeOnTouch(heart)

  double.scale.set(0.2, 0.2)
  double.x = getGameWidth() * 0.6
  double.y = getGameHeight() * 0.07

  explodeOnTouch(double)

  coin.scale.set(0.2, 0.2)
  coin.x = getGameWidth() * 0.3 + coin.width / 2
  coin.y = getGameHeight() * 0.07

  explodeOnTouch(coin)

  bomb.scale.set(0.25, 0.25)
  bomb.x = getGameWidth() - bomb.width * 0.75
  bomb.y = getGameHeight() * 0.07

  explodeOnTouch(bomb)

  // Play button
  const playRed = new PIXI.Sprite(bubbleState.resources.playRed)
  playRed.scale.set(0.4, 0.4)

  playRed.x = getGameWidth() / 2 - playRed.width / 2
  playRed.y = getGameHeight() / 2

  playRed.interactive = true
  playRed.cursor = 'pointer'

  playRed.on('pointertap', () => {
    if (!bubbleState.viewport) return

    sound.find(SoundName.mainMenuPlay).play()

    initWorldScreen()
  })

  playRed.filters = [dropShadowBlack() as unknown as Filter]

  ease.add(playRed, {
    alpha: 0.3
  }, {
    repeat: -1,
    ease: 'easeInOutQuad',
    reverse: true,
  })

  // Text pirate squad

  const style = new TextStyle({
    fontFamily: 'Riffic',
    fill: 0xFFFFFF,
    fontSize: 64,
    lineHeight: 64,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowColor: 0x333333,
    dropShadowAlpha: 0.70
  })

  const title = new Text('PIRATE\nSQUAD', style)

  const textMetrics = TextMetrics.measureText('PIRATE', style)

  title.x = getGameWidth() / 2 - textMetrics.width / 2
  title.y = playRed.y - playRed.height * 1.5
  title.alpha = 0

  ease.add(title, {
    y: title.y + title.height * 0.1,
    alpha: 1
  }, {
    ease: 'easeOutQuad',
  })

  // Text bubble
  const styleBubble = new TextStyle({
    fontFamily: 'Riffic',
    fill: 0xD02300,
    fontSize: 32
  })

  const bubbleText = new Text('BUBBLE', styleBubble)

  const textMetricsBubble = TextMetrics.measureText('BUBBLE', styleBubble)

  const rectangle = new Graphics()
    .beginFill(0xffffff)
    .drawRoundedRect(0, 0, title.width * 0.8, textMetricsBubble.height, 100)
    .endFill()

  rectangle.x = getGameWidth() / 2 - rectangle.width / 2
  rectangle.y = title.y + title.height * 1.1
  rectangle.alpha = 0

  bubbleText.x = rectangle.width / 2 - textMetricsBubble.width / 2

  rectangle.addChild(bubbleText)

  ease.add(rectangle, {
    alpha: 1
  }, {
    ease: 'easeOutQuad',
  })

  // Text version
  const styleVersion = new TextStyle({
    fontFamily: 'Riffic',
    fill: 0xFFFFFF,
    fontSize: 12
  })

  const versionText = new Text(`BETA v${VERSION}`, styleVersion)

  const textMetricsVersion = TextMetrics.measureText(versionText.text, styleVersion)

  versionText.x = getGameWidth() / 2 - textMetricsVersion.width / 2
  versionText.y = getGameHeight() - textMetricsVersion.height
  versionText.alpha = 0.75

  const background = new PIXI.Graphics()
    .beginTextureFill({
      texture: radialGradient(
        'rgba(255, 255, 255, 0.70)',
        'rgba(255, 255, 255, 0)',
        getGameWidth() * 0.5,
        getGameHeight() * 0.65,
        0,
        Math.max(getGameWidth(), getGameHeight()) * 0.85,
        getGameWidth(),
        getGameHeight())
    })
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  background.addChild(playRed, title, rectangle)

  bubbleState.viewport.addChild(
    bg,
    waterEffect,
    ground,
    cannon,
    pirate,
    ship,
    heart,
    double,
    coin,
    bomb,
    background,
    versionText
  )
}

const initSounds = () => {
  sound.stopAll()

  playMusic(SoundName.music5)
}
