@import "scss-variables";

.profile-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;

  .profile-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;
    font-size: 1rem;
    overflow: hidden;

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;
      width: 33%;
      height: 6px;
      border-radius: 6px;

      background: $color-blue;
      box-shadow: 0px -2px 8px 2px rgba($color-blue, 0.6), 0px -4px 17px 5px rgba($color-blue, 0.3);
    }

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0;
      font-size: 1.5rem;
      height: auto;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      .user {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-weight: bold;
        font-size: 1.25rem;

        .pfp {
          width: 5rem;
          height: 5rem;
          border-radius: 5rem;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      .tip {
        height: 2rem;
        font-family: "baloo thambi";
        font-weight: bold;
        background: linear-gradient(260.09deg, #ffd834 4.24%, #fff4b1 97.3%);
        color: #b94700;
        gap: 1rem;
        white-space: nowrap;

        &:hover {
          box-shadow: 0 0 0 1px #b94700;
          cursor: pointer;
        }

        img {
          height: 100%;
        }
      }
    }

    .stats-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;

      .grouped {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        border-radius: 0.5rem;

        &.unified-bg {
          background: $color-lighten-gray;
        }

        .stats {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 1rem;
          background: $color-lighten-gray;
          border-radius: 0.5rem;

          .name {
            opacity: 0.7;
            white-space: nowrap;
          }

          .value {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            font-size: 1.25rem;
            font-weight: bold;

            svg {
              font-size: 1rem;
            }

            img {
              height: 1rem;
            }
          }
        }
      }
    }

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: auto;
    }
  }
}
