import React, {useState} from 'react'
import {formatClassName} from '../../../utils/global'
import {useHubContext} from '../../state/context'
import {Button} from '../buttons/button'
import {OverlayModal, OverlayModalProps} from '../modal/modal'
import {Consent} from './consent/consent'
import {Coupons} from './content/coupons/coupons'
import {Deposit} from './content/deposit/deposit'
import {History} from './content/history/history'
import {Withdraw} from './content/withdraw/withdraw'
import styles from './wallet.module.scss'

export type WalletProps = OverlayModalProps & {
  onHide: () => void
}

export const Wallet = ({className, onHide, ...props}: WalletProps) => {
  const [activeTab, setActiveTab] = useState<'deposit' | 'withdraw' | 'coupons' | 'history'>('deposit')
  const {state: {consent}} = useHubContext()

  let content
  switch (activeTab) {
    case 'deposit':
      content = <Deposit />
      break
    case 'withdraw':
      content = <Withdraw />
      break
    case 'coupons':
      content = <Coupons />
      break
    case 'history':
      content = <History />
      break
  }

  return <OverlayModal className={formatClassName(styles, `wallet-overlay ${className}`)} {...props}>
    <div className={formatClassName(styles, 'wallet')}>
      <div className={formatClassName(styles, 'menu')}>
        <Button className={formatClassName(styles, `no-color ${activeTab === 'deposit' ? 'active' : ''}`)} onClick={() => setActiveTab('deposit')}>deposit</Button>
        <Button className={formatClassName(styles, `no-color ${activeTab === 'withdraw' ? 'active' : ''}`)} onClick={() => setActiveTab('withdraw')}>withdraw</Button>
        <Button className={formatClassName(styles, `no-color ${activeTab === 'coupons' ? 'active' : ''}`)} onClick={() => setActiveTab('coupons')}>coupons & referral</Button>
        <Button className={formatClassName(styles, `no-color ${activeTab === 'history' ? 'active' : ''}`)} onClick={() => setActiveTab('history')}>history</Button>
      </div>
      <div className={formatClassName(styles, 'content')}>
        {
          consent
            ? content
            : <Consent onRefuse={onHide} className={formatClassName(styles, 'no-blur consent')} show />
        }
      </div>
    </div>
  </OverlayModal>
}