@import "scss-variables";

.deposit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;

      @media screen and (min-width: $xs-breakpoint) {
        grid-template-columns: 1fr 1fr;
      }

      .input-icon {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;

        .clickable {
          cursor: pointer;
        }

        button {
          position: absolute;
          top: 0.5rem;
          bottom: 0;
          width: 3rem;
          height: calc(100% - 1rem);
          right: 0.5rem;
        }

        .left {
          position: absolute;
          left: 0.5rem;
          display: flex;
          align-items: center;
        }

        svg,
        img {
          width: 1rem;
          height: 1rem;

          &.selector {
            width: 0.4em;
            height: 0.75em;
            margin-right: 0.2em;
            opacity: 0.8;
          }
        }

        input {
          flex: 1;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          width: 100%;
        }

        &.to-amount {
          cursor: not-allowed;
          opacity: 0.5;

          input {
            pointer-events: none;
          }
        }
      }
    }

    .special-offer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1rem;
      background: #e9a200;
      color: white;
      padding: 0.5rem;
      border-radius: 0.5rem;
      font-family: "Baloo Thambi";

      animation: appear 1s ease forwards;

      .title {
        font-weight: 700;
      }

      .text {
        flex: 1;

        svg {
          opacity: 0.8;
        }

        a {
          color: white;
          padding-left: 1rem;
        }
      }

      .action {
        button {
          height: 2rem;

          background-color: #e5a413;

          &:hover {
            background-color: #c78c0c;
          }
        }
      }
    }
  }
}
