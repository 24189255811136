import React from 'react'
import {YOOH} from '../../../../../../svg/token'
import {formatClassName} from '../../../../../../utils/global'
import {OverlayModal, OverlayModalProps} from '../../../../modal/modal'
import styles from './referral-modal.module.scss'

export type ReferralModalProps = OverlayModalProps & {
  onHide: () => void
  followers?: {
    address: string
    username?: string
    deposits?: number
  }[]
}

export const ReferralModal = ({className, onHide, followers, ...props}: ReferralModalProps) => {
  return <OverlayModal className={formatClassName(styles, `referral-modal-overlay no-blur ${className}`)} {...props}>
    <div className={formatClassName(styles, 'referral-modal')}>
      <div className={formatClassName(styles, 'list')}>
        {
          (followers ?? []).map(follower => (
            <div>{[follower.username, follower.address].filter(element => !!element).join(' - ')} (deposits: <YOOH /> {(follower.deposits ?? 0) / 100})</div>
          ))
        }
      </div>
    </div>
  </OverlayModal>
}