import {ReelItem} from './types'

export const SLOTS_NUMBER_OF_LINES = 5

export const SLOTS_REELS_ITEMS: {
  [reel: string]: ReelItem[]
} = {
  reelA: [
    'bar', // 1
    'number7', 'number7', 'number7', // 3
    'apple', 'apple', 'apple', 'apple', 'apple', // 5
    'citrus', 'citrus', 'citrus', 'citrus', 'citrus', // 5
    'plum', 'plum', 'plum', 'plum', 'plum', // 5
    'cherry', 'cherry', 'cherry' // 3
  ], // 22
  reelB: [
    'bar', // 1
    'number7', // 1
    'apple', 'apple', 'apple', 'apple', 'apple', 'apple', // 6
    'citrus', 'citrus', 'citrus', 'citrus', 'citrus', 'citrus', // 6
    'plum', 'plum', 'plum', 'plum', 'plum', // 5
    'cherry', 'cherry', 'cherry' // 3
  ], // 22
  reelC: [
    'bar', // 1
    'number7', // 1
    'apple', 'apple', 'apple', 'apple', 'apple', 'apple', // 6
    'citrus', 'citrus', 'citrus', 'citrus', 'citrus', 'citrus', // 6
    'plum', 'plum', 'plum', 'plum', 'plum', // 5
    'cherry', 'cherry' // 2
  ], // 21
}