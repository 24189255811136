import axios from 'axios'
import {bubbleState} from '../state'
import {GameState} from '../types'
import {BUBBLE_CDN_URL} from '../utils/constants'

//export const saveLevelStatusRequest = async (level: number, status: LevelStatus) => {
//  if (!bubbleState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

//  const result = await bubbleState.external.tryOrReconnect<{levelStatuses: GameState['levelStatuses'] | undefined}>({
//    url: '/api/v1/game/bubble/level-status',
//    method: 'POST',
//    data: {
//      level,
//      status
//    }
//  })

//  return result?.data
//}

export const loadGameConfigRequest = async () => {
  let file = 'config.json'

  if (process.env.NODE_ENV === 'development') {
    file = 'config-test.json'
  }

  const response = await axios.get(`${BUBBLE_CDN_URL}/config/${file}`)

  if (response.status !== 200) return

  return response.data
}

export const loadGameStateRequest = async () => {
  if (!bubbleState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

  const result = await bubbleState.external.tryOrReconnect<{gameState: GameState | undefined}>({
    url: '/api/v1/game/bubble/game-state',
    method: 'GET'
  })

  return result?.data?.gameState
}

//export const changePirateCharacterRequest = async (pirateCharacter: PirateCharacter) => {
//  if (!bubbleState.external.tryOrReconnect) throw new Error('Provide a try or reconnect function')

//  const result = await bubbleState.external.tryOrReconnect<{pirateCharacter: GameState['pirateCharacter'] | undefined}>({
//    url: '/api/v1/game/bubble/pirate-character',
//    method: 'POST',
//    data: {
//      pirateCharacter
//    }
//  })

//  return result?.data
//}