@import "scss-variables";

.twitter-link-button {
  * {
    color: $color-twitter;
  }

  &:disabled {
    * {
      color: lighten($color: $color-twitter, $amount: 30);
    }
  }
}
