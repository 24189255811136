import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Decimal from 'decimal.js'
import React, {ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import Countdown, {calcTimeDelta} from 'react-countdown'
import {toast} from 'react-toastify'
import {YOOH} from '../../../svg/token'
import {CDN_URL} from '../../../utils/constants'
import {formatClassName} from '../../../utils/global'
import {Button, ButtonIcon} from '../../components/buttons/button'
import {ConnectButton} from '../../components/buttons/connect-button'
import {Input} from '../../components/input/input'
import {PopoverHelp} from '../../components/popover/popover'
import {Toggle} from '../../components/toggle/toggle'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import styles from './bombz.module.scss'
import {BOMBZ_NUMBER_OF_SLOTS} from './constants'
import {initBombzGame, setBombzMultiplierText} from './game'
import {useBombz} from './hook'
import {coinsSoundBombz, selectSoundBombz, selectSoundRepeat} from './sounds'
import {computeBombzMultiplier} from './utils'

const timeAgoRenderer = (createdAt: number) => {
  const {seconds, minutes, hours, days} = calcTimeDelta(createdAt, {overtime: true})

  return <span className={formatClassName(styles, 'countdown')}>
    {
      days > 0 || hours > 0
        ? 'hours ago'
        : minutes > 0
          ? `${minutes}m ago`
          : `${seconds}s ago`
    }
  </span>
}

export type BombzProps = HtmlHTMLAttributes<HTMLDivElement>

//export const BombzClientURL = 'ws://localhost:3022'
//export const BombzClientPath = undefined
//export const BombzClientURL = 'wss://test-server.piratesquadnft.com'
//export const BombzClientPath = '/bombz/socket.io'
//export const BombzClientURL = 'wss://s1.piratesquadnft.com'
//export const BombzClientPath = '/bombz/socket.io'
export const BombzClientURL = 'wss://s2.piratesquadnft.com'
export const BombzClientPath = '/bombz/socket.io'

export const Bombz = ({className, ...props}: BombzProps) => {
  const {dispatch, state: {tokens, sessionToken, userUuid}} = useHubContext()
  const {
    createGame,
    sendRevealCell,
    cashout,
    startProgram,
    exitProgram,
    resetProgram,
    stopProgram,
    setMode,
    setAutoMode,
    setInitialized,
    setBombs,
    setBet,
    setRepeat,
    setMaxProgramStep,
    isConnected,
    maxWin,
    runningGame,
    waitingForCashout,
    history,
    myHistory,
    creatingGame,
    confetti,
    mode,
    autoMode,
    botPlaying,
    bombs,
    bet,
    repeat,
    maxProgramStep,
    program,
    initialized
  } = useBombz()

  const [myGamesChecked, setMyGamesChecked] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const betChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number)) {
      setBet(value)
    } else {
      if (number < 0) {
        setBet('0')
      } else {
        setBet(value)
      }
    }
  }

  const bombsChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number) || value.length === 0) {
      setBombs('')
    } else {
      setBombs(String(Math.max(1, Math.min(number, BOMBZ_NUMBER_OF_SLOTS - 1))))
    }
  }

  const repeatChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number) || value.length === 0) {
      setRepeat('')
    } else {
      setRepeat(String(Math.max(number, -1)))
    }
  }

  const maxProgramStepChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number) || value.length === 0) {
      setMaxProgramStep('')
    } else {
      setMaxProgramStep(String(Math.min(Math.max(number, 1), 15)))
    }
  }

  const allIn = () => {
    if (tokens?.yooh === undefined) return

    coinsSoundBombz.play()

    betChange({target: {value: String(tokens.yooh / 100)}} as any)
  }

  useEffect(() => {
    if (!canvasRef.current) return

    initBombzGame(
      canvasRef.current,
      {
        sendRevealCell
      }
    ).then((clearGame) => {
      hubState.clearGame = () => {
        if (clearGame) clearGame()

        hubState.clearGame = undefined
      }

      setInitialized(true)
    }).catch(err => {
      toast.error('Error while creating the game viewer, please contact the support team')

      console.error(err)
    })

  }, [canvasRef])

  useEffect(() => {
    if (autoMode === 'program') {
      startProgram()
    } else {
      exitProgram()
    }
  }, [autoMode])

  useEffect(() => {
    if (mode === 'normal') {
      setAutoMode('random')
    }
  }, [mode])

  useEffect(() => {
    if (!initialized) return

    if (mode === 'auto') {
      if (autoMode === 'random' && Number(maxProgramStep) > 0) {
        setBombzMultiplierText(`max multiplier ${computeBombzMultiplier(Math.min(Number(maxProgramStep), 16 - Number(bombs)), Number(bombs))}x`)
      } else if (autoMode === 'program') {
        setBombzMultiplierText(`max multiplier ${computeBombzMultiplier(Math.min(program.length, 16 - Number(bombs)), Number(bombs))}x`)
      } else {
        setBombzMultiplierText('max multiplier 1x')
      }
    } else {
      setBombzMultiplierText(`max multiplier ${computeBombzMultiplier(16 - Number(bombs), Number(bombs))}x`)
    }
  }, [bombs, bet, maxProgramStep, mode, autoMode, program, initialized])

  const myGamesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMyGamesChecked(event.target.checked)
  }

  const openProfile = (userUuid: string) => {
    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  return (
    <div className={formatClassName(styles, `bombz ${className ? className : ''}`)} {...props}>
      <div className={formatClassName(styles, 'game-container')}>
        <div className={formatClassName(styles, 'game')}>
          <div className={formatClassName(styles, 'settings')}>
            <div className={formatClassName(styles, 'setting')}>
              <div className={formatClassName(styles, 'tabs')}>
                <div className={formatClassName(styles, 'multi-button')}>
                  <Button disabled={!!runningGame || !isConnected || botPlaying} className={formatClassName(styles, `no-color ${mode === 'normal' && 'selected'}`)} onClick={() => {
                    setMode('normal')
                    selectSoundBombz.play()
                  }}>
                    normal
                  </Button>
                  <ButtonIcon disabled={!!runningGame || !isConnected || botPlaying} icon="repeat" className={formatClassName(styles, `no-color ${mode === 'auto' && 'selected'}`)} onClick={() => {
                    setMode('auto')
                    selectSoundBombz.play()
                  }}>
                    autoplay
                  </ButtonIcon>
                </div>
              </div>
              <div className={formatClassName(styles, 'title')}>
                Parameters <PopoverHelp>
                  <>
                    <div>How to play</div>
                    <ul>
                      <li>
                        Set wager amount in $YOOH and number of bombs
                      </li>
                      <li>
                        Pop bubbles to reveal coins or bombs
                      </li>
                      <li>
                        More bombs = higher volatility and bigger payouts
                      </li>
                      <li>
                        End round by hitting a bomb or keep collecting coins
                      </li>
                      <li>
                        Cash out or keep playing for bigger rewards
                      </li>
                    </ul>
                    <div>
                      The maximum you can win is shown after "max win". Even if you have a bigger multiplier, the reward will never be higher than this max win amount
                    </div>
                  </>
                </PopoverHelp>
              </div>
              <div className={formatClassName(styles, 'content parameters')}>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div className={formatClassName(styles, 'label')}>Bet Amount (max win: <YOOH />{maxWin})</div>
                    <div className={formatClassName(styles, 'all-in')} onClick={allIn}>max bet</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <YOOH />
                    <Input type="number" placeholder='0.00' min={0} value={bet} onChange={betChange} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).add(1).toString()}} as any)
                        coinsSoundBombz.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).minus(1).toString()}} as any)
                        coinsSoundBombz.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div>Number of bombs (1-{BOMBZ_NUMBER_OF_SLOTS - 1})</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <img src={`${CDN_URL}/games/bombz/images/bomb.png`} alt="bomb" />
                    <Input type="number" placeholder={`number of bombs (1 - ${BOMBZ_NUMBER_OF_SLOTS})`} min={1} max={BOMBZ_NUMBER_OF_SLOTS - 1} value={bombs} onChange={bombsChange} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        bombsChange({target: {value: Number(bombs) + 1}} as any)
                        selectSoundBombz.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        bombsChange({target: {value: Number(bombs) - 1}} as any)
                        selectSoundBombz.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
                {
                  mode === 'auto' &&
                  <>
                    <div className={formatClassName(styles, 'parameter')}>
                      <div className={formatClassName(styles, 'name')}>
                        <div>Number of games</div>
                      </div>
                      <div className={formatClassName(styles, 'input')}>
                        <FontAwesomeIcon icon="repeat" />
                        <Input type="text" placeholder={'number of games'} min={1} value={Number(repeat) === -1 ? 'infinity' : repeat} onChange={repeatChange} />
                        <ButtonIcon className={formatClassName(styles, 'infinity')} title="infinity" icon="infinity" onClick={() => {
                          repeatChange({target: {value: -1}} as any)
                          selectSoundRepeat.play()
                        }} />
                        <div className={formatClassName(styles, 'increment')}>
                          <Button onClick={() => {
                            repeatChange({target: {value: Number(repeat) + 1}} as any)
                            selectSoundRepeat.play()
                          }}>+</Button>
                          <Button onClick={() => {
                            repeatChange({target: {value: Number(repeat) - 1}} as any)
                            selectSoundRepeat.play()
                          }}>-</Button>
                        </div>
                      </div>
                    </div>
                    <div className={formatClassName(styles, 'multi-button')}>
                      <ButtonIcon icon="shuffle" disabled={botPlaying || !!runningGame} className={formatClassName(styles, `no-color ${autoMode === 'random' && 'selected'}`)} onClick={() => {
                        setAutoMode('random')
                        selectSoundBombz.play()
                      }}>
                        random
                      </ButtonIcon>
                      <ButtonIcon icon="code-branch" disabled={botPlaying || !!runningGame} className={formatClassName(styles, `no-color ${autoMode === 'program' && 'selected'}`)} onClick={() => {
                        setAutoMode('program')
                        selectSoundBombz.play()
                      }}>
                        <div>
                          program <PopoverHelp>
                            <>
                              <div>How to program</div>
                              <ul>
                                <li>
                                  Activate the program mode
                                </li>
                                <li>
                                  Click on the bubbles to define in which order you want the bot to reveal
                                </li>
                                <li>
                                  Click on the "reset program" button to clean the program
                                </li>
                                <li>
                                  Click on "let's go" when the program is ready
                                </li>
                                <li>
                                  The program auto cashout when no programed steps are left
                                </li>
                                <li>
                                  Click on "stop" when you want to stop the bot
                                </li>
                              </ul>
                            </>
                          </PopoverHelp>
                        </div>
                      </ButtonIcon>
                    </div>
                    {
                      autoMode === 'random'
                        ? <div className={formatClassName(styles, 'parameter')}>
                          <div className={formatClassName(styles, 'name')}>
                            <div>Auto cashout after x reveals</div>
                          </div>
                          <div className={formatClassName(styles, 'input')}>
                            <Input type="text" placeholder={'cashout after 1 to 15 reveals'} value={maxProgramStep} onChange={maxProgramStepChange} />
                            <div className={formatClassName(styles, 'increment')}>
                              <Button onClick={() => {
                                maxProgramStepChange({target: {value: Number(maxProgramStep) + 1}} as any)
                                selectSoundRepeat.play()
                              }}>+</Button>
                              <Button onClick={() => {
                                maxProgramStepChange({target: {value: Number(maxProgramStep) - 1}} as any)
                                selectSoundRepeat.play()
                              }}>-</Button>
                            </div>
                          </div>
                        </div>
                        : <ButtonIcon icon="broom" disabled={botPlaying} className={formatClassName(styles, 'clean-program no-color')} onClick={resetProgram}>
                          reset program
                        </ButtonIcon>
                    }
                  </>
                }
              </div>
            </div>
            <div className={formatClassName(styles, 'setting create')}>
              {
                !sessionToken
                  ? <ConnectButton />
                  : botPlaying
                    ? <ButtonIcon icon="right-from-bracket" onClick={stopProgram}>
                      Stop autoplay
                    </ButtonIcon>
                    : runningGame
                      ? <ButtonIcon icon="right-from-bracket" loading={waitingForCashout} loadingWithChildren onClick={cashout}>
                        {!isConnected ? 'Connecting' : !tokens ? 'Loading tokens' : waitingForCashout ? 'Loading...' : 'Cashout'}
                      </ButtonIcon>
                      : <ButtonIcon icon="coins" loading={creatingGame || !isConnected || !tokens} loadingWithChildren onClick={() => createGame(Number(bet), Number(bombs))}>
                        {!isConnected ? 'Connecting' : !tokens ? 'Loading tokens' : creatingGame ? 'Creating game' : mode === 'auto' ? 'Start Autoplay' : 'Let\'s Go!'}
                      </ButtonIcon>
              }

              <div className={formatClassName(styles, 'confetti')}>
                {confetti}
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'viewer')}>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, 'history-games')}>
        <div className={formatClassName(styles, 'title')}>
          History
          {
            sessionToken && <span className={formatClassName(styles, 'my-games-toggle')} onClick={() => myGamesChange({target: {checked: !myGamesChecked}} as any)} >
              <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={myGamesChecked} readOnly />
              <span>only my games</span>
            </span>
          }
        </div>
        <div className={formatClassName(styles, 'list')}>
          {
            (myGamesChecked ? myHistory : history).slice(0, 9).map(game => {
              const myGame = game.user.userUuid === userUuid
              const won = !!game.won

              return <div key={`history-${game.roundId}`} className={formatClassName(styles, `history ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                <div className={formatClassName(styles, 'info')}>
                  {myGame
                    ? <>
                      <div className={formatClassName(styles, `username ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                        You {won ? <>won <YOOH /> {(game.winnerAmount ?? 0) / 100} ({game.winnerRate ?? '1'}x)</> : <>lost <YOOH /> {(game.formattedAmount ?? 0) / 100}</>}
                      </div>
                      <div>
                        vs PirateSquad
                      </div>
                    </>
                    : <>
                      <div className={formatClassName(styles, 'username')}>
                        {won ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.user.userUuid)}>{game.user.username}</span> : 'PirateSquad '}
                        won <YOOH /> {(game.winnerAmount ?? 0) / 100} {won && <>({game.winnerRate ?? '1'}x)</>}
                      </div>
                      <div>
                        vs {won ? 'PirateSquad' : <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.user.userUuid)}>{game.user.username}</span>}
                      </div>
                    </>
                  }
                </div>
                <div className={formatClassName(styles, 'badge')}>
                  <img src={`${CDN_URL}/games/bombz/images/${game.won ? 'yooh' : 'bomb'}.png`} alt={game.won ? 'yooh' : 'bomb'} />
                </div>
                <div className={formatClassName(styles, 'time-ago')}>
                  <Countdown date={game.createdAt} renderer={() => timeAgoRenderer(game.endedAt ?? Date.now())} overtime></Countdown>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}