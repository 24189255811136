@import "scss-variables";

.games {
  display: flex;
  flex: 1;
  color: #333;
  font-family: "Riffic";
  font-size: 32px;
  max-width: 1200px;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;

    > * {
      width: 100%;
      aspect-ratio: 1/1;
      margin: 0 auto;
      cursor: pointer;
    }

    @media screen and (min-width: $sm-breakpoint) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $lg-breakpoint) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .player {
    width: 100%;
    height: 100%;
    max-width: 480px;

    canvas {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
