import {BubbleCollideEffect, BubbleDestroyEffect, BubbleSkinName, PowerUpSkinName, SoundName} from './enums'
import {updateBubbleSkin} from './level'
import {setCoinCount, setHeartCount} from './level-ui'
import {playPower, randomBubblePop} from './sound'
import {State} from './types'

export const powerUpEffects: {
  [key in PowerUpSkinName]?: (state: State) => void
} = {
  CoinSkin(state) {
    if (!state.level) return

    setCoinCount(++state.level.state.coins)

    playPower(SoundName.powerCoin)
  },
  HeartSkin(state) {
    if (!state.level) return

    setHeartCount(++state.level.state.lives)

    playPower(SoundName.powerHeart)
  },
  BombSkin(state) {
    if (!state.level) return

    state.level.state.nextBubbleDestroyEffect = BubbleDestroyEffect.Bomb

    randomBubblePop().play()
  },
  FireSkin(state) {
    if (!state.level) return

    state.level.state.nextBubbleDestroyEffect = BubbleDestroyEffect.Fire

    randomBubblePop().play()
  },
  HammerSkin(state) {
    if (!state.level) return

    state.level.state.nextBubbleCollideEffect = BubbleCollideEffect.Hammer

    updateBubbleSkin(BubbleSkinName.IronBubble)

    playPower(SoundName.powerHammer)
  }
}