@import "scss-variables";

.input {
  display: flex;
  font-family: "Baloo Thambi";
  font-size: 1rem;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline-offset: -1px;
  background-color: $color-lighter-gray;
  color: $text-color;

  &::placeholder {
    font-size: 0.9em;
  }

  &:focus {
    outline: 1px solid $color-blue;
  }
}
