import {Room, RoomAvailable as OldRoomAvailable} from 'colyseus.js'
import {ClassicRoomState, getBubbleColyseusClient, startClassicGame} from 'pirate-squad-bubble'
import React, {ChangeEventHandler, Fragment, HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../../utils/global'
import {Button, ButtonIcon} from '../../../../../../../components/buttons/button'
import {Input} from '../../../../../../../components/input/input'
import {HubContextType, useHubContext} from '../../../../../../../state/context'
import {hubState} from '../../../../../../../state/hub'
import {BubbleCreateRoomModal} from './bubble-create-room-modal'
import styles from './bubble-lobby.module.scss'
import {BubbleRoomDetails} from './bubble-room-details'
import {RoomInfo} from './types'

export type NewRoomAvailable = OldRoomAvailable & {
  name: string
  createdAt: Date
}

export type BubbleLobbyProps = HtmlHTMLAttributes<HTMLDivElement> & {
  onClose: () => void
  clientInfo: {
    clientWidth: number
    clientHeight: number
  }
}

export const BubbleLobby = ({className, onClose, clientInfo, ...props}: BubbleLobbyProps) => {
  const {state: {sessionToken, room, roomSession}, dispatch} = useHubContext()
  const [loadingRooms, setLoadingRooms] = useState(true)
  const [roomsAvailable, setRoomsAvailable] = useState<NewRoomAvailable[]>([])

  const [showCreateRoom, setShowCreateRoom] = useState(false)
  const [name, setName] = useState<string>('')

  const [joining, setJoining] = useState(false)

  const joinOrReconnect = async (roomAvailable: NewRoomAvailable, roomSession?: HubContextType['roomSession'], password?: string, paidEntranceAccepted?: boolean) => {
    if (joining) {
      return
    }

    setJoining(true)

    if (!roomSession && !paidEntranceAccepted && roomAvailable.metadata.tokenToEnter && hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Paid entrance',
        text: `You need to pay ${roomAvailable.metadata.tokenQuantity / 100} ${roomAvailable.metadata.tokenToEnter} to enter this room. If you leave before the game starts, you'll receive a refund. The winner wins all the deposits minus 3% of fees`,
        async onAccept() {
          await joinOrReconnect(roomAvailable, roomSession, password, true)

          if (hubState.refreshTokens) {
            hubState.refreshTokens()
          }

          setJoining(false)
        },
        onRefuse() {
          setJoining(false)
        },
        acceptText: 'enter',
        refuseText: 'leave'
      })

      return
    }

    if (!roomSession && !password && roomAvailable.metadata.private && hubState.showInputModal) {
      hubState.showInputModal({
        title: 'Enter the room password',
        inputProps: {
          type: 'password',
          maxLength: 32,
          placeholder: 'password...'
        },
        async onAccept(password) {
          await joinOrReconnect(roomAvailable, roomSession, password, paidEntranceAccepted)
        }
      })

      return
    }

    try {
      let room: Room

      if (roomSession) {
        room = await getBubbleColyseusClient().reconnect<ClassicRoomState>(roomSession.roomId, roomSession.sessionId)

        await startClassicGame(room)
      } else {
        room = await getBubbleColyseusClient().joinById<ClassicRoomState>(roomAvailable.roomId, {...clientInfo, password, sessionToken})
      }

      dispatch({
        type: 'SET_ROOM',
        room: room
      })

      dispatch({
        type: 'SET_ROOM_METADATA',
        roomMetadata: roomAvailable.metadata
      })

      dispatch({
        type: 'SET_ROOM_SESSION',
        roomSession: {
          roomId: room.id,
          sessionId: room.sessionId,
          roomAvailable
        }
      })
    } catch (err) {
      if (hubState.showModal) {
        hubState.showModal({
          title: 'Error',
          text: `${err}`
        })
      }
      console.error(err)
    }

    setJoining(false)
  }

  const createClassicRoom = async (roomInfo: RoomInfo, password?: string) => {
    try {
      const room = await getBubbleColyseusClient().create<ClassicRoomState>('classic', {...clientInfo, roomInfo, password, sessionToken})

      const roomAvailable = (await getBubbleColyseusClient().getAvailableRooms()).find(roomAvailable => roomAvailable.roomId === room.id) as NewRoomAvailable

      setShowCreateRoom(false)

      dispatch({
        type: 'SET_ROOM',
        room: room
      })

      dispatch({
        type: 'SET_ROOM_METADATA',
        roomMetadata: roomAvailable?.metadata
      })

      dispatch({
        type: 'SET_ROOM_SESSION',
        roomSession: {
          roomId: room.id,
          sessionId: room.sessionId,
          roomAvailable: roomAvailable
        }
      })
    } catch (err) {
      if (hubState.showModal) {
        hubState.showModal({
          title: 'Error',
          text: `${err}`
        })
      }
      console.error(err)
    }
  }

  useEffect(() => {
    (async () => {
      const lobby = await getBubbleColyseusClient().joinOrCreate('lobby')

      let allRooms: NewRoomAvailable[] = []

      lobby.onMessage('rooms', (rooms) => {
        allRooms = rooms

        setRoomsAvailable([...allRooms])

        setLoadingRooms(false)

        console.log(rooms)
      })

      lobby.onMessage('+', ([roomId, room]) => {
        const roomIndex = allRooms.findIndex((room) => room.roomId === roomId)
        if (roomIndex !== -1) {
          allRooms[roomIndex] = room

        } else {
          allRooms.push(room)
        }

        setRoomsAvailable([...allRooms])
      })

      lobby.onMessage('-', (roomId) => {
        allRooms = allRooms.filter((room) => room.roomId !== roomId)

        setRoomsAvailable([...allRooms])
      })

      lobby.onLeave((code) => {
        if (code === 1000) return

        if (hubState.showModal) {
          hubState.showModal({
            title: 'Disconnected',
            text: 'You have been disconnected from the lobby'
          })
        }

        onClose()
      })
    })()
  }, [onClose])

  useEffect(() => {
    dispatch({
      type: 'SET_ROOM'
    })

    dispatch({
      type: 'SET_ROOM_METADATA'
    })
  }, [])

  useEffect(() => {
    if (roomSession && !room && roomsAvailable) {
      const roomAvailable = roomsAvailable.find((roomAvailable) => roomAvailable.roomId === roomSession.roomId)

      if (roomAvailable && hubState.showConfirm) {
        hubState.showConfirm({
          title: 'Reconnect to the game',
          text: 'We detected a running game. Do you want to reconnect to it?',
          onAccept: () => joinOrReconnect(roomAvailable, roomSession),
          onRefuse: () => dispatch({
            type: 'SET_ROOM_SESSION'
          }),
          refuseText: 'leave'
        })
      }
    }
  }, [roomsAvailable, room, roomSession])

  const nameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value)
  }

  const selectedRoomAvailable = roomsAvailable.find((roomAvailable) => roomAvailable.roomId === room?.id)

  const roomsOpened = roomsAvailable.filter((roomAvailable) => roomAvailable.clients < roomAvailable.maxClients && !roomAvailable.metadata.started)

  return <>
    <div className={formatClassName(styles, `bubble-lobby ${className}`)} {...props}>
      {room && selectedRoomAvailable
        ? <BubbleRoomDetails roomAvailable={selectedRoomAvailable} />
        : <div className={formatClassName(styles, 'room-list')}>
          <h2>ROOMS</h2>
          <Input type="text" maxLength={32} placeholder="Search by room's name..." value={name} onChange={nameChange} />
          <div className={formatClassName(styles, 'rooms')}>
            <div className={formatClassName(styles, 'rooms-grid')}>
              <div className={formatClassName(styles, 'header')}>Name</div>
              <div className={formatClassName(styles, 'header')}>Players</div>
              <div className={formatClassName(styles, 'header')}>Entry cost</div>
              <div></div>
              {
                roomsOpened.filter(roomAvailable => roomAvailable.metadata.roomName.includes(name)).map((roomAvailable, index) =>
                  <Fragment key={`room-${index}`}>
                    <div>{roomAvailable.metadata.roomName}</div>
                    <div>{roomAvailable.clients}/{roomAvailable.maxClients}</div>
                    <div>{roomAvailable.metadata.tokenToEnter ? `${roomAvailable.metadata.tokenQuantity / 100} ${roomAvailable.metadata.tokenToEnter}` : 'free'}</div>
                    <div><Button disabled={roomAvailable.clients === roomAvailable.maxClients || roomAvailable.metadata.started} onClick={() => joinOrReconnect(roomAvailable)}>join</Button></div>
                  </Fragment>
                )
              }
            </div>
            <div className={formatClassName(styles, 'status')}>{loadingRooms ? 'Loading rooms...' : roomsOpened.length === 0 && 'No rooms available, create one!'}</div>
          </div>
          <div className={formatClassName(styles, 'actions')}>
            <ButtonIcon flip="horizontal" icon="right-from-bracket" className={formatClassName(styles, 'exit-button no-color')} onClick={onClose} >exit</ButtonIcon>
            <ButtonIcon icon="circle-plus" className={formatClassName(styles, 'create-room-button')} onClick={() => setShowCreateRoom(true)}>Create a room</ButtonIcon>
          </div>
        </div>
      }
    </div>
    <BubbleCreateRoomModal show={showCreateRoom} onClose={() => setShowCreateRoom(false)} onCreate={createClassicRoom} />
  </>
}