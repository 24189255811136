import {sound} from '@pixi/sound'
import {Room} from 'colyseus.js'
import Decimal from 'decimal.js'
import {ease} from 'pixi-ease'
import {Spine} from 'pixi-spine'
import * as PIXI from 'pixi.js'
import {Container, Point} from 'pixi.js'
import {initBlueBackground, initGreyBackground, initNightBackground, initYellowBackground} from './backgrounds'
import {destroyBubble, initBubble} from './bubble'
import {cannonFireAt, cannonLookAt, initCannon, loadBubbleIntoCannon} from './cannon'
import {BubbleCollideEffect, BubbleDestroyEffect, BubbleSkinName, SoundName} from './enums'
import {createViewport} from './game'
import {initLevelUI, setBubbleCount, showLevelNumber, showScoreAndCombo, spawnScoreBubble} from './level-ui'
import {initPirate, loadBubbleIntoHands, throwBubble} from './pirate'
import {initPowerUp} from './power-up'
import {powerUpEffects} from './power-up-effects'
import {createSoloLevelRoom} from './server'
import {BubbleSchema} from './server/schema/BubbleSchema'
import {LevelSchema} from './server/schema/LevelSchema'
import {PowerUpSchema} from './server/schema/PowerUpSchema'
import {exitRoom, onConnect, onJoinError, onLeave} from './server/server-handler'
import {playAmbience, playCombo, playMusic, randomBubblePopNeutral, randomPirateThrowBubble, stopAllMusicAndAmbience} from './sound'
import {bubbleState} from './state'
import {Cluster, DestroyClusterData, Level, State} from './types'
import {distanceBetween, getGameHeight, getGameWidth, index1DFrom2D, index2DFrom1D} from './utils'

/**
 * Initialize the level (background, cannon and listeners)
 */
export const initLevel = async (level: Partial<Level>, reset = false, mode: State['mode'] = 'solo', room?: Room) => {
  clearReferences()

  bubbleState.loading = true
  bubbleState.mode = mode
  bubbleState.level = {
    ...level,
    state: JSON.parse(JSON.stringify({...level.initialState, lastPauseTimestamp: Date.now()})),
    references: {
      ui: {}
    }
  } as Level


  if (mode === 'classic' && bubbleState.external.showModal) {
    bubbleState.external.showModal({
      title: 'Multiplayer room',
      text: 'Highest score wins. Use the smallest amount of bubbles and do the biggest combos!'
    })
  }

  if (!bubbleState.app || !bubbleState.viewport) return

  if (!room && bubbleState.gameState?.actualLevel !== undefined) {

    if (bubbleState.external.showLoading) {
      bubbleState.external.showLoading(true, undefined, 'Connection...')
    }

    await exitRoom()

    bubbleState.room = await createSoloLevelRoom({
      options: {
        clientWidth: getGameWidth(),
        clientHeight: getGameHeight(),
        level: bubbleState.gameState.actualLevel,
        sessionToken: bubbleState.external.sessionToken
      },
      onConnect: (room, level) => onConnect(room, level, reset),
      onJoinError,
      onLeave
    })
  } else {
    bubbleState.room = room
  }
}

export const initLevelAfterServer = (level: LevelSchema, reset?: boolean) => {
  if (!bubbleState.level) return

  bubbleState.level.state.actualTopRowIndex = level.actualTopRowIndex
  bubbleState.level.state.numberOfBubblesSent = level.numberOfBubblesSent
  bubbleState.level.state.canShoot = level.canShoot

  createViewport()

  initLevelSounds()

  initLevelBackground()
  initLevelBubbles(level.tiles, level.bubbleWidth)

  initPirate()
  initCannon()

  initLevelUI()

  loadBubbleIntoHands(level.bubbleInCannon.skin)

  throwAndLoadBubble(() => {
    loadBubbleIntoHands(level.bubbleInHand.skin)
  })

  randomPirateThrowBubble().play()

  initLevelListeners()

  showLevelNumber()

  if (!reset) {
    setTimeout(() => {
      if (bubbleState.external.showModal) {
        bubbleState.external.showModal({
          title: '✨ Destroy all the bubbles ✨',
          text: 'The number of tries are shown near the cannon! Use less bubbles as possible to raise final score 🚀',
        })
      }
    }, 200)
  }

  if (bubbleState.external.showLoading) {
    bubbleState.external.showLoading(false)
  }

  bubbleState.loading = false
}

export const clearReferences = () => {
  if (bubbleState.references.bubbleFired) bubbleState.references.bubbleFired.spine.destroy()
  bubbleState.references.bubbleFired = undefined
  bubbleState.references.bubbleInCannon = undefined
  bubbleState.references.bubbleInHand = undefined

  bubbleState.references.powerUp?.forEach((powerUp) => {
    if (powerUp) {
      try {
        powerUp.spine.destroy()
        // eslint-disable-next-line no-empty
      } catch {}
    }
  })

  bubbleState.references.powerUp = []
}

export const initLevelSounds = () => {
  if (!bubbleState.level) return

  stopAllMusicAndAmbience()

  playAmbience(bubbleState.level.ambienceSound)

  sound.find(bubbleState.level.ambienceSound).volume = 0.15
}

export const tiles1Dto2D = (index: number) => {
  if (!bubbleState.level?.state || !bubbleState.references.tiles) return

  return index2DFrom1D(index, bubbleState.level.numberOfBubblePerRow)
}

/**
 * Creating the background sprites
 */
export const initLevelBackground = () => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const background = bubbleState.level.background

  switch (background) {
    case 'blue':
      initBlueBackground()
      break
    case 'yellow':
      initYellowBackground()
      break
    case 'grey':
      initGreyBackground()
      break
    case 'night':
      initNightBackground()
      break
  }
}

/**
 * Creating the bubbles
 */
export const initLevelBubbles = (tiles: LevelSchema['tiles'], bubbleWidth: number) => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const bubbleContainer = new Container()

  bubbleState.references.bubbleContainer = bubbleContainer

  bubbleState.viewport.addChild(bubbleContainer)

  bubbleState.level.state.bubbleWidth = bubbleWidth

  const {row: maxRow} = index2DFrom1D(tiles.length, bubbleState.level.numberOfBubblePerRow)

  bubbleState.references.tiles = []

  for (let row = 0; row < maxRow; row++) {
    bubbleState.references.tiles[row] = []
    for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
      const modelSkinName = tiles[index1DFrom2D(row, column, bubbleState.level.numberOfBubblePerRow)].skin

      if (modelSkinName === 'Empty' as BubbleSkinName) {
        continue
      }

      const bubble = initBubble(modelSkinName)

      if (bubble) {
        const bubbleWidthRatio = bubbleWidth / bubble.width

        // Scale because the bubble object contain space for effects
        bubble.scale.set(bubbleWidthRatio * 1.8, bubbleWidthRatio * 1.8)

        bubbleState.level.state.bubbleWidthRatio = bubbleWidthRatio * 1.8

        drawBubbleOnGrid(row, column, bubble)
      }
    }
  }
}

/**
 * Add a bubble object into a cell of the actual game
 * @param row The row
 * @param column The column
 * @param bubble The bubble
 */
export const drawBubbleOnGrid = (row: number, column: number, bubble: Spine) => {
  if (!bubbleState.viewport
    || !bubbleState.references.tiles
    || !bubbleState.level?.state.bubbleWidth
    //|| state.references.tiles?.[row]?.[column]
    || !bubbleState.references.bubbleContainer
  ) return

  // !!!!!!!!!! if modifying this script, adapt the nearestTile function
  const container = new Container()
  container.width = bubbleState.level.state.bubbleWidth
  container.height = bubbleState.level.state.bubbleWidth

  const coords = rowColumnToCoord(row, column)

  if (!coords) return

  const {x, y} = coords

  container.x = x - bubbleState.level.state.bubbleWidth * 0.5
  container.y = y - bubbleState.level.state.bubbleWidth * 0.5

  bubble.x = bubbleState.level.state.bubbleWidth * 0.5
  bubble.y = bubbleState.level.state.bubbleWidth * 0.5

  if (bubble.skeleton.skin?.name === BubbleSkinName.ColoredBubble) {
    bubble.scale.set(bubble.scale.x * 0.8, bubble.scale.y * 0.8)
  }

  container.addChild(bubble)

  bubbleState.references.bubbleContainer.addChild(container)

  if (!bubbleState.references.tiles[row]) bubbleState.references.tiles[row] = []

  bubbleState.references.tiles[row][column] = container
}

/**
 *  x x -         - x x
 *  x o x   and   x o x
 *  x x -         - x x
 */
const neighbors: [number, number][][] = [
  [[0, -1], [1, -1], [-1, 0], [1, 0], [0, 1], [1, 1]],
  [[-1, -1], [0, -1], [-1, 0], [1, 0], [-1, 1], [0, 1]]
]

/**
 * Compute the nearest free slot into the grid
 * @param row The row of the colliding bubble
 * @param column The column of the colliding bubble
 * @param bubble The bubble moving
 * @param addCenter Add the center in list of neigbors
 */
export const nearestFreeTile = (row: number, column: number, bubble: Spine, addCenter = false) => {
  if (!bubbleState.references.tiles || !bubbleState.level?.state.bubbleWidth) return

  let bestRow, bestColumn
  let bestDistance = Number.MAX_VALUE

  const effectiveRow = (bubbleState.level.state.actualTopRowIndex ?? 0) + row

  const actualNeighbors = [
    ...neighbors[effectiveRow % 2]
  ]

  if (addCenter) {
    actualNeighbors.push([0, 0])
  }

  for (const [dColumn, dRow] of actualNeighbors) {
    const actualRow = row + dRow
    const actualColumn = column + dColumn

    if (actualRow < 0 || actualColumn < 0 || actualColumn >= bubbleState.level.numberOfBubblePerRow) continue

    if (!bubbleState.references.tiles[actualRow]) bubbleState.references.tiles[actualRow] = []

    const tile = bubbleState.references.tiles[actualRow][actualColumn]

    if (tile && (tile.children[0] as Spine)?.skeleton.skin?.name !== BubbleSkinName.Empty) continue

    const coords = rowColumnToCoord(actualRow, actualColumn)

    if (!coords) return

    const {x: x1, y: y1} = coords
    const {x: x2, y: y2} = bubble

    const distance = distanceBetween(x1, y1, x2, y2)

    if (distance < bestDistance) {
      bestDistance = distance
      bestRow = actualRow
      bestColumn = actualColumn
    }
  }

  return {
    row: bestRow,
    column: bestColumn
  }
}

/**
 * Get the neighbors information
 * @param row Row of the origin bubble
 * @param column Column of the origin bubble
 * @returns Neighbors information
 */
export const getNeighbors = (row: number, column: number) => {
  const tiles = []

  if (!bubbleState.references.tiles || !bubbleState.level) return []

  const effectiveRow = (bubbleState.level.state.actualTopRowIndex ?? 0) + row

  const actualNeighbors = neighbors[effectiveRow % 2]

  for (const [dColumn, dRow] of actualNeighbors) {
    const actualRow = row + dRow
    const actualColumn = column + dColumn

    if (actualRow < 0 || actualRow >= bubbleState.references.tiles.length || actualColumn < 0 || actualColumn >= bubbleState.level.numberOfBubblePerRow) continue

    const tile = bubbleState.references.tiles[actualRow][actualColumn]

    if (tile?.children[0]) {
      tiles.push({
        row: actualRow,
        column: actualColumn,
        bubble: tile.children[0] as Spine
      })
    }
  }

  return tiles
}

/**
 * Find a cluster of bubbles
 * @param row Row of the origin bubble
 * @param column Column of the origin bubble
 * @param bubble The origin bubble Spine object
 * @param skinName The skin name if needed (without, all the connected bubbles will be added)
 * @param reset Reset the processed status of the bubble
 * @returns The cluster of bubbles
 */
export const findCluster = (row: number, column: number, bubble: Spine, skinName?: string, reset = true) => {
  if (reset) resetProcessed()

  const toBeProcessed = [{row, column, bubble}]
  const cluster = []

  while (toBeProcessed.length > 0) {
    const bubbleInfo = toBeProcessed.shift()

    if (!bubbleInfo) continue

    const {row: actualRow, column: actualColumn, bubble: actualBubble} = bubbleInfo

    if (skinName && skinName !== actualBubble.skeleton.skin?.name) continue

    if ((actualBubble as any).processed) continue
    (actualBubble as any).processed = true

    const neighbors = getNeighbors(actualRow, actualColumn)

    toBeProcessed.push(...neighbors)

    cluster.push(bubbleInfo)
  }

  return cluster
}

/**
 * Find all the floating clusters in the current tiles
 * @param reset Reset processed
 * @returns Floating clusters
 */
export const findFloatingClusters = (reset = true) => {
  if (reset) resetProcessed()

  const foundclusters: Cluster[] = []

  if (!bubbleState.references.tiles || !bubbleState.level) return foundclusters

  for (let row = 0; row < bubbleState.references.tiles.length; row++) {
    for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
      const tile = bubbleState.references.tiles[row][column]

      if (!tile) continue

      const bubble = tile.children[0] as Spine

      if (!(bubble as any).processed) {
        const foundcluster = findCluster(row, column, bubble, undefined, false)

        let floating = true
        for (let k = 0; k < foundcluster.length; k++) {
          (foundcluster[k].bubble as any).processed = true
          if (foundcluster[k].row === 0) {
            floating = false
            break
          }
        }

        if (floating) {
          foundclusters.push(foundcluster)
        }
      }

      (bubble as any).processed = true
    }
  }

  return foundclusters
}

/**
 * Destroy a cluster and remaining floating clusters
 * @param cluster The cluster to destroy
 * @param showScore Show the score on bubbles
 * @param bubble The bubble center of destroy (to have a wave effect)
 * @param silent Delete the cluster without sound
 * @param minClusterSize The minimum size of the cluster to be destroyed
 * @returns The destroyed bubbles and the maximum delay
 */
export const destroyCluster = (cluster: Cluster, showScore = true, bubble?: Spine, silent?: boolean, minClusterSize = 3): DestroyClusterData => {
  if (!bubbleState.viewport || !bubbleState.references.bubbleContainer || !bubbleState.references.topContainer) return {destroyedBubbles: [], maxExplosionDelay: 0}

  const destroyedBubbles: Cluster = []

  let maxExplosionDelay = 0

  if (cluster.length >= minClusterSize) {
    const explosionDelay = destroyBubbles(cluster, showScore, bubble ? bubble.getGlobalPosition() : undefined, silent)

    if (explosionDelay !== undefined && explosionDelay > maxExplosionDelay) maxExplosionDelay = explosionDelay

    destroyedBubbles.push(...cluster)

    resetProcessed()

    const floatingClusters = findFloatingClusters(false)

    floatingClusters.forEach((floatingCluster) => {
      const explosionDelay = destroyBubbles(floatingCluster, showScore, bubble ? bubble.getGlobalPosition() : undefined, silent)

      if (explosionDelay !== undefined && explosionDelay > maxExplosionDelay) maxExplosionDelay = explosionDelay

      destroyedBubbles.push(...floatingCluster)
    })

    const shake = Math.ceil(Math.min(10, destroyedBubbles.length / 10)) + 1

    ease.add([bubbleState.references.bubbleContainer, bubbleState.references.topContainer], {shake}, {duration: maxExplosionDelay * 2})
  }

  return {
    destroyedBubbles,
    maxExplosionDelay
  }
}

/**
 * Reset the processed status of all the bubbles
 */
export const resetProcessed = () => {
  if (!bubbleState.references.tiles || !bubbleState.level) return

  for (let row = 0; row < bubbleState.references.tiles.length; row++) {
    for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
      const tile = bubbleState.references.tiles[row][column]

      if (tile?.children[0]) {
        (tile.children[0] as any).processed = false
      }
    }
  }
}

/**
 * Destroy a cluster of bubbles
 * @param cluster Cluster of bubbles to destroy
 * @param showScore Show the score on bubbles
 * @param explosionCenter The center of the explosion (used to compute the delay before the animation launch)
 * @param silent Delete the bubble without sound
 * @returns The maximum delay of explosion for one bubble
 */
export const destroyBubbles = (cluster: {row: number, column: number, bubble: Spine}[], showScore = true, explosionCenter?: PIXI.Point, silent?: boolean) => {
  if (!bubbleState.references.tiles) return

  let maxDelay = 0

  for (const {row, column, bubble} of cluster) {
    if (!explosionCenter) {
      destroyBubble(bubble, showScore ? 20 : undefined, silent)
    } else {
      const {x: x1, y: y1} = explosionCenter
      const {x: x2, y: y2} = bubble.getGlobalPosition()

      const distance = distanceBetween(x1, y1, x2, y2)

      if (maxDelay < distance) maxDelay = distance

      setTimeout(() => {
        destroyBubble(bubble, showScore ? 20 : undefined, silent)
      }, distance * 2)
    }

    bubbleState.references.tiles[row][column] = undefined
  }

  return maxDelay
}

export const existingBubbleSkinsInLevel = (addSpecialBubbles = false): string[] => {
  if (!bubbleState.references.tiles) return []

  let additionalSkins: string[] = []
  const bubblePowerUpSkins = [BubbleSkinName.IronBubble]

  if (addSpecialBubbles) {
    additionalSkins = bubblePowerUpSkins
  }

  let skins = [
    ...new Set(
      [
        ...bubbleState.references.tiles.flat().filter(tile => tile !== undefined).map(tile => (tile?.children[0] as Spine)?.skeleton?.skin?.name),
        ...additionalSkins
      ]
    )
  ]

  if (!addSpecialBubbles) {
    skins = skins.filter(skin => !bubblePowerUpSkins.includes(skin as BubbleSkinName))
  }

  return skins as string[]
}

export const randomBubbleSkinExistingInLevel = () => {
  const existingSkins = existingBubbleSkinsInLevel()

  if (!existingSkins) return bubbleState.level?.bubbleInLevel[0]

  return existingSkins[Math.floor(Math.random() * existingSkins?.length)] as BubbleSkinName
}

export const throwAndLoadBubble = (newBubbleEventCallback?: () => void, forceSkin?: BubbleSkinName) => {
  if (!bubbleState.level) return

  const thrownBubble = bubbleState.references.bubbleInHand

  if (thrownBubble) {
    if (forceSkin) {
      thrownBubble.skeleton.setSkinByName(forceSkin)
    }

    throwBubble(() => {
      if (!bubbleState.references.cannon || !bubbleState.level) return

      bubbleState.references.bubbleInCannon = thrownBubble

      //bubbleState.level.state.canShoot = true

      loadBubbleIntoCannon(bubbleState.references.cannon, thrownBubble)
    }, newBubbleEventCallback)
  }
}

export const addPowerUp = (powerUpSchema: PowerUpSchema) => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.level.state.bubbleWidth || !bubbleState.level.powerUpInLevel) return

  if (!bubbleState.references.powerUp) {
    bubbleState.references.powerUp = []
  }

  const powerUpList = bubbleState.references.powerUp

  const powerUp = initPowerUp(powerUpSchema.skin)

  if (powerUp) {
    powerUp.zIndex = 10

    powerUp.width = 1.6 * bubbleState.level.state.bubbleWidth
    powerUp.height = 1.6 * bubbleState.level.state.bubbleWidth
    powerUp.x = powerUpSchema.x
    powerUp.y = powerUpSchema.y

    powerUpList.push({
      uid: powerUpSchema.uid,
      dx: powerUpSchema.dx,
      dy: 0,
      spine: powerUp
    })

    bubbleState.viewport.addChild(powerUp)
  }
}

/**
 * Get the row and column of x and y coords
 * @param x The x coord
 * @param y The y coord
 * @returns {row, column} object with the row and column pointed by the x and y coords
 */
export const coordToRowColumn = (x: number, y: number) => {
  if (!bubbleState.level) return {row: undefined, column: undefined}

  let row = -1
  let column = -1

  if (bubbleState.level.state.bubbleWidth) {
    row = Math.floor((y - bubbleState.level.firstRowTopMargin * bubbleState.level.state.bubbleWidth) / (bubbleState.level.state.bubbleWidth * bubbleState.level.rowSpacing))
    column = Math.floor((x - (((row + (bubbleState.level.state.actualTopRowIndex ?? 0))) % 2 === 0 ? bubbleState.level.state.bubbleWidth * 0.5 : 0)) / bubbleState.level.state.bubbleWidth)
  }

  return {
    row,
    column
  }
}

/**
 * Get x and y coords for a row and column
 * @param row The row of the tile
 * @param column The column of the tile
 * @returns {x, y} object with x and y coord of the center of the tile
 */
export const rowColumnToCoord = (row: number, column: number) => {
  if (!bubbleState.level) return

  let x = -1
  let y = -1

  const topRowIndex = bubbleState.level.state.actualTopRowIndex ?? 0

  const effectiveRow = row + topRowIndex

  if (bubbleState.level.state.bubbleWidth) {
    x = (bubbleState.level.state.bubbleWidth * column)
    y = (bubbleState.level.state.bubbleWidth * row * bubbleState.level.rowSpacing) + bubbleState.level.state.bubbleWidth * bubbleState.level.firstRowTopMargin

    if (effectiveRow % 2 === 0) {
      x += bubbleState.level.state.bubbleWidth * 0.5
    }

    x += bubbleState.level.state.bubbleWidth * 0.5
    y += bubbleState.level.state.bubbleWidth * 0.5
  }

  return {
    x,
    y
  }
}

export const resetLevel = () => {
  if (!bubbleState.level || !bubbleState.viewport) return

  bubbleState.viewport.removeChildren()

  initLevel(bubbleState.level, true)
}

/**
 * Move and check for collision with the moving bubble (fired bubble)
 * @param delta The delta (time passed since previous update)
 */
export const moveAndCheckCollide = (delta: number) => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.level.state.bubbleWidth) return

  const level = bubbleState.level

  if (!bubbleState.references.bubbleFired || !bubbleState.references.tiles) return

  // Compute the real half size of the bubble
  const bubbleSize = bubbleState.level.state.bubbleWidth
  const halfBubbleSize = new Decimal(bubbleSize).div(2)

  const samplingRate = new Decimal(delta).mul(10)

  const bubbleFired = bubbleState.references.bubbleFired
  const bubbleSpine = bubbleFired.spine

  let dxSample = new Decimal(bubbleFired.dx).div(10)
  const dySample = new Decimal(bubbleFired.dy).div(10)

  let row = 0, column = 0
  let colliding = false
  let collidingTop = false

  for (let sample = 0; sample < samplingRate.toNumber(); sample++) {
    const tempX = new Decimal(bubbleSpine.x).add(dxSample)

    // If colliding with horizontal limits, revert dx and calculate the result precisely (simulating bouncing)
    if (tempX.lt(halfBubbleSize) || tempX.gt(new Decimal(getGameWidth()).minus(halfBubbleSize))) {
      if (tempX.lt(halfBubbleSize)) {
        tempX.add(halfBubbleSize).minus(tempX)
      } else {
        tempX.minus(tempX).minus(getGameWidth()).minus(halfBubbleSize)
      }

      bubbleFired.dx = -bubbleFired.dx
      bubbleFired.dxOriginal = -bubbleFired.dxOriginal
      dxSample = dxSample.neg()
    }

    bubbleSpine.x = tempX.toNumber()
    bubbleSpine.y = new Decimal(bubbleSpine.y).add(dySample).toNumber()

    const rowColumn = coordToRowColumn(
      bubbleSpine.x,
      bubbleSpine.y
    )

    if (rowColumn.row === undefined || rowColumn.column === undefined) continue

    const maxRow = rowColumn.row + 1
    const minRow = maxRow - 1
    const maxColumn = rowColumn.column + 2
    const minColumn = maxColumn - 2

    let minDistance = Number.MAX_VALUE

    for (let actualRow = maxRow; actualRow >= minRow; actualRow--) {
      for (let actualColumn = maxColumn; actualColumn >= minColumn; actualColumn--) {
        const tile = bubbleState.references.tiles[actualRow]?.[actualColumn]?.children[0] as Spine

        if (!tile) continue

        const {x: x1, y: y1} = rowColumnToCoord(actualRow, actualColumn) ?? tile.getGlobalPosition()
        const {x: x2, y: y2} = bubbleSpine

        const distance = distanceBetween(x1, y1, x2, y2)

        // Allow a little tolerance for the bubble to go between to others 
        const collided = distance * level.bubbleCollidingTolerance <= bubbleSize

        if (collided && minDistance > distance) {
          minDistance = distance

          row = actualRow
          column = actualColumn

          colliding = true
        }
      }

      if (colliding) {
        break
      }
    }

    if (!colliding && halfBubbleSize.add(level.firstRowTopMargin * bubbleSize).gt(bubbleSpine.y)) {
      const {x, y} = bubbleSpine
      const {column: c} = coordToRowColumn(x, y)

      if (c) {
        row = 0
        column = c
        colliding = true
        collidingTop = true
      }
    }

    if (colliding) {
      break
    }
  }

  if (colliding) {
    //let result: DestroyClusterData = {
    //  destroyedBubbles: [],
    //  maxExplosionDelay: 0
    //}

    let actionFinished = true
    //let addBubbleOnGrid = true

    //if (bubbleState.level.state.actualBubbleCollideEffect && collidingTop) {
    //  bubbleState.level.state.actualBubbleCollideEffect = undefined

    //  addBubbleOnGrid = false
    //}

    if (bubbleState.level.state.actualBubbleCollideEffect) {
      //const effect = bubbleCollideEffects[bubbleState.level.state.actualBubbleCollideEffect]

      //if (effect) {
      //  result = effect(row, column)

      //  if (bubbleState.level.state.combo) {
      //    bubbleState.level.state.combo.destroyedBubbles.push(...result.destroyedBubbles)
      //    bubbleState.level.state.combo.maxExplosionDelay = Math.max(bubbleState.level.state.combo.maxExplosionDelay, result.maxExplosionDelay)
      //  }
      //}

      actionFinished = false
    } else {
      //if (bubbleState.level.state.actualBubbleDestroyEffect) {
      //  const effect = bubbleDestroyEffects[bubbleState.level.state.actualBubbleDestroyEffect]

      //  if (effect) {
      //    effect(bubbleSpine)
      //  }
      //} else {
      //result = checkForClusterAndExplosion(row, column, bubbleSpine, addBubbleOnGrid, collidingTop)

      //if (bubbleState.level.state.combo) {
      //  result.destroyedBubbles.push(...bubbleState.level.state.combo.destroyedBubbles)
      //  result.maxExplosionDelay = Math.max(bubbleState.level.state.combo.maxExplosionDelay, result.maxExplosionDelay)

      //  destroyBubble(bubbleSpine, undefined, true)

      //  bubbleState.level.state.combo = undefined
      //}
      //}
    }

    if (actionFinished) {
      //generatePowerUp()
      //throwAndLoadBubble()

      // Update actual and next effects
      //if (bubbleState.level.state.nextBubbleCollideEffect) {
      //  bubbleState.level.state.actualBubbleCollideEffect = bubbleState.level.state.nextBubbleCollideEffect
      //  bubbleState.level.state.nextBubbleCollideEffect = undefined

      //  bubbleState.level.state.combo = {
      //    destroyedBubbles: [],
      //    maxExplosionDelay: 0
      //  }
      //}

      //bubbleState.level.state.actualBubbleDestroyEffect = bubbleState.level.state.nextBubbleDestroyEffect
      //bubbleState.level.state.nextBubbleDestroyEffect = undefined

      //checkPushOneLineFromTop(result.maxExplosionDelay, bubbleSpine, true)

      //updateBubbleSkin()

      if (bubbleState.references.bubbleFired) {
        bubbleState.references.bubbleFired.spine.destroy()
        bubbleState.references.bubbleFired = undefined
      }

      //updateScoreAndStars(result.destroyedBubbles)

      //++bubbleState.level.state.numberOfBubblesSent

      //setBubbleCount(remainingBubblesToFire())
    }
  }
}

export const checkForClusterAndExplosion = (row: number, column: number, bubble: Spine, drawOnGrid = true, addCenterInNeighbors = false): DestroyClusterData => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.references.tiles) return {
    destroyedBubbles: [],
    maxExplosionDelay: 0
  }

  const destroyedBubbles = []

  const tile = nearestFreeTile(row, column, bubble, addCenterInNeighbors)

  let maxExplosionDelay = 0

  if (tile?.row !== undefined && tile?.column !== undefined) {
    if (drawOnGrid) {
      drawBubbleOnGrid(tile.row, tile.column, bubble)
    }

    const cluster = findCluster(tile.row, tile.column, bubble, bubble.skeleton.skin?.name, true)

    const result = destroyCluster(cluster, true, bubble)

    destroyedBubbles.push(...result.destroyedBubbles)

    maxExplosionDelay = result.maxExplosionDelay
  }

  destroyLastLine()

  return {
    destroyedBubbles,
    maxExplosionDelay
  }
}

///**
// * Execute the logic for pushing one line on the top
// * @param delay The delay after which the push will occur
// * @param bubble The bubble center of "explosion"
// * @param silent Destroy the bubble without sound
// */
//export const checkPushOneLineFromTop = (delay: number, bubble?: Spine, silent?: boolean) => {
//  setTimeout(() => {
//    if (!bubbleState.level || !bubbleState.references.tiles) return

//    const bubbleInGame = bubbleState.references.tiles.find(row => row.find(bubble => bubble !== undefined))

//    if (!bubbleInGame || (bubbleState.level.state.numberOfBubblesSent % bubbleState.level.pushLineBubbleCount === 0 && bubbleState.level.state.numberOfBubblesSent !== 0)) {
//      pushOneLineFromTop()

//      const floatingClusters = findFloatingClusters()

//      floatingClusters.forEach((floatingCluster) => {
//        destroyBubbles(floatingCluster, false, bubble ? bubble.getGlobalPosition() : undefined, silent)
//      })
//    }

//    destroyLastLine()

//    updateWavesLeftCount()
//  }, delay * 2)
//}

export const pushNewWave = (wave: BubbleSchema[]) => {
  if (!bubbleState.references.tiles || !bubbleState.level?.state.bubbleWidth || !bubbleState.level.model || bubbleState.level.state.actualTopRowIndex === undefined) return

  bubbleState.references.tiles = [
    [],
    ...bubbleState.references.tiles
  ]

  let row = 0

  for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
    const modelSkinName = wave[column].skin

    if (modelSkinName === 'Empty' as BubbleSkinName) {
      continue
    }

    const bubble = initBubble(modelSkinName)

    if (bubble) {
      bubble.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)

      drawBubbleOnGrid(row, column, bubble)
    }
  }

  for (row = 1; row < bubbleState.references.tiles.length; row++) {
    for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
      const container = bubbleState.references.tiles[row][column]

      if (container) {
        const coords = rowColumnToCoord(row, column)

        if (!coords) return

        const {y} = coords

        //container.x = x - state.level.state.bubbleWidth * 0.5
        container.y = y - bubbleState.level.state.bubbleWidth * 0.5
      }
    }
  }

  //const floatingClusters = findFloatingClusters()

  //floatingClusters.forEach((floatingCluster) => {
  //  destroyBubbles(floatingCluster, false, undefined)
  //})

  //destroyLastLine()
}

export const destroyLastLine = () => {
  if (!bubbleState.references.tiles || !bubbleState.level) return

  const linesOut = bubbleState.references.tiles.slice(bubbleState.level.limitNumberOfRow - 1)

  if (linesOut.length > 0) {
    const bubblesToDestroy = []

    for (let row = 0; row < linesOut.length; row++) {
      for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
        const container = linesOut[row][column]

        if (!container) continue

        bubblesToDestroy.push({
          row: row + bubbleState.level.limitNumberOfRow - 1,
          column,
          bubble: container.children[0] as Spine
        })
      }
    }

    if (bubblesToDestroy.length > 0) {
      //bubbleState.level.state.lives -= bubblesToDestroy.length

      //setHeartCount(bubbleState.level.state.lives)

      destroyBubbles(bubblesToDestroy, false, bubblesToDestroy[Math.floor(bubblesToDestroy.length / 2)].bubble.getGlobalPosition())
    }

    // remove the empty rows
    bubbleState.references.tiles.splice(bubbleState.level.limitNumberOfRow - 1)
  }
}

export const updateBubbleSkin = (forceSkin?: BubbleSkinName) => {
  if (!bubbleState.level) return

  const bubbleSkins = existingBubbleSkinsInLevel(true)

  const skinName = bubbleState.references.bubbleInHand?.skeleton.skin?.name

  // If the next bubbles are not in the skins present in the current game, update it with a random skin present in the game
  if (forceSkin || (skinName && !bubbleSkins?.includes(skinName))) {
    const skin = forceSkin ?? randomBubbleSkinExistingInLevel()

    if (skin) {
      if (bubbleState.references.bubbleInCannon) {
        bubbleState.references.bubbleInCannon?.skeleton.setSkinByName(skin)
      } else {
        bubbleState.references.pirate?.skeleton.setSkinByName(skin)
        bubbleState.references.bubbleInHand?.skeleton.setSkinByName(skin)
      }
    }
  }
}

export const updateScoreAndStars = (destroyedBubbles: Cluster) => {
  if (!bubbleState.level) return

  const numberOfBubbles = destroyedBubbles.length

  // > 1 if bombs don't destroy anything, only the original bubble is destroyed
  if (numberOfBubbles <= 1) {
    randomBubblePopNeutral().play()
  } else {
    let addedScore = 0
    let multiply = 1

    if (numberOfBubbles >= 10 && numberOfBubbles < 20) {
      multiply = 2
      playCombo(SoundName.combo1)
    } else if (numberOfBubbles >= 20 && numberOfBubbles < 30) {
      multiply = 3
      playCombo(SoundName.combo2)
    } else if (numberOfBubbles >= 30) {
      multiply = numberOfBubbles / 10
      playCombo(SoundName.combo3)
    }

    addedScore = Math.floor((numberOfBubbles * 20) * multiply)

    bubbleState.level.state.score += addedScore

    if (multiply > 1) {
      const barycenter = {x: 0, y: 0}

      destroyedBubbles.forEach(({bubble}) => {
        if (!bubble) return

        barycenter.x += bubble.getGlobalPosition().x
        barycenter.y += bubble.getGlobalPosition().y
      })

      barycenter.x /= numberOfBubbles
      barycenter.y /= numberOfBubbles

      showScoreAndCombo(new Point(barycenter.x, barycenter.y), addedScore, multiply)
    }

    updateStars()
  }
}

export const updateStars = () => {
  if (!bubbleState.level || !bubbleState.references.ui.level.stars) return

  const activateStars = computeStars()

  if (activateStars > 0 && bubbleState.references.ui.level.stars[activateStars - 1].skeleton.skin?.name !== 'Full') {
    switch (activateStars) {
      case 1:
        playMusic(SoundName.music2)
        sound.find(bubbleState.level.ambienceSound).volume = 0.04
        break
      case 2:
        stopAllMusicAndAmbience()
        playMusic(SoundName.music3)
        break
      case 3:
        stopAllMusicAndAmbience()
        playMusic(SoundName.music4)
        break
    }

    for (let i = 0; i < activateStars; i++) {
      const star = bubbleState.references.ui.level.stars[i]

      if (star && star.skeleton.skin?.name === 'Empty') {
        star.skeleton.setSkinByName('Full')
        star.state.setAnimation(0, 'StarAppears')
      }
    }
  }
}

export const computeStars = () => {
  if (bubbleState.level?.state.score === undefined || !bubbleState.level.scoreStars) return 0

  const score = bubbleState.level.state.score
  const stars = bubbleState.level.scoreStars

  let nbStars = 0

  if (score > stars[2]) {
    nbStars = 3
  } else if (score > stars[1]) {
    nbStars = 2
  } else if (score > stars[0]) {
    nbStars = 1
  }

  return nbStars
}

export const endLevel = (bubblesLeft: number, afterComputeCallback: () => void) => {
  for (let i = bubblesLeft; i > 0; i--) {
    const actualIndex = i

    setTimeout(() => {
      spawnScoreBubble(() => {
        setBubbleCount(i - 1)

        updateStars()

        if (actualIndex === 1) {
          afterComputeCallback()
        }
      })
    }, (bubblesLeft - i) * 100)
  }
}

//export const updatePowerUp = (delta: number) => {
//  if (!bubbleState.viewport || !bubbleState.level) return

//  const powerUpList = bubbleState.references.powerUp

//  if (powerUpList && powerUpList.length > 0) {
//    const bubbleFired = bubbleState.references.bubbleFired
//    const powerUpExploded: number[] = []

//    powerUpList.forEach((powerUp, index) => {
//      if (!bubbleState.viewport || !powerUp) return

//      const sinusoide = 0.2 * Math.sin(powerUp.spine.x / 40)

//      powerUp.dy = sinusoide

//      powerUp.spine.x += powerUp.dx * delta
//      powerUp.spine.y += powerUp.dy * delta

//      const {x, y} = powerUp.spine.getGlobalPosition()

//      const halfSizePowerUp = powerUp.spine.width * 0.5

//      if (x + halfSizePowerUp <= 0 || x - halfSizePowerUp > getGameWidth() || y + halfSizePowerUp <= 0 || y - halfSizePowerUp > getGameHeight()) {
//        powerUpExploded.push(index)

//        return
//      }

//      if (bubbleFired) {
//        const {x: x2, y: y2} = bubbleFired.spine.getGlobalPosition()

//        if (distanceBetween(x, y, x2, y2) < powerUp.spine.width * 0.5 + bubbleFired.spine.width * 0.25) {
//          powerUpExploded.push(index)

//          explodePowerUp(powerUp.spine)
//        }
//      }
//    })

//    bubbleState.references.powerUp = powerUpList.filter((_, index) => !powerUpExploded.includes(index))
//  }
//}

/**
 * Returns if the level is empty (no bubbles + no waves)
 * @returns The level is empty
 */
export const isLevelEmpty = () => {
  if (!bubbleState.references.tiles || !bubbleState.level) return

  const bubbleInGame = bubbleState.references.tiles.find(row => row.find(bubble => bubble !== undefined))
  const remainingWaves = (bubbleState.level.state.actualTopRowIndex ?? 0) > 0

  return !bubbleInGame && !remainingWaves
}

/**
 * Returns the number of bubble the player can still send
 * @returns Number of bubble the player can still send
 */
export const remainingBubblesToFire = () => {
  if (!bubbleState.level) return 0

  return bubbleState.level.limitNumberOfBubbles - bubbleState.level.state.numberOfBubblesSent
}

/**
 * Creating the listeners of the level
 */
export const initLevelListeners = () => {
  if (!bubbleState.viewport || !bubbleState.references.cannon) return

  bubbleState.viewport.on('pointermove', (event) => {
    if (!bubbleState.references.cannon) return

    const {x, y} = event.global

    cannonLookAt(bubbleState.references.cannon, x, y)
  })

  bubbleState.viewport.on('pointertap', (event) => {
    if (bubbleState.level?.state.ended) return

    const {x, y} = event.global

    if (event.data.button === 0) {
      cannonFireAt(x, y)
    }
  })

  //initLevelEditor()
}

export const initLevelEditor = () => {
  if (!bubbleState.viewport || !bubbleState.references.tiles || !bubbleState.level) return

  let activeBubbleSkin: string | undefined = BubbleSkinName.BlueTwo
  let painting = false
  let tiles = JSON.parse(JSON.stringify(bubbleState.level?.model)) as string[][]

  if (!tiles) {
    tiles = []

    for (let row = 0; row < bubbleState.references.tiles.length; row++) {
      tiles[row] = []
      for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
        const bubble = bubbleState.references.tiles[row][column]?.children[0]

        if (bubble) {
          tiles[row][column] = (bubble as Spine).skeleton.skin?.name as string
        } else {
          tiles[row][column] = 'Empty'
        }
      }
    }
  }

  const createBubble = (x: number, y: number) => {
    if (!bubbleState.level || !bubbleState.references.tiles) return

    const {row, column} = coordToRowColumn(x, y)

    if (
      row === undefined ||
      column === undefined ||
      row < 0 ||
      row >= bubbleState.level.limitNumberOfRow ||
      column >= bubbleState.level.numberOfBubblePerRow ||
      column < 0
    ) return

    if (!bubbleState.references.tiles[row]) bubbleState.references.tiles[row] = []

    if (activeBubbleSkin) {
      const bubbleContainer = bubbleState.references.tiles[row][column]

      if (bubbleContainer) {
        destroyBubble(bubbleContainer.children[0] as Spine, 20)

        bubbleState.references.tiles[row][column] = undefined
        if (tiles) {
          tiles[row + (bubbleState.level.state.actualTopRowIndex ?? 0)][column] = 'Empty'
        }
      }

      const bubble = initBubble(activeBubbleSkin as BubbleSkinName)

      if (bubble) {
        bubble.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)

        drawBubbleOnGrid(row, column, bubble)

        if (tiles) {
          tiles[row + (bubbleState.level.state.actualTopRowIndex ?? 0)][column] = activeBubbleSkin
        }
      }
    } else {
      const bubble = bubbleState.references.tiles[row][column]

      if (bubble) {
        destroyBubble(bubble.children[0] as Spine, 20)

        bubbleState.references.tiles[row][column] = undefined
        if (tiles) {
          tiles[row + (bubbleState.level.state.actualTopRowIndex ?? 0)][column] = 'Empty'
        }
      }
    }
  }

  document.addEventListener('keypress', (event) => {
    if (!bubbleState.references.tiles || !bubbleState.level) return

    console.log(event.code)

    switch (event.code) {
      case 'KeyA':
        powerUpEffects.BombSkin!(bubbleState)
        bubbleState.level.state.nextBubbleDestroyEffect = undefined
        bubbleState.level.state.actualBubbleDestroyEffect = BubbleDestroyEffect.Bomb
        break
      case 'KeyS':
        powerUpEffects.FireSkin!(bubbleState)
        bubbleState.level.state.nextBubbleDestroyEffect = undefined
        bubbleState.level.state.actualBubbleDestroyEffect = BubbleDestroyEffect.Fire
        break
      case 'KeyD':
        powerUpEffects.HammerSkin!(bubbleState)

        bubbleState.level.state.nextBubbleCollideEffect = undefined
        bubbleState.level.state.actualBubbleCollideEffect = BubbleCollideEffect.Hammer

        bubbleState.level.state.combo = {
          destroyedBubbles: [],
          maxExplosionDelay: 0
        }
        break
      case 'KeyF':
        bubbleState.level.state.actualBubbleDestroyEffect = undefined
        bubbleState.level.state.actualBubbleCollideEffect = undefined
        break
      case 'Backquote':
        activeBubbleSkin = undefined
        break
      case 'Digit1':
        activeBubbleSkin = BubbleSkinName.BlueTwo
        break
      case 'Digit2':
        activeBubbleSkin = BubbleSkinName.RedTwo
        break
      case 'Digit3':
        activeBubbleSkin = BubbleSkinName.GreenTwo
        break
      case 'Digit4':
        activeBubbleSkin = BubbleSkinName.YellowTwo
        break
      case 'Digit5':
        activeBubbleSkin = BubbleSkinName.PurpleTwo
        break
      case 'Digit6':
        activeBubbleSkin = BubbleSkinName.BlackTwo
        break
      case 'KeyR':
        activeBubbleSkin = BubbleSkinName.ColoredBubble
        break
      case 'KeyE': // Export
        console.log(tiles)
        break
      case 'KeyC': // Clean
        if (!tiles) return

        for (let row = 0; row < bubbleState.references.tiles.length; row++) {
          for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
            tiles[row][column] = 'Empty'

            const bubbleContainer = bubbleState.references.tiles[row][column]

            if (bubbleContainer) {
              destroyBubble(bubbleContainer.children[0] as Spine, 20)

              bubbleState.references.tiles[row][column] = undefined
            }
          }
        }
        break
      case 'KeyQ': // Change to editor bubbles (colored for random)
        if (!tiles) return

        for (let row = 0; row < bubbleState.references.tiles.length; row++) {
          for (let column = 0; column < bubbleState.level.numberOfBubblePerRow; column++) {
            const skinName = tiles[row]?.[column]

            if (skinName === BubbleSkinName.ColoredBubble) {
              const bubbleContainer = bubbleState.references.tiles[row][column]

              if (bubbleContainer) {
                destroyBubble(bubbleContainer.children[0] as Spine, 20)

                bubbleState.references.tiles[row][column] = undefined
              }

              const bubble = initBubble(BubbleSkinName.ColoredBubble)

              if (bubble) {
                bubble.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)

                drawBubbleOnGrid(row, column, bubble)
              }
            }
          }
        }
        break
    }
  })

  bubbleState.viewport.on('pointermove', (event) => {
    if (!painting) return

    const {x, y} = event.global

    createBubble(x, y)
  })

  bubbleState.viewport.on('pointerup', (event) => {
    const {x, y} = event.global

    createBubble(x, y)

    if (event.button === 2) {
      painting = false
    }
  })

  bubbleState.viewport.on('pointerdown', (event) => {
    if (event.button === 2) {
      painting = true
    }
  })
}