import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Socket, io} from 'socket.io-client'
import {formatTextWithYOOH} from '../../../utils/global'
import {useHubContext} from '../../state/context'
import {slotsState} from './game/state'
import {SlotsClientPath, SlotsClientURL} from './slots'
import {clickAffirmativeSoundSlots, clickNegativeSoundSlots} from './sounds'
import {SlotsClientToServerEvents, SlotsGame, SlotsServerToClientEvents} from './types'

const confettiKey = 0

export const useSlots = () => {
  const {state: {tokens, sessionToken, userUuid}, dispatch} = useHubContext()
  const [confetti, setConffeti] = useState<JSX.Element[]>([])

  const [bet, setBet] = useState('')
  const [lines, setLines] = useState('1')
  const [repeat, setRepeat] = useState('1')
  const [actualRepeat, setActualRepeat] = useState(0)

  const [botPlaying, setBotPlaying] = useState(false)
  const [executeSpin, setExecuteSpin] = useState(false)

  const [socket, setSocket] = useState<Socket<SlotsServerToClientEvents, SlotsClientToServerEvents>>()
  const [isConnected, setIsConnected] = useState(false)
  const [addToHistory, setAddToHistory] = useState<SlotsGame>()
  const [history, setHistory] = useState<SlotsGame[]>([])
  const [myHistory, setMyHistory] = useState<SlotsGame[]>([])
  const [maxWin, setMaxWin] = useState<number>()
  const [spinning, setSpinning] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const spin = (bet: number, lines: number) => {
    if (!socket || (spinning && !botPlaying)) return

    if (bet === 0) {
      clickNegativeSoundSlots.play()

      toast.error(formatTextWithYOOH('Minimal amount is $YOOH 1'))

      return
    }

    const cost = bet * lines * 100

    if (maxWin === undefined || maxWin * 100 <= cost) {
      toast.error(formatTextWithYOOH(`Not enough $YOOH in the master account, max win is $YOOH ${(maxWin ?? 0)}, we encourage you to bet accordingly`))

      setSpinning(false)
      setBotPlaying(false)
      setActualRepeat(0)
    } else if ((tokens?.yooh ?? 0) >= cost) {
      if (slotsState.external.spin) {
        slotsState.external.spin(lines)
      }

      socket.emit('spin', bet, lines)

      clickAffirmativeSoundSlots.play()

      if (tokens?.yooh !== undefined) {
        dispatch({
          type: 'ADD_TOKENS',
          tokens: {
            yooh: -bet * lines * 100
          }
        })
      }
    } else {
      clickNegativeSoundSlots.play()

      setSpinning(false)
      setBotPlaying(false)
      setActualRepeat(0)

      toast.error(formatTextWithYOOH('Not enough $YOOH on your account to create the game'))
    }
  }

  const endSpin = (game: SlotsGame) => {
    if (!slotsState.external.endSpin) return

    slotsState.external.endSpin(game)
  }

  useEffect(() => {
    setSocket(undefined)
  }, [sessionToken])

  useEffect(() => {
    if (socket || !initialized) return

    let connected = false

    const newSocket: Socket<SlotsServerToClientEvents, SlotsClientToServerEvents> = io(SlotsClientURL, {
      path: SlotsClientPath
    })

    setSocket(newSocket)

    newSocket.on('connect', () => {
      if (sessionToken) {
        newSocket.emit('authenticate', sessionToken)
      } else {
        newSocket.emit('history')
      }
    })

    newSocket.on('connected', () => {
      setIsConnected(true)

      connected = true
    })

    newSocket.on('max-win', (yooh) => {
      setMaxWin(yooh)
    })

    newSocket.on('disconnect', () => {
      setIsConnected(false)

      connected = false
    })

    newSocket.on('history', (message) => {
      const parsedValue = JSON.parse(message)

      setHistory(parsedValue.history)

      if (parsedValue.myHistory) {
        setMyHistory(parsedValue.myHistory)
      }
    })

    newSocket.on('error', (message, code, data) => {
      if (data) {
        const parsedData = JSON.parse(data)

        if (parsedData.amount !== undefined && tokens) {
          dispatch({
            type: 'ADD_TOKENS',
            tokens: {
              yooh: (parsedData.amount ?? 0) * 100
            }
          })
        }
      }

      setSpinning(false)
      setBotPlaying(false)
      setActualRepeat(0)

      if (connected) {
        toast.error(formatTextWithYOOH(message))
      } else if (socket && sessionToken) {
        setTimeout(() => newSocket.emit('authenticate', sessionToken), 1000)
      }
    })
  }, [sessionToken, socket, initialized])

  useEffect(() => {
    if (!socket) return

    return () => {
      socket.disconnect()
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return

    socket.removeListener('add-history')
    socket.on('add-history', (message) => {
      const game = JSON.parse(message) as SlotsGame

      setHistory([
        game,
        ...history.slice(0, 8)
      ])

      if (game.user.userUuid === userUuid) {
        setMyHistory([
          game,
          ...myHistory.slice(0, 8)
        ])
      }
    })

    socket.removeListener('end-spin')
    socket.on('end-spin', (message) => {
      const game = JSON.parse(message) as SlotsGame

      setTimeout(() => {
        if (!game.results) return

        setAddToHistory(game)

        if (game.user.userUuid === userUuid) {
          let maxMultiplier = 0

          Object.values(game.results.lines).forEach((result) => {
            if (!result.wonMultiplier) return

            maxMultiplier = Math.max(maxMultiplier, result.wonMultiplier)
          })

          if (maxMultiplier >= 40) {
            setBotPlaying(false)
            setActualRepeat(0)
          } else if (botPlaying) {
            if ((actualRepeat < Number(repeat) || Number(repeat) === -1)) {
              setTimeout(() => {
                setExecuteSpin(true)
              }, 800)

              setActualRepeat(actualRepeat + 1)
            } else {
              setBotPlaying(false)
              setActualRepeat(0)
            }
          }

          if (game.won) {
            dispatch({
              type: 'ADD_TOKENS',
              tokens: {
                yooh: game.winnerAmount ?? 0
              }
            })
          }
        }
      }, 1400)

      endSpin(game)
    })
  }, [socket, tokens, userUuid, history, myHistory, confetti, bet, lines, botPlaying, repeat, actualRepeat])

  useEffect(() => {
    if (executeSpin && botPlaying) {
      spin(Number(bet), Number(lines))
    }

    setExecuteSpin(false)
  }, [executeSpin, botPlaying])

  useEffect(() => {
    if (!addToHistory) return

    const game = addToHistory

    setHistory([
      game,
      ...history.slice(0, 8)
    ])

    if (game.user.userUuid === userUuid) {
      setMyHistory([
        game,
        ...myHistory.slice(0, 8)
      ])
    }

    setAddToHistory(undefined)
  }, [addToHistory, history, myHistory])

  const stopBot = () => {
    setBotPlaying(false)
    setActualRepeat(0)
  }

  const startSpin = () => {
    if (Number(repeat) !== 1) {
      setBotPlaying(true)
      setActualRepeat(1)
    }

    spin(Number(bet), Number(lines))
  }

  return {
    spin,
    endSpin,
    setInitialized,
    setLines,
    setBet,
    setSpinning,
    setRepeat,
    setBotPlaying,
    stopBot,
    startSpin,
    isConnected,
    maxWin,
    history,
    myHistory,
    spinning,
    confetti,
    initialized,
    lines,
    bet,
    repeat,
    botPlaying
  }
}