@import "scss-variables";

.pay-table-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;

  .pay-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;
    font-size: 1rem;
    overflow: hidden;

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;
      width: 33%;
      height: 6px;
      border-radius: 6px;

      background: $color-blue;
      box-shadow: 0px -2px 8px 2px rgba($color-blue, 0.6), 0px -4px 17px 5px rgba($color-blue, 0.3);
    }

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0;
      font-size: 1.5rem;
      height: auto;
    }

    .table {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      font-size: 1.5rem;

      @media screen and (min-width: $sm-breakpoint) {
        grid-template-columns: 1fr 1fr;
        font-size: 2rem;
      }

      .element {
        display: flex;
        gap: 1rem;
        align-items: center;

        .symbols {
          display: flex;
          gap: 0.2rem;

          img {
            height: 1.5rem;
            @media screen and (min-width: $sm-breakpoint) {
              height: 2rem;
            }
          }
        }
      }
    }
  }
}
