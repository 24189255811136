import axios from 'axios'
import {DailyLeaderboard} from '../pages/dashboard/sub-pages/quests/daily-leaderboard/daily-leaderboard'
import {IQuest, TwitterProgress} from '../pages/dashboard/sub-pages/quests/types'
import {tryOrReconnect} from './hub'

export const getQuests = async () => {
  const result = await tryOrReconnect<{
    quests: IQuest[]
  }>({
    method: 'GET',
    url: '/api/v1/quests/'
  })

  if (!result || result.status !== 200) throw new Error('Impossible to retrieve the quests')

  return result.data
}

export const getDailyLeaderboard = async (date = new Date()) => {
  const response = await axios.get<{
    leaderboard: DailyLeaderboard
  }>('/api/v1/quests/daily-leaderboard', {
    params: {
      date: date.toISOString().split('T')[0]
    }
  })

  if (!response || response.status !== 200) throw new Error('Impossible to retrieve the daily reward leaderboard')

  return response.data.leaderboard
}

export const generateWLKeyRequest = async (discordUsername: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{key: string | undefined}>({
    url: '/api/v1/quests/wl-key',
    method: 'POST',
    data: {
      discordUsername
    }
  }, onError)

  return result?.data?.key
}

export const buyRaffleTicket = async (questID: string, onError?: (err: unknown) => void) => {
  return (await tryOrReconnect({
    url: '/api/v1/quests/raffle-ticket',
    method: 'POST',
    data: {
      questID
    }
  }, onError))?.status === 200
}

export const claimNFTRaffle = async (questID: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{nft: any}>({
    url: '/api/v1/quests/claim-nft-raffle',
    method: 'POST',
    data: {
      questID
    }
  }, onError)

  return result?.data
}

export const questProgress = async (questID: string, progress: TwitterProgress, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/quests/quest-progress',
    method: 'POST',
    data: {
      questID,
      progress
    }
  }, onError)

  return result
}