import {Dispatch} from 'react'
import {toast} from 'react-toastify'
import {changeUsernameRequest} from '../../../../api/hub'
import {Actions} from '../../../../state/actions'
import {hubState} from '../../../../state/hub'

export const changeUsername = (dispatch: Dispatch<Actions>) => {
  if (hubState.showInputModal) {
    hubState.showInputModal({
      title: 'Please enter your username',
      text: 'Used in games, chats, etc.',
      inputProps: {
        minLength: 2,
        maxLength: 16,
        placeholder: 'Your username',
        autoFocus: true
      },
      acceptText: 'save',
      async onAccept(username) {
        if (!username || username.length < 2 || username.length > 16) {
          if (hubState.showModal) {
            hubState.showModal({
              title: 'Wrong username format',
              text: 'The username should be between 2 and 16 characters',
              onClose() {
                changeUsername(dispatch)
              },
              closeText: 'understood'
            })
          }

          return
        }

        const response = await changeUsernameRequest(username)

        if (response?.status === 200) {
          toast.success(`"${username}" is now your username!`)

          dispatch({
            type: 'SET_USERNAME',
            username
          })
        } else {
          toast.error('Impossible to change your username, please try again later')
        }
      },
      refuseText: 'cancel'
    })
  }
}