import {sound} from '@pixi/sound'
import {SkeletonData, Spine} from 'pixi-spine'
import * as PIXI from 'pixi.js'
import {initBubble} from './bubble'
import {BubbleCollideEffect, BubbleDestroyEffect, BubbleSkinName, SoundName} from './enums'
import {fireAtMessage} from './server/message'
import {randomCannonFire} from './sound'
import {bubbleState} from './state'
import {getGameHeight, getGameWidth, getRatio} from './utils'

/**
 * Creating the cannon sprite
 * @returns The created cannon Spine object if data are available
 */
export const initCannon = (): Spine | undefined => {
  if (!bubbleState.viewport || !bubbleState.level || !bubbleState.resources?.cannon.spineData) return

  const ratio = getRatio()

  const cannonBase = new PIXI.Sprite(bubbleState.resources.cannonBase)
  cannonBase.scale.set(0.3 * ratio, 0.3 * ratio)
  cannonBase.x = getGameWidth() / 2 - cannonBase.width / 2
  cannonBase.y = getGameHeight() - cannonBase.height

  const cannon = new Spine(bubbleState.resources.cannon.spineData as SkeletonData)

  cannon.x = getGameWidth() / 2
  cannon.y = getGameHeight() - 30 * ratio

  cannon.scale.set(0.3 * ratio, 0.3 * ratio)

  bubbleState.viewport.addChild(cannonBase, cannon)

  cannon.skeleton.setSkinByName('CannonOneSkin')

  bubbleState.references.cannon = cannon

  cannon.zIndex = 10

  return cannon
}

/**
* Make the cannon look into the direction of the specified point
* @param cannon The cannon Spine object
* @param x The x coordinate to look at
* @param y The y coordinate to look at
*/
export const cannonLookAt = (cannon: Spine, x: number, y: number) => {
  const {x: x2, y: y2} = cannon.position

  const angle = Math.atan2(y2 - y, x2 - x) * (180 / Math.PI)

  // Cannot go lower than xx degrees from floor
  const limit = 30

  cannon.angle = (angle <= limit && angle > -90 ? limit : angle >= 180 - limit || angle < -90 ? 180 - limit : angle) - 90
}

/**
* Make the cannon fire in the direction of the specified point
* @param x The x coordinate to look at
* @param y The y coordinate to look at
* @param outCannonCallback Callback called after the OutCannon animation of the bubble
*/
export const cannonFireAt = (x: number, y: number, outCannonCallback?: () => void) => {
  if (
    !bubbleState.viewport ||
    !bubbleState.room ||
    !bubbleState.references.bubbleInCannon ||
    !bubbleState.level?.state.canShoot ||
    bubbleState.references.bubbleFired ||
    !bubbleState.references.cannon
  ) return

  const cannon = bubbleState.references.cannon
  const bubble = bubbleState.references.bubbleInCannon

  // Comment this line because we should not change the state sent by server locally (out of sync)
  //bubbleState.level.state.canShoot = false
  bubbleState.references.bubbleInCannon = undefined

  fireAtMessage(bubbleState.room, {x, y})

  cannonLookAt(cannon, x, y)

  bubble.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'OutCannon') {
        setTimeout(() => {
          if (outCannonCallback) outCannonCallback()

          bubble.destroy()
        }, 300)
      }
    }
  })

  cannon.state.setAnimation(0, 'ShootAnimation', false)
  bubble.state.setAnimation(0, 'OutCannon', false)

  const bubbleFired = initBubble(bubble.skeleton.skin?.name as BubbleSkinName)

  if (!bubbleFired) return

  bubbleFired.scale.set(bubbleState.level.state.bubbleWidthRatio, bubbleState.level.state.bubbleWidthRatio)

  const rad = (cannon.angle - 90) * (Math.PI / 180)

  const dx = bubbleState.level.state.bubbleSpeed * Math.cos(rad)
  const dy = bubbleState.level.state.bubbleSpeed * Math.sin(rad)

  bubbleState.references.bubbleFired = {
    dx: dx * 0.7, // 0.7 to compensate server latency
    dy: dy * 0.7,
    dxOriginal: dx,
    dyOriginal: dy,
    spine: bubbleFired
  }

  bubbleFired.x = getGameWidth() / 2 + (dx * 2 * getRatio()) // 2 and not 5 to compensate the server latency
  bubbleFired.y = getGameHeight() - 30 * getRatio() + (dy * 2 * getRatio())

  bubbleState.viewport.addChild(bubbleFired)

  if (bubbleState.level.state.actualBubbleDestroyEffect === BubbleDestroyEffect.Bomb || bubbleState.level.state.actualBubbleCollideEffect === BubbleCollideEffect.Hammer) {
    randomCannonFire().play()
  }
}

/**
 * Load a bubble into the cannon
 * @param cannon The cannon Spine object
 * @param bubble The bubble Spine object
 */
export const loadBubbleIntoCannon = (cannon: Spine, bubble: Spine, InCannonCallback?: () => void) => {
  if (InCannonCallback) {
    bubble.state.addListener({
      complete(entry) {
        if (entry.animation?.name === 'InCannon') {
          InCannonCallback()
        }
      }
    })
  }

  bubble.state.setAnimation(0, 'InCannon', false)

  bubble.scale.set(0.45, 0.45)

  bubble.x = 2
  bubble.y = -13

  cannon.addChild(bubble)

  sound.find(SoundName.cannonLoadBubble).play()
}