import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {isAxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {YOOH} from '../../../svg/token'
import {PFP_CDN_URL} from '../../../utils/constants'
import {formatClassName, formatTextWithYOOH} from '../../../utils/global'
import {getProfile, sendTipRequest} from '../../api/hub'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import {Button, ButtonIcon} from '../buttons/button'
import {OverlayModal, OverlayModalProps} from '../modal/modal'
import {LoadingOverlay} from '../overlay/loading'
import styles from './profile-modal.module.scss'

export type ProfileModalProps = OverlayModalProps & {
  onHide: () => void
}

const numberFormat = new Intl.NumberFormat('en-US')

export const ProfileModal = ({className, onHide, ...props}: ProfileModalProps) => {
  const {state: {profileUuid, tokens}} = useHubContext()
  const [profile, setProfile] = useState<{
    username: string
    pfp: string
    totalWagered: number
    wonGames: number
    lostGames: number
    bestStreak: number
    worstStreak: number
  }>()
  const [loading, setLoading] = useState(true)

  const sendTip = async (userUuid?: string) => {
    if (!userUuid) return

    if (hubState.showInputModal) {
      hubState.showInputModal({
        title: 'Send tip',
        async onAccept(value) {
          if (!value || isNaN(Number(value)) || Number(value) < 0.01) {
            toast.error(formatTextWithYOOH('The tip should be at least $YOOH 0.01'))

            return
          }

          const amount = Number(value)

          const response = await sendTipRequest(amount, userUuid, (err) => {
            if (isAxiosError(err)) {
              toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the user stats, please contact the support team')
            } else {
              console.log(err, isAxiosError(err))
              toast.error('Impossible to retrieve the user stats, please contact the support team')
            }
          })

          if (response?.status === 200) {
            toast.success(formatTextWithYOOH(`$YOOH ${amount} tip sent`))
          }
        },
        inputProps: {
          placeholder: `Tip you want to send (from 0.01 to ${(tokens?.yooh ?? 0) / 100}`
        },
        acceptText: 'send',
        refuseText: 'cancel'
      })
    }
  }

  const refreshUserStats = async (userUuid: string) => {
    setLoading(true)

    const response = await getProfile(userUuid, (err) => {
      if (isAxiosError(err)) {
        toast.error(err.response?.data?.message ? err.response.data.message : 'Impossible to retrieve the user stats, please contact the support team')
      } else {
        console.log(err, isAxiosError(err))
        toast.error('Impossible to retrieve the user stats, please contact the support team')
      }
    })

    if (response?.status === 200) {
      setProfile(response.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!profileUuid) return

    refreshUserStats(profileUuid)
  }, [profileUuid])

  const totalGames = (profile?.wonGames ?? 0) + (profile?.lostGames ?? 0)

  return <OverlayModal className={formatClassName(styles, `profile-modal-overlay ${className}`)} {...props}>
    <div className={formatClassName(styles, 'profile-modal')}>
      {loading && <LoadingOverlay className='blur' />}
      <ButtonIcon className={formatClassName(styles, 'close-button no-color')} icon="xmark" onClick={onHide}></ButtonIcon>
      <div className={formatClassName(styles, 'top')}>
        <div className={formatClassName(styles, 'user')}>
          <div className={formatClassName(styles, 'pfp')}>
            <img src={`${PFP_CDN_URL}/${profile?.pfp ?? 'anon.jpg'}`} alt="pfp" />
          </div>
          {profile?.username}
        </div>
        <Button className={formatClassName(styles, 'no-color tip')} onClick={() => sendTip(profileUuid)}><YOOH /> Send Tip</Button>
      </div>
      <div className={formatClassName(styles, 'title')}>Statistics</div>
      <div className={formatClassName(styles, 'stats-container')}>
        <div className={formatClassName(styles, 'grouped unified-bg')}>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>TOTAL GAMES</div>
            <div className={formatClassName(styles, 'value')}>{numberFormat.format(totalGames)}</div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>GAMES WON</div>
            <div className={formatClassName(styles, 'value')}>
              <FontAwesomeIcon icon="award" />
              {numberFormat.format(profile?.wonGames ?? 0)}
            </div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>GAMES LOST</div>
            <div className={formatClassName(styles, 'value')}>
              <FontAwesomeIcon icon="skull" />
              {numberFormat.format(profile?.lostGames ?? 0)}
            </div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>WIN RATIO</div>
            <div className={formatClassName(styles, 'value')}>{Number((((profile?.wonGames ?? 0) / totalGames) * 100).toFixed(2))}%</div>
          </div>
        </div>

        <div className={formatClassName(styles, 'grouped')}>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>BEST & WORST STREAKS</div>
            <div className={formatClassName(styles, 'value')}><FontAwesomeIcon icon="award" />{(profile?.bestStreak ?? 0)} / <FontAwesomeIcon icon="skull" />{(profile?.worstStreak ?? 0)}</div>
          </div>
          <div className={formatClassName(styles, 'stats')}>
            <div className={formatClassName(styles, 'name')}>TOTAL WAGERED</div>
            <div className={formatClassName(styles, 'value')}><YOOH />{numberFormat.format((profile?.totalWagered ?? 0) / 100)}</div>
          </div>
        </div>
      </div>
    </div>
  </OverlayModal>
}