import {tryOrReconnect} from './hub'

export const withdrawRequest = async (amount: number, toToken: string, onError?: (err: unknown) => void) => {
  const response = await tryOrReconnect<{message: string}>({
    url: '/api/v1/liquidity/withdraw',
    method: 'post',
    data: {
      amount,
      toToken
    }
  }, onError)

  return response
}

export type LiquidityHistoryResponseType = {
  depositHistory: {
    signature: string
    createdAt: number
    amount: number
    tokenMint: string
  }[]
  withdrawHistory: {
    signature: string
    createdAt: number
    amount: number
    tokenMint: string
  }[]
}

export const liquidityHistory = async (onError?: (err: unknown) => void) => {
  const response = await tryOrReconnect<LiquidityHistoryResponseType>({
    url: '/api/v1/liquidity/history',
    method: 'get'
  }, onError)

  return response
}