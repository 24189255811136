import {bombzState} from './state'

export const getBombzGameWidth = () => {
  if (!bombzState.app) return 0

  return (bombzState.app.view.parentNode as HTMLElement).offsetWidth
}

export const getBombzGameHeight = () => {
  if (!bombzState.app) return 0

  return (bombzState.app.view.parentNode as HTMLElement).offsetHeight
}