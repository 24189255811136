import Tick from '@pqina/flip'
import '@pqina/flip/dist/flip.min.css'
import React, {useEffect, useRef} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './flip.module.scss'

export type FlipDateProps = {
  date: Date
  format?: string[]
  onEnded?: () => void
  showLabels?: boolean
}

export const FlipDate = ({date, onEnded, format = ['h', 'm', 's'], showLabels = true}: FlipDateProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current || !date) return

    let instance = Tick.DOM.find(ref.current)

    if (instance) {
      const counter = Tick.count.down(date, {format})

      // update tick with the counter value
      counter.onupdate = function(value: any) {
        instance.value = value
      }

      counter.onended = onEnded
    } else {
      instance = Tick.DOM.create(ref.current, {
        didInit: function(tick: any) {
          const counter = Tick.count.down(date, {format})

          // update tick with the counter value
          counter.onupdate = function(value: any) {
            tick.value = value
          }

          counter.onended = onEnded
        }
      })
    }

    return () => {
      Tick.DOM.destroy(instance)
    }
  }, [ref, date, format, onEnded])

  return <div ref={ref} className={formatClassName(styles, 'flip')}>
    <div data-repeat="true" data-layout="horizontal fit" data-transform={`preset(${format.join(',')}) -> delay`}>
      <div className={formatClassName(styles, 'tick-group')}>
        <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">
          <span data-view="flip"></span>
        </div>
        {
          showLabels && <span data-key="label" data-view="text" className={formatClassName(styles, 'tick-label')}></span>
        }
      </div>
    </div>
  </div>
}