@import "scss-variables";

.daily-leaderboard-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Baloo Thambi" !important;
  font-size: 1rem;

  .daily-leaderboard {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $color-gray;
    border-radius: 1rem;
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;
    background-color: $color-gray;
    overflow: hidden;

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;
      width: 33%;
      height: 6px;
      border-radius: 6px;

      background: $color-success;
      box-shadow: 0px -2px 8px 2px rgba($color-success, 0.6), 0px -4px 17px 5px rgba($color-success, 0.3);
    }

    .title {
      position: relative;
      font-family: "Riffic";
      font-size: 1.5rem;
      text-align: center;
      padding: 0 1rem;

      .close-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0;
        font-size: 1.5rem;
      }
    }

    .countdown {
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      & > div {
        max-width: 200px;
      }
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      img {
        width: 10px;
        height: 10px;
      }

      .detail {
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        background: $color-dark-gray;
        border-radius: 0.5rem;
        gap: 0.25rem;
        white-space: nowrap;
        font-size: 0.75rem;
      }
    }

    .leaderboard {
      overflow-y: auto;

      table {
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          background-color: $color-gray;
        }

        th {
          text-align: left;
        }

        td {
          padding-bottom: 0.5rem;

          img {
            height: 10px;
            width: 10px;
          }
        }

        .progress {
          width: 40px;
          height: 0.5rem;

          .bar {
            height: 100%;
            background-color: $color-blue;

            &.top {
              background-color: $color-success;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      margin-top: auto;

      .refresh {
        flex: 1;
      }
    }
  }

  .profile {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
