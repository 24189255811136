@import "scss-variables";

.alert {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  background-color: #fff1;
  border-radius: 0.5rem;
  border-left: 0.5rem solid gray;
  font-size: smaller;

  .content {
    flex: 1;

    img {
      max-width: 10px;
      max-height: 10px;
      padding: 0 0.2rem;
    }
  }

  .close {
    cursor: pointer;
  }

  &.success {
    color: $color-success;
    border-left-color: $color-success;
  }

  &.info {
    color: $color-info;
    border-left-color: $color-info;
  }

  &.warning {
    color: $color-warning;
    border-left-color: $color-warning;
  }

  &.danger {
    color: $color-danger;
    border-left-color: $color-danger;
  }
}
