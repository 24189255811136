import {bubbleState} from './state'

export const executeLevelComplete = (levelCompleteName: string, stars: number) => {
  let fct

  switch (levelCompleteName) {
    case 'checkWL':
      fct = checkWL
      break
    case 'checkOG':
      fct = checkOG
      break
  }

  if (fct) {
    fct(stars)
  }
}

export const checkWL = (stars: number) => {
  if (bubbleState.external.showModal && bubbleState.external.showLoading) {
    if (stars === 3) {
      //bubbleState.external.showLoading(true, undefined, 'Generating WL key...')

      //const key = await generateWLKeyRequest((err) => {
      //  if (axios.isAxiosError(err) && err.response?.status === 400 && err.response.data === 'Too much WL' && bubbleState.external.showModal) {
      //    bubbleState.external.showModal({
      //      title: 'Too late! The WL has been fully distributed for now, come back for the next wave (announced on Twitter)'
      //    })
      //  }
      //})

      //if (key) {
      //  bubbleState.external.showModal({
      //    title: 'WL key to enter in the Discord "claim-wl" chan (valid 10 min)',
      //    text: key,
      //    copyText: key,
      //    image: BUBBLE_CDN_URL + '/ui/big-chest.png'
      //  })
      //} else {
      //  bubbleState.external.showLoading(false)
      //}
      bubbleState.external.showModal({
        title: 'You finished the WL level!',
        text: 'Please, go back to quests and complete the associated quest to claim your WL key!'
      })
    } else {
      bubbleState.external.showModal({
        title: 'You need 3 stars to unlock the WL! Try again'
      })
    }
  }
}

export const checkOG = (stars: number) => {
  if (bubbleState.external.showModal) {
    bubbleState.external.showModal({
      title: 'OG processus is still in development, sorry!'
    })
  }
}