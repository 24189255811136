@import "scss-variables";

.bubble-lobby {
  display: flex;
  position: absolute;
  padding: 1rem;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5rem);
  background: #ffffff88;
  box-sizing: border-box;
  gap: 1rem;
  color: #333;

  .room-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;

    input {
      background-color: white;
      color: #666;
    }

    h2 {
      margin-top: 0;
      font-weight: normal;
      text-align: center;
    }

    .status {
      text-align: center;
    }

    .rooms {
      flex: 1;
      overflow-y: auto;

      .rooms-grid {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        align-items: center;
        gap: 0.5rem;

        .header {
          font-style: italic;
        }
      }
    }

    .actions {
      display: flex;
      gap: 1rem;

      .exit-button {
        color: #333;

        &:hover:not(:disabled),
        &.selected {
          background-color: #33333311 !important;
        }
      }

      .create-room-button {
        flex: 1;
      }
    }
  }
}
