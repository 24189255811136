import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Decimal from 'decimal.js'
import React, {ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import Countdown, {calcTimeDelta} from 'react-countdown'
import {toast} from 'react-toastify'
import {YOOH} from '../../../svg/token'
import {CDN_URL} from '../../../utils/constants'
import {formatClassName} from '../../../utils/global'
import {Button, ButtonIcon} from '../../components/buttons/button'
import {ConnectButton} from '../../components/buttons/connect-button'
import {Input} from '../../components/input/input'
import {PopoverHelp} from '../../components/popover/popover'
import {Toggle} from '../../components/toggle/toggle'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import {SLOTS_NUMBER_OF_LINES} from './constants'
import {initSlotsGame} from './game'
import {SlotsSymbolName} from './game/enums'
import {slotsState} from './game/state'
import {useSlots} from './hook'
import {PayTable} from './pay-table/pay-table'
import styles from './slots.module.scss'
import {bgmSoundSlots, coinsSoundSlots, selectSoundSlots} from './sounds'
import {SlotsGame} from './types'

const timeAgoRenderer = (createdAt: number) => {
  const {seconds, minutes, hours, days} = calcTimeDelta(createdAt, {overtime: true})

  return <span className={formatClassName(styles, 'countdown')}>
    {
      days > 0 || hours > 0
        ? 'hours ago'
        : minutes > 0
          ? `${minutes}m ago`
          : `${seconds}s ago`
    }
  </span>
}

export type SlotsProps = HtmlHTMLAttributes<HTMLDivElement>

//export const SlotsClientURL = 'ws://localhost:3023'
//export const SlotsClientPath = undefined
//export const SlotsClientURL = 'wss://test-server.piratesquadnft.com'
//export const SlotsClientPath = '/slots/socket.io'
//export const SlotsClientURL = 'wss://s1.piratesquadnft.com'
//export const SlotsClientPath = '/slots/socket.io'
export const SlotsClientURL = 'wss://s2.piratesquadnft.com'
export const SlotsClientPath = '/slots/socket.io'

export const Slots = ({className, ...props}: SlotsProps) => {
  const {dispatch, state: {tokens, sessionToken, userUuid}} = useHubContext()
  const {
    setInitialized,
    setBet,
    setLines,
    setSpinning,
    setRepeat,
    stopBot,
    startSpin,
    isConnected,
    maxWin,
    history,
    myHistory,
    confetti,
    bet,
    lines,
    spinning,
    initialized,
    repeat,
    botPlaying
  } = useSlots()

  const [myGamesChecked, setMyGamesChecked] = useState(false)
  const [soundMuted, setSoundMuted] = useState(false)
  const [showPayTable, setShowPayTable] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const betChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number)) {
      setBet(value)
    } else {
      if (number < 0) {
        setBet('0')
      } else {
        setBet(value)
      }
    }
  }

  const linesChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number) || value.length === 0) {
      if (slotsState.external.showLineIndicators) {
        slotsState.external.showLineIndicators(1)
      }

      setLines('')
    } else {
      const lines = Math.max(1, Math.min(number, SLOTS_NUMBER_OF_LINES))

      if (slotsState.external.showLineIndicators) {
        slotsState.external.showLineIndicators(lines)
      }

      setLines(String(lines))
    }
  }

  const repeatChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    const number = Number(value)

    if (isNaN(number) || value.length === 0) {
      setRepeat('')
    } else {
      setRepeat(String(Math.max(number, -1)))
    }
  }

  const allIn = () => {
    if (tokens?.yooh === undefined) return

    coinsSoundSlots.play()

    betChange({target: {value: String(tokens.yooh / 100)}} as any)
  }

  useEffect(() => {
    if (!canvasRef.current) return

    bgmSoundSlots.play()

    initSlotsGame(
      canvasRef.current,
      {
        pauseRefreshToken: (pause) => {
          dispatch({
            type: 'SET_PAUSE_REFRESH_TOKENS',
            pauseRefreshTokens: pause
          })
        },
        setSpinning
      }
    ).then((clearGame) => {
      hubState.clearGame = () => {
        if (clearGame) clearGame()

        bgmSoundSlots.stop()

        hubState.clearGame = undefined
      }

      if (slotsState.external.showLineIndicators) {
        slotsState.external.showLineIndicators(1)
      }

      setInitialized(true)
    }).catch(err => {
      toast.error('Error while creating the game viewer, please contact the support team')

      console.error(err)
    })

  }, [canvasRef])

  const myGamesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMyGamesChecked(event.target.checked)
  }

  const openProfile = (userUuid: string) => {
    if (!sessionToken) {
      dispatch({
        type: 'SET_SHOW_CONNECT_MODAL',
        showConnectModal: true
      })

      return
    }

    dispatch({
      type: 'SET_SHOW_PROFILE_MODAL',
      showProfileModal: true,
      userUuid
    })
  }

  const getBadge = (game: SlotsGame) => {
    if (!game.results) return

    const biggestMultiplier = Object.values(game.results.lines).sort((a, b) => (b.wonMultiplier ?? 0) - (a.wonMultiplier ?? 0))[0]

    let item: SlotsSymbolName | undefined

    if (biggestMultiplier.wonMultiplier) {
      if (biggestMultiplier.wonMultiplier === 1 || biggestMultiplier.wonMultiplier === 3) {
        item = SlotsSymbolName.cherry
      } else if (biggestMultiplier.wonMultiplier >= 10) {
        item = biggestMultiplier.items[0]
      }
    }

    return item
  }

  const toggleSound = () => {
    bgmSoundSlots.mute(!soundMuted)

    setSoundMuted(!soundMuted)
  }

  return (
    <div className={formatClassName(styles, `slots ${className ? className : ''}`)} {...props}>
      <div className={formatClassName(styles, 'game-container')}>
        <div className={formatClassName(styles, 'game')}>
          <div className={formatClassName(styles, 'settings')}>
            <div className={formatClassName(styles, 'setting')}>
              <div className={formatClassName(styles, 'title')}>
                <div>
                  Parameters <PopoverHelp>
                    <>
                      <div>How to play</div>
                      <ul>
                        <li>
                          Set wager amount in $YOOH
                        </li>
                        <li>
                          Set the number of lines you want to play with
                        </li>
                        <li>
                          Click the "spin" button when ready to spin!
                        </li>
                      </ul>
                      <div>
                        The maximum you can win is shown after "max win". Even if you have a bigger multiplier, the reward will never be higher than this max win amount
                      </div>
                    </>
                  </PopoverHelp>
                </div>
                <FontAwesomeIcon className={formatClassName(styles, 'sound')} icon={soundMuted ? 'volume-xmark' : 'volume-low'} onClick={toggleSound} />
              </div>
              <div className={formatClassName(styles, 'content parameters')}>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div className={formatClassName(styles, 'label')}>Bet Amount (max win: <YOOH />{maxWin})</div>
                    <div className={formatClassName(styles, 'all-in')} onClick={allIn}>max bet</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <YOOH />
                    <Input type="number" placeholder='0.00' min={0} value={bet} onChange={betChange} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).add(1).toString()}} as any)
                        coinsSoundSlots.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        betChange({target: {value: new Decimal(Number(bet)).minus(1).toString()}} as any)
                        coinsSoundSlots.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div>Number of lines (1-{SLOTS_NUMBER_OF_LINES})</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <FontAwesomeIcon icon="bars" />
                    <Input type="number" placeholder={`number of lines (1 - ${SLOTS_NUMBER_OF_LINES})`} min={1} max={SLOTS_NUMBER_OF_LINES} value={lines} onChange={linesChange} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        linesChange({target: {value: Number(lines) + 1}} as any)
                        selectSoundSlots.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        linesChange({target: {value: Number(lines) - 1}} as any)
                        selectSoundSlots.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
                <div className={formatClassName(styles, 'parameter')}>
                  <div className={formatClassName(styles, 'name')}>
                    <div>Number of spins</div>
                  </div>
                  <div className={formatClassName(styles, 'input')}>
                    <FontAwesomeIcon icon="repeat" />
                    <Input type="text" placeholder={'number of spins'} min={1} value={Number(repeat) === -1 ? 'infinity' : repeat} onChange={repeatChange} />
                    <ButtonIcon className={formatClassName(styles, 'infinity')} title="infinity" icon="infinity" onClick={() => {
                      repeatChange({target: {value: -1}} as any)
                      selectSoundSlots.play()
                    }} />
                    <div className={formatClassName(styles, 'increment')}>
                      <Button onClick={() => {
                        repeatChange({target: {value: Number(repeat) + 1}} as any)
                        selectSoundSlots.play()
                      }}>+</Button>
                      <Button onClick={() => {
                        repeatChange({target: {value: Number(repeat) - 1}} as any)
                        selectSoundSlots.play()
                      }}>-</Button>
                    </div>
                  </div>
                </div>
                <a href="#" onClick={() => setShowPayTable(true)}>show pay table</a>
              </div>
            </div>
            <div className={formatClassName(styles, 'setting create')}>
              {
                !sessionToken
                  ? <ConnectButton />
                  : botPlaying
                    ? <ButtonIcon icon="right-from-bracket" onClick={stopBot}>
                      Stop autoplay
                    </ButtonIcon>
                    : <Button loading={spinning || !isConnected || !tokens || !initialized} loadingWithChildren onClick={startSpin}>
                      {!isConnected ? 'Connecting' : !tokens ? 'Loading tokens' : spinning ? 'Spinning' : <span className={formatClassName(styles, 'label')}>Spin for <YOOH /> {Number(lines) * Number(bet)}</span>}
                    </Button>
              }
              <div className={formatClassName(styles, 'confetti')}>
                {confetti}
              </div>
            </div>
          </div>
          <div className={formatClassName(styles, 'viewer')}>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, 'history-games')}>
        <div className={formatClassName(styles, 'title')}>
          History
          {
            sessionToken && <span className={formatClassName(styles, 'my-games-toggle')} onClick={() => myGamesChange({target: {checked: !myGamesChecked}} as any)} >
              <Toggle containerClassName={formatClassName(styles, 'toggle')} checked={myGamesChecked} readOnly />
              <span>only my games</span>
            </span>
          }
        </div>
        <div className={formatClassName(styles, 'list')}>
          {
            (myGamesChecked ? myHistory : history).slice(0, 9).map(game => {
              const myGame = game.user.userUuid === userUuid
              const won = !!game.won

              return <div key={`history-${game.roundId}`} className={formatClassName(styles, `history ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                <div className={formatClassName(styles, 'info')}>
                  {myGame
                    ? <>
                      <div className={formatClassName(styles, `username ${myGame ? won ? 'won' : 'lost' : ''}`)}>
                        You {won ? <>won <YOOH /> {(game.winnerAmount ?? 0) / 100}</> : <>lost <YOOH /> {((game.formattedAmount ?? 0) * game.lines) / 100}</>}
                      </div>
                      <div>
                        vs PirateSquad
                      </div>
                    </>
                    : <>
                      <div className={formatClassName(styles, 'username')}>
                        {won ? <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.user.userUuid)}>{game.user.username}</span> : 'PirateSquad '}
                        won <YOOH /> {(game.winnerAmount ?? 0) / 100}
                      </div>
                      <div>
                        vs {won ? 'PirateSquad' : <span className={formatClassName(styles, 'profile')} onClick={() => openProfile(game.user.userUuid)}>{game.user.username}</span>}
                      </div>
                    </>
                  }
                </div>
                <div className={formatClassName(styles, 'badge')}>
                  {getBadge(game) &&
                    <img src={`${CDN_URL}/games/slots/images/${getBadge(game)}.png`} alt={getBadge(game)} />
                  }
                </div>
                <div className={formatClassName(styles, 'time-ago')}>
                  <Countdown date={game.createdAt} renderer={() => timeAgoRenderer(game.endedAt ?? game.createdAt)} overtime></Countdown>
                </div>
              </div>
            })
          }
        </div>
      </div>
      <PayTable show={showPayTable} onHide={() => setShowPayTable(false)} onClickBackground={() => setShowPayTable(false)} />
    </div>
  )
}