import {ease} from 'pixi-ease'
import * as PIXI from 'pixi.js'
import {Graphics} from 'pixi.js'
import {bubbleState} from './state'
import {getGameHeight, getGameWidth} from './utils'

export const initBlueBackground = () => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const background = new Graphics()
    .beginFill(0xE7F6FF)
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  const clouds = new PIXI.Sprite(bubbleState.resources.whiteClouds)
  const cloudsWidthRatio = getGameWidth() / clouds.width
  clouds.scale.set(cloudsWidthRatio, cloudsWidthRatio)
  clouds.x = getGameWidth() / 2 - clouds.width / 2
  clouds.y = getGameHeight() - clouds.height

  const ground = new PIXI.Sprite(bubbleState.resources.ground)
  const groundWidthRatio = getGameWidth() / ground.width
  ground.scale.set(groundWidthRatio, groundWidthRatio)
  ground.x = getGameWidth() / 2 - ground.width / 2
  ground.y = getGameHeight() - ground.height

  bubbleState.viewport.addChild(background, clouds, ground)
}

export const initYellowBackground = () => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const background = new Graphics()
    .beginFill(0xFFF6E1)
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  const sun = new PIXI.Sprite(bubbleState.resources.sun)
  const sunWidthRatio = (getGameWidth() / sun.width) * 0.5
  sun.scale.set(sunWidthRatio, sunWidthRatio)
  sun.x = getGameWidth() / 2 - sun.width * 0.4
  sun.y = getGameHeight() - sun.height * 1.8

  const clouds = new PIXI.Sprite(bubbleState.resources.whiteCloudsTwo)
  const cloudsWidthRatio = getGameWidth() / clouds.width
  clouds.scale.set(cloudsWidthRatio, cloudsWidthRatio)
  clouds.x = getGameWidth() / 2 - clouds.width / 2
  clouds.y = getGameHeight() - clouds.height

  const ground = new PIXI.Sprite(bubbleState.resources.ground)
  const groundWidthRatio = getGameWidth() / ground.width
  ground.scale.set(groundWidthRatio, groundWidthRatio)
  ground.x = getGameWidth() / 2 - ground.width / 2
  ground.y = getGameHeight() - ground.height

  bubbleState.viewport.addChild(background, sun, clouds, ground)
}

export const initGreyBackground = () => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const background = new Graphics()
    .beginFill(0xE8E8E8)
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  const smallWhiteClouds = new PIXI.Sprite(bubbleState.resources.smallWhiteClouds)
  const smallWhiteCloudsWidthRatio = (getGameWidth() / smallWhiteClouds.width) * 0.5
  smallWhiteClouds.scale.set(smallWhiteCloudsWidthRatio * 0.8, smallWhiteCloudsWidthRatio * 0.8)
  smallWhiteClouds.x = getGameWidth() / 2 - smallWhiteClouds.width * 1.35 - 8
  smallWhiteClouds.y = getGameHeight() - smallWhiteClouds.height * 1.8

  const smallWhiteClouds2 = new PIXI.Sprite(bubbleState.resources.smallWhiteClouds)
  smallWhiteClouds2.scale.set(smallWhiteCloudsWidthRatio, smallWhiteCloudsWidthRatio)
  smallWhiteClouds2.x = getGameWidth() / 2 - smallWhiteClouds2.width * 0.5 - 8
  smallWhiteClouds2.y = getGameHeight() - smallWhiteClouds2.height * 1.6

  const smallWhiteClouds3 = new PIXI.Sprite(bubbleState.resources.smallWhiteClouds)
  smallWhiteClouds3.scale.set(smallWhiteCloudsWidthRatio * 0.8, smallWhiteCloudsWidthRatio * 0.8)
  smallWhiteClouds3.x = getGameWidth() / 2 + smallWhiteClouds3.width * 0.25 - 8
  smallWhiteClouds3.y = getGameHeight() - smallWhiteClouds3.height * 1.8

  const smallLightGreyClouds = new PIXI.Sprite(bubbleState.resources.smallLightGreyClouds)
  const smallLightGreyCloudsWidthRatio = (getGameWidth() / smallLightGreyClouds.width) * 0.5
  smallLightGreyClouds.scale.set(smallLightGreyCloudsWidthRatio * 0.6, smallLightGreyCloudsWidthRatio * 0.6)
  smallLightGreyClouds.x = getGameWidth() / 2 + smallLightGreyClouds.width * 0.1 - 5
  smallLightGreyClouds.y = getGameHeight() - smallLightGreyClouds.height * 2.4

  const smallLightGreyClouds2 = new PIXI.Sprite(bubbleState.resources.smallLightGreyClouds)
  smallLightGreyClouds2.scale.set(smallLightGreyCloudsWidthRatio * 0.6, smallLightGreyCloudsWidthRatio * 0.6)
  smallLightGreyClouds2.x = getGameWidth() / 2 - smallLightGreyClouds2.width * 1.2
  smallLightGreyClouds2.y = getGameHeight() - smallLightGreyClouds2.height * 2.3 - 5

  ease.add(smallLightGreyClouds, {
    x: smallLightGreyClouds.x + 10,
  }, {
    repeat: -1,
    reverse: true,
    duration: 4000
  })

  ease.add(smallLightGreyClouds2, {
    x: smallLightGreyClouds2.x + 10,
  }, {
    repeat: -1,
    reverse: true,
    wait: 1000,
    duration: 5000
  })

  ease.add(smallWhiteClouds, {
    x: smallWhiteClouds.x + 15,
  }, {
    repeat: -1,
    reverse: true,
    duration: 4000
  })

  ease.add(smallWhiteClouds2, {
    x: smallWhiteClouds2.x + 15,
  }, {
    repeat: -1,
    reverse: true,
    wait: 1000,
    duration: 5000
  })

  ease.add(smallWhiteClouds3, {
    x: smallWhiteClouds3.x + 15,
  }, {
    repeat: -1,
    reverse: true,
    duration: 4500
  })

  const sea = new PIXI.Sprite(bubbleState.resources.sea)
  const seaWidthRatio = getGameWidth() / sea.width
  sea.scale.set(seaWidthRatio, seaWidthRatio)
  sea.x = getGameWidth() / 2 - sea.width / 2
  sea.y = getGameHeight() - sea.height

  const ground = new PIXI.Sprite(bubbleState.resources.ground)
  const groundWidthRatio = getGameWidth() / ground.width
  ground.scale.set(groundWidthRatio, groundWidthRatio)
  ground.x = getGameWidth() / 2 - ground.width / 2
  ground.y = getGameHeight() - ground.height

  bubbleState.viewport.addChild(background, smallLightGreyClouds, smallLightGreyClouds2, smallWhiteClouds, smallWhiteClouds2, smallWhiteClouds3, sea, ground)
}

export const initNightBackground = () => {
  if (!bubbleState.viewport || !bubbleState.resources || !bubbleState.level) return

  const background = new Graphics()
    .beginFill(0x233656)
    .drawRect(0, 0, getGameWidth(), getGameHeight())
    .endFill()

  const ground = new PIXI.Sprite(bubbleState.resources.ground)
  const groundWidthRatio = getGameWidth() / ground.width
  ground.scale.set(groundWidthRatio, groundWidthRatio)
  ground.x = getGameWidth() / 2 - ground.width / 2
  ground.y = getGameHeight() - ground.height

  const stars = new Graphics()
    .beginFill(0xE7C900)

  for (let i = 0; i < 20; i++) {
    const x = (Math.random() * getGameWidth() - 50) + 50
    const y = (Math.random() * getGameHeight() - ground.height) + 50

    stars.drawCircle(x, y, Math.random() * 3 + 1)
  }

  stars.endFill()

  bubbleState.viewport.addChild(background, stars, ground)
}