import React, {useState} from 'react'
import {isAndroid, isIOS} from 'react-device-detect'
import {toast} from 'react-toastify'
import {formatClassName, isPhantomInstalled} from '../../../utils/global'
import {generatePKCE, getTwitterUser} from '../../api/twitter'
import {useHubContext} from '../../state/context'
import {hubState} from '../../state/hub'
import {ButtonIcon, ButtonProps} from '../buttons/button'
import styles from './twitter-link-button.module.scss'

const TWITTER_CLIENT_ID = 'U0tKWnhjMzEtMHlvLVVONl9KTmM6MTpjaQ'

const getTwitterOauthUrl = async () => {
  const result = await generatePKCE()

  if (!result) {
    toast.error('Impossible to connect with Twitter, please try again later or contact the support team')
    return
  }

  const {state, codeChallenge} = result

  const rootUrl = 'https://twitter.com/i/oauth2/authorize'
  const options = {
    redirect_uri: `${window.location.origin}/api/v1/twitter/oauth`,
    //redirect_uri: 'http://localhost:5001/pirate-squad-c583d/us-central1/twitter/api/v1/twitter/oauth',
    client_id: TWITTER_CLIENT_ID,
    state,
    response_type: 'code',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    scope: ['users.read', 'tweet.read', 'follows.read'].join(' ') // , 'offline.access'
  }
  const qs = new URLSearchParams(options).toString()
  return `${rootUrl}?${qs}`
}

const MAX_TRIES = 5
let tries = 1

let interval: NodeJS.Timer

type TwitterLinkButtonProps = Omit<ButtonProps, 'onclick'>

export const TwitterLinkButton = ({className, value, ...props}: TwitterLinkButtonProps) => {
  const {dispatch, state: {twitterUsername}} = useHubContext()
  const [linking, setLinking] = useState(false)
  const [cacheHref, setCacheHref] = useState<string>()
  const [numberOfTries, setNumberOfTries] = useState(1)

  const syncUsername = async () => {
    const data = await getTwitterUser()

    if (data?.twitterUsername) {
      setLinking(false)
      dispatch({
        type: 'SET_TWITTER_USERNAME',
        twitterUsername: data.twitterUsername
      })

      return data.twitterUsername
    }

    return
  }

  const openWindow = async () => {
    setLinking(true)
    dispatch({
      type: 'SET_TWITTER_USERNAME',
      twitterUsername: undefined
    })
    setNumberOfTries(1)
    tries = 1

    if ((isIOS || isAndroid) && isPhantomInstalled && hubState.showLoading) {
      hubState.showLoading(true)
    }

    let href
    if (cacheHref) {
      href = cacheHref
    } else {
      href = await getTwitterOauthUrl()

      if (!href) {
        setLinking(false)

        return
      }
    }

    if ((isIOS || isAndroid) && isPhantomInstalled) {
      window.location.replace(href)

      return
    }

    const openedWindow = window.open(
      href,
      'Pirate Squad - Twitter Link',
      'toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left=240,top=212'
    )

    if (openedWindow === null) {
      setLinking(false)

      if (!cacheHref) {
        toast.info('Please click again on the Twitter button (normal process if you have an adblock 😉)')
      }

      setCacheHref(href)
    } else {
      if (hubState.showLoading) hubState.showLoading(true, undefined, 'Linking Twitter, please wait... (can take few seconds)')

      setCacheHref(undefined)

      interval = setInterval(async () => {
        ++tries
        setNumberOfTries(tries)

        if (tries >= MAX_TRIES) {
          setLinking(false)
          clearInterval(interval)

          if (hubState.showLoading) hubState.showLoading(false)

          toast.error('Impossible to refresh the Twitter user, if you successfuly authorized the connection, please refresh this page and reconnect')
        } else {
          try {
            const twitterUsername = await syncUsername()

            if (twitterUsername) {
              clearInterval(interval)

              if (hubState.showLoading) hubState.showLoading(false)

              toast.success('Twitter successfuly connected to your account')
            }
          } catch (err) {
            console.error(err)
            setLinking(false)
            clearInterval(interval)

            if (hubState.showLoading) hubState.showLoading(false)

            toast.error('Impossible to refresh the Twitter user, if you successfuly authorized the connection, please refresh this page and reconnect')
          }
        }
      }, 10000)
    }
  }

  const openConfirm: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Are you sure you want to change the linked Twitter account?',
        onAccept: openWindow,
      })
    }
  }

  return <>
    {twitterUsername
      ? <ButtonIcon icon={['fab', 'twitter']} className={formatClassName(styles, `twitter-link-button no-color ${className}`)} onClick={openConfirm} disabled={linking} {...props}>
        <span>
          {
            linking
              ? `Please wait... (${numberOfTries}/${MAX_TRIES})`
              : value ?? twitterUsername
          }
        </span>
      </ButtonIcon>
      : <ButtonIcon icon={['fab', 'twitter']} className={formatClassName(styles, `twitter-link-button no-color ${className}`)} onClick={openWindow} disabled={linking} {...props}>
        <span>
          {
            linking
              ? `Please wait... (${numberOfTries}/${MAX_TRIES})`
              : value ?? 'Link Twitter'
          }
        </span>
      </ButtonIcon>}
  </>
}