import {MapSchema} from '@colyseus/schema'
import {PlayerSchema, startClassicGame} from 'pirate-squad-bubble'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import Countdown, {CountdownRendererFn} from 'react-countdown'
import {formatClassName} from '../../../../../../../../utils/global'
import {Button, ButtonIcon} from '../../../../../../../components/buttons/button'
import {useHubContext} from '../../../../../../../state/context'
import {hubState} from '../../../../../../../state/hub'
import {NewRoomAvailable} from './bubble-lobby'
import styles from './bubble-room-details.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({days, hours, minutes, seconds}) =>
  <span>
    {addZeroToNumber(hours + days * 24)}:{addZeroToNumber(minutes)}:{addZeroToNumber(seconds)}
  </span>

export type BubbleRoomDetailsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  roomAvailable: NewRoomAvailable
}

export const BubbleRoomDetails = ({roomAvailable, className, ...props}: BubbleRoomDetailsProps) => {
  const {state: {room, roomMetadata}, dispatch} = useHubContext()

  const [ready, setReady] = useState(false)
  const [players, setPlayers] = useState<PlayerSchema[]>([])
  const [showTimer, setShowTimer] = useState(false)
  const [endDate, setEndDate] = useState<number>()

  useEffect(() => {
    if (!room) return

    room.onError((code, message) => {
      if (hubState.showModal) {
        hubState.showModal({
          title: 'Error',
          text: `Message: ${message}, Code: ${code}`
        })
      }
    })

    room.onLeave((code) => {
      if (code === 1000) return

      if (hubState.showModal) {
        hubState.showModal({
          title: 'Disconnected',
          text: 'You have been disconnected from the server'
        })
      }

      dispatch({
        type: 'SET_ROOM'
      })

      dispatch({
        type: 'SET_ROOM_METADATA'
      })

      dispatch({
        type: 'SET_ROOM_SESSION'
      })
    })

    room.onMessage('players', async (message) => {
      const players = new MapSchema<PlayerSchema, string>(message)

      const array: PlayerSchema[] = []

      players.forEach((player) => array.push(player))

      setPlayers(array)
    })

    room.onMessage('start-game', async () => {
      await startClassicGame(room)
    })

    room.onMessage<{
      endDate: number
    }>('timer-started', async ({endDate}) => {
      setShowTimer(true)
      setEndDate(endDate)
    })

    room.onMessage('timer-stopped', async () => {
      setShowTimer(false)
    })

    room.send('players')

    if (hubState.refreshTokens) {
      hubState.refreshTokens()
    }

    setReady(false)
  }, [room])

  const sendReadyMessage = () => {
    if (!room) return

    room.send('ready', !ready)

    setReady(!ready)
  }

  const exitRoom = async () => {
    if (!room) return

    await room.leave(true)

    if (hubState.refreshTokens) {
      hubState.refreshTokens()
    }

    dispatch({
      type: 'SET_ROOM'
    })

    dispatch({
      type: 'SET_ROOM_METADATA'
    })

    dispatch({
      type: 'SET_ROOM_SESSION'
    })
  }

  return <>
    {
      room && <div className={formatClassName(styles, `bubble-room-details ${className}`)} {...props}>
        <div className={formatClassName(styles, 'room-info')}>
          <div>room name: {roomMetadata?.roomName} ({room.id})</div>
          <div>players: {players.length}/{roomAvailable.maxClients}</div>
        </div>

        <div className={formatClassName(styles, 'players')}>
          {
            players.map((player, index) =>
              <div className={formatClassName(styles, 'player')} key={`player-${index}`}>
                <div className={formatClassName(styles, 'player-name')}>{player.name}</div>
                <div className={formatClassName(styles, 'player-ready')}>{player.ready ? 'ready' : 'not ready'}</div>
              </div>
            )
          }
        </div>
        <div className={formatClassName(styles, 'timer')}>
          {showTimer
            ? <>
              <span>starting in <Countdown date={endDate} renderer={renderer} /></span>
            </>
            : <>
              {players.length === roomAvailable.maxClients ? 'waiting for players to be ready' : 'waiting for other players to join'}
            </>
          }
        </div>
        <div className={formatClassName(styles, 'actions')}>
          <ButtonIcon flip="horizontal" icon="right-from-bracket" className={formatClassName(styles, 'exit-button no-color')} onClick={exitRoom} >exit</ButtonIcon>
          <Button className={formatClassName(styles, `ready-button ${ready ? 'warning' : 'success'}`)} onClick={sendReadyMessage} >{ready ? 'NOT READY' : 'READY'}</Button>
        </div>
      </div>
    }
  </>
}