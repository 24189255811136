import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import {ArrowContainer, Popover as TinyPopover, PopoverPosition} from 'react-tiny-popover'
import {formatClassName} from '../../../utils/global'
import styles from './popover.module.scss'

export const PopoverHover = ({positions, popoverContent, children}: {positions?: PopoverPosition[], popoverContent: JSX.Element, children: JSX.Element}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const clone = React.cloneElement(children, {
    onMouseOver: () => setIsPopoverOpen(true),
    onMouseOut: () => setIsPopoverOpen(false)
  })

  return (
    <TinyPopover
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      containerClassName={formatClassName(styles, 'popover-container')}
      positions={positions}
      padding={5}
      boundaryInset={8}
      content={({position, childRect, popoverRect}) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'rgba(0, 0, 0, 0.75)'}
          arrowSize={5}
          className={formatClassName(styles, 'popover-arrow-container')}
          arrowClassName={formatClassName(styles, 'popover-arrow')}
        >
          <div className={formatClassName(styles, 'popover-content')}>
            {popoverContent}
          </div>
        </ArrowContainer>
      )}
    >
      {clone}
    </TinyPopover>
  )
}

export const PopoverHelp = ({children}: {children: JSX.Element}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <span className={formatClassName(styles, 'popover-help')}>
      <TinyPopover
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        containerClassName={formatClassName(styles, 'popover-container')}
        boundaryInset={8}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#3f3f3f'}
            arrowSize={5}
            className={formatClassName(styles, 'popover-arrow-container')}
            arrowClassName={formatClassName(styles, 'popover-arrow')}
          >
            <div className={formatClassName(styles, 'popover-content')}>
              {children}
            </div>
          </ArrowContainer>
        )}
      >
        <FontAwesomeIcon className={formatClassName(styles, 'popover-icon')} onClick={() => setIsPopoverOpen(!isPopoverOpen)} icon="circle-question" title="Help" />
      </TinyPopover>
    </span>
  )
}