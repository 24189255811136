import React from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {HubLayout} from './layouts/hub-layout'
import {WalletLayout} from './layouts/wallet-layout'
import {ABC} from './luck-games/abc/abc'
import {BaddieBeast} from './luck-games/baddie-beast/baddie-beast'
import {Bombz} from './luck-games/bombz/bombz'
import {Slots} from './luck-games/slots/slots'
import {DashboardPage} from './pages/dashboard/dashboard'
import {Games} from './pages/dashboard/sub-pages/games/games'
import {Player} from './pages/dashboard/sub-pages/games/player/player'
import {Home} from './pages/dashboard/sub-pages/home/home'
import {Profile} from './pages/dashboard/sub-pages/profile/profile'
import {RevShare} from './pages/dashboard/sub-pages/rev-share/rev-share'
import {Rewards} from './pages/dashboard/sub-pages/rewards/rewards'
import {HubProvider} from './state/context'

export const Hub = () =>
  <HubProvider>
    <BrowserRouter>
      <Routes>
        <Route element={<HubLayout />} >
          {/*<Route path="select-chain" element={<SelectChainPage />} />*/}

          <Route element={<WalletLayout />}>
            <Route path="dashboard" element={<DashboardPage />}>
              <Route path="games/slots" element={<Slots />} />
              <Route path="games/baddie-beast" element={<BaddieBeast />} />
              <Route path="games/bombz" element={<Bombz />} />
              <Route path="games/abc" element={<ABC />} />
              <Route path="games/:game" element={<Player />} />
              {/*<Route path="apps/reveal" element={<Reveal />} />
              <Route path="apps/reveal/:id" element={<RevealPlayer />} />*/}
              <Route path="home" element={<Home />} />
              <Route path="games" element={<Games />} />
              {/*<Route path="apps" element={<Apps />} />*/}
              <Route path="rewards" element={<Rewards />} />
              {/*<Route path="quests" element={<Quests />} />*/}
              <Route path="profile" element={<Profile />} />
              <Route path="rev-share" element={<RevShare />} />
              <Route path="*" element={<Navigate to="home" replace />} />
              <Route index element={<Navigate to="home" replace />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </HubProvider>