// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 1.0.44
// 

import {type} from '@colyseus/schema'
import {EntitySchema} from './EntitySchema'

export class MovingEntitySchema extends EntitySchema {
  @type('number') public dx!: number
  @type('number') public dy!: number
}
