import {DropShadowFilter} from 'pixi-filters'
import {Container, Graphics, Sprite} from 'pixi.js'
import {createViewport} from './reveal'
import {revealMessage, syncHistoryMessage, syncRevealMessage} from './server/message'
import {revealState} from './state'
import {RevealData} from './types'

export const initViewer = (revealData: RevealData) => {
  createViewport()

  if (!revealState.viewport || !revealState.room || !revealState.resources) return

  const container = new Container()

  container.x = -revealData.width / 2
  container.y = -revealData.height / 2

  container.filters = [
    new DropShadowFilter({
      distance: 0,
      blur: 10,
      alpha: 0.15,
      quality: 5,
      pixelSize: 0.5
    })
  ]

  container.sortableChildren = true

  revealState.references.image = container

  revealState.viewport.addChild(container)

  const logo = Sprite.from(revealState.resources['fullLogo'].texture!)

  const ratio = (revealData.width * 0.6) / logo.width

  logo.scale.set(ratio, ratio)
  logo.zIndex = 2
  logo.x = revealData.width / 2 - logo.width / 2
  logo.y = revealData.height / 2 - logo.height / 2
  logo.alpha = 0.1

  container.addChild(logo)

  syncHistoryMessage(revealState.room)
  syncRevealMessage(revealState.room)

  Object.values(revealData.chunks).forEach(({top, left, width, height, revealed}, index) => {
    if (revealed) return

    const rectangle = new Graphics()
      .lineStyle({
        width: 1,
        alpha: 0.05,
        alignment: 0
      })
      .beginFill(0xDFE4F4)
      .drawRoundedRect(left, top, width, height, 0)
      .endFill()

    rectangle.zIndex = 1
    rectangle.name = `c${index}`
    rectangle.interactive = true
    rectangle.cursor = 'pointer'

    rectangle.on('pointerover', () => {
      rectangle.tint = 0xefefef
    })
    rectangle.on('pointerdown', () => {
      rectangle.tint = 0xefefef
    })
    rectangle.on('pointerout', () => {
      rectangle.tint = 0xffffff
    })
    rectangle.on('pointerup', () => {
      if (revealState.dragged || !revealState.room) return

      rectangle.tint = 0xffffff

      revealMessage(revealState.room, index)
    })

    container.addChild(rectangle)
  })

  let width = revealState.viewport.worldWidth
  let height = revealState.viewport.worldHeight

  if (width <= revealState.references.image.width) {
    width += 50
  }

  if (height <= revealState.references.image.height) {
    height += 50
  }

  revealState.viewport.fit(undefined, width, height).moveCenter(0, 0)
}