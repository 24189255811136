import {sound} from '@pixi/sound'
import Decimal from 'decimal.js'
import FontFaceObserver from 'fontfaceobserver'
import gsap from 'gsap'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {Container} from 'pixi.js'
import {loadGameConfigRequest, loadGameStateRequest} from './api/game'
import {LevelStatus, PirateCharacter} from './enums'
import {initHomeScreen} from './homescreen'
import {initLevel, moveAndCheckCollide} from './level'
import {exitRoom} from './server/server-handler'
import {loadSounds} from './sound'
import {bubbleState} from './state'
import {State} from './types'
import {getGameHeight, getGameWidth} from './utils'
import {BUBBLE_CDN_URL} from './utils/constants'

/**
 * Initialize the game into a canvas DOM object
 * @param canvas The canvas DOM object to inject the game into
 * @returns The application object
 */
export const initBubbleGame = async (
  canvas: HTMLCanvasElement | undefined,
  config?: State['external'],
  onLoaderProgress?: (progress: number, exited?: boolean) => void
) => {
  if (!canvas) return

  bubbleState.external = {...config}
  bubbleState.exited = false

  //PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR
  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2

  const parent = canvas.parentElement!

  const app = new PIXI.Application({
    view: canvas,
    resolution: Math.max(2, window.devicePixelRatio),
    autoDensity: true,
    antialias: true,
    resizeTo: parent,
    backgroundAlpha: 0
  })

  bubbleState.app = app

  app.stage.interactive = true

  app.ticker.stop()

  const loader = PIXI.Assets

  const gsapTicker = () => {
    app.ticker.update()
  }

  // Now, we use 'tick' from gsap
  gsap.ticker.add(gsapTicker)

  bubbleState.external.clearGame = async () => {
    bubbleState.exited = true

    gsap.ticker.remove(gsapTicker)
    loader.unloadBundle('bubble')
    sound.removeAll()
    app.destroy()

    if (bubbleState.room) {
      await exitRoom()
    }

    bubbleState.external.clearGame = undefined
  }

  await loadStatusAndConfig()

  const rifficFont = new FontFaceObserver('Riffic')
  const balooThambiFont = new FontFaceObserver('Baloo Thambi')

  loader.addBundle('bubble', {
    cannonBase: BUBBLE_CDN_URL + '/cannons/cannon-one-part.png',
    pauseWhite: BUBBLE_CDN_URL + '/ui/in-game/pause-white.png',
    playBlack: BUBBLE_CDN_URL + '/ui/in-game/play-black.png',
    playYellow: BUBBLE_CDN_URL + '/ui/in-game/play-yellow.png',
    nextYellow: BUBBLE_CDN_URL + '/ui/in-game/next-yellow.png',
    soundBlack: BUBBLE_CDN_URL + '/ui/in-game/sound-black.png',
    retryBlack: BUBBLE_CDN_URL + '/ui/in-game/retry-black.png',
    homeBlack: BUBBLE_CDN_URL + '/ui/in-game/home-black.png',
    leaderboardYellow: BUBBLE_CDN_URL + '/ui/in-game/leaderboard-yellow.png',
    // Backgrounds
    whiteClouds: BUBBLE_CDN_URL + '/levels/clouds/white-clouds.png',
    whiteCloudsTwo: BUBBLE_CDN_URL + '/levels/clouds/white-clouds-two.png',
    smallWhiteClouds: BUBBLE_CDN_URL + '/levels/clouds/small-white-clouds.png',
    smallLightGreyClouds: BUBBLE_CDN_URL + '/levels/clouds/small-light-grey-clouds.png',
    ground: BUBBLE_CDN_URL + '/levels/ground.png',
    sun: BUBBLE_CDN_URL + '/levels/sun.png',
    sea: BUBBLE_CDN_URL + '/levels/sea/sea.png',
    seaTwo: BUBBLE_CDN_URL + '/levels/sea/sea-two.png',
    // Homescreen
    bubbleFlyingHS: BUBBLE_CDN_URL + '/ui/homescreen/bubble-flying.png',
    cannonHS: BUBBLE_CDN_URL + '/ui/homescreen/cannon.png',
    groundPartHS: BUBBLE_CDN_URL + '/ui/homescreen/ground-part.png',
    pirateHS: BUBBLE_CDN_URL + '/ui/homescreen/pirate.png',
    shipHS: BUBBLE_CDN_URL + '/ui/homescreen/ship.png',
    waterEffectHS: BUBBLE_CDN_URL + '/ui/homescreen/water-effect.png',
    playRed: BUBBLE_CDN_URL + '/ui/homescreen/play-red.png',
    // Worldscreen
    flagCross: BUBBLE_CDN_URL + '/ui/worldscreen/flag-cross.png',
    flagEmpty: BUBBLE_CDN_URL + '/ui/worldscreen/flag-empty.png',
    flagOneStar: BUBBLE_CDN_URL + '/ui/worldscreen/flag-one-star.png',
    flagTwoStars: BUBBLE_CDN_URL + '/ui/worldscreen/flag-two-stars.png',
    flagThreeStars: BUBBLE_CDN_URL + '/ui/worldscreen/flag-three-stars.png',
    chestCross: BUBBLE_CDN_URL + '/ui/worldscreen/chest-cross.png',
    chestEmpty: BUBBLE_CDN_URL + '/ui/worldscreen/chest-empty.png',
    chestOneStar: BUBBLE_CDN_URL + '/ui/worldscreen/chest-one-star.png',
    chestTwoStars: BUBBLE_CDN_URL + '/ui/worldscreen/chest-two-stars.png',
    chestThreeStars: BUBBLE_CDN_URL + '/ui/worldscreen/chest-three-stars.png',
    islandTree: BUBBLE_CDN_URL + '/ui/worldscreen/island-tree.png',
    island: BUBBLE_CDN_URL + '/ui/worldscreen/island.png',
    octopus: BUBBLE_CDN_URL + '/ui/worldscreen/octopus.png',
    shipSinking: BUBBLE_CDN_URL + '/ui/worldscreen/ship-sinking.png',
    shipWS: BUBBLE_CDN_URL + '/ui/worldscreen/ship.png',
    waterTyphon: BUBBLE_CDN_URL + '/ui/worldscreen/water-typhon.png',
    plus: BUBBLE_CDN_URL + '/ui/worldscreen/plus.png',
    energy: BUBBLE_CDN_URL + '/ui/worldscreen/energy-3d.png',
    lock: BUBBLE_CDN_URL + '/ui/worldscreen/lock.png',
    weapons: BUBBLE_CDN_URL + '/ui/worldscreen/weapons.png',
    settings: BUBBLE_CDN_URL + '/ui/worldscreen/settings.png',
    shop: BUBBLE_CDN_URL + '/ui/worldscreen/shop.png',
    trophy: BUBBLE_CDN_URL + '/ui/worldscreen/trophy.png',
    starWS: BUBBLE_CDN_URL + '/ui/worldscreen/star.png',
    // Effects
    waterDisplacement: BUBBLE_CDN_URL + '/effects/water-displacement.jpg',
    // Spine
    cannon: BUBBLE_CDN_URL + '/animations/cannons/Cannon.json',
    pirate: BUBBLE_CDN_URL + `/animations/pirates/${bubbleState.gameState?.pirateCharacter ?? PirateCharacter.PirateOne}.json`,
    bubble: BUBBLE_CDN_URL + '/animations/bubbles/Bubbles.json',
    heart: BUBBLE_CDN_URL + '/animations/heart/Heart.json',
    coin: BUBBLE_CDN_URL + '/animations/coin/Coin.json',
    star: BUBBLE_CDN_URL + '/animations/star/Star.json',
    threeStars: BUBBLE_CDN_URL + '/animations/three-stars/ThreeStars.json',
    powerUp: BUBBLE_CDN_URL + '/animations/power-up/PowerUp.json',
    explosion: BUBBLE_CDN_URL + '/animations/explosion/Explosion.json',
    // Particles
    fireParticle: BUBBLE_CDN_URL + '/particles/fire.png',
    circleParticle: BUBBLE_CDN_URL + '/particles/circle.png',
  })

  let resources

  try {
    if (onLoaderProgress) {
      onLoaderProgress(0)

      let soundProgress = 0
      let loaderProgress = 0

      const updateProgress = (newSoundProgress?: number, newLoaderProgress?: number) => {
        if (newSoundProgress !== undefined) {
          soundProgress = newSoundProgress
        }

        if (newLoaderProgress !== undefined) {
          loaderProgress = newLoaderProgress
        }

        onLoaderProgress(soundProgress * 0.5 + loaderProgress * 0.5, bubbleState.exited)
      }

      loadSounds((progress) => {
        updateProgress(progress)
      }, () => {
        updateProgress(100)
      })

      resources = await loader.loadBundle('bubble', (progress) => updateProgress(undefined, progress))

      updateProgress(undefined, 100)
    } else {
      loadSounds()
      resources = await loader.loadBundle('bubble')
    }
  } catch (err) {
    console.error(err)

    if (!bubbleState.exited && bubbleState.external.clearGame) {
      bubbleState.exited = true

      bubbleState.external.clearGame()

      if (bubbleState.external.showLoading) bubbleState.external.showLoading(false)

      if (bubbleState.external.showModal) bubbleState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the graphic resources, please check your internet connection and refresh the page'
      })
    }

    return
  }

  bubbleState.resources = resources

  Promise.all([
    rifficFont.load(),
    balooThambiFont.load()
  ]).then(() => {
    if (onLoaderProgress) onLoaderProgress(100)

    app.resize()

    initHomeScreen()

    //app.ticker.minFPS = 30
    //app.ticker.maxFPS = 60

    app.ticker.add((delta) => {
      const roundedDelta = new Decimal(delta).mul(10).round().div(10).toNumber()

      update(roundedDelta)
    })
  }).catch((err) => {
    console.error('loading failed', err)
  })

  return bubbleState.external.clearGame
}

export const createViewport = () => {
  if (!bubbleState.app || !bubbleState.resources) return

  if (bubbleState.viewport) {
    try {
      bubbleState.viewport.destroy()
    } catch (err) {
      console.error(err)
    }
  }

  bubbleState.app.stage.removeChildren()
  bubbleState.app.stage.removeAllListeners()

  const viewport = new Viewport({
    screenWidth: getGameWidth(),
    screenHeight: getGameHeight(),
    events: bubbleState.app.renderer.events
  })

  bubbleState.app.renderer.on('resize', () => {
    viewport.resize(getGameWidth(), getGameHeight())
  })

  viewport.sortableChildren = true

  bubbleState.app.stage.addChild(viewport)

  bubbleState.viewport = viewport

  // Particles

  const particlesContainer = new Container()

  particlesContainer.zIndex = 95

  bubbleState.particles.container = particlesContainer

  viewport.addChild(particlesContainer)

  // addWaterEffect(state.app.stage, new Sprite(state.resources.waterDisplacement))

  // addShockwaveEffect(viewport, new Point(getGameWidth() / 2, getGameHeight() / 2), undefined, {duration: 5})
}

/**
 * Update the state of the game
 * @param delta The delta (time passed since previous update)
 */
const update = (delta: number) => {
  updateEmitters(delta)

  if (!bubbleState.level || !bubbleState.references.tiles || bubbleState.level.state.paused || bubbleState.level.state.ended) return

  if (bubbleState.references.bubbleFired && bubbleState.references.tiles) {
    moveAndCheckCollide(delta)
  }

  //updatePowerUp(delta)
}

const updateEmitters = (delta: number) => {
  bubbleState.particles.emitters?.forEach(emitter => {
    emitter.update(delta * 0.01)
  })
}

export const goToNextLevel = () => {
  if (bubbleState.gameState?.actualLevel === undefined || !bubbleState.levels) return

  if (nextLevelAccessible()) {
    initLevel(bubbleState.levels[++bubbleState.gameState.actualLevel])
  }
}

export const nextLevelAccessible = () => {
  const actualLevel = bubbleState.gameState?.actualLevel

  return (actualLevel !== undefined && ![LevelStatus.Unavailable, LevelStatus.Locked, undefined].includes(bubbleState.gameState?.levelStatuses[actualLevel + 1].status))
}

const loadStatusAndConfig = async () => {
  bubbleState.gameState = await loadGameStateRequest()

  const config = await loadGameConfigRequest()

  if (config) {
    bubbleState.levels = config.levels
  }

  //changePirate(bubbleState.gameState?.pirateCharacter ?? PirateCharacter.PirateOne)
}